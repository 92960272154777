import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";

import Heading from "@emcm-ui/component-heading";
import getClassNameFactory from "@emcm-ui/utility-class-names";

const PageHeadingWrapper = ({
  boldOrange,
  compact,
  description,
  heading,
  large
}) => {
  const getClassName = getClassNameFactory(PageHeadingWrapper.displayName);

  return (
    <h1 className={getClassName({ modifiers: classNames({ compact, large }) })}>
      <span className={getClassName({ descendantName: "heading" })}>
        <Heading type="xl" boldOrange={boldOrange}>
          {heading}
        </Heading>
      </span>
      <span className={getClassName({ descendantName: "description" })}>
        {description}
      </span>
    </h1>
  );
};

PageHeadingWrapper.displayName = "PageHeadingWrapper";

PageHeadingWrapper.propTypes = {
  /**
   * Set color of inline `<b />` and `<strong />` elements in heading to
   * dark orange.
   */
  boldOrange: PropTypes.bool,
  /**
   * Compact description variant modifier
   */
  compact: PropTypes.bool,
  /**
   * Text or inline markup only
   */
  description: PropTypes.node.isRequired,
  /**
   * Text or inline markup only
   */
  heading: PropTypes.node.isRequired,
  /**
   * Large description variant modifier
   */
  large: PropTypes.bool
};

export default PageHeadingWrapper;
