import React from "react";
import { storiesOf } from "@storybook/react";
import { withKnobs, select, number } from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";
import Grid from "@emcm-ui/component-grid";
import { Language } from "@emcm-ui/component-theme";
import TinyTile from "./index";
import "./TinyTile.css";
import TinyTileREADME from "../README.md";
import Icon from "@emcm-ui/component-icon";

const stories = storiesOf("Components/TinyTile", module);

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(TinyTileREADME));

const supportedGridVariants = ["even-3", "even-4", "even-6"];

const initialNumTiles = 6;

const tileIcons = [
  "./icons/nucleo/business-finance/small/agenda.svg",
  "./icons/nucleo/business-finance/small/building.svg",
  "./icons/nucleo/business-finance/small/chart-bar-growth.svg",
  "./icons/nucleo/business-finance/small/chart-pie-2.svg",
  "./icons/nucleo/business-finance/small/signature.svg",
  "./icons/nucleo/business-finance/small/coins.svg"
];

stories
  .add("Default", () => (
    <Grid
      variant={select("Grid variant", supportedGridVariants, "even-6")}
      gutterless
    >
      {[...Array(number("Number of tiles", initialNumTiles)).keys()].map(i => {
        return (
          <Grid.Item key={i}>
            <TinyTile
              title={`Link #${i + 1}`}
              href="https://example.com"
              icon={<Icon path={tileIcons[i]} size="s" />}
            />
          </Grid.Item>
        );
      })}
    </Grid>
  ))
  .add("Model", () => (
    <Grid
      variant={select("Grid variant", supportedGridVariants, "even-6")}
      gutterless
    >
      {[...Array(number("Number of tiles", initialNumTiles)).keys()].map(i => {
        return (
          <Grid.Item key={i}>
            <TinyTile
              title={`Link #${i + 1}`}
              icon={<Icon path={tileIcons[i]} size="s" />}
              model={{
                contentLocation:
                  "https://www.mocky.io/v2/5b891b8a3000006e0c338312",
                modalLabel: "A bunch of text"
              }}
            />
          </Grid.Item>
        );
      })}
    </Grid>
  ))
  .add("Language: Arabic (RTL)", () => (
    <Language code="ar">
      <Grid
        variant={select("Grid variant", supportedGridVariants, "even-6")}
        gutterless
      >
        {[...Array(number("Number of tiles", initialNumTiles)).keys()].map(
          i => {
            return (
              <Grid.Item key={i}>
                <TinyTile
                  title={`مرحبا بالعالم #${i + 1}`}
                  href="https://example.com"
                  icon={<Icon path={tileIcons[i]} size="s" />}
                />
              </Grid.Item>
            );
          }
        )}
      </Grid>
    </Language>
  ));
