import React from "react";
import { storiesOf } from "@storybook/react";
import { withKnobs } from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";
import Anchor from "./index";
import AnchorREADME from "../README.md";
import { options } from "@emcm-ui/bundle-emcm/src/reactFromMarkup";

const stories = storiesOf("Components/Anchor", module);

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(AnchorREADME));

stories
  .add("Default", () => (
    <Anchor
      className="tr-Temp"
      href="https://example.com"
      ariaLabel="normal link"
    >
      normal link
    </Anchor>
  ))
  .add("open Modal", () => (
    <Anchor
      className="tr-Temp"
      href="https://example.com"
      contentLocation="https://www.mocky.io/v2/5b8527613000008100729143"
      reactFromMarkupRehydrators={options.extra.rehydrators}
      reactFromMarkupOptions={options}
    >
      Click me to open a modal
    </Anchor>
  ));
