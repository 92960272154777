import PropTypes from "prop-types";
import React from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import getRehydratableName from "@emcm-ui/utility-rehydratable-name";

import classNames from "classnames";

const Item = ({ active, href, title, datasets }) => {
  const getClassName = getClassNameFactory("PageNavItem");
  const getDatasets = datasets ? JSON.parse(datasets) : null;

  return (
    <li
      className={getClassName({ states: classNames({ active }) })}
      data-active={active ? JSON.stringify(active) : null}
      aria-current={active ? "true" : "false"}
      data-href={JSON.stringify(href)}
      data-title={JSON.stringify(title)}
      data-rehydratable={getRehydratableName(Item.displayName)}
    >
      <a
        {...getDatasets}
        className={getClassName({ descendantName: "inner" })}
        href={href}
      >
        <span className={getClassName({ descendantName: "title" })}>
          {title}
        </span>
      </a>
    </li>
  );
};

Item.displayName = "PageNav.Item";

Item.propTypes = {
  /**
   * Set this item to active. Use for setting the default item, or defining the current item if not using PageNav's `spy` functionality. Will be overwritten when using `spy`.
   */
  active: PropTypes.bool,
  /**
   * URL for the item. Should be an anchor if using PageNav on a single page (such as with `spy`) or a URL if not. If using on multiple pages, you will need to set the `active` Item manually.
   */
  href: PropTypes.string.isRequired,
  /**
   * Title of the item.
   */
  title: PropTypes.string.isRequired,
  /**
   * Pass custom classes, attributes for analytics.
   */
  datasets: PropTypes.string
};

export default Item;
