import React from "react";

import Attribution from "@emcm-ui/component-attribution";
import BlockQuote from "@emcm-ui/component-block-quote";
import Button from "@emcm-ui/component-button";
import DataCallout from "@emcm-ui/component-data-callout";
import Expandable from "@emcm-ui/component-expandable";
import Eyebrow from "@emcm-ui/component-eyebrow";
import Grid from "@emcm-ui/component-grid";
import Group from "@emcm-ui/component-group";
import Header from "@emcm-ui/component-header";
import Heading from "@emcm-ui/component-heading";
import Link from "@emcm-ui/component-link";
import MarkupContainer from "@emcm-ui/component-markup-container";
import PageNav from "@emcm-ui/component-page-nav";
import Panel from "@emcm-ui/component-panel";
import Poster from "@emcm-ui/component-poster";
import RichText from "@emcm-ui/component-rich-text";
import Section, { SectionBridge } from "@emcm-ui/component-section";
import SemanticFooter from "@emcm-ui/component-semantic-footer";
import SemanticMain from "@emcm-ui/component-semantic-main";
import SiteFooter from "@emcm-ui/component-site-footer";
import SocialDropdown from "@emcm-ui/component-social-dropdown";
import SocialShare from "@emcm-ui/component-social-share";
import Tabs from "@emcm-ui/component-tabs";
import VerticalSpacing from "@emcm-ui/component-vertical-spacing";
import { SVGIcon } from "@emcm-ui/component-icon/lib/svg";
import { getSocialIcon } from "../../sampleAssets/icons";

import SampleGlobalFooter from "../../fragments/footer/SampleGlobalFooter";
import SampleHeader, { SampleGlobalHeaderTax } from "../../fragments/header";
import SampleSchemaOrganization from "../../fragments/schema-organization";

import Icon from "@emcm-ui/component-icon";

import billboardOne from "../../sampleAssets/billboards/billboard-one-1440.jpg";
import billboardThree from "../../sampleAssets/billboards/billboard-three-1440.jpg";
import heroEleven from "../../sampleAssets/heroes/hero-eleven-1440.jpg";

const Icons = {
  arrowRightBlue: "./icons/ui/blue/arrow-right.svg",
  arrowRightWhite: "./icons/ui/white/arrow-right.svg",

  facebook: "./icons/social/grey/facebook-07.svg",
  linkedin: "./icons/social/grey/linkedin-07.svg",
  twitter: "./icons/social/grey/twitter-07.svg",
  email: "./icons/ui/grey/email-07.svg"
};

const playtriangleIcon = (
  <svg
    fill="currentColor"
    viewBox="0 0 32 32"
    className="tr-Icon tr-Icon--s"
    xmlns="https://www.w3.org/2000/svg"
    xmlnsXlink="https://www.w3.org/1999/xlink"
  >
    <defs />
    <defs>
      <path d="M29 16L3 30V2z" id="a" />
    </defs>
    <use xlinkHref="#a" fillRule="evenodd" />
  </svg>
);

const EventDetail = () => {
  return (
    <div>
      <SampleSchemaOrganization />
      <SampleHeader
        globalHeader={<SampleGlobalHeaderTax />}
        siteName="Tax & Accounting"
      />

      <Section background="grayDark" fullWidth>
        <Poster height="l" imageSrc={heroEleven}>
          <Grid variant="7/12,5/12" gutterlessAtNarrow>
            <Grid.Item>
              <VerticalSpacing size="xs">
                <Eyebrow text="Tradeshow" />
              </VerticalSpacing>
              <VerticalSpacing>
                <Heading type="xl" rank="1">
                  <strong>SYNERGY 2017 Professional Conference</strong>
                </Heading>
              </VerticalSpacing>
              <VerticalSpacing size="l">
                <RichText compact>
                  <p>
                    Today is the most exciting time to be a tax professional.
                    That’s because tax is moving to the forefront of business.
                    And now, so can you.
                  </p>
                </RichText>
              </VerticalSpacing>
              <Group>
                <Group.Item>
                  <Button
                    color="primary"
                    kind="link"
                    href="https://example.com"
                  >
                    Register now
                  </Button>
                </Group.Item>
                <Group.Item>
                  <Button
                    color="secondaryDark"
                    href="https://example.com"
                    icon={playtriangleIcon}
                    kind="link"
                  >
                    Watch how it’s done
                  </Button>
                </Group.Item>
              </Group>
            </Grid.Item>
          </Grid>
        </Poster>
      </Section>
      <MarkupContainer sticky>
        <PageNav
          share={
            <SocialDropdown>
              <SocialDropdown.Item>
                <SocialShare
                  pageUrl="https://www.thomsonreuters.com"
                  type="facebook"
                >
                  <SVGIcon
                    name="facebook"
                    size="s"
                    getExternalIconPath={getSocialIcon}
                  />
                </SocialShare>
              </SocialDropdown.Item>
              <SocialDropdown.Item>
                <SocialShare
                  pageTitle="Thomson Reuters: The Answer Company"
                  pageUrl="https://www.thomsonreuters.com"
                  type="twitter"
                >
                  <SVGIcon
                    name="twitter"
                    size="s"
                    getExternalIconPath={getSocialIcon}
                  />
                </SocialShare>
              </SocialDropdown.Item>
              <SocialDropdown.Item>
                <SocialShare
                  pageTitle="Thomson Reuters: The Answer Company"
                  pageUrl="https://www.thomsonreuters.com"
                  type="linkedIn"
                >
                  <SVGIcon
                    name="linkedin"
                    size="s"
                    getExternalIconPath={getSocialIcon}
                  />
                </SocialShare>
              </SocialDropdown.Item>
              <SocialDropdown.Item>
                <SocialShare
                  pageTitle="Thomson Reuters: The Answer Company"
                  pageUrl="https://www.thomsonreuters.com"
                  type="email"
                >
                  <SVGIcon
                    name="email"
                    size="s"
                    getExternalIconPath={getSocialIcon}
                  />
                </SocialShare>
              </SocialDropdown.Item>
            </SocialDropdown>
          }
        >
          <PageNav.Item href="#" title="Overview" />
          <PageNav.Item href="#speakers" title="Speakers" />
          <PageNav.Item href="#agenda" title="Agenda" />
          <PageNav.Item href="#pricing" title="Pricing" />
          <PageNav.Item href="#faq" title="FAQ" />
        </PageNav>
      </MarkupContainer>
      <SemanticMain>
        <Section>
          <VerticalSpacing>
            <Heading rank="2" type="l">
              What’s <strong>happening</strong>
            </Heading>
          </VerticalSpacing>
          <Grid variant="2/3,1/3">
            <Grid.Item>
              <RichText large>
                <p>
                  You are invited to join us in Nashville for SYNERGY, the
                  Thomson Reuters users’ conference for tax and accounting
                  professionals. It’s a fantastic opportunity to learn, network,
                  socialize and experience Southern hospitality at its finest.
                  You’ll be able to attend workshops and learn from the best and
                  brightest in tax and accounting — plus you’ll spend four days
                  earning CPE credit, getting hands-on training, learning about
                  technology updates and being inspired by nationally recognized
                  accounting, technology and motivational experts.
                </p>
              </RichText>
            </Grid.Item>
            <Grid.Item>
              <VerticalSpacing>
                <Heading rank="2" type="small-caps">
                  Details
                </Heading>
              </VerticalSpacing>
            </Grid.Item>
          </Grid>
        </Section>
        <SectionBridge>
          <PageNav.Anchor id="speakers" />
          <DataCallout borderAndBackground>
            <DataCallout.Item value="50+" description="Expert speakers" />
            <DataCallout.Item value="200+" description="Training sessions" />
            <DataCallout.Item
              value="50"
              description="Tax and accounting experts"
            />
            <DataCallout.Item value="17" description="CPE credits" />
          </DataCallout>
        </SectionBridge>
        <Section background="grayLightest">
          <Header
            align="center"
            heading={
              <Heading rank="2" type="l">
                Meet out <strong>keynote speakers</strong>
              </Heading>
            }
          />
        </Section>
        <Section background="grayDark" fullWidth>
          <Poster height="m" imageSrc={billboardOne} imageInlineAtNarrow>
            <Grid variant="even-2" gutterlessAtNarrow>
              <Grid.Item>
                <VerticalSpacing size="xs">
                  <Eyebrow text="Location" />
                </VerticalSpacing>
                <VerticalSpacing size="xs">
                  <Heading type="l">
                    Discover the <strong>Gaylord Opryland Resort</strong> for
                    SYNERGY conference
                  </Heading>
                </VerticalSpacing>
                <VerticalSpacing size="l">
                  <RichText>
                    <p>
                      Book your room online or call the hotel directly at{" "}
                      <a href="tel:1-877-382-7299">1-877-382-7299</a>. Mention
                      Thomson Reuters for a discounted rate (Code: TR1).
                    </p>
                  </RichText>
                </VerticalSpacing>
                <Link
                  href="https://example.com"
                  type="icon"
                  icon={<Icon path={Icons.arrowRightWhite} size="s" />}
                  iconPosition={"right"}
                >
                  Explore the resort
                </Link>
              </Grid.Item>
            </Grid>
          </Poster>
          <PageNav.Anchor id="agenda" />
        </Section>
        <Section>
          <VerticalSpacing size="xl">
            <Header
              heading={
                <Heading rank="2" type="l">
                  <strong>Our schedule</strong> at a galance
                </Heading>
              }
              callToAction={
                <Link
                  href="https://example.com"
                  type="icon"
                  icon={<Icon path={Icons.arrowRightBlue} size="s" />}
                  iconPosition={"right"}
                >
                  <strong>Already registered? View schedule</strong>
                </Link>
              }
            />
          </VerticalSpacing>
          <Tabs layoutVariant="verticalAtDesktop">
            <Tabs.Panel id="tab1" tabTitle="Day 1 — Wednesday">
              <VerticalSpacing>
                <Heading rank="3" type="m">
                  <strong>Wednesday, November 8</strong>
                </Heading>
              </VerticalSpacing>
              <RichText large>
                Welcome to day one. Check out our pre-conference workshops and
                join us at the reception to connect and reconnect with your
                peers over cocktails and hor d’oeuvres.
              </RichText>
            </Tabs.Panel>
            <Tabs.Panel id="tab2" tabTitle="Day 2 — Thursday">
              <VerticalSpacing>
                <Heading rank="3" type="m">
                  <strong>Thursday, November 9</strong>
                </Heading>
              </VerticalSpacing>
              <RichText large>
                Welcome to day one. Check out our pre-conference workshops and
                join us at the reception to connect and reconnect with your
                peers over cocktails and hor d’oeuvres.
              </RichText>
            </Tabs.Panel>
            <Tabs.Panel id="tab3" tabTitle="Day 3 — Friday">
              <VerticalSpacing>
                <Heading rank="3" type="m">
                  <strong>Friday, November 10</strong>
                </Heading>
              </VerticalSpacing>
              <RichText large>
                Welcome to day one. Check out our pre-conference workshops and
                join us at the reception to connect and reconnect with your
                peers over cocktails and hor d’oeuvres.
              </RichText>
            </Tabs.Panel>
            <Tabs.Panel id="tab4" tabTitle="Day 4 — Saturday">
              <VerticalSpacing>
                <Heading rank="3" type="m">
                  <strong>Saturday, November 11</strong>
                </Heading>
              </VerticalSpacing>
              <RichText large>
                Welcome to day one. Check out our pre-conference workshops and
                join us at the reception to connect and reconnect with your
                peers over cocktails and hor d’oeuvres.
              </RichText>
            </Tabs.Panel>
          </Tabs>
        </Section>
        <Section background="grayDark" fullWidth>
          <Poster height="m" imageSrc={billboardThree}>
            <Grid variant="even-2" gutterlessAtNarrow>
              <Grid.Item>
                <VerticalSpacing size="xs">
                  <Eyebrow text="Special event" />
                </VerticalSpacing>
                <VerticalSpacing size="xs">
                  <Heading type="l">
                    Catch a live performance by celebrated country pop duo,{" "}
                    <strong>LOCASH</strong>
                  </Heading>
                </VerticalSpacing>
                <VerticalSpacing size="l">
                  <RichText>
                    <p>Friday, November 10 @ Wildhorse Saloon • 8 pm</p>
                  </RichText>
                </VerticalSpacing>
                <Link
                  href="https://not-visited.com"
                  type="icon"
                  icon={<Icon path={Icons.arrowRightWhite} size="s" />}
                  iconPosition={"right"}
                >
                  Learn more
                </Link>
              </Grid.Item>
            </Grid>
          </Poster>
        </Section>
        <Section background="grayLightest">
          <VerticalSpacing size="xl">
            <Heading align="center" rank="2" type="l">
              What <strong>attendees are saying</strong>
            </Heading>
          </VerticalSpacing>
          <Grid gutterBorder variant="even-3">
            <Grid.Item>
              <BlockQuote
                attribution={<Attribution caption="KNEG" name="Scott N." />}
                small
                text="The most valuable takeaway from SYNERGY is bringing back ideas and concepts to the office that will make us more effective."
              />
            </Grid.Item>
            <Grid.Item>
              <BlockQuote
                attribution={<Attribution caption="CPA" name="Amalie G." />}
                small
                text="Jon Baron’s keynote speech at SYNERGY every year is just outstanding. His knowledge of statistics and how we apply it to the products is one of the highlights of the conference."
              />
            </Grid.Item>
            <Grid.Item>
              <BlockQuote
                attribution={<Attribution caption="FMA" name="Mark M." />}
                cite="https://not-visited.com"
                small
                text="We’ve implemented a lot of new practices based on things I’ve learned at SYNERGY."
              />
            </Grid.Item>
          </Grid>
          <PageNav.Anchor id="pricing" />
        </Section>
        <SectionBridge backgroundBottom="white" backgroundTop="grayLightest">
          <Panel>CEC</Panel>
          <PageNav.Anchor id="faqs" />
        </SectionBridge>
        <Section>
          <VerticalSpacing size="xl">
            <Header
              heading={
                <Heading rank="2" type="l">
                  Frequently asked <strong>questions</strong>
                </Heading>
              }
              callToAction={
                <Link
                  href="https://example.com"
                  type="icon"
                  icon={<Icon path={Icons.arrowRightBlue} size="s" />}
                  iconPosition={"right"}
                >
                  <strong>Still have questions? Contact us</strong>
                </Link>
              }
            />
          </VerticalSpacing>
          <Expandable.List>
            <Expandable.Item
              isExpanded={true}
              header={props => (
                <Expandable.Item.Header {...props}>
                  <strong>Registration</strong>
                </Expandable.Item.Header>
              )}
            >
              <VerticalSpacing size="xs">
                <Heading rank="3" type="xs">
                  <strong>How do I register?</strong>
                </Heading>
              </VerticalSpacing>
              <VerticalSpacing size="l">
                <RichText large>
                  <p>
                    Click on the ‘Register for SYNERGY’ button and complete the
                    registration form.
                  </p>
                </RichText>
              </VerticalSpacing>
              <VerticalSpacing size="xs">
                <Heading rank="3" type="xs">
                  <strong>What is the cancellation and refund policy?</strong>
                </Heading>
              </VerticalSpacing>
              <VerticalSpacing size="l">
                <RichText large>
                  <p>
                    A full refund will be issued if registration is cancelled on
                    or before October 10, 2017. Email{" "}
                    <a href="mailto:cs.service@tr.com">cs.service@tr.com</a> to
                    cancel.
                  </p>
                </RichText>
              </VerticalSpacing>
              <VerticalSpacing size="xs">
                <Heading rank="3" type="xs">
                  <strong>What workshops are available?</strong>
                </Heading>
              </VerticalSpacing>
              <VerticalSpacing size="l">
                <RichText large>
                  <p>
                    You can view all available workshops{" "}
                    <a href="https://no-visited.com">here</a>.
                  </p>
                </RichText>
              </VerticalSpacing>
            </Expandable.Item>
            <Expandable.Item
              header={props => (
                <Expandable.Item.Header {...props}>
                  <strong>Hotel</strong>
                </Expandable.Item.Header>
              )}
            >
              Lorem ipsum
            </Expandable.Item>
            <Expandable.Item
              header={props => (
                <Expandable.Item.Header {...props}>
                  <strong>Travel</strong>
                </Expandable.Item.Header>
              )}
            >
              Lorem ipsum
            </Expandable.Item>
            <Expandable.Item
              header={props => (
                <Expandable.Item.Header {...props}>
                  <strong>Agenda &amp; workshops</strong>
                </Expandable.Item.Header>
              )}
            >
              Lorem ipsum
            </Expandable.Item>
            <Expandable.Item
              header={props => (
                <Expandable.Item.Header {...props}>
                  <strong>Friday night event</strong>
                </Expandable.Item.Header>
              )}
            >
              Lorem ipsum
            </Expandable.Item>
            <Expandable.Item
              header={props => (
                <Expandable.Item.Header {...props}>
                  <strong>Miscellaneous</strong>
                </Expandable.Item.Header>
              )}
            >
              Lorem ipsum
            </Expandable.Item>
          </Expandable.List>
        </Section>
      </SemanticMain>

      <SemanticFooter>
        <MarkupContainer>
          <SiteFooter
            searchFormAction="/"
            searchFormMethod="get"
            typeaheadUrl="//content.atomz.com/autocomplete/sp10/05/0d/51?_=1506335725065"
          >
            <SiteFooter.NavList title="Products & Services">
              <SiteFooter.NavItem title="For accounting firms" href="#" />
              <SiteFooter.NavItem title="For corporations" href="#" />
              <SiteFooter.NavItem title="For governments" href="#" />
              <SiteFooter.NavItem title="Explore our brands" href="#" />
              <SiteFooter.NavItem title="All products" href="#" />
            </SiteFooter.NavList>
            <SiteFooter.NavList title="Resources">
              <SiteFooter.NavItem title="Insights" href="#" />
              <SiteFooter.NavItem title="Community" href="#" />
              <SiteFooter.NavItem title="Social media" href="#" />
              <SiteFooter.NavItem title="Press releases" href="#" />
            </SiteFooter.NavList>
            <SiteFooter.NavList title="Useful Links">
              <SiteFooter.NavItem title="About us" href="#" />
              <SiteFooter.NavItem title="Contact us" href="#" />
              <SiteFooter.NavItem title="Find your account manager" href="#" />
              <SiteFooter.NavItem title="Your accounts" href="#" />
            </SiteFooter.NavList>
            <SiteFooter.NavList title="Shop">
              <SiteFooter.NavItem title="Store" href="#" />
              <SiteFooter.NavItem title="Store FAQ" href="#" />
              <SiteFooter.NavItem
                title="Payment, shipping, and returns"
                href="#"
              />
            </SiteFooter.NavList>
          </SiteFooter>
        </MarkupContainer>
        <SampleGlobalFooter />
      </SemanticFooter>
    </div>
  );
};

export default EventDetail;
