import React from "react";

import { ArticleCardFooter, PairedCard } from "@emcm-ui/component-card";
import Alignment from "@emcm-ui/component-alignment";
import Attribution from "@emcm-ui/component-attribution";
import Block from "@emcm-ui/component-block";
import BlockQuote from "@emcm-ui/component-block-quote";
import Breadcrumb from "@emcm-ui/component-breadcrumb";
import Button from "@emcm-ui/component-button";
import Duration from "@emcm-ui/component-duration";
import Grid from "@emcm-ui/component-grid";
import Group from "@emcm-ui/component-group";
import Header from "@emcm-ui/component-header";
import Heading from "@emcm-ui/component-heading";
import Image from "@emcm-ui/component-image";
import Avatar from "@emcm-ui/component-avatar";
import Link from "@emcm-ui/component-link";
import ListOfLinks from "@emcm-ui/component-list-of-links";
import MarkupContainer from "@emcm-ui/component-markup-container";
import PageNav from "@emcm-ui/component-page-nav";
import Poster from "@emcm-ui/component-poster";
import RichText from "@emcm-ui/component-rich-text";
import Section from "@emcm-ui/component-section";
import SemanticFooter from "@emcm-ui/component-semantic-footer";
import SemanticMain from "@emcm-ui/component-semantic-main";
import SiteFooter from "@emcm-ui/component-site-footer";
import SocialDropdown from "@emcm-ui/component-social-dropdown";
import SocialShare from "@emcm-ui/component-social-share";
import Tile from "@emcm-ui/component-tile";
import VerticalSpacing from "@emcm-ui/component-vertical-spacing";
import VideoPlayer from "@emcm-ui/component-video-player";
import { SVGIcon } from "@emcm-ui/component-icon/lib/svg";
import { getSocialIcon } from "../../sampleAssets/icons";

import Icon from "@emcm-ui/component-icon";

import SampleGlobalFooter from "../../fragments/footer/SampleGlobalFooter";
import SampleHeader, { SampleGlobalHeaderTax } from "../../fragments/header";
import SampleSchemaOrganization from "../../fragments/schema-organization";

import articleHeadshot from "../../sampleAssets/Article-headshot.jpg";
import imgLarge2 from "../../sampleAssets/large_2.1x.jpg";

import blockchainCardOne288 from "../../sampleAssets/blockchain/blockchain-card-one-288.jpg";
import blockchainCardOne368 from "../../sampleAssets/blockchain/blockchain-card-one-368.jpg";
import blockchainCardOne576 from "../../sampleAssets/blockchain/blockchain-card-one-576.jpg";
import blockchainCardOne768 from "../../sampleAssets/blockchain/blockchain-card-one-768.jpg";
import blockchainCardOne1168 from "../../sampleAssets/blockchain/blockchain-card-one-1168.jpg";
import blockchainCardOne1536 from "../../sampleAssets/blockchain/blockchain-card-one-1536.jpg";

import blockchainCardTwo288 from "../../sampleAssets/blockchain/blockchain-card-two-288.jpg";
import blockchainCardTwo368 from "../../sampleAssets/blockchain/blockchain-card-two-368.jpg";
import blockchainCardTwo576 from "../../sampleAssets/blockchain/blockchain-card-two-576.jpg";
import blockchainCardTwo768 from "../../sampleAssets/blockchain/blockchain-card-two-768.jpg";
import blockchainCardTwo1168 from "../../sampleAssets/blockchain/blockchain-card-two-1168.jpg";
import blockchainCardTwo1536 from "../../sampleAssets/blockchain/blockchain-card-two-1536.jpg";

import blockchainCardThree288 from "../../sampleAssets/blockchain/blockchain-card-three-288.jpg";
import blockchainCardThree368 from "../../sampleAssets/blockchain/blockchain-card-three-368.jpg";
import blockchainCardThree576 from "../../sampleAssets/blockchain/blockchain-card-three-576.jpg";
import blockchainCardThree768 from "../../sampleAssets/blockchain/blockchain-card-three-768.jpg";
import blockchainCardThree1168 from "../../sampleAssets/blockchain/blockchain-card-three-1168.jpg";
import blockchainCardThree1536 from "../../sampleAssets/blockchain/blockchain-card-three-1536.jpg";

const Icons = {
  arrowRightBlue: "./icons/ui/blue/arrow-right.svg",
  arrowRightWhite: "./icons/ui/white/arrow-right.svg",
  time: "./icons/ui/grey/time-07.svg",
  timeWhite: "./icons/ui/white/time.svg",

  facebook: "./icons/social/grey/facebook-07.svg",
  linkedin: "./icons/social/grey/linkedin-07.svg",
  twitter: "./icons/social/grey/twitter-07.svg",
  email: "./icons/ui/grey/email-07.svg",

  transform2d: "./icons/nucleo/design-development/large/transform-2d.svg"
};

const cartIcon = (
  <svg
    fill="currentColor"
    viewBox="0 0 32 32"
    className="tr-Icon tr-Icon--s"
    xmlns="https://www.w3.org/2000/svg"
    xmlnsXlink="https://www.w3.org/1999/xlink"
  >
    <defs />
    <defs>
      <path
        d="M7.45 3l1.34 5.01h18.89a2 2 0 011.93 2.52l-2.68 9.99A2 2 0 0125 22H12a2 2 0 01-1.92-1.44L5.93 5H3.17C2.58 5 2 4.59 2 4c0-.59.58-1 1.17-1h4.28zm20.23 7H9.32L12 20h13l2.68-10zM12 24a3 3 0 110 6 3 3 0 010-6zm0 2a1 1 0 100 2 1 1 0 000-2zm14-2a3 3 0 110 6 3 3 0 010-6zm0 2a1 1 0 100 2 1 1 0 000-2z"
        id="a"
      />
    </defs>
    <use xlinkHref="#a" fillRule="evenodd" />
  </svg>
);

const ProductDetailPage = () => {
  return (
    <div>
      <SampleSchemaOrganization />
      <SampleHeader
        globalHeader={<SampleGlobalHeaderTax />}
        siteName="Tax & Accounting"
      />
      <Breadcrumb>
        <Breadcrumb.Item href="/" text="Home" />
        <Breadcrumb.Item href="/products" text="Products" />
        <Breadcrumb.Item
          current
          href="/products/ultra-tax-cs"
          text="UltraTax CS"
        />
      </Breadcrumb>
      <Section fullWidth>
        <Poster height="s" imageSrc={imgLarge2}>
          <Grid variant="7/12,5/12" gutterlessAtNarrow>
            <Grid.Item>
              <VerticalSpacing>
                <Heading type="xl" rank="1">
                  <strong>UltraTax CS</strong>
                </Heading>
              </VerticalSpacing>
              <Group>
                <Group.Item>
                  <Button color="primary" kind="link">
                    Try it for free
                  </Button>
                </Group.Item>
                <Group.Item>
                  <Button color="secondary" kind="link">
                    Contact sales
                  </Button>
                </Group.Item>
              </Group>
            </Grid.Item>
          </Grid>
        </Poster>
      </Section>
      <MarkupContainer sticky>
        <PageNav
          share={
            <SocialDropdown>
              <SocialDropdown.Item>
                <SocialShare
                  pageUrl="https://www.thomsonreuters.com"
                  type="facebook"
                >
                  <SVGIcon
                    name="facebook"
                    size="s"
                    getExternalIconPath={getSocialIcon}
                  />
                </SocialShare>
              </SocialDropdown.Item>
              <SocialDropdown.Item>
                <SocialShare
                  pageTitle="Thomson Reuters: The Answer Company"
                  pageUrl="https://www.thomsonreuters.com"
                  type="twitter"
                >
                  <SVGIcon
                    name="twitter"
                    size="s"
                    getExternalIconPath={getSocialIcon}
                  />
                </SocialShare>
              </SocialDropdown.Item>
              <SocialDropdown.Item>
                <SocialShare
                  pageTitle="Thomson Reuters: The Answer Company"
                  pageUrl="https://www.thomsonreuters.com"
                  type="linkedIn"
                >
                  <SVGIcon
                    name="linkedin"
                    size="s"
                    getExternalIconPath={getSocialIcon}
                  />
                </SocialShare>
              </SocialDropdown.Item>
              <SocialDropdown.Item>
                <SocialShare
                  pageTitle="Thomson Reuters: The Answer Company"
                  pageUrl="https://www.thomsonreuters.com"
                  type="email"
                >
                  <SVGIcon
                    name="email"
                    size="s"
                    getExternalIconPath={getSocialIcon}
                  />
                </SocialShare>
              </SocialDropdown.Item>
            </SocialDropdown>
          }
        >
          <PageNav.Item href="#" title="Overview" />
          <PageNav.Item href="#features" title="Features" />
          <PageNav.Item href="#how-it-works" title="How it works" />
          <PageNav.Item href="#related-products" title="Related products" />
          <PageNav.Item href="#testimonials" title="Testimonials" />
        </PageNav>
      </MarkupContainer>
      <SemanticMain>
        <Section>
          <VerticalSpacing>
            <Heading rank="2" type="l">
              <strong>Product overview</strong>
            </Heading>
          </VerticalSpacing>
          <Grid variant="2/3,1/3">
            <Grid.Item>
              <RichText large>
                <p>
                  Thomson Reuters UltraTax CS professional tax software
                  automates your entire business or individual tax workflow and
                  delivers powerful, time-saving tools. You’ll access a full
                  line of federal, state, and local tax programs including 1040
                  individual, 1120 corporate, 1065 partnership, 1041 estates and
                  trusts, multi-state returns, and more.
                </p>
                <p>
                  UltraTax CS seamlessly integrates with{" "}
                  <a href="https://not-visited.com">CS Professional Suite</a>{" "}
                  and{" "}
                  <a href="https://not-visited.com">Thomson Reuters Onvio</a>{" "}
                  cloud software so you never lose any billable hours to manual
                  processing. Meet all of your tax workflow needs with a
                  customized, end-to-end solution built on cloud computing,
                  advanced data sharing, and paperless processing.
                </p>
              </RichText>
            </Grid.Item>
            <Grid.Item>
              <VerticalSpacing>
                <Heading rank="2" type="small-caps">
                  Resources
                </Heading>
              </VerticalSpacing>
              <ListOfLinks>
                <ListOfLinks.BlockLink
                  href="https://www.example.com"
                  icon="download"
                  size="small"
                >
                  Brochure
                </ListOfLinks.BlockLink>
                <ListOfLinks.BlockLink
                  href="https://www.example.com"
                  icon="download"
                  size="small"
                >
                  Product benefits
                </ListOfLinks.BlockLink>
                <ListOfLinks.BlockLink
                  href="https://www.example.com"
                  icon="download"
                  size="small"
                >
                  System requirements
                </ListOfLinks.BlockLink>
              </ListOfLinks>
            </Grid.Item>
          </Grid>
        </Section>
        <Section background="grayDark">
          <Grid variant="even-2">
            <Grid.Item>
              <MarkupContainer>
                <VideoPlayer
                  duration="PT1M35S"
                  kind="vimeo"
                  playLabel="Play video"
                  videoId="114765613"
                >
                  <Image alt="Placeholder image" fit="cover" src={imgLarge2} />
                </VideoPlayer>
              </MarkupContainer>
            </Grid.Item>
            <Grid.Item>
              <Alignment vertical="center">
                <VerticalSpacing size="s">
                  <Heading rank="2" type="l">
                    <strong>Get more value</strong> out of your billable hours
                    with UltraTax CS
                  </Heading>
                </VerticalSpacing>
                <VerticalSpacing>
                  <RichText compact>
                    <p>
                      See how your firm can meet rising client expectations and
                      get extra value from every billable hour.
                    </p>
                  </RichText>
                </VerticalSpacing>
                <Duration
                  duration="P2M"
                  icon={<Icon path={Icons.timeWhite} size="xs" />}
                >
                  2 mins
                </Duration>
              </Alignment>
            </Grid.Item>
          </Grid>
          <PageNav.Anchor id="features" />
        </Section>
        <Section>
          <VerticalSpacing size="xl">
            <Heading align="center" rank="2" type="l">
              Powerful features and benefits that{" "}
              <strong>make a difference</strong>
            </Heading>
          </VerticalSpacing>
          <Grid gutterBorder variant="even-3">
            <Grid.Item>
              <Tile
                description="View input, forms, prior year input, diagnostics, and more on up to four separate monitors — all at once."
                icon={<Icon path={Icons.transform2d} size="xl" />}
                title="Multiple monitor flexibility"
              />
            </Grid.Item>
            <Grid.Item>
              <Tile
                description="Start each year with a checklist that instantly pulls in a list of fields with your client’s data from last year’s return."
                icon={<Icon path={Icons.transform2d} size="xl" />}
                title="Instant prep checklist"
              />
            </Grid.Item>
            <Grid.Item>
              <Tile
                description="UltraTax CS automatically links returns using Tax ID numbers so there's no need to manually link 1120S, 1065, or 1041 returns to the member 1040 returns."
                icon={<Icon path={Icons.transform2d} size="xl" />}
                title="Easy data sharing"
              />
            </Grid.Item>
            <Grid.Item>
              <Tile
                description="e-File tools monitor each step of the e-file process to ensure that returns are complete and accurate."
                icon={<Icon path={Icons.transform2d} size="xl" />}
                title="Advanced e-file capabilities"
              />
            </Grid.Item>
            <Grid.Item>
              <Tile
                description="Use the Apportionment grid for business returns and Multi-State Allocation grid for Schedule C or K-1 income across states."
                icon={<Icon path={Icons.transform2d} size="xl" />}
                title="Multi-state processing"
              />
            </Grid.Item>
            <Grid.Item>
              <Tile
                description="Offer your clients a convenient, secure and efficient way to electronically sign tax documents with this fee-based feature."
                href="https://not-visited.com"
                icon={<Icon path={Icons.transform2d} size="xl" />}
                linkTitle="Learn more"
                linkIcon={<Icon path={Icons.arrowRightBlue} size="s" />}
                title="UltraTax CS eSignature"
              />
            </Grid.Item>
          </Grid>
          <PageNav.Anchor id="how-it-works" />
        </Section>
        <Section background="grayLightest">
          <VerticalSpacing size="xl">
            <Heading align="center" rank="2" type="l">
              See UltraTax CS <strong>in action</strong>
            </Heading>
          </VerticalSpacing>
          <VerticalSpacing size="xl">
            <Grid variant="1/3,2/3">
              <Grid.Item>
                <Alignment vertical="center">
                  <VerticalSpacing>
                    <Heading rank="3" type="m">
                      <strong>Demo: paperless workflow</strong>
                    </Heading>
                  </VerticalSpacing>
                  <RichText large>
                    <p>
                      Increase efficiency, accuracy, and the speed of processing
                      business tax returns with the paperless workflow of the CS
                      Professional Suite.
                    </p>
                  </RichText>
                </Alignment>
              </Grid.Item>
              <Grid.Item>
                <MarkupContainer>
                  <VideoPlayer
                    duration="PT1M35S"
                    kind="vimeo"
                    playLabel="Play video"
                    videoId="114765613"
                  >
                    <Image
                      alt="Placeholder image"
                      fit="cover"
                      src={imgLarge2}
                    />
                  </VideoPlayer>
                </MarkupContainer>
              </Grid.Item>
            </Grid>
          </VerticalSpacing>
          <VerticalSpacing size="xl">
            <Grid variant="2/3,1/3">
              <Grid.Item>
                <MarkupContainer>
                  <VideoPlayer
                    duration="PT1M35S"
                    kind="vimeo"
                    playLabel="Play video"
                    videoId="114765613"
                  >
                    <Image
                      alt="Placeholder image"
                      fit="cover"
                      src={imgLarge2}
                    />
                  </VideoPlayer>
                </MarkupContainer>
              </Grid.Item>
              <Grid.Item>
                <Alignment vertical="center">
                  <Heading rank="3" type="m">
                    <strong>
                      Demo: individual tax workflow with Thomson Reuters Onvio
                      and UltraTax CS
                    </strong>
                  </Heading>
                </Alignment>
              </Grid.Item>
            </Grid>
          </VerticalSpacing>
          <VerticalSpacing size="xl">
            <Grid variant="1/3,2/3">
              <Grid.Item>
                <Alignment vertical="center">
                  <Heading rank="3" type="m">
                    <strong>
                      Demo: Business Tax Workflow with Thomson Reuters Onvio,
                      UltraTax CS, and Workpapers CS
                    </strong>
                  </Heading>
                </Alignment>
              </Grid.Item>
              <Grid.Item>
                <MarkupContainer>
                  <VideoPlayer
                    duration="PT1M35S"
                    kind="vimeo"
                    playLabel="Play video"
                    videoId="114765613"
                  >
                    <Image
                      alt="Placeholder image"
                      fit="cover"
                      src={imgLarge2}
                    />
                  </VideoPlayer>
                </MarkupContainer>
              </Grid.Item>
            </Grid>
          </VerticalSpacing>
          <PageNav.Anchor id="related-products" />
        </Section>
        <Section>
          <VerticalSpacing size="xl">
            <Heading align="center" rank="2" type="l">
              Anytime, anywhere <strong>hosting</strong>
            </Heading>
          </VerticalSpacing>
          <Grid gutterBorder variant="even-2">
            <Grid.Item>
              <Tile
                description="Get anytime, anywhere access to CS Professional Suite and select Microsoft products with our cloud accounting software."
                href="https://not-visited.com"
                icon={<Icon path={Icons.transform2d} size="xl" />}
                linkTitle="Learn more"
                linkIcon={<Icon path={Icons.arrowRightBlue} size="s" />}
                title="Virtual Office CS"
              />
            </Grid.Item>
            <Grid.Item>
              <Tile
                description="Lease CS Professional Suite plus select Microsoft products and scale our cloud-based software to meet the unique needs of your firm."
                href="https://not-visited.com"
                icon={<Icon path={Icons.transform2d} size="xl" />}
                linkTitle="Learn more"
                linkIcon={<Icon path={Icons.arrowRightBlue} size="s" />}
                title="Software as a Service for CS Professional Suite (SaaS)"
              />
            </Grid.Item>
          </Grid>
          <PageNav.Anchor id="testimonials" />
        </Section>
        <Section background="grayDark">
          <BlockQuote
            attribution={
              <Attribution
                caption="Partner & Co-Owner, Evans & Associates, Inc."
                href="https://not-visited.com"
                name="Matthew Evans"
              />
            }
            avatar={<Avatar alt="Avatar" src={articleHeadshot} />}
            link={
              <Link
                href="https://not-visited.com"
                type="icon"
                icon={<Icon path={Icons.arrowRightWhite} size="s" />}
                iconPosition={"right"}
              >
                Read the case study
              </Link>
            }
            cite="https://not-visited.com"
            text="UltraTax CS is tantamount to an employee… because the
            software is so capable of preparing the complicated tax
            returns that all practitioners end up doing over time. I
            haven’t run across a form yet that UltraTax CS can’t
            handle."
          />
        </Section>
        <Section background="grayLightest">
          <VerticalSpacing size="xl">
            <Block height="12rem" text="Ready to start a conversation?" />
          </VerticalSpacing>
          <Grid variant="2/3,1/3">
            <Grid.Item>
              <Heading rank="2" type="m">
                <strong>Visit the store</strong> to learn more about the
                Checkpoint compliance package for UltraTax CS.
              </Heading>
            </Grid.Item>
            <Grid.Item>
              <Alignment vertical="center">
                <Button
                  color="secondary"
                  icon={cartIcon}
                  kind="link"
                  href="https://not-visited.com"
                >
                  Explore our store
                </Button>
              </Alignment>
            </Grid.Item>
          </Grid>
        </Section>
        <Section>
          <VerticalSpacing size="l">
            <Header
              heading={
                <Heading rank="2" type="m">
                  <strong>Insights</strong> to keep you one step ahead
                </Heading>
              }
              callToAction={
                <Link
                  href="https://not-visited.com"
                  type="icon"
                  icon={<Icon path={Icons.arrowRightBlue} size="s" />}
                  iconPosition={"right"}
                >
                  See more insights
                </Link>
              }
            />
          </VerticalSpacing>
          <Grid variant="even-3">
            <Grid.Item>
              <PairedCard
                eyebrow="Report"
                footer={
                  <ArticleCardFooter
                    dateTime="2017-04-01 13:00"
                    readingTime="5 min"
                    icon={<Icon path={Icons.time} size="xs" />}
                  />
                }
                heading="Five Security Questions Firms Should Ask"
                href="https://not-visited.com"
                imgAnchor="C"
                imgSrc={blockchainCardOne368}
                imgSrcSet={`${blockchainCardOne288} 288w, ${blockchainCardOne368} 368w, ${blockchainCardOne576} 576w, ${blockchainCardOne768} 768w, ${blockchainCardOne1168} 1168w, ${blockchainCardOne1536} 1536w`}
              />
            </Grid.Item>
            <Grid.Item>
              <PairedCard
                eyebrow="Case study"
                footer={
                  <ArticleCardFooter
                    dateTime="2017-08-26 13:00"
                    readingTime="8 min"
                    icon={<Icon path={Icons.time} size="xs" />}
                  />
                }
                heading="Alfermann, Gray &amp; Company, LLC share their UltraTax CS experience"
                href="https://not-visited.com"
                imgAnchor="C"
                imgSrc={blockchainCardTwo368}
                imgSrcSet={`${blockchainCardTwo288} 288w, ${blockchainCardTwo368} 368w, ${blockchainCardTwo576} 576w, ${blockchainCardTwo768} 768w, ${blockchainCardTwo1168} 1168w, ${blockchainCardTwo1536} 1536w`}
              />
            </Grid.Item>
            <Grid.Item>
              <PairedCard
                eyebrow="Case study"
                footer={
                  <ArticleCardFooter
                    dateTime="2017-08-02 13:00"
                    readingTime="12 min"
                    icon={<Icon path={Icons.time} size="xs" />}
                  />
                }
                heading="W CPA Group share their UltraTax CS experience"
                href="https://not-visited.com"
                imgAnchor="C"
                imgSrc={blockchainCardThree368}
                imgSrcSet={`${blockchainCardThree288} 288w, ${blockchainCardThree368} 368w, ${blockchainCardThree576} 576w, ${blockchainCardThree768} 768w, ${blockchainCardThree1168} 1168w, ${blockchainCardThree1536} 1536w`}
              />
            </Grid.Item>
          </Grid>
        </Section>
      </SemanticMain>

      <SemanticFooter>
        <MarkupContainer>
          <SiteFooter
            searchFormAction="/"
            searchFormMethod="get"
            typeaheadUrl="//content.atomz.com/autocomplete/sp10/05/0d/51?_=1506335725065"
          >
            <SiteFooter.NavList title="Products & Services">
              <SiteFooter.NavItem title="For accounting firms" href="#" />
              <SiteFooter.NavItem title="For corporations" href="#" />
              <SiteFooter.NavItem title="For governments" href="#" />
              <SiteFooter.NavItem title="Explore our brands" href="#" />
              <SiteFooter.NavItem title="All products" href="#" />
            </SiteFooter.NavList>
            <SiteFooter.NavList title="Resources">
              <SiteFooter.NavItem title="Insights" href="#" />
              <SiteFooter.NavItem title="Community" href="#" />
              <SiteFooter.NavItem title="Social media" href="#" />
              <SiteFooter.NavItem title="Press releases" href="#" />
            </SiteFooter.NavList>
            <SiteFooter.NavList title="Useful Links">
              <SiteFooter.NavItem title="About us" href="#" />
              <SiteFooter.NavItem title="Contact us" href="#" />
              <SiteFooter.NavItem title="Find your account manager" href="#" />
              <SiteFooter.NavItem title="Your accounts" href="#" />
            </SiteFooter.NavList>
            <SiteFooter.NavList title="Shop">
              <SiteFooter.NavItem title="Store" href="#" />
              <SiteFooter.NavItem title="Store FAQ" href="#" />
              <SiteFooter.NavItem
                title="Payment, shipping, and returns"
                href="#"
              />
            </SiteFooter.NavList>
          </SiteFooter>
        </MarkupContainer>
        <SampleGlobalFooter />
      </SemanticFooter>
    </div>
  );
};

export default ProductDetailPage;
