import React from "react";
import PropTypes from "prop-types";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import classNames from "classnames";
import AutosizeInput from "react-input-autosize";

import MultiDownshift from "./components/MultiDownshift";
import { getItems } from "./utilities";

import { SVGIcon } from "@emcm-ui/component-icon/lib/svg";

class MultiSelectDropdown extends React.Component {
  input = React.createRef();

  state = {
    hasFocus: false
  };

  render() {
    const {
      handleChange,
      name,
      noOptionsMessage,
      options,
      placeholder
    } = this.props;
    const { hasFocus } = this.state;

    return (
      <MultiDownshift onChange={handleChange} itemToString={this.itemToString}>
        {({
          getInputProps,
          getToggleButtonProps,
          getMenuProps,
          getRemoveButtonProps,
          removeItem,
          isOpen,
          inputValue,
          selectedItems,
          getItemProps,
          highlightedIndex,
          toggleMenu
        }) => {
          const dropdownOnClickKeypressHandler = () => {
            toggleMenu();
            if (!isOpen) {
              this.input.current.focus();
            }
          };

          return (
            <div className={this.getClassName()}>
              <div
                className={this.getClassName({
                  descendantName: "dropdown",
                  states: classNames({
                    open: isOpen,
                    focus: hasFocus,
                    populated: selectedItems.length > 0
                  })
                })}
                onClick={dropdownOnClickKeypressHandler}
                onKeyPress={dropdownOnClickKeypressHandler}
                role="button"
                data-testid="dropdown"
              >
                <ul
                  className={this.getClassName({
                    descendantName: "inner"
                  })}
                >
                  {selectedItems.length > 0 || inputValue ? (
                    selectedItems.map(item => (
                      <li
                        key={item.value}
                        className={this.getClassName({
                          descendantName: "multiValue"
                        })}
                        data-testid="multiValue"
                      >
                        <button
                          {...getRemoveButtonProps({
                            item,
                            className: this.getClassName({
                              descendantName: "multiValueClose"
                            }),
                            "data-testid": "multiValueClose"
                          })}
                          aria-label={`Remove filter: ${item.label}`}
                        >
                          <SVGIcon name="close" size="xs" />
                          <span
                            className={this.getClassName({
                              descendantName: "multiValueLabel"
                            })}
                          >
                            {item.label}
                          </span>
                        </button>
                      </li>
                    ))
                  ) : (
                    <span
                      className={this.getClassName({
                        descendantName: "placeholder"
                      })}
                    >
                      {placeholder}
                    </span>
                  )}
                  <AutosizeInput
                    {...getInputProps({
                      ref: this.input,
                      onKeyUp: event => {
                        if (event.key === "Backspace" && !inputValue) {
                          removeItem(selectedItems[selectedItems.length - 1]);
                        }
                      },
                      onFocus: () => this.setState({ hasFocus: true }),
                      onBlur: () => this.setState({ hasFocus: false }),
                      inputClassName: this.getClassName({
                        descendantName: "input"
                      }),
                      "data-input": "autosize",
                      "data-testid": "textInput",
                      id: name,
                      value: inputValue || ""
                    })}
                  />
                </ul>
                <button
                  {...getToggleButtonProps({
                    // prevents the menu from immediately toggling
                    // closed (due to our custom click handler above).
                    onClick(event) {
                      event.stopPropagation();
                    }
                  })}
                  className={this.getClassName({
                    descendantName: "controllerButton"
                  })}
                  data-testid="controllerButton"
                >
                  <span
                    className={this.getClassName({
                      descendantName: "controllerArrow",
                      states: classNames({ open: isOpen })
                    })}
                  >
                    <SVGIcon name="caret" size="s" />
                  </span>
                </button>
              </div>
              <ul
                {...getMenuProps()}
                className={this.getClassName({
                  descendantName: "menu",
                  states: classNames({ open: isOpen })
                })}
                data-testid="menu"
              >
                {isOpen
                  ? (() => {
                      const items = getItems({ options, filter: inputValue });

                      return items.length ? (
                        items.map((item, index) => (
                          <li
                            key={item.value}
                            {...getItemProps({
                              item,
                              index
                            })}
                            className={this.getClassName({
                              descendantName: "item",
                              states: classNames({
                                active: highlightedIndex === index,
                                selected: selectedItems.some(
                                  i =>
                                    this.itemToString(i) ===
                                    this.itemToString(item)
                                )
                              })
                            })}
                            data-testid="menuItem"
                          >
                            {item.label}
                          </li>
                        ))
                      ) : (
                        <li
                          className={this.getClassName({
                            descendantName: "noOptionsMessage"
                          })}
                          data-testid="noOptionsMessage"
                        >
                          {noOptionsMessage}
                        </li>
                      );
                    })()
                  : null}
              </ul>
            </div>
          );
        }}
      </MultiDownshift>
    );
  }

  getClassName = getClassNameFactory(MultiSelectDropdown.displayName);

  itemToString = item => (item ? item.value : "");
}

MultiSelectDropdown.displayName = "MultiSelectDropdown";

MultiSelectDropdown.propTypes = {
  /**
   * On change event handler
   */
  handleChange: PropTypes.func,

  /**
   * Text input name/id
   */
  name: PropTypes.string.isRequired,

  /**
   * Message displayed in the filter if there is no option resulting from the typeahead input
   */
  noOptionsMessage: PropTypes.string.isRequired,

  /**
   * Data to generate listed options
   */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string
    })
  ),

  /**
   * Placeholder for the filter input
   */
  placeholder: PropTypes.string.isRequired
};

export default MultiSelectDropdown;
