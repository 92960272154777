import React from "react";
import SharePriceTracker from "./SharePriceTracker";

// eslint-disable-next-line require-await
export default async domNode => {
  const props = {
    apiUrl: domNode.getAttribute("data-api-url"),
    format: domNode.getAttribute("data-date-format"),
    overlay: domNode.getAttribute("data-overlay"),
    backgroundType: domNode.getAttribute("data-background-type"),
    title: domNode.getAttribute("data-title")
  };

  return <SharePriceTracker {...props} />;
};
