import PropTypes from "prop-types";
import React from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import TextCardBase from "../../bases/TextCardBase";

const TextCard = ({
  borderless,
  description,
  eyebrow,
  footer,
  heading,
  headingRank,
  href,
  size
}) => {
  const getClassName = getClassNameFactory(TextCard.displayName);

  return (
    <div className={getClassName()}>
      <TextCardBase
        borderless={borderless}
        description={description}
        eyebrow={eyebrow}
        footer={footer}
        heading={heading}
        headingRank={headingRank}
        href={href}
        size={size}
      />
    </div>
  );
};

TextCard.displayName = "TextCard";

TextCard.sizes = TextCardBase.sizes;
TextCard.headingRanks = TextCardBase.headingRanks;

/* eslint-disable max-len */
TextCard.propTypes = {
  /**
   * Hide the border
   */
  borderless: PropTypes.bool,
  /**
   * Description text
   */
  description: PropTypes.node,
  /**
   * Eyebrow text
   */
  eyebrow: PropTypes.string,
  /**
   * Footer. Should be a valid card footer, like ArticleCardFooter.
   */
  footer: PropTypes.node.isRequired,
  /**
   * Heading text
   */
  heading: PropTypes.string.isRequired,
  /**
   * Heading rank. 1 will render `<h1 />`, and so on. Defaults to 3.
   */
  headingRank: PropTypes.oneOf(TextCard.headingRanks),
  /**
   * Card URL
   */
  href: PropTypes.string.isRequired,
  /**
   * Size of the Card. Will set the minumum height and grow to fit content.
   */
  size: PropTypes.oneOf(TextCard.sizes).isRequired
};
/* eslint-enable max-len */

TextCard.defaultProps = {
  headingRank: "3"
};

export default TextCard;
