import PropTypes from "prop-types";
import React from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import DateTime from "@emcm-ui/component-date-time";
import Eyebrow from "@emcm-ui/component-eyebrow";
import Heading from "@emcm-ui/component-heading";
import Image from "@emcm-ui/component-image";

import ArticleInfoFooter from "./components/ArticleInfoFooter";
import TagFooter from "./components/TagFooter";

const Result = ({
  dateTime,
  description,
  eyebrow,
  footer,
  href,
  imgSrc,
  imgSrcSet,
  links,
  title,
  icon,
  subtitle,
  iconInMobile
}) => {
  const getClassName = getClassNameFactory("SearchResults");

  return (
    <div className={getClassName({ descendantName: "result" })}>
      {icon && (
        <span
          className={getClassName({
            descendantName: iconInMobile ? "resultIconMobile" : "resultIcon"
          })}
        >
          {icon}
        </span>
      )}

      <div className={getClassName({ descendantName: "resultBody" })}>
        {dateTime && !eyebrow ? (
          <span
            className={getClassName({
              descendantName: "resultDateTime",
              utilities: "typographySmallCaps"
            })}
          >
            <DateTime dateTime={dateTime} />
          </span>
        ) : null}
        {eyebrow ? (
          <span
            className={getClassName({
              descendantName: "resultEyebrow"
            })}
          >
            <Eyebrow text={eyebrow} />
          </span>
        ) : null}
        <a
          className={getClassName({ descendantName: "resultTitle" })}
          href={href}
        >
          <Heading rank="3" type="s">
            {title}
          </Heading>
        </a>
        <div className={getClassName({ descendantName: "resultDescription" })}>
          {description}
        </div>
        {subtitle && (
          <div className={getClassName({ descendantName: "resultSubtitle" })}>
            {subtitle}
          </div>
        )}
        {footer && (
          <div className={getClassName({ descendantName: "resultFooter" })}>
            {footer}
          </div>
        )}
        {links && links.length > 0 ? (
          <div className={getClassName({ descendantName: "resultLinks" })}>
            {links.map((link, i) => (
              <span
                className={getClassName({ descendantName: "resultLink" })}
                key={i}
              >
                {link}
              </span>
            ))}
          </div>
        ) : null}
      </div>

      {imgSrc ? (
        <div className={getClassName({ descendantName: "resultImage" })}>
          <Image
            sizes={"calc(364rem / 16)"}
            src={imgSrc}
            srcSet={imgSrcSet}
            alt="Result image"
            cover
          />
        </div>
      ) : null}
    </div>
  );
};

Result.displayName = "SearchResults.Result";

Result.propTypes = {
  /**
   * DEPRECATED: The date associated with this search result. Should be ISO 8601.
   */
  dateTime: PropTypes.string,

  /**
   * Description for the result. Can contain markup.
   */
  description: PropTypes.node.isRequired,
  /**
   * Eyebrow text
   */
  eyebrow: PropTypes.string,
  /**
   * Footer to show on this result.
   */
  footer: PropTypes.element,
  /**
   * URL for the result.
   */
  href: PropTypes.string.isRequired,
  /**
   * Image `src`. Used if srcSet is not defined, or as a fallback.
   */
  imgSrc: PropTypes.string,
  /**
   * A comma-separated list indicating a set of possible image sources for the result. The image will be fit to cover. This is used by the user agent to determine the correct image for different screen sizes. Same format as [srcSet](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/img#Example_4_Using_the_srcset_and_sizes_attributes), i.e. `my-image-200.png 200w, my-image-200.png 200w`.
   */
  imgSrcSet: PropTypes.string,
  /**
   * Additional links that appear at the bottom of the result.
   */
  links: PropTypes.arrayOf(PropTypes.node),
  /**
   * Title text.
   */
  title: PropTypes.string.isRequired,
  /**
   * Icon of Document Type
   */
  icon: PropTypes.node,
  /**
   * Show Icon in Mobile
   */
  iconInMobile: PropTypes.bool,
  /**
   * Show Sub Title
   */
  subtitle: PropTypes.string
};

Result.ArticleInfoFooter = ArticleInfoFooter;
Result.TagFooter = TagFooter;

export default Result;
