import PropTypes from "prop-types";
import React from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import classNames from "classnames";

const PageItem = ({ active, children, href, onClick, ...rest }) => {
  const getClassName = getClassNameFactory(PageItem.displayName);

  let InnerName = "span";
  let clickable = false;

  const innerProps = {};

  if (active) {
    innerProps["aria-current"] = "true";
    innerProps["aria-label"] = `Current Page, Page ${children}`;
    innerProps.role = "button";
    innerProps.tabIndex = "0";
  } else if (href) {
    InnerName = "a";
    innerProps.href = href;
    innerProps["aria-current"] = "false";
    innerProps["aria-label"] = `Go to Page ${children}`;
    innerProps.role = "button";
    innerProps.tabIndex = "0";
    clickable = true;
  } else if (onClick) {
    InnerName = "button";
    innerProps.onClick = onClick;
    clickable = true;
  }

  return (
    <li
      className={getClassName({
        states: classNames({ active })
      })}
    >
      <InnerName
        className={getClassName({
          descendantName: "inner",
          states: classNames({ clickable })
        })}
        {...innerProps}
        {...rest}
      >
        <span
          className={getClassName({
            descendantName: "innerText"
          })}
        >
          {children}
        </span>
        <span
          className={getClassName({
            descendantName: "innerTextActive"
          })}
        />
      </InnerName>
    </li>
  );
};

PageItem.displayName = "Pagination.PageItem";

/* eslint-disable max-len */
PageItem.propTypes = {
  /**
   * Flag the page as active. Will disable the link.
   */
  active: PropTypes.bool,

  /**
   * Page number.
   */
  children: PropTypes.node.isRequired,

  /**
   * Page URL.
   */
  href: PropTypes.string,

  /**
   * On click event handler.
   */
  onClick: PropTypes.func
};
/* eslint-enable max-len */

PageItem.defaultProps = {
  active: false
};

export default PageItem;
