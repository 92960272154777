import PropTypes from "prop-types";
import React, { Component } from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import Modal from "@emcm-ui/component-modal";
import AjaxContent from "@emcm-ui/component-ajax-content";

class Anchor extends Component {
  static displayName = "Anchor";
  getClassName = getClassNameFactory(Anchor.displayName);

  state = { isModalOpen: false };

  static defaultProps = {
    kind: "link",
    fallbackElementTag: "span"
  };

  handleCloseModal = () => {
    this.setState({ isModalOpen: false });
  };

  handleOnClick = event => {
    if (this.props.onClick) {
      event.preventDefault();

      this.props.onClick();
    } else {
      event.preventDefault();

      this.setState({ isModalOpen: true });
    }
  };

  render() {
    const {
      ariaLabel,
      className,
      contentLocation,
      datasets,
      disabled,
      download,
      fallbackElementTag,
      href,
      kind,
      modalLabel,
      newWindow,
      onChange,
      onClick,
      reactFromMarkupOptions,
      reactFromMarkupRehydrators,
      children
    } = this.props;

    const elementProps = {};
    let Element;
    const getDatasets = datasets ? JSON.parse(datasets) : null;

    if (kind === "link") {
      Element = fallbackElementTag;
      if (href) {
        Element = "a";
        elementProps.href = href;
        elementProps.download = download;
      }

      if (newWindow) {
        elementProps.target = "_blank";
        elementProps.rel = "noopener";
      }
    } else {
      Element = "button";
      elementProps.type = kind;
      elementProps.disabled = disabled;
    }

    const shouldRehydrate = Boolean(contentLocation);

    /**
     * `data-content-modal` provides us an identifier which
     * the wel-trdotcom global modal click handlers can
     * match against.
     */

    if (shouldRehydrate) {
      elementProps["data-content-location"] = contentLocation;
      elementProps["data-modal-label"] = modalLabel;
      elementProps["data-content-modal"] = true;
    }

    const shouldHandleClick = contentLocation || onClick;

    if (shouldHandleClick) {
      elementProps.onClick = this.handleOnClick;
    }

    return (
      <Element
        className={`${this.getClassName()} ${className}`}
        {...elementProps}
        {...getDatasets}
        aria-label={ariaLabel}
      >
        {children}
        {this.state.isModalOpen && (
          <Modal
            ariaLabel={modalLabel}
            closeLabel="Close"
            closeThisComponent={this.handleCloseModal}
          >
            <AjaxContent
              failedContent="Failed to retrieve content"
              loadingLabel="Loading"
              location={contentLocation}
              onChange={onChange}
              reactFromMarkupOptions={reactFromMarkupOptions}
              reactFromMarkupRehydrators={reactFromMarkupRehydrators}
            />
          </Modal>
        )}
      </Element>
    );
  }
  static propTypes = {
    /**
     * Aria label for anchor
     */
    ariaLabel: PropTypes.string,
    /**
     * Component className, required. This is a utility component
     * it falls outside of the WEL composition model and so, applies
     * only functionality, zero styling.
     */
    className: PropTypes.string.isRequired,

    /**
     * Children
     */
    children: PropTypes.node.isRequired,

    /**
     * content location to AJAX load content into modal, should provide href
     * for a fallback where possible.
     */
    contentLocation: PropTypes.string,

    /**
     * datasets object is used to add analytic code to the element
     * which is being passed from the external source
     */
    datasets: PropTypes.string,

    /**
     * Instruct the browser to download the link URL instead of navigating to it.
     * If the prop is given a string value, it is used as the pre-filled file name
     * in the Save prompt. only works where `kind=link`
     */
    download: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),

    /**
     * disabled, only works where `kind=button`
     */
    disabled: PropTypes.bool,

    /**
     * if no href is provided, what element tag to fallback to.
     */
    fallbackElementTag: PropTypes.oneOf(["span", "div"]),

    /**
     * anchor href, if used the element will always be an `<a>` tag.
     */
    href: PropTypes.string,

    /**
     * Aria label for modal dialog, required if `contentLocation` is defined.
     */
    modalLabel: PropTypes.string,

    /**
     * kind of link/button, defaults to `link`
     */
    kind: PropTypes.oneOf(["link", "button", "submit"]),

    /**
     * Add an onChange callback. Called on loading new AJAX content
     */
    onChange: PropTypes.func,

    /**
     * onClick callback, if this is defined `contentLocation` for modal windows
     * will be ignored.
     */
    onClick: PropTypes.func,

    /**
     * Open link in new tab/window.
     */
    newWindow: PropTypes.bool,

    /**
     * Rehydration options.
     *
     * If unspecified, will use the default (empty) AjaxContent options.
     */
    reactFromMarkupOptions: PropTypes.object,

    /**
     * A list of rehydrators that are available. Used to rehydrate markup from
     * the server.
     *
     * If unspecified, will use the default (empty) AjaxContent rehydrators.
     */
    reactFromMarkupRehydrators: PropTypes.object
  };
}

export default Anchor;
