import React from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import FormsNewStyle from "./FormsNewStyle";

export default async (domNode, rehydrateChildren) => {
  const getClassName = getClassNameFactory(FormsNewStyle.displayName);

  const childrenNode = domNode.querySelector("[data-rehydratable-children]");
  const reactifiedChildrenPromise = rehydrateChildren(childrenNode);

  const headerNode = domNode.querySelector(
    `.${getClassName({ descendantName: "header" })}`
  );
  const footerNode = domNode.querySelector(
    `.${getClassName({ descendantName: "footer" })}`
  );

  const footerPromise = footerNode
    ? rehydrateChildren(footerNode)
    : Promise.resolve(null);
  const headerPromise = headerNode
    ? rehydrateChildren(headerNode)
    : Promise.resolve(null);

  // Ensure that all 3 rehydrateChildren calls can run in parallel, by
  // only awaiting _after_ the rehydrateChildren process has started.
  const promiseResult = await Promise.all([
    reactifiedChildrenPromise,
    footerPromise,
    headerPromise
  ]);
  const reactifiedChildren = promiseResult[0];
  const footer = promiseResult[1];
  const header = promiseResult[2];

  const props = {
    isRehydrating: true,
    action: domNode.getAttribute("data-action"),
    id: domNode.getAttribute("data-id"),
    isDark: domNode.getAttribute("data-is-dark") === "true",
    analyticsName: domNode.getAttribute("data-analytics-name"),
    analyticsNodes: JSON.parse(
      domNode.getAttribute("data-analytics-nodes") || `{}`
    ),
    analyticsType: domNode.getAttribute("data-analytics-type"),
    defaultFailMessage: domNode.getAttribute("data-default-fail-message"),
    defaultSuccessMessage: domNode.getAttribute("data-default-success-message"),
    ajax: domNode.getAttribute("data-ajax") === "true",
    footer,
    header,
    submitButtonColor: domNode.getAttribute("data-submit-button-color"),
    submitButtonText: domNode.getAttribute("data-submit-button-text"),
    redirectPage: domNode.getAttribute("data-redirect-page"),
    confirmationType: domNode.getAttribute("data-message-type")
  };

  return <FormsNewStyle {...props}>{reactifiedChildren}</FormsNewStyle>;
};
