import PropTypes from "prop-types";
import React from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import Link from "@emcm-ui/component-link";
import { SVGIcon } from "@emcm-ui/component-icon/lib/svg";

const TinyTile = ({ icon, title, href, newWindow, model }) => {
  const getClassName = getClassNameFactory(TinyTile.displayName);

  return (
    <div className={getClassName()}>
      {/* <div
        className={getClassName({
          descendantName: "icon"
        })}
      >
        {icon}
      </div> */}

      {!model && (
        <React.Fragment>
          <div className={getClassName({ descendantName: "link" })}>
            <Link
              href={href}
              newWindow={newWindow}
              type="icon"
              icon={<SVGIcon name="arrow" size="s" />}
              iconPosition={"right"}
              block
            >
              <div
                className={getClassName({
                  descendantName: "icon"
                })}
              >
                {icon}
              </div>
              {`${title} - Link`}
            </Link>
          </div>

          <div
            className={getClassName({
              descendantName: "link",
              modifiers: "hiddenOnMobile"
            })}
          >
            <Link href={href} newWindow={newWindow} type="inline" block>
              <div
                className={getClassName({
                  descendantName: "icon"
                })}
              >
                {icon}
              </div>
              {`${title} - Link`}
            </Link>
          </div>
        </React.Fragment>
      )}

      {model && (
        <React.Fragment>
          <div className={getClassName({ descendantName: "link" })}>
            <Link
              contentLocation={model.contentLocation}
              href={href}
              modalLabel={model.modalLabel}
              type="inline"
            >
              <div
                className={getClassName({
                  descendantName: "icon"
                })}
              >
                {icon}
              </div>
              {`${title} - model`}
            </Link>
          </div>

          <div
            className={getClassName({
              descendantName: "link",
              modifiers: "hiddenOnMobile"
            })}
          >
            <Link
              contentLocation={model.contentLocation}
              href={href}
              modalLabel={model.modalLabel}
              type="inline"
            >
              <div
                className={getClassName({
                  descendantName: "icon"
                })}
              >
                {icon}
              </div>
              {`${title} - model`}
            </Link>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

TinyTile.displayName = "TinyTile";

/* eslint-disable max-len */

TinyTile.propTypes = {
  /**
   * Icon or image component
   */
  icon: PropTypes.node.isRequired,

  /**
   * Title text
   */
  title: PropTypes.string.isRequired,

  /**
   * Href used for link
   */
  href: PropTypes.string.isRequired,

  /**
   * Link opens in new window
   */
  newWindow: PropTypes.bool,
  model: {}
};
/* eslint-enable max-len */

TinyTile.defaultProps = {
  newWindow: false
};

export default TinyTile;
