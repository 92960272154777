import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import anchorPoints from "./anchorPoints.js";

const Image = ({
  alt,
  anchor,
  fillColor,
  fit,
  height,
  overlay,
  sizes,
  src,
  srcSet,
  width
}) => {
  const getClassName = getClassNameFactory(Image.displayName);

  return (
    <div
      className={getClassName({
        modifiers: classNames({
          cover: fit === "cover",
          [`anchor${anchor}`]: fit === "cover",
          overlay,
          [`${overlay}`]: overlay
        })
      })}
      style={{
        ...(fillColor && { backgroundColor: fillColor }),
        ...(fit === "cover" && { backgroundImage: `url(${src})` }), // Optionally set backgroundImage
        ...(height && { height: `${height}px` }),
        ...(width && { width: `${width}px` })
      }}
    >
      <img
        className={getClassName({
          descendantName: "img"
        })}
        alt={alt}
        height={height}
        sizes={sizes}
        src={src}
        srcSet={srcSet}
        width={width}
      />
    </div>
  );
};

Image.displayName = "Image";

Image.overlays = ["scrimBottomDark"];

/* eslint-disable max-len */
Image.propTypes = {
  /**
   * Alternative text for the image, shown if the image cannot be displayed.
   */
  alt: PropTypes.string.isRequired,

  /**
   * Anchor point for the image if using fit="cover".
   */
  anchor: PropTypes.oneOf(anchorPoints),

  /**
   * Fallback hexadecimal fill color. The intent is to increase perceived loading speed. Do not use on images with transparency. Requires that an explicit width and height be set for the image.
   */
  fillColor: PropTypes.string,

  /**
   * Fit of the image.
   */
  fit: PropTypes.oneOf(["cover"]),

  /**
   * Height of the image, in pixels
   */
  height: PropTypes.number,

  /**
   * Apply an overlay to the image, e.g. a scrim gradient for text legibility.
   * Should not be used if the image has a rasterized overlay.
   */
  overlay: PropTypes.oneOf(Image.overlays),

  /**
   * The HTML [sizes](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/img#Example_4_Using_the_srcset_and_sizes_attributes attribute. Required if srcSet is defined.
   */
  sizes: PropTypes.string,

  /**
   * Image `src`. Used if srcSet is not defined, or as a fallback.
   */
  src: PropTypes.string.isRequired,

  /**
   * A comma-separated list indicating a set of possible image sources for the user agent to use for different screen sizes. Same format as [srcSet](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/img#Example_4_Using_the_srcset_and_sizes_attributes), i.e. `my-image-200.png 200w, my-image-200.png 200w`.
   */
  srcSet: PropTypes.string,

  /**
   * Width of the image, in pixels
   */
  width: PropTypes.number
};
/* eslint-enable max-len */

Image.defaultProps = {
  anchor: "C"
};

// Provide sane defaults for sizes props on images in common scenarios.
Image.sizes = {
  // These use the following variables:
  //   80rem = 1280px
  //   48rem = 768px
  //   1rem = 16px (half grid gutter)
  //
  // So this is the equivalent of:
  //   (min-width: 80rem): calc(var(--space-section-max-width) - var(--space-grid-gutter))
  //   default: calc(100vw - var(--space-grid-gutter))
  section: "(min-width: 80rem) calc(80rem - 2rem), calc(100vw - 2rem)",

  // This is the equivalent of:
  //   (min-width: 80rem): calc((var(--space-section-max-width) - (6 * var(--space-grid-gutter-half))) * 1/3)
  //   (min-width: 48rem): calc(100vw - (6 * var(--space-grid-gutter-half))) * 1/3)
  //   default: calc(100vw - var(--space-grid-gutter))
  oneThird:
    "(min-width: 80rem) calc((80rem - 6rem) * (1/3)), (min-width: 48rem) calc((100vw - 6rem) * (1/3)), calc(100vw - 2rem)",

  // This is the equivalent of:
  //   (min-width: 80rem): calc((var(--space-section-max-width) - (3 * var(--space-grid-gutter-half))) * 2/3)
  //   (min-width: 48rem): calc(100vw - (3 * var(--space-grid-gutter-half))) * 2/3)
  //   default: calc(100vw - var(--space-grid-gutter))
  twoThirds:
    "(min-width: 80rem) calc((80rem - 3rem) * (2/3)), (min-width: 48rem) calc((100vw - 3rem) * (2/3)), calc(100vw - 2rem)",

  // This is just 100% of the viewport width
  edgeToEdge: "100vw"
};

export default Image;
