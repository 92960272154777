import PropTypes from "prop-types";
import React from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import classNames from "classnames";

import CardBase from "../../../../bases/CardBase";
import Icon from "@emcm-ui/component-icon";

const EventCardBase = ({
  size,
  day,
  date,
  time,
  orgName,
  orgAddress,
  orgCity,
  clockIcon,
  locationIcon,
  isEventOnline,
  eventOnlineDetails
}) => {
  const getClassName = getClassNameFactory(EventCardBase.displayName);

  return (
    <div
      className={getClassName({
        modifiers: classNames({
          [size]: true
        })
      })}
      size={size}
    >
      {isEventOnline && (
        <div className={getClassName({ descendantName: "when" })}>
          {eventOnlineDetails &&
            eventOnlineDetails.icon && (
              <div
                className={getClassName({
                  modifiers: classNames({
                    locationIcon: "locationIcon"
                  })
                })}
              >
                <Icon path={eventOnlineDetails.icon} size="xs" />
              </div>
            )}
          <div className={getClassName({ descendantName: "address" })}>
            <div className={getClassName({ descendantName: "row" })}>
              <span
                className={getClassName({
                  modifiers: classNames({
                    organisationname: "organisationname"
                  })
                })}
              >
                {eventOnlineDetails.label}
              </span>
            </div>
          </div>
        </div>
      )}

      <div className={getClassName({ descendantName: "when" })}>
        {clockIcon && (
          <div
            className={getClassName({
              modifiers: classNames({
                clockIcon: "clockIcon"
              })
            })}
          >
            <Icon path={clockIcon} size="xs" />
          </div>
        )}
        <div className={getClassName({ descendantName: "datetime" })}>
          <div className={getClassName({ descendantName: "row" })}>
            <span
              className={getClassName({
                modifiers: classNames({
                  day: "day"
                })
              })}
            >
              {day}
            </span>
            <span
              className={getClassName({
                modifiers: classNames({
                  date: "date"
                })
              })}
            >
              {date}
            </span>
          </div>
          <div className={getClassName({ descendantName: "row" })}>
            <span
              className={getClassName({
                modifiers: classNames({
                  time: "time"
                })
              })}
            >
              {time}
            </span>
          </div>
        </div>
      </div>

      {!isEventOnline && (
        <div className={getClassName({ descendantName: "where" })}>
          {locationIcon && (
            <div
              className={getClassName({
                modifiers: classNames({
                  locationIcon: "locationIcon"
                })
              })}
            >
              <Icon path={locationIcon} size="xs" />
            </div>
          )}
          <div className={getClassName({ descendantName: "address" })}>
            <div className={getClassName({ descendantName: "row" })}>
              <span
                className={getClassName({
                  modifiers: classNames({
                    organisationname: "organisationname"
                  })
                })}
              >
                {orgName}
              </span>
            </div>
            <div className={getClassName({ descendantName: "row" })}>
              <span
                className={getClassName({
                  modifiers: classNames({
                    organisationaddress: "organisationaddress"
                  })
                })}
              >
                {orgAddress}
              </span>
            </div>
            <div className={getClassName({ descendantName: "row" })}>
              <span
                className={getClassName({
                  modifiers: classNames({
                    organisationcity: "organisationcity"
                  })
                })}
              >
                {orgCity}
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

EventCardBase.displayName = "EventCardBase";

/* eslint-disable max-len */
EventCardBase.propTypes = {
  /**
   * day
   */
  day: PropTypes.string,
  /**
   * date
   */
  date: PropTypes.string,
  /**
   * time
   */
  time: PropTypes.string,
  /**
   * orgName
   */
  orgName: PropTypes.string,
  /**
   * orgAddress
   */
  orgAddress: PropTypes.string,
  /**
   * orgCity
   */
  orgCity: PropTypes.string,
  /**
   * Size of the Card. Will set the minumum height and grow to fit content.
   */
  size: PropTypes.oneOf(CardBase.sizes).isRequired,
  /**
   * clockIcon.
   */
  clockIcon: PropTypes.string,
  /**
   * locationIcon.
   */
  locationIcon: PropTypes.string,
  /**
   * Show Online Event.
   */
  isEventOnline: PropTypes.bool,
  /**
   * Online event details.
   */
  eventOnlineDetails: PropTypes.object
};
/* eslint-enable max-len */

EventCardBase.defaultProps = {
  size: "small"
};

export default EventCardBase;
