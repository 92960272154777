import React from "react";
import { storiesOf } from "@storybook/react";
import { withKnobs, boolean, select, text } from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";
import { Language } from "@emcm-ui/component-theme";
import TextCardBase from "./index";
import "./TextCardBase.css";

import Block from "@emcm-ui/component-block";

import TextCardBaseREADME from "../../../../README.md";

const stories = storiesOf("Components/Card/Bases/TextCardBase", module);

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(TextCardBaseREADME));

const headingRanks = [""].concat(TextCardBase.headingRanks);

stories
  .add("Default", () => (
    <div style={{ maxWidth: 360 }}>
      <TextCardBase
        anchor={select("anchor", ["top", "bottom"], "top")}
        borderless={boolean("borderless", false)}
        href="https://www.example.com"
        size={select("size", TextCardBase.sizes, "small")}
        eyebrow={text("eyebrow", "Eyebrow")}
        heading={text("heading", "Heading")}
        headingRank={select("headingRank", headingRanks)}
        largeHeading={boolean("largeHeading", false)}
        description={text("description", "Description")}
        renderContent={content => content}
        metaData={[{ label: "This", value: "World" }]}
        footer={<Block color="purple" text="Footer" />}
      />
    </div>
  ))
  .add("Language: Arabic (RTL)", () => (
    <div style={{ maxWidth: 360 }}>
      <Language code="ar">
        <TextCardBase
          anchor={select("anchor", ["top", "bottom"], "top")}
          borderless={boolean("borderless", false)}
          href="https://www.example.com"
          size={select("size", TextCardBase.sizes, "small")}
          eyebrow={text("eyebrow", "حاجب العين")}
          heading={text("heading", "عنوان")}
          headingRank={select("headingRank", headingRanks)}
          largeHeading={boolean("largeHeading", false)}
          description={text("description", "وصف")}
          renderContent={content => content}
          footer={<Block color="purple" text="تذييل" />}
        />
      </Language>
    </div>
  ));
