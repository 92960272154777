import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";
import getClassNameFactory from "@emcm-ui/utility-class-names";

const Asset = ({ border, caption, children }) => {
  const getClassName = getClassNameFactory(Asset.displayName);

  return (
    <div className={getClassName({ modifiers: classNames({ border }) })}>
      <div className={getClassName({ descendantName: "inner" })}>
        {children}
      </div>
      <div className={getClassName({ descendantName: "caption" })}>
        {caption}
      </div>
    </div>
  );
};

Asset.propTypes = {
  /**
   * Render an optional border around the asset
   */
  border: PropTypes.bool,

  /**
   * A caption to attach to the asset. Children should be restricted to text,
   * <a>, <em> and <strong>
   */
  caption: PropTypes.node,

  /**
   * The asset. Children should be restricted to Image or VideoPlayer
   */
  children: PropTypes.node.isRequired
};

Asset.displayName = "Longform.Asset";

export default Asset;
