import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import { SVGIcon } from "@emcm-ui/component-icon/lib/svg";

const NavItem = ({ href, onClick, summary, title, icon }) => {
  const getClassName = getClassNameFactory("GlobalHeaderNavItem");
  const LinkElementType = href ? "a" : "button";

  return (
    <li
      className={getClassName({
        modifiers: classNames({
          summary
        })
      })}
    >
      <LinkElementType
        className={getClassName({ descendantName: "link" })}
        href={href}
        onClick={onClick}
      >
        {icon && (
          <div className={getClassName({ descendantName: "icon" })}>{icon}</div>
        )}

        <div className={getClassName({ descendantName: "text" })}>{title}</div>

        {summary && (
          <span className={getClassName({ descendantName: "summaryIcon" })}>
            <SVGIcon
              name="arrow"
              style={{ transform: "rotate(270deg)" }}
              size="xs"
            />
          </span>
        )}
      </LinkElementType>
    </li>
  );
};

NavItem.propTypes = {
  /**
   * The destination that this `NavItem` links to
   */
  href: PropTypes.string,

  /**
   * On click
   */
  onClick: PropTypes.func,

  /**
   * Whether or not this is a summary item, like "All Products ->"
   */
  summary: PropTypes.bool,

  /**
   * The title of this `NavItem`
   */
  title: PropTypes.string.isRequired,

  /**
   * The icon type id
   */
  icon: PropTypes.object
};

NavItem.displayName = "GlobalHeader.NavItem";

export default NavItem;
