import React from "react";
import propTypes from "prop-types";
import MarkupContainer from "@emcm-ui/component-markup-container";
import SiteHeader from "@emcm-ui/component-site-header";

import Block from "@emcm-ui/component-block";
import Eyebrow from "@emcm-ui/component-eyebrow";
import Heading from "@emcm-ui/component-heading";
import Link from "@emcm-ui/component-link";
import RichText from "@emcm-ui/component-rich-text";
import VerticalSpacing from "@emcm-ui/component-vertical-spacing";

const featuredItem = (
  <SiteHeader.FeaturedItem
    imageSrc="test-images/16-9/large_1.jpg"
    imageSrcSet="test-images/16-9/large_1.jpg 2000w, test-images/16-9/medium_1.jpg 800w, test-images/16-9/small_1.jpg 400w"
  >
    <VerticalSpacing size="xs">
      <Eyebrow text="Smallcaps" />
    </VerticalSpacing>
    <VerticalSpacing size="s">
      <Heading rank="4" type="xs">
        <b>Feature headline example text</b>
      </Heading>
    </VerticalSpacing>
    <VerticalSpacing size="l">
      <RichText>
        <p>
          Thomson Reuters provides professionals with the intelligence,
          technology and human expertise they need to find trusted answers.
        </p>
      </RichText>
    </VerticalSpacing>
    <Link href="https://example.com" type="inline">
      <b>Link Title</b>
    </Link>
  </SiteHeader.FeaturedItem>
);

const SampleSiteHeader = ({ siteName }) => (
  <MarkupContainer>
    <SiteHeader
      logoHref="#"
      searchFormAction="/"
      searchFormMethod="get"
      siteName={siteName}
      typeaheadUrl="//content.atomz.com/autocomplete/sp10/05/0d/51?_=1506335725065"
    >
      <SiteHeader.Menu title="1col">
        <SiteHeader.Section title="Section title">
          <SiteHeader.Column>
            <SiteHeader.NavList>
              <SiteHeader.NavItem title="Link Title" href="#" />
              <SiteHeader.NavItem
                title="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla auctor arcu ipsum."
                href="#"
              />
              <SiteHeader.NavItem title="Link Title" href="#" />
              <SiteHeader.NavItem
                description="Description"
                title="Link Title"
                href="#"
              />
              <SiteHeader.NavItem
                description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla auctor arcu ipsum."
                title="Link Title"
                href="#"
              />
              <SiteHeader.NavItem title="Something else" href="#" summary />
            </SiteHeader.NavList>
          </SiteHeader.Column>
        </SiteHeader.Section>
      </SiteHeader.Menu>
      <SiteHeader.Menu title="2col">
        <SiteHeader.Section title="Section title">
          <SiteHeader.Column>
            <SiteHeader.NavList>
              <SiteHeader.NavItem title="Link Title" href="#" />
              <SiteHeader.NavItem
                title="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla auctor arcu ipsum."
                href="#"
              />
              <SiteHeader.NavItem title="Link Title" href="#" />
              <SiteHeader.NavItem
                description="Description"
                title="Link Title"
                href="#"
              />
              <SiteHeader.NavItem
                description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla auctor arcu ipsum."
                title="Link Title"
                href="#"
              />
              <SiteHeader.NavItem title="Something else" href="#" summary />
            </SiteHeader.NavList>
          </SiteHeader.Column>
        </SiteHeader.Section>
        <SiteHeader.SectionStack>
          <SiteHeader.Section title="Section title">
            <SiteHeader.Column>
              <SiteHeader.NavList>
                <SiteHeader.NavItem title="Link Title" href="#" />
                <SiteHeader.NavItem title="Link Title" href="#" />
                <SiteHeader.NavItem title="Link Title" href="#" />
              </SiteHeader.NavList>
            </SiteHeader.Column>
          </SiteHeader.Section>
          <SiteHeader.Section title="Section title">
            <SiteHeader.Column>
              <SiteHeader.NavList>
                <SiteHeader.NavItem title="Link Title" href="#" />
                <SiteHeader.NavItem
                  title="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla auctor arcu ipsum."
                  href="#"
                />
                <SiteHeader.NavItem title="Link Title" href="#" />
              </SiteHeader.NavList>
            </SiteHeader.Column>
          </SiteHeader.Section>
        </SiteHeader.SectionStack>
      </SiteHeader.Menu>
      <SiteHeader.Menu title="3col">
        <SiteHeader.Section title="Section title">
          <SiteHeader.Column>
            <SiteHeader.NavList>
              <SiteHeader.NavItem title="Link Title" href="#" />
              <SiteHeader.NavItem
                title="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla auctor arcu ipsum."
                href="#"
              />
              <SiteHeader.NavItem title="Link Title" href="#" />
              <SiteHeader.NavItem
                description="Description"
                title="Link Title"
                href="#"
              />
              <SiteHeader.NavItem
                description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla auctor arcu ipsum."
                title="Link Title"
                href="#"
              />
              <SiteHeader.NavItem title="Something else" href="#" summary />
            </SiteHeader.NavList>
          </SiteHeader.Column>
        </SiteHeader.Section>
        <SiteHeader.SectionStack>
          <SiteHeader.Section title="Section title">
            <SiteHeader.Column>
              <SiteHeader.NavList>
                <SiteHeader.NavItem title="Link Title" href="#" />
                <SiteHeader.NavItem title="Link Title" href="#" />
                <SiteHeader.NavItem title="Link Title" href="#" />
              </SiteHeader.NavList>
            </SiteHeader.Column>
          </SiteHeader.Section>
          <SiteHeader.Section title="Section title">
            <SiteHeader.Column>
              <SiteHeader.NavList>
                <SiteHeader.NavItem title="Link Title" href="#" />
                <SiteHeader.NavItem
                  title="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla auctor arcu ipsum."
                  href="#"
                />
                <SiteHeader.NavItem title="Link Title" href="#" />
              </SiteHeader.NavList>
            </SiteHeader.Column>
          </SiteHeader.Section>
        </SiteHeader.SectionStack>
        <SiteHeader.Section title="Section title">
          <SiteHeader.Column>{featuredItem}</SiteHeader.Column>
        </SiteHeader.Section>
      </SiteHeader.Menu>
      <SiteHeader.Menu title="4col">
        <SiteHeader.SectionStack>
          <SiteHeader.Section title="Section title">
            <SiteHeader.Column>
              <SiteHeader.NavList>
                <SiteHeader.NavItem title="Link Title" href="#" />
                <SiteHeader.NavItem title="Link Title" href="#" />
              </SiteHeader.NavList>
            </SiteHeader.Column>
          </SiteHeader.Section>
          <SiteHeader.Section title="Section title">
            <SiteHeader.Column>
              <SiteHeader.NavList>
                <SiteHeader.NavItem title="Link Title" href="#" />
                <SiteHeader.NavItem
                  title="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla auctor arcu ipsum."
                  href="#"
                />
                <SiteHeader.NavItem title="Link Title" href="#" />
              </SiteHeader.NavList>
            </SiteHeader.Column>
          </SiteHeader.Section>
        </SiteHeader.SectionStack>
        <SiteHeader.Section title="Section title">
          <SiteHeader.Column>
            <SiteHeader.NavList>
              <SiteHeader.NavItem title="Link Title" href="#" />
              <SiteHeader.NavItem
                title="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla auctor arcu ipsum."
                href="#"
              />
              <SiteHeader.NavItem title="Link Title" href="#" />
              <SiteHeader.NavItem
                description="Description"
                title="Link Title"
                href="#"
              />
              <SiteHeader.NavItem
                description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla auctor arcu ipsum."
                title="Link Title"
                href="#"
              />
              <SiteHeader.NavItem title="Something else" href="#" summary />
            </SiteHeader.NavList>
          </SiteHeader.Column>
        </SiteHeader.Section>
        <SiteHeader.SectionStack>
          <SiteHeader.Section title="Section title">
            <SiteHeader.Column>
              <SiteHeader.NavList>
                <SiteHeader.NavItem title="Link Title" href="#" />
                <SiteHeader.NavItem title="Link Title" href="#" />
              </SiteHeader.NavList>
            </SiteHeader.Column>
          </SiteHeader.Section>
          <SiteHeader.Section title="Section title">
            <SiteHeader.Column>
              <SiteHeader.NavList>
                <SiteHeader.NavItem title="Link Title" href="#" />
                <SiteHeader.NavItem
                  title="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla auctor arcu ipsum."
                  href="#"
                />
                <SiteHeader.NavItem title="Link Title" href="#" />
              </SiteHeader.NavList>
            </SiteHeader.Column>
          </SiteHeader.Section>
        </SiteHeader.SectionStack>
        <SiteHeader.Section title="Section title">
          <SiteHeader.Column>{featuredItem}</SiteHeader.Column>
        </SiteHeader.Section>
      </SiteHeader.Menu>
      <SiteHeader.Menu title="1-2">
        <SiteHeader.Section title="Section title">
          <SiteHeader.Column>
            <Block height="403px" />
          </SiteHeader.Column>
        </SiteHeader.Section>
        <SiteHeader.Section title="Section title" columns={2}>
          <SiteHeader.Column>
            <Block height="403px" />
          </SiteHeader.Column>
          <SiteHeader.Column>
            <Block height="403px" />
          </SiteHeader.Column>
        </SiteHeader.Section>
      </SiteHeader.Menu>
      <SiteHeader.Menu title="1-3">
        <SiteHeader.Section title="Section title">
          <SiteHeader.Column>
            <Block height="403px" />
          </SiteHeader.Column>
        </SiteHeader.Section>
        <SiteHeader.Section title="Section title" columns={3}>
          <SiteHeader.Column>
            <Block height="403px" />
          </SiteHeader.Column>
          <SiteHeader.Column>
            <Block height="403px" />
          </SiteHeader.Column>
          <SiteHeader.Column>
            <Block height="403px" />
          </SiteHeader.Column>
        </SiteHeader.Section>
      </SiteHeader.Menu>
      <SiteHeader.Menu title="2-2">
        <SiteHeader.Section title="Section title" columns={2}>
          <SiteHeader.Column>
            <Block height="403px" />
          </SiteHeader.Column>
          <SiteHeader.Column>
            <Block height="403px" />
          </SiteHeader.Column>
        </SiteHeader.Section>
        <SiteHeader.Section title="Section title" columns={2}>
          <SiteHeader.Column>
            <Block height="403px" />
          </SiteHeader.Column>
          <SiteHeader.Column>
            <Block height="403px" />
          </SiteHeader.Column>
        </SiteHeader.Section>
      </SiteHeader.Menu>
      <SiteHeader.Menu title="Flyouts" fallbackHref="https://www.example.com">
        <SiteHeader.Flyouts
          summaryLinkHref="https://example.com"
          summaryLinkText="Link Title"
        >
          <SiteHeader.Flyouts.TabPanel id="tab1" tabTitle="1col">
            <SiteHeader.SectionStack>
              <SiteHeader.Section title="Section title">
                <SiteHeader.Column>
                  <SiteHeader.NavList>
                    <SiteHeader.NavItem title="Link Title" href="#" />
                    <SiteHeader.NavItem title="Link Title" href="#" />
                    <SiteHeader.NavItem title="Link Title" href="#" />
                  </SiteHeader.NavList>
                </SiteHeader.Column>
              </SiteHeader.Section>
              <SiteHeader.Section title="Section title">
                <SiteHeader.Column>
                  <SiteHeader.NavList>
                    <SiteHeader.NavItem title="Link Title" href="#" />
                    <SiteHeader.NavItem
                      title="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla auctor arcu ipsum."
                      href="#"
                    />
                    <SiteHeader.NavItem title="Link Title" href="#" />
                  </SiteHeader.NavList>
                </SiteHeader.Column>
              </SiteHeader.Section>
            </SiteHeader.SectionStack>
          </SiteHeader.Flyouts.TabPanel>
          <SiteHeader.Flyouts.TabPanel id="tab2" tabTitle="2col">
            <SiteHeader.Section title="Section title">
              <SiteHeader.Column>
                <SiteHeader.NavList>
                  <SiteHeader.NavItem title="Link Title" href="#" />
                  <SiteHeader.NavItem
                    title="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla auctor arcu ipsum."
                    href="#"
                  />
                  <SiteHeader.NavItem title="Link Title" href="#" />
                  <SiteHeader.NavItem
                    description="Description"
                    title="Link Title"
                    href="#"
                  />
                  <SiteHeader.NavItem
                    description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla auctor arcu ipsum."
                    title="Link Title"
                    href="#"
                  />
                  <SiteHeader.NavItem title="Link title" href="#" summary />
                </SiteHeader.NavList>
              </SiteHeader.Column>
            </SiteHeader.Section>
            <SiteHeader.Section title="Featured Item">
              <SiteHeader.Column>{featuredItem}</SiteHeader.Column>
            </SiteHeader.Section>
          </SiteHeader.Flyouts.TabPanel>
          <SiteHeader.Flyouts.TabPanel id="tab3" tabTitle="3col">
            <SiteHeader.Section title="Section title">
              <SiteHeader.Column>
                <SiteHeader.NavList>
                  <SiteHeader.NavItem title="Link Title" href="#" />
                  <SiteHeader.NavItem
                    title="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla auctor arcu ipsum."
                    href="#"
                  />
                  <SiteHeader.NavItem title="Link Title" href="#" />
                  <SiteHeader.NavItem
                    description="Description"
                    title="Link Title"
                    href="#"
                  />
                  <SiteHeader.NavItem
                    description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla auctor arcu ipsum."
                    title="Link Title"
                    href="#"
                  />
                  <SiteHeader.NavItem title="Link title" href="#" summary />
                </SiteHeader.NavList>
              </SiteHeader.Column>
            </SiteHeader.Section>
            <SiteHeader.SectionStack>
              <SiteHeader.Section title="Section title">
                <SiteHeader.Column>
                  <SiteHeader.NavList>
                    <SiteHeader.NavItem title="Link Title" href="#" />
                    <SiteHeader.NavItem title="Link Title" href="#" />
                    <SiteHeader.NavItem title="Link Title" href="#" />
                  </SiteHeader.NavList>
                </SiteHeader.Column>
              </SiteHeader.Section>
              <SiteHeader.Section title="Section title">
                <SiteHeader.Column>
                  <SiteHeader.NavList>
                    <SiteHeader.NavItem title="Link Title" href="#" />
                    <SiteHeader.NavItem
                      title="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla auctor arcu ipsum."
                      href="#"
                    />
                    <SiteHeader.NavItem title="Link Title" href="#" />
                  </SiteHeader.NavList>
                </SiteHeader.Column>
              </SiteHeader.Section>
            </SiteHeader.SectionStack>
            <SiteHeader.Section title="Featured Item">
              <SiteHeader.Column>{featuredItem}</SiteHeader.Column>
            </SiteHeader.Section>
          </SiteHeader.Flyouts.TabPanel>
          <SiteHeader.Flyouts.TabPanel id="tab4" tabTitle="2-1">
            <SiteHeader.Section title="Section title" columns={2}>
              <SiteHeader.Column>
                <SiteHeader.NavList>
                  <SiteHeader.NavItem title="Link Title" href="#" />
                  <SiteHeader.NavItem
                    title="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla auctor arcu ipsum."
                    href="#"
                  />
                  <SiteHeader.NavItem title="Link Title" href="#" />
                  <SiteHeader.NavItem
                    description="Description"
                    title="Link Title"
                    href="#"
                  />
                  <SiteHeader.NavItem
                    description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla auctor arcu ipsum."
                    title="Link Title"
                    href="#"
                  />
                  <SiteHeader.NavItem title="Link title" href="#" summary />
                </SiteHeader.NavList>
              </SiteHeader.Column>
              <SiteHeader.Column>
                <SiteHeader.NavList>
                  <SiteHeader.NavItem title="Link Title" href="#" />
                  <SiteHeader.NavItem
                    title="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla auctor arcu ipsum."
                    href="#"
                  />
                  <SiteHeader.NavItem title="Link Title" href="#" />
                  <SiteHeader.NavItem
                    description="Description"
                    title="Link Title"
                    href="#"
                  />
                  <SiteHeader.NavItem
                    description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla auctor arcu ipsum."
                    title="Link Title"
                    href="#"
                  />
                </SiteHeader.NavList>
              </SiteHeader.Column>
            </SiteHeader.Section>
            <SiteHeader.SectionStack>
              <SiteHeader.Section title="Section title">
                <SiteHeader.Column>
                  <SiteHeader.NavList>
                    <SiteHeader.NavItem title="Link Title" href="#" />
                    <SiteHeader.NavItem title="Link Title" href="#" />
                    <SiteHeader.NavItem title="Link Title" href="#" />
                  </SiteHeader.NavList>
                </SiteHeader.Column>
              </SiteHeader.Section>
              <SiteHeader.Section title="Section title">
                <SiteHeader.Column>
                  <SiteHeader.NavList>
                    <SiteHeader.NavItem title="Link Title" href="#" />
                    <SiteHeader.NavItem
                      title="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla auctor arcu ipsum."
                      href="#"
                    />
                    <SiteHeader.NavItem title="Link Title" href="#" />
                  </SiteHeader.NavList>
                </SiteHeader.Column>
              </SiteHeader.Section>
            </SiteHeader.SectionStack>
          </SiteHeader.Flyouts.TabPanel>
          <SiteHeader.Flyouts.TabPanel
            id="tab5"
            tabTitle="Lorem ipsum dolor sit amet consectetur adipiscing elit"
          >
            <SiteHeader.Section title="Featured Item">
              <SiteHeader.Column>{featuredItem}</SiteHeader.Column>
            </SiteHeader.Section>
          </SiteHeader.Flyouts.TabPanel>
        </SiteHeader.Flyouts>
      </SiteHeader.Menu>
    </SiteHeader>
  </MarkupContainer>
);

SampleSiteHeader.propTypes = {
  siteName: propTypes.string
};

export default SampleSiteHeader;
