import React from "react";
import { storiesOf } from "@storybook/react";
import { text, number, withKnobs } from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";
import { Language } from "@emcm-ui/component-theme";
import StaticRenderer from "@emcm-ui/component-static-renderer";
import SiteFooter from "./index";
import Icon from "@emcm-ui/component-icon";
import "./SiteFooter.css";
import SiteFooterREADME from "../README.md";

const stories = storiesOf("Components/SiteFooter", module);

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(SiteFooterREADME));

const getQueryParamValByName = param => {
  const params = new URLSearchParams(window.top.document.location.search);

  return params.get(param);
};

const elasticURL =
  "https://run.mocky.io/v3/fa0967b7-8058-4356-a92f-48d2197205e2?";

const elasticResultSizeLimit = 7;

const lists = [
  <SiteFooter.NavList title="About LSEG">
    <SiteFooter.NavItem title="LSEG Group" href="#" />
    <SiteFooter.NavItem title="Careers" href="#" />
    <SiteFooter.NavItem title="Media Centre" href="#" />
    <SiteFooter.NavItem title="Studio/Venue Hire" href="#" />
  </SiteFooter.NavList>,
  <SiteFooter.NavList title="Corporate">
    <SiteFooter.NavItem title="Stakeholder Info" href="#" />
    <SiteFooter.NavItem title="Board of Directors" href="#" />
    <SiteFooter.NavItem title="Executive Teams" href="#" />
    <SiteFooter.NavItem title="Structure &amp; Governance" href="#" />
  </SiteFooter.NavList>,
  <SiteFooter.NavList title="Contact Us">
    <SiteFooter.NavItem title="Sales" href="#" />
    <SiteFooter.NavItem title="Product Support" href="#" />
    <SiteFooter.NavItem title="Account Support" href="#" />
    <SiteFooter.NavItem title="Media Relations" href="#" />
    <SiteFooter.NavItem title="Office Locations" href="#" />
  </SiteFooter.NavList>,
  <SiteFooter.NavList title="LSEG Brands">
    <SiteFooter.NavItem title="FTSE Russell" href="#" />
    <SiteFooter.NavItem title="LCH" href="#" />
    <SiteFooter.NavItem title="London Stock Exchange" href="#" />
    <SiteFooter.NavItem title="Refinitiv" href="#" />
  </SiteFooter.NavList>,
  <SiteFooter.NavList title="Connect">
    <SiteFooter.NavItem title="Support & Training" href="#" />
    <SiteFooter.NavItem title="Office locations" href="#" />
    <SiteFooter.NavItem title="Media relations" href="#" />
    <SiteFooter.NavItem title="sales" href="#" />
  </SiteFooter.NavList>
];

const arabicLists = [
  <SiteFooter.NavList title="حول تومسون رويترز">
    <SiteFooter.NavItem title="أبوت أوس" href="#" />
    <SiteFooter.NavItem title="علاقات المستثمرين" href="#" />
    <SiteFooter.NavItem title="نيوسروم" href="#" />
    <SiteFooter.NavItem title="بيان صحفي" href="#" />
    <SiteFooter.NavItem title="الابتكار" href="#" />
    <SiteFooter.NavItem title="غلوبال سيت ديركتوري" href="#" />
  </SiteFooter.NavList>,
  <SiteFooter.NavList title="المنتجات والخدمات">
    <SiteFooter.NavItem title="جميع المنتجات" href="#" />
    <SiteFooter.NavItem title="فينانسيال" href="#" />
    <SiteFooter.NavItem title="غوفرنمنت" href="#" />
    <SiteFooter.NavItem title="ليجال" href="#" />
    <SiteFooter.NavItem title="وكالة رويترز للأنباء " href="#" />
    <SiteFooter.NavItem title="إدارة المخاطر" href="#" />
    <SiteFooter.NavItem title="تاكس & أكونتينغ" href="#" />
  </SiteFooter.NavList>,
  <SiteFooter.NavList title="الموارد">
    <SiteFooter.NavItem title="وظائف" href="#" />
    <SiteFooter.NavItem title="أنسويرس أون" href="#" />
    <SiteFooter.NavItem title="برودوكت لوجينز" href="#" />
    <SiteFooter.NavItem title="موبيل أبس" href="#" />
    <SiteFooter.NavItem title="نو 360 بوبليكاتيونس" href="#" />
  </SiteFooter.NavList>,
  <SiteFooter.NavList title="اتصل بنا">
    <SiteFooter.NavItem title="الدعم والتدريب" href="#" />
    <SiteFooter.NavItem title="مواقع المكاتب" href="#" />
    <SiteFooter.NavItem title="العلاقات الإعلامية" href="#" />
    <SiteFooter.NavItem title="ساليس" href="#" />
  </SiteFooter.NavList>,
  <SiteFooter.NavList title="كونيكت">
    <SiteFooter.NavItem title="فاسيبوك" href="#" />
    <SiteFooter.NavItem title="تويتر" href="#" />
    <SiteFooter.NavItem title="لينكيدين" href="#" />
    <SiteFooter.NavItem title="Google+" href="#" />
    <SiteFooter.NavItem title="يوتوب" href="#" />
    <SiteFooter.NavItem title="إنستاغرام" href="#" />
  </SiteFooter.NavList>
];

const listsWithSocial = [
  <SiteFooter.NavList title="About LSEG">
    <SiteFooter.NavItem title="LSEG Group" href="#" />
    <SiteFooter.NavItem title="Careers" href="#" />
    <SiteFooter.NavItem title="Media Centre" href="#" />
    <SiteFooter.NavItem title="Studio/Venue Hire" href="#" />
  </SiteFooter.NavList>,
  <SiteFooter.NavList title="Corporate">
    <SiteFooter.NavItem title="Stakeholder Info" href="#" />
    <SiteFooter.NavItem title="Board of Directors" href="#" />
    <SiteFooter.NavItem title="Executive Teams" href="#" />
    <SiteFooter.NavItem title="Structure &amp; Governance" href="#" />
  </SiteFooter.NavList>,
  <SiteFooter.NavList title="Contact Us">
    <SiteFooter.NavItem title="Sales" href="#" />
    <SiteFooter.NavItem title="Product Support" href="#" />
    <SiteFooter.NavItem title="Account Support" href="#" />
    <SiteFooter.NavItem title="Media Relations" href="#" />
    <SiteFooter.NavItem title="Office Locations" href="#" />
  </SiteFooter.NavList>,
  <SiteFooter.NavList title="LSEG Brands">
    <SiteFooter.NavItem title="FTSE Russell" href="#" />
    <SiteFooter.NavItem title="LCH" href="#" />
    <SiteFooter.NavItem title="London Stock Exchange" href="#" />
    <SiteFooter.NavItem title="Refinitiv" href="#" />
  </SiteFooter.NavList>,
  <SiteFooter.NavList title="Connect with us">
    <SiteFooter.NavItem
      title="Facebook"
      href="#"
      icon={<Icon path="./icons/social/white/facebook.svg" size="s" />}
    />
    <SiteFooter.NavItem
      title="Instagram"
      href="#"
      icon={<Icon path="./icons/social/white/instagram.svg" size="s" />}
    />
    <SiteFooter.NavItem
      title="LinkedIn"
      href="#"
      icon={<Icon path="./icons/social/white/linkedin.svg" size="s" />}
    />
    <SiteFooter.NavItem
      title="Twitter"
      href="#"
      icon={<Icon path="./icons/social/white/twitter.svg" size="s" />}
    />
    <SiteFooter.NavItem
      title="YouTube"
      href="#"
      icon={<Icon path="./icons/social/white/youtube.svg" size="s" />}
    />
  </SiteFooter.NavList>
];

stories
  .add("Default", () => (
    <SiteFooter
      searchFormAction={text("searchFormAction", "/")}
      searchFormMethod={text("searchFormMethod", "get")}
      searchInputName={text("searchInputName", "q")}
      typeaheadUrl={text(
        "typeaheadUrl",
        "//content.atomz.com/autocomplete/sp10/05/0d/51?_=1506335725065"
      )}
    >
      {lists.slice(0, number("Number of lists", lists.length))}
    </SiteFooter>
  ))
  .add("Social Links", () => (
    <SiteFooter
      searchFormAction={text("searchFormAction", "/")}
      searchFormMethod={text("searchFormMethod", "get")}
      searchInputName={text("searchInputName", "q")}
      typeaheadUrl={text(
        "typeaheadUrl",
        "//content.atomz.com/autocomplete/sp10/05/0d/51?_=1506335725065"
      )}
    >
      {listsWithSocial.slice(
        0,
        number("Number of lists", listsWithSocial.length)
      )}
    </SiteFooter>
  ))
  .add("Language: Arabic (RTL)", () => (
    <Language code="ar">
      <SiteFooter
        searchFormAction={text("searchFormAction", "/")}
        searchFormMethod={text("searchFormMethod", "get")}
        searchInputName={text("searchInputName", "q")}
        typeaheadUrl={text(
          "typeaheadUrl",
          "//content.atomz.com/autocomplete/sp10/05/0d/51?_=1506335725065"
        )}
      >
        {arabicLists.slice(0, number("Number of lists", lists.length))}
      </SiteFooter>
    </Language>
  ))
  .add("Rehydrated", () => (
    <StaticRenderer>
      <SiteFooter
        searchFormAction={text("searchFormAction", "/")}
        searchFormMethod={text("searchFormMethod", "get")}
        searchInputName={text("searchInputName", "q")}
        typeaheadUrl={text(
          "typeaheadUrl",
          "//content.atomz.com/autocomplete/sp10/05/0d/51?_=1506335725065"
        )}
      >
        {lists}
      </SiteFooter>
    </StaticRenderer>
  ))
  .add("Elastic Search", () => (
    <SiteFooter
      searchFormAction={text("searchFormAction", "/")}
      searchFormMethod={text("searchFormMethod", "get")}
      searchInputName={text("searchInputName", "q")}
      typeaheadUrl={text("typeaheadUrl", elasticURL)}
      elasticKey={text("elasticKey", getQueryParamValByName("elasticKey"))}
      autosuggestion={text("autosuggestion", "description,w_internal_title")}
      size={number("size", elasticResultSizeLimit)}
    >
      {lists.slice(0, number("Number of lists", lists.length))}
    </SiteFooter>
  ))
  .add("Rehyderated Elastic Search", () => (
    <StaticRenderer>
      <SiteFooter
        searchFormAction={text("searchFormAction", "/")}
        searchFormMethod={text("searchFormMethod", "get")}
        searchInputName={text("searchInputName", "q")}
        typeaheadUrl={text("typeaheadUrl", elasticURL)}
        elasticKey={text("elasticKey", getQueryParamValByName("elasticKey"))}
        autosuggestion={text("autosuggestion", "description,w_internal_title")}
        size={number("size", elasticResultSizeLimit)}
      >
        {lists.slice(0, number("Number of lists", lists.length))}
      </SiteFooter>
    </StaticRenderer>
  ));
