import React, { Component } from "react";
import PropTypes from "prop-types";

import { SVGIcon } from "@emcm-ui/component-icon/lib/svg";

class DropdownHeader extends Component {
  static displayName = "DropdownHeader";

  componentDidUpdate() {
    // eslint-disable-next-line no-unused-expressions
    this.inputElm && this.inputElm.focus();
  }

  render() {
    const {
      classNames,
      expanded,
      getClassName,
      hasError,
      icon,
      onFilterItems,
      searchPlaceholder = "Search from the list",
      userInput,
      text,
      toggleExpand,
      typeAheadEnabled
    } = this.props;

    return expanded && typeAheadEnabled ? (
      <div
        className={getClassName({
          descendantName: "header",
          states: classNames({
            error: hasError
          })
        })}
      >
        <input
          className={getClassName({ descendantName: "input" })}
          ref={input => (this.inputElm = input)}
          placeholder={searchPlaceholder}
          onChange={elm => onFilterItems(elm.target.value)}
          value={userInput}
        />
        <span className={getClassName({ descendantName: "headerIcon" })}>
          {expanded ? (
            <SVGIcon
              name="caret"
              style={{ transform: "rotate(180deg)" }}
              size="s"
            />
          ) : (
            <SVGIcon name="caret" size="s" />
          )}
        </span>
      </div>
    ) : (
      <button
        className={getClassName({
          descendantName: "header",
          states: classNames({
            error: hasError
          })
        })}
        onClick={toggleExpand}
        tabIndex="0"
      >
        {icon && (
          <span className={getClassName({ descendantName: "itemIcon" })}>
            {icon}
          </span>
        )}
        <span className={getClassName({ descendantName: "headerText" })}>
          {text}
        </span>

        <span className={getClassName({ descendantName: "headerIcon" })}>
          {expanded ? (
            <SVGIcon
              name="caret"
              style={{ transform: "rotate(180deg)" }}
              size="s"
            />
          ) : (
            <SVGIcon name="caret" size="s" />
          )}
        </span>
      </button>
    );
  }
  static propTypes = {
    classNames: PropTypes.func.isRequired,
    expanded: PropTypes.bool.isRequired,
    getClassName: PropTypes.func.isRequired,
    hasError: PropTypes.bool.isRequired,
    icon: PropTypes.node,
    onFilterItems: PropTypes.func,
    searchPlaceholder: PropTypes.string,
    userInput: PropTypes.string,
    text: PropTypes.string.isRequired,
    toggleExpand: PropTypes.func.isRequired,
    typeAheadEnabled: PropTypes.bool
  };
}

export default DropdownHeader;
