import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";
import getClassNameFactory from "@emcm-ui/utility-class-names";

const BlockQuote = ({ attribution, avatar, cite, link, small, text }) => {
  const getClassName = getClassNameFactory(BlockQuote.displayName);
  const withAvatar = avatar ? "withAvatar" : null;

  return (
    <div
      className={getClassName({ modifiers: classNames({ small, withAvatar }) })}
    >
      {avatar &&
        !small && (
          <div className={getClassName({ descendantName: "avatar" })}>
            <div className={getClassName({ descendantName: "avatarBounding" })}>
              {avatar}
            </div>
          </div>
        )}
      <div className={getClassName({ descendantName: "body" })}>
        <blockquote
          cite={cite}
          className={getClassName({ descendantName: "quote" })}
        >
          {text}
        </blockquote>
        {avatar &&
          small && (
            <div className={getClassName({ descendantName: "avatar" })}>
              <div
                className={getClassName({ descendantName: "avatarBounding" })}
              >
                {avatar}
              </div>
            </div>
          )}
        {(attribution || link) && (
          <cite className={getClassName({ descendantName: "footer" })}>
            {attribution && (
              <div className={getClassName({ descendantName: "attribution" })}>
                {attribution}
              </div>
            )}
            {link && (
              <div className={getClassName({ descendantName: "link" })}>
                {link}
              </div>
            )}
          </cite>
        )}
      </div>
    </div>
  );
};

BlockQuote.displayName = "BlockQuote";

/* eslint-disable max-len */
BlockQuote.propTypes = {
  /**
   * Attribution component for attributing the quotation
   */
  attribution: PropTypes.node,
  /**
   * Avatar component to accompany the quotation
   */
  avatar: PropTypes.node,
  /**
   * A URL that designates a source document or message for the
   * information quoted.
   */
  cite: PropTypes.string,
  /**
   * Link component to accompany the quotation
   */
  link: PropTypes.node,
  /**
   * The quotation
   */
  text: PropTypes.string.isRequired,
  /**
   * Optional small variant
   */
  small: PropTypes.bool
};
/* eslint-enable max-len */

BlockQuote.defaultProps = { small: false };

export default BlockQuote;
