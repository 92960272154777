import React from "react";
import GlobalFooter from "./GlobalFooter";

export default async (domNode, rehydrateChildren) => {
  const childrenNode = domNode.querySelector("[data-rehydratable-children]");
  const reactifiedChildren = await rehydrateChildren(childrenNode);

  const props = {
    logoHref: domNode.getAttribute("data-logo-href"),
    logoText: domNode.getAttribute("data-logo-text"),
    logoPath: domNode.getAttribute("data-logo-path")
  };

  return <GlobalFooter {...props}>{reactifiedChildren}</GlobalFooter>;
};
