import React from "react";
import { storiesOf } from "@storybook/react";
import { withKnobs, boolean, select, text } from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";
import { Language } from "@emcm-ui/component-theme";
import Icon from "@emcm-ui/component-icon";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import ListOfLinks from "./index";
import "./ListOfLinks.css";
import ListOfLinksREADME from "../README.md";

const getClassName = getClassNameFactory(ListOfLinks.BlockLink.displayName);

const singleColStories = storiesOf(
  "Components/ListOfLinks/Single Column",
  module
);
const multiColStories = storiesOf(
  "Components/ListOfLinks/Multi Column",
  module
);
const arabicStories = storiesOf(
  "Components/ListOfLinks/Language: Arabic (RTL)",
  module
);

singleColStories.addDecorator(withKnobs);
singleColStories.addDecorator(withReadme(ListOfLinksREADME));
multiColStories.addDecorator(withKnobs);
multiColStories.addDecorator(withReadme(ListOfLinksREADME));
arabicStories.addDecorator(withKnobs);
arabicStories.addDecorator(withReadme(ListOfLinksREADME));

const BlockLinkIconList = ["blue", "grey"];
const iconName = ["arrow", "download", "external"];
const icon = {
  arrow: <Icon path="./icons/ui/blue/arrow-right.svg" size="s" />,
  download: <Icon path="./icons/ui/blue/download.svg" size="s" />,
  external: <Icon path="./icons/ui/blue/external.svg" size="s" />
};
const blueIcons = {
  arrow: <Icon path="./icons/ui/blue/arrow-right.svg" size="s" />,
  download: <Icon path="./icons/ui/blue/download.svg" size="s" />,
  external: <Icon path="./icons/ui/blue/external.svg" size="s" />
};
const greyIcons = {
  arrow: <Icon path="./icons/ui/grey/arrow-right-07.svg" size="s" />,
  download: <Icon path="./icons/ui/grey/download-07.svg" size="s" />,
  external: <Icon path="./icons/ui/grey/external-07.svg" size="s" />
};

singleColStories
  .add("Simple", () => (
    <ListOfLinks topBorder={boolean("topBorder", true)}>
      <ListOfLinks.Link href="https://www.example.com" type="inline">
        {text("title", "Link")}
      </ListOfLinks.Link>
      <ListOfLinks.Link href="https://www.example.com" type="inline">
        {text("title", "Link")}
      </ListOfLinks.Link>
      <ListOfLinks.Link href="https://www.example.com" type="inline">
        {text("title", "Link")}
      </ListOfLinks.Link>
      <ListOfLinks.Link href="https://www.example.com" type="inline">
        {text("title", "Link")}
      </ListOfLinks.Link>
      <ListOfLinks.Link
        href="https://www.example.com"
        type="icon"
        icon={icon[select("icon", iconName, "arrow")]}
        iconPosition={"right"}
      >
        Link
      </ListOfLinks.Link>
    </ListOfLinks>
  ))
  .add("Nested", () => (
    <ListOfLinks topBorder={boolean("topBorder", true)}>
      <ListOfLinks.Link href="https://www.example.com" type="inline">
        Munere Omnesque
      </ListOfLinks.Link>
      <ListOfLinks.Link href="https://www.example.com" type="inline">
        Legendos Posidonium
      </ListOfLinks.Link>
      <ListOfLinks.Link href="https://www.example.com" type="inline">
        Voluptaria Referrentur Mei
      </ListOfLinks.Link>

      <ListOfLinks.Group
        title="Non Link Parent Moderatius Delicatissim"
        type="icon"
      >
        <ListOfLinks.Link href="https://www.example.com" type="inline">
          Laoreet Minimum
        </ListOfLinks.Link>
        <ListOfLinks.Link href="https://www.example.com" type="inline">
          Legendos Posidonium
        </ListOfLinks.Link>
      </ListOfLinks.Group>

      <ListOfLinks.Link href="https://www.example.com" type="inline">
        Munere Omnesque
      </ListOfLinks.Link>
      <ListOfLinks.Link href="https://www.example.com" type="inline">
        Legendos Posidonium
      </ListOfLinks.Link>
      <ListOfLinks.Link href="https://www.example.com" type="inline">
        Voluptaria Referrentur Mei
      </ListOfLinks.Link>

      <ListOfLinks.Group
        title="Link Parent Moderatius Delicatissim"
        href="https://www.example.com"
        type="icon"
        icon={icon[select("icon", iconName, "arrow")]}
        iconPosition={"right"}
      >
        <ListOfLinks.Link href="https://www.example.com" type="inline">
          Laoreet Minimum
        </ListOfLinks.Link>
        <ListOfLinks.Link href="https://www.example.com" type="inline">
          Legendos Posidonium
        </ListOfLinks.Link>
      </ListOfLinks.Group>

      <ListOfLinks.Link href="https://www.example.com" type="inline">
        Munere Omnesque
      </ListOfLinks.Link>
      <ListOfLinks.Link href="https://www.example.com" type="inline">
        Legendos Posidonium
      </ListOfLinks.Link>
      <ListOfLinks.Link href="https://www.example.com" type="icon">
        View More Resources
      </ListOfLinks.Link>
    </ListOfLinks>
  ))
  .add("BlockLink", () => (
    <ListOfLinks>
      <ListOfLinks.BlockLink
        color={select("color", BlockLinkIconList)}
        href="https://www.example.com"
        eyebrow={text("eyebrow", "Eyebrow")}
        icon={
          select("color", BlockLinkIconList) === "grey"
            ? greyIcons[select("icon", iconName)]
            : blueIcons[select("icon", iconName)]
        }
      >
        {text("title", "BlockLink")}
      </ListOfLinks.BlockLink>
      <ListOfLinks.BlockLink
        color={select("color", BlockLinkIconList)}
        href="https://www.example.com"
        eyebrow={text("eyebrow", "Eyebrow")}
        icon={
          select("color", BlockLinkIconList) === "grey"
            ? greyIcons[select("icon", iconName)]
            : blueIcons[select("icon", iconName)]
        }
      >
        {text("title", "BlockLink")}
      </ListOfLinks.BlockLink>
      <ListOfLinks.BlockLink
        color={select("color", BlockLinkIconList)}
        href="https://www.example.com"
        eyebrow={text("eyebrow", "Eyebrow")}
        icon={
          select("color", BlockLinkIconList) === "grey"
            ? greyIcons[select("icon", iconName)]
            : blueIcons[select("icon", iconName)]
        }
      >
        {text("title", "BlockLink")}
      </ListOfLinks.BlockLink>
      <ListOfLinks.BlockLink
        color={select("color", BlockLinkIconList)}
        href="https://www.example.com"
        eyebrow={text("eyebrow", "Eyebrow")}
        icon={
          select("color", BlockLinkIconList) === "grey"
            ? greyIcons[select("icon", iconName)]
            : blueIcons[select("icon", iconName)]
        }
      >
        {text("title", "BlockLink")}
      </ListOfLinks.BlockLink>
      <ListOfLinks.BlockLink
        color={select("color", BlockLinkIconList)}
        href="https://www.example.com"
        eyebrow={text("eyebrow", "Eyebrow")}
        icon={
          select("color", BlockLinkIconList) === "grey"
            ? greyIcons[select("icon", iconName)]
            : blueIcons[select("icon", iconName)]
        }
      >
        BlockLink with a long title. Lorem ipsum dolor sit amet, consectetur
        adipiscing elit.
      </ListOfLinks.BlockLink>
    </ListOfLinks>
  ))
  .add("Link to file", () => (
    <ListOfLinks>
      <ListOfLinks.BlockLink
        color={select("color", BlockLinkIconList)}
        href="https://www.example.com"
        icon={
          select("color", BlockLinkIconList) === "grey"
            ? greyIcons[select("icon", iconName)]
            : blueIcons[select("icon", iconName)]
        }
        metadata="285KB  •  PDF"
      >
        {text("title", "Link text")}
      </ListOfLinks.BlockLink>
      <ListOfLinks.BlockLink
        color={select("color", BlockLinkIconList)}
        href="https://www.example.com"
        icon={
          select("color", BlockLinkIconList) === "grey"
            ? greyIcons[select("icon", iconName)]
            : blueIcons[select("icon", iconName)]
        }
        metadata="285KB  •  PDF"
      >
        {text("title", "Link text")}
      </ListOfLinks.BlockLink>
      <ListOfLinks.BlockLink
        color={select("color", BlockLinkIconList)}
        href="https://www.example.com"
        icon={
          select("color", BlockLinkIconList) === "grey"
            ? greyIcons[select("icon", iconName)]
            : blueIcons[select("icon", iconName)]
        }
        metadata="285KB  •  PDF"
      >
        {text("title", "Link text")}
      </ListOfLinks.BlockLink>
      <ListOfLinks.BlockLink
        color={select("color", BlockLinkIconList)}
        href="https://www.example.com"
        icon={
          select("color", BlockLinkIconList) === "grey"
            ? greyIcons[select("icon", iconName)]
            : blueIcons[select("icon", iconName)]
        }
        metadata="285KB  •  PDF"
      >
        {text("title", "Link text")}
      </ListOfLinks.BlockLink>
    </ListOfLinks>
  ))
  .add("BlockLink Small", () => (
    <ListOfLinks>
      <ListOfLinks.BlockLink
        color={select("color", BlockLinkIconList)}
        href="https://www.example.com"
        eyebrow={text("eyebrow", "Eyebrow")}
        size="small"
        icon={
          select("color", BlockLinkIconList) === "grey"
            ? greyIcons[select("icon", iconName)]
            : blueIcons[select("icon", iconName)]
        }
      >
        {text("title", "BlockLink")}
      </ListOfLinks.BlockLink>
      <ListOfLinks.BlockLink
        color={select("color", BlockLinkIconList)}
        href="https://www.example.com"
        eyebrow={text("eyebrow", "Eyebrow")}
        size="small"
        icon={
          select("color", BlockLinkIconList) === "grey"
            ? greyIcons[select("icon", iconName)]
            : blueIcons[select("icon", iconName)]
        }
      >
        {text("title", "BlockLink")}
      </ListOfLinks.BlockLink>
      <ListOfLinks.BlockLink
        color={select("color", BlockLinkIconList)}
        href="https://www.example.com"
        eyebrow={text("eyebrow", "Eyebrow")}
        size="small"
        icon={
          select("color", BlockLinkIconList) === "grey"
            ? greyIcons[select("icon", iconName)]
            : blueIcons[select("icon", iconName)]
        }
      >
        {text("title", "BlockLink")}
      </ListOfLinks.BlockLink>
      <ListOfLinks.BlockLink
        color={select("color", BlockLinkIconList)}
        href="https://www.example.com"
        eyebrow={text("eyebrow", "Eyebrow")}
        size="small"
        icon={
          select("color", BlockLinkIconList) === "grey"
            ? greyIcons[select("icon", iconName)]
            : blueIcons[select("icon", iconName)]
        }
      >
        {text("title", "BlockLink")}
      </ListOfLinks.BlockLink>
      <ListOfLinks.BlockLink
        color={select("color", BlockLinkIconList)}
        href="https://www.example.com"
        eyebrow={text("eyebrow", "Eyebrow")}
        size="small"
        icon={
          select("color", BlockLinkIconList) === "grey"
            ? greyIcons[select("icon", iconName)]
            : blueIcons[select("icon", iconName)]
        }
      >
        BlockLink with a long title. Lorem ipsum dolor sit amet, consectetur
        adipiscing elit.
      </ListOfLinks.BlockLink>
      <ListOfLinks.BlockLink
        color={select("color", BlockLinkIconList)}
        href="https://www.example.com"
        size="small"
        metadata="New York  •  3 openings"
        icon={
          select("color", BlockLinkIconList) === "grey"
            ? greyIcons[select("icon", iconName)]
            : blueIcons[select("icon", iconName)]
        }
      >
        Small BlockLink with Metadata
      </ListOfLinks.BlockLink>
      <ListOfLinks.BlockLink
        color={select("color", BlockLinkIconList)}
        href="https://www.example.com"
        size="small"
        description={`The most well-known dummy text is the 'Lorem Ipsum', which is said to have originated in the 16th century. Lorem Ipsum is composed in a pseudo-Latin language which more or less corresponds to 'proper' Latin. It contains a series of real Latin words. This ancient dummy text is also incomprehensible, but it imitates the rhythm of most European languages in Latin script.`}
        icon={
          select("color", BlockLinkIconList) === "grey"
            ? greyIcons[select("icon", iconName)]
            : blueIcons[select("icon", iconName)]
        }
      >
        Small BlockLink with Description
      </ListOfLinks.BlockLink>
    </ListOfLinks>
  ))

  .add("Small BlockLink for Events", () => (
    <ListOfLinks>
      <ListOfLinks.BlockLink
        color={select("color", BlockLinkIconList)}
        href="https://www.example.com"
        size="small"
        eventMonth="JUL"
        eventDay="30"
        metadata="Mumbai, India"
        icon={
          select("color", BlockLinkIconList) === "grey"
            ? greyIcons[select("icon", iconName)]
            : blueIcons[select("icon", iconName)]
        }
      >
        Developer World - Mumbai
      </ListOfLinks.BlockLink>
      <ListOfLinks.BlockLink
        color={select("color", BlockLinkIconList)}
        href="https://www.example.com"
        size="small"
        eventMonth="JUL"
        eventDay="22"
        metadata="Baar, Switzerland"
        icon={
          select("color", BlockLinkIconList) === "grey"
            ? greyIcons[select("icon", iconName)]
            : blueIcons[select("icon", iconName)]
        }
      >
        Hack the Valley
      </ListOfLinks.BlockLink>
      <ListOfLinks.BlockLink
        color={select("color", BlockLinkIconList)}
        href="https://www.example.com"
        size="small"
        eventMonth="OCT"
        eventDay="4"
        metadata="Excel, London"
        icon={
          select("color", BlockLinkIconList) === "grey"
            ? greyIcons[select("icon", iconName)]
            : blueIcons[select("icon", iconName)]
        }
      >
        Code Central 2019
      </ListOfLinks.BlockLink>
    </ListOfLinks>
  ))

  .add("BlockLink with Meta Separator", () => (
    <ListOfLinks>
      <ListOfLinks.BlockLink
        color={select("color", BlockLinkIconList)}
        href="https://www.example.com"
        size="small"
        metadata={
          <React.Fragment>
            New York
            <span className={getClassName({ descendantName: "dotSeparator" })}>
              •
            </span>
            3 openings
          </React.Fragment>
        }
        icon={
          select("color", BlockLinkIconList) === "grey"
            ? greyIcons[select("icon", iconName)]
            : blueIcons[select("icon", iconName)]
        }
      >
        Small BlockLink with Metadata
      </ListOfLinks.BlockLink>
      <ListOfLinks.BlockLink
        color={select("color", BlockLinkIconList)}
        href="https://www.example.com"
        size="small"
        eventMonth="JUL"
        eventDay="30"
        metadata={
          <React.Fragment>
            Mumbai
            <span className={getClassName({ descendantName: "dotSeparator" })}>
              •
            </span>
            India
          </React.Fragment>
        }
        icon={
          select("color", BlockLinkIconList) === "grey"
            ? greyIcons[select("icon", iconName)]
            : blueIcons[select("icon", iconName)]
        }
      >
        Developer World - Mumbai
      </ListOfLinks.BlockLink>
    </ListOfLinks>
  ));

multiColStories
  .add("Simple", () => (
    <ListOfLinks columns={2} topBorder={boolean("topBorder", true)}>
      <ListOfLinks.Column>
        <ListOfLinks.Link href="https://www.example.com" type="inline">
          {text("title", "Link")}
        </ListOfLinks.Link>
        <ListOfLinks.Link href="https://www.example.com" type="inline">
          {text("title", "Link")}
        </ListOfLinks.Link>
        <ListOfLinks.Link href="https://www.example.com" type="inline">
          {text("title", "Link")}
        </ListOfLinks.Link>
        <ListOfLinks.Link href="https://www.example.com" type="inline">
          {text("title", "Link")}
        </ListOfLinks.Link>
        <ListOfLinks.Link href="https://www.example.com" type="inline">
          {text("title", "Link")}
        </ListOfLinks.Link>
      </ListOfLinks.Column>

      <ListOfLinks.Column>
        <ListOfLinks.Link href="https://www.example.com" type="inline">
          {text("title", "Link")}
        </ListOfLinks.Link>
        <ListOfLinks.Link href="https://www.example.com" type="inline">
          {text("title", "Link")}
        </ListOfLinks.Link>
        <ListOfLinks.Link href="https://www.example.com" type="inline">
          {text("title", "Link")}
        </ListOfLinks.Link>
        <ListOfLinks.Link href="https://www.example.com" type="inline">
          {text("title", "Link")}
        </ListOfLinks.Link>
        <ListOfLinks.Link href="https://www.example.com" type="inline">
          {text("title", "Link")}
        </ListOfLinks.Link>
      </ListOfLinks.Column>
    </ListOfLinks>
  ))
  .add("Nested, aka Large List Of Links", () => (
    <ListOfLinks columns={3} topBorder={boolean("topBorder", true)}>
      <ListOfLinks.Column>
        <ListOfLinks.Link href="https://www.example.com" type="inline">
          Munere Omnesque
        </ListOfLinks.Link>
        <ListOfLinks.Link href="https://www.example.com" type="inline">
          Legendos Posidonium
        </ListOfLinks.Link>
        <ListOfLinks.Link href="https://www.example.com" type="inline">
          Voluptaria Referrentur Mei
        </ListOfLinks.Link>

        <ListOfLinks.Group
          title="Non Link Parent Moderatius Delicatissim"
          type="icon"
        >
          <ListOfLinks.Link href="https://www.example.com" type="inline">
            Laoreet Minimum
          </ListOfLinks.Link>
          <ListOfLinks.Link href="https://www.example.com" type="inline">
            Legendos Posidonium
          </ListOfLinks.Link>
        </ListOfLinks.Group>
      </ListOfLinks.Column>

      <ListOfLinks.Column>
        <ListOfLinks.Link href="https://www.example.com" type="inline">
          Munere Omnesque
        </ListOfLinks.Link>
        <ListOfLinks.Link href="https://www.example.com" type="inline">
          Legendos Posidonium
        </ListOfLinks.Link>
        <ListOfLinks.Group
          title="Link Parent Moderatius Delicatissim"
          href="https://www.example.com"
          type="icon"
          icon={
            select("color", BlockLinkIconList) === "grey"
              ? greyIcons[select("icon", iconName)]
              : blueIcons[select("icon", iconName)]
          }
          iconPosition={"right"}
        >
          <ListOfLinks.Link href="https://www.example.com" type="inline">
            Laoreet Minimum
          </ListOfLinks.Link>
          <ListOfLinks.Link href="https://www.example.com" type="inline">
            Legendos Posidonium
          </ListOfLinks.Link>
        </ListOfLinks.Group>

        <ListOfLinks.Link href="https://www.example.com" type="inline">
          Munere Omnesque
        </ListOfLinks.Link>
      </ListOfLinks.Column>

      <ListOfLinks.Column>
        <ListOfLinks.Group
          title="Link Parent Moderatius Delicatissim"
          href="https://www.example.com"
          type="icon"
          icon={
            select("color", BlockLinkIconList) === "grey"
              ? greyIcons[select("icon", iconName)]
              : blueIcons[select("icon", iconName)]
          }
          iconPosition={"right"}
        >
          <ListOfLinks.Link href="https://www.example.com" type="inline">
            Laoreet Minimum
          </ListOfLinks.Link>
          <ListOfLinks.Link href="https://www.example.com" type="inline">
            Legendos Posidonium
          </ListOfLinks.Link>
        </ListOfLinks.Group>

        <ListOfLinks.Link href="https://www.example.com" type="inline">
          Munere Omnesque
        </ListOfLinks.Link>
        <ListOfLinks.Link href="https://www.example.com" type="inline">
          Legendos Posidonium
        </ListOfLinks.Link>
        <ListOfLinks.Link href="https://www.example.com" type="icon">
          View More Resources
        </ListOfLinks.Link>
      </ListOfLinks.Column>
    </ListOfLinks>
  ))
  .add("BlockLink", () => (
    <ListOfLinks columns={2}>
      <ListOfLinks.Column>
        <ListOfLinks.BlockLink
          color={select("color", BlockLinkIconList)}
          href="https://www.example.com"
          eyebrow={text("eyebrow", "Eyebrow")}
          icon={
            select("color", BlockLinkIconList) === "grey"
              ? greyIcons[select("icon", iconName)]
              : blueIcons[select("icon", iconName)]
          }
        >
          {text("title", "BlockLink")}
        </ListOfLinks.BlockLink>
        <ListOfLinks.BlockLink
          color={select("color", BlockLinkIconList)}
          href="https://www.example.com"
          eyebrow={text("eyebrow", "Eyebrow")}
          icon={
            select("color", BlockLinkIconList) === "grey"
              ? greyIcons[select("icon", iconName)]
              : blueIcons[select("icon", iconName)]
          }
        >
          {text("title", "BlockLink")}
        </ListOfLinks.BlockLink>
        <ListOfLinks.BlockLink
          color={select("color", BlockLinkIconList)}
          href="https://www.example.com"
          eyebrow={text("eyebrow", "Eyebrow")}
          icon={
            select("color", BlockLinkIconList) === "grey"
              ? greyIcons[select("icon", iconName)]
              : blueIcons[select("icon", iconName)]
          }
        >
          {text("title", "BlockLink")}
        </ListOfLinks.BlockLink>
      </ListOfLinks.Column>
      <ListOfLinks.Column>
        <ListOfLinks.BlockLink
          color={select("color", BlockLinkIconList)}
          href="https://www.example.com"
          eyebrow={text("eyebrow", "Eyebrow")}
          icon={
            select("color", BlockLinkIconList) === "grey"
              ? greyIcons[select("icon", iconName)]
              : blueIcons[select("icon", iconName)]
          }
        >
          {text("title", "BlockLink")}
        </ListOfLinks.BlockLink>
        <ListOfLinks.BlockLink
          color={select("color", BlockLinkIconList)}
          href="https://www.example.com"
          eyebrow={text("eyebrow", "Eyebrow")}
          icon={
            select("color", BlockLinkIconList) === "grey"
              ? greyIcons[select("icon", iconName)]
              : blueIcons[select("icon", iconName)]
          }
        >
          {text("title", "BlockLink")}
        </ListOfLinks.BlockLink>
        <ListOfLinks.BlockLink
          color={select("color", BlockLinkIconList)}
          href="https://www.example.com"
          eyebrow={text("eyebrow", "Eyebrow")}
          icon={
            select("color", BlockLinkIconList) === "grey"
              ? greyIcons[select("icon", iconName)]
              : blueIcons[select("icon", iconName)]
          }
        >
          {text("title", "BlockLink")}
        </ListOfLinks.BlockLink>
      </ListOfLinks.Column>
    </ListOfLinks>
  ))
  .add("Small BlockLink", () => (
    <ListOfLinks columns={2}>
      <ListOfLinks.Column>
        <ListOfLinks.BlockLink
          color={select("color", BlockLinkIconList)}
          href="https://www.example.com"
          size="small"
          icon={
            select("color", BlockLinkIconList) === "grey"
              ? greyIcons[select("icon", iconName)]
              : blueIcons[select("icon", iconName)]
          }
        >
          Small BlockLink
        </ListOfLinks.BlockLink>
        <ListOfLinks.BlockLink
          color={select("color", BlockLinkIconList)}
          href="https://www.example.com"
          size="small"
          icon={
            select("color", BlockLinkIconList) === "grey"
              ? greyIcons[select("icon", iconName)]
              : blueIcons[select("icon", iconName)]
          }
        >
          Small BlockLink
        </ListOfLinks.BlockLink>
        <ListOfLinks.BlockLink
          color={select("color", BlockLinkIconList)}
          href="https://www.example.com"
          size="small"
          icon={
            select("color", BlockLinkIconList) === "grey"
              ? greyIcons[select("icon", iconName)]
              : blueIcons[select("icon", iconName)]
          }
        >
          Small BlockLink
        </ListOfLinks.BlockLink>
      </ListOfLinks.Column>
      <ListOfLinks.Column>
        <ListOfLinks.BlockLink
          color={select("color", BlockLinkIconList)}
          href="https://www.example.com"
          size="small"
          icon={
            select("color", BlockLinkIconList) === "grey"
              ? greyIcons[select("icon", iconName)]
              : blueIcons[select("icon", iconName)]
          }
        >
          Small BlockLink
        </ListOfLinks.BlockLink>
        <ListOfLinks.BlockLink
          color={select("color", BlockLinkIconList)}
          href="https://www.example.com"
          size="small"
          icon={
            select("color", BlockLinkIconList) === "grey"
              ? greyIcons[select("icon", iconName)]
              : blueIcons[select("icon", iconName)]
          }
        >
          Small BlockLink
        </ListOfLinks.BlockLink>
        <ListOfLinks.BlockLink
          color={select("color", BlockLinkIconList)}
          href="https://www.example.com"
          size="small"
          icon={
            select("color", BlockLinkIconList) === "grey"
              ? greyIcons[select("icon", iconName)]
              : blueIcons[select("icon", iconName)]
          }
        >
          Small BlockLink
        </ListOfLinks.BlockLink>
      </ListOfLinks.Column>
    </ListOfLinks>
  ));

arabicStories.add("Multi Column: Nested", () => (
  <Language code="ar">
    <ListOfLinks columns={3} topBorder={boolean("topBorder", true)}>
      <ListOfLinks.Column>
        <ListOfLinks.Link href="https://www.example.com" type="inline">
          قام جدول شعار قد, عرض
        </ListOfLinks.Link>
        <ListOfLinks.Link href="https://www.example.com" type="inline">
          كل للصين تحرّكت. أخ
        </ListOfLinks.Link>
        <ListOfLinks.Link href="https://www.example.com" type="inline">
          تعديل وفنلندا
        </ListOfLinks.Link>

        <ListOfLinks.Group
          title="أي تُصب الخارجية لكل Non Link Parent"
          type="inline"
        >
          <ListOfLinks.Link href="https://www.example.com" type="inline">
            ما, لان الشمل أجزاء
          </ListOfLinks.Link>
          <ListOfLinks.Link href="https://www.example.com" type="inline">
            ويكيبيديا ان
          </ListOfLinks.Link>
        </ListOfLinks.Group>
      </ListOfLinks.Column>

      <ListOfLinks.Column>
        <ListOfLinks.Link href="https://www.example.com" type="inline">
          قام جدول شعار قد, عرض
        </ListOfLinks.Link>
        <ListOfLinks.Link href="https://www.example.com" type="inline">
          كل للصين تحرّكت. أخ
        </ListOfLinks.Link>
        <ListOfLinks.Group
          title="أي تُصب الخارجية لكل Link Parent"
          href="https://www.example.com"
          type="icon"
          icon={<Icon path="./icons/ui/blue/arrow-right.svg" size="s" />}
          iconPosition={"right"}
        >
          <ListOfLinks.Link href="https://www.example.com" type="inline">
            ما, لان الشمل أجزاء
          </ListOfLinks.Link>
          <ListOfLinks.Link href="https://www.example.com" type="inline">
            ويكيبيديا ان
          </ListOfLinks.Link>
        </ListOfLinks.Group>

        <ListOfLinks.Link href="https://www.example.com" type="inline">
          قام جدول شعار قد, عرض
        </ListOfLinks.Link>
      </ListOfLinks.Column>

      <ListOfLinks.Column>
        <ListOfLinks.Group
          title="أي تُصب الخارجية لكل Link Parent"
          href="https://www.example.com"
          type="icon"
          icon={<Icon path="./icons/ui/blue/arrow-right.svg" size="s" />}
          iconPosition={"right"}
        >
          <ListOfLinks.Link href="https://www.example.com" type="inline">
            قام جدول شعار قد, عرض
          </ListOfLinks.Link>
          <ListOfLinks.Link href="https://www.example.com" type="inline">
            كل للصين تحرّكت. أخ
          </ListOfLinks.Link>
        </ListOfLinks.Group>

        <ListOfLinks.Link href="https://www.example.com" type="inline">
          قام جدول شعار قد, عرض
        </ListOfLinks.Link>
        <ListOfLinks.Link href="https://www.example.com" type="inline">
          كل للصين تحرّكت. أخ
        </ListOfLinks.Link>
        <ListOfLinks.Link
          href="https://www.example.com"
          type="icon"
          icon={<Icon path="./icons/ui/blue/arrow-right.svg" size="s" />}
          iconPosition={"right"}
        >
          تعديل وفنلندا
        </ListOfLinks.Link>
      </ListOfLinks.Column>
    </ListOfLinks>
  </Language>
));
// .add("Multi Column: BlockLink", () => (
//   <Language code="ar">
//     <ListOfLinks columns={2}>
//       <ListOfLinks.Column>
//         <ListOfLinks.BlockLink
//           iconColor={select(
//             "iconColor",
//             ListOfLinks.BlockLink.iconColors,
//             defaultIconColor
//           )}
//           iconType={select(
//             "iconType",
//             ListOfLinks.BlockLink.iconTypes,
//             defaultIconType
//           )}
//           href="https://www.example.com"
//           eyebrow={text("eyebrow", "حاجب العين")}
//         >
//           {text("title", "حظر الرابط")}
//         </ListOfLinks.BlockLink>
//         <ListOfLinks.BlockLink
//           iconColor={select(
//             "iconColor",
//             ListOfLinks.BlockLink.iconColors,
//             defaultIconColor
//           )}
//           iconType={select(
//             "iconType",
//             ListOfLinks.BlockLink.iconTypes,
//             defaultIconType
//           )}
//           href="https://www.example.com"
//           eyebrow={text("eyebrow", "حاجب العين")}
//         >
//           {text("title", "حظر الرابط")}
//         </ListOfLinks.BlockLink>
//         <ListOfLinks.BlockLink
//           iconColor={select(
//             "iconColor",
//             ListOfLinks.BlockLink.iconColors,
//             defaultIconColor
//           )}
//           iconType={select(
//             "iconType",
//             ListOfLinks.BlockLink.iconTypes,
//             defaultIconType
//           )}
//           href="https://www.example.com"
//           eyebrow={text("eyebrow", "حاجب العين")}
//         >
//           {text("title", "حظر الرابط")}
//         </ListOfLinks.BlockLink>
//       </ListOfLinks.Column>
//       <ListOfLinks.Column>
//         <ListOfLinks.BlockLink
//           iconColor={select(
//             "iconColor",
//             ListOfLinks.BlockLink.iconColors,
//             defaultIconColor
//           )}
//           iconType={select(
//             "iconType",
//             ListOfLinks.BlockLink.iconTypes,
//             defaultIconType
//           )}
//           href="https://www.example.com"
//           eyebrow={text("eyebrow", "حاجب العين")}
//         >
//           {text("title", "حظر الرابط")}
//         </ListOfLinks.BlockLink>
//         <ListOfLinks.BlockLink
//           iconColor={select(
//             "iconColor",
//             ListOfLinks.BlockLink.iconColors,
//             defaultIconColor
//           )}
//           iconType={select(
//             "iconType",
//             ListOfLinks.BlockLink.iconTypes,
//             defaultIconType
//           )}
//           href="https://www.example.com"
//           eyebrow={text("eyebrow", "حاجب العين")}
//         >
//           {text("title", "حظر الرابط")}
//         </ListOfLinks.BlockLink>
//         <ListOfLinks.BlockLink
//           iconColor={select(
//             "iconColor",
//             ListOfLinks.BlockLink.iconColors,
//             defaultIconColor
//           )}
//           iconType={select(
//             "iconType",
//             ListOfLinks.BlockLink.iconTypes,
//             defaultIconType
//           )}
//           href="https://www.example.com"
//           eyebrow={text("eyebrow", "حاجب العين")}
//         >
//           {text("title", "حظر الرابط")}
//         </ListOfLinks.BlockLink>
//       </ListOfLinks.Column>
//     </ListOfLinks>
//   </Language>
// ));
