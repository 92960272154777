import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";

import Anchor from "@emcm-ui/component-anchor";
import getClassNameFactory from "@emcm-ui/utility-class-names";

const Link = ({
  analyticClassNames = "",
  block,
  children,
  contentLocation,
  datasets,
  download,
  href,
  icon,
  iconPosition,
  dark,
  modalLabel,
  newWindow,
  type
}) => {
  const getClassName = getClassNameFactory(Link.displayName);

  const Wrapper = props => (
    <Anchor
      className={`${getClassName({
        modifiers: classNames({ block, dark }, type, `icon-${iconPosition}`)
      })}${analyticClassNames && ` ${analyticClassNames}`}`}
      contentLocation={contentLocation}
      datasets={datasets || "{}"}
      download={download}
      fallbackElementTag="span"
      href={href}
      modalLabel={modalLabel}
      newWindow={newWindow}
    >
      {props.children}
    </Anchor>
  );

  if (iconPosition) {
    return (
      <Wrapper>
        {iconPosition === "left" && (
          <span
            aria-hidden="true"
            className={getClassName({ descendantName: "icon" })}
          >
            {icon}
          </span>
        )}
        <span className={getClassName({ descendantName: "inner" })}>
          {children}
        </span>
        {iconPosition === "right" && (
          <span
            aria-hidden="true"
            className={getClassName({ descendantName: "icon" })}
          >
            {icon}
          </span>
        )}
      </Wrapper>
    );
  }

  // Deliberately without descendants
  return <Wrapper>{children}</Wrapper>;
};

Link.defaultProps = {
  type: "inline",
  analyticClassNames: ""
};

Link.displayName = "Link";

Link.types = ["icon", "inline"];

Link.position = ["left", "right"];

/* eslint-disable max-len */
Link.propTypes = {
  /**
   * classnames for analytics
   */
  analyticClassNames: PropTypes.string,
  /**
   * Enable block behaviour.
   */
  block: PropTypes.bool,

  /**
   * Link text.
   */
  children: PropTypes.node.isRequired,

  /**
   * content location to AJAX load content into modal, should provide href
   * for a fallback where possible.
   */
  contentLocation: PropTypes.string,

  /**
   * datasets object is used to add analytic code to the element
   * which is being passed from the external source
   */
  datasets: PropTypes.string,

  /**
   * Instruct the browser to download the link URL instead of navigating to it.
   * If the prop is given a string value, it is used as the pre-filled file name
   * in the Save prompt.
   */
  download: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),

  /**
   * URL for the link.
   */
  href: PropTypes.string,

  /**
   * Icon
   */
  icon: PropTypes.node,

  /**
   * Icon Position
   */
  iconPosition: PropTypes.oneOf(Link.position),

  /**
   * Aria label for modal dialog, required if `contentLocation` is defined.
   */
  modalLabel: PropTypes.string,

  /**
   * Type of link.
   */
  type: PropTypes.oneOf(Link.types),

  /**
   * Open link in new tab/window.
   */
  newWindow: PropTypes.bool,

  /**
   * For dark backgrounf color
   */
  dark: PropTypes.bool
};
/* eslint-enable max-len */

export default Link;
