import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import { SVGIcon } from "@emcm-ui/component-icon/lib/svg";

class Section extends Component {
  static displayName = "SiteHeader.Section";

  static propTypes = {
    /**
     *Section highlighted property.
     */
    isHighlighted: PropTypes.bool,

    /**
     * The contents of the section. These should be `SiteHeader.Column`s.
     */
    children: PropTypes.node,

    /**
     * The number of columns this Section should contain
     */
    columns: PropTypes.number,

    /**
     * The title of the section.
     */
    title: PropTypes.string,

    /**
     * The related link of the section.
     */
    relatedUrl: PropTypes.string,

    /**
     * The related name of the section.
     */
    relatedName: PropTypes.string
  };

  constructor(props) {
    super(props);

    this.getClassName = getClassNameFactory("SiteHeaderSection");
  }

  render() {
    const {
      children,
      columns,
      title,
      relatedUrl,
      relatedName,
      isHighlighted
    } = this.props;

    const checkRelatedLinkEnabled = relatedName && relatedUrl;
    const relatedLink = checkRelatedLinkEnabled
      ? this.getClassName({ descendantName: "show-related-link" })
      : this.getClassName({ descendantName: "hide-related-link" });

    return (
      <section
        className={`${this.getClassName({
          modifiers: classNames(
            columns ? `${columns}col` : null,
            isHighlighted ? "grey" : null
          )
        })} ${relatedLink}`}
      >
        <article>
          {title ? (
            <h3
              className={this.getClassName({
                descendantName: "title"
              })}
            >
              {title}
            </h3>
          ) : null}

          <div
            className={this.getClassName({
              descendantName: "children"
            })}
          >
            {children}
          </div>
        </article>

        {relatedName && relatedUrl ? (
          <a
            className={this.getClassName({ descendantName: "related" })}
            href={relatedUrl}
            role="menuitem"
          >
            <span
              className={this.getClassName({ descendantName: "related-inner" })}
            >
              <span
                className={this.getClassName({
                  descendantName: "related-text"
                })}
              >
                {relatedName}
              </span>
              <span
                className={this.getClassName({
                  descendantName: "related-icon"
                })}
              >
                <SVGIcon name="arrow" size="s" />
              </span>
            </span>
          </a>
        ) : null}
      </section>
    );
  }
}

export default Section;
