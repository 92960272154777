import React from "react";
import PropTypes from "prop-types";

const DropdownItem = ({
  getClassName,
  item,
  onItemClicked,
  onBlur,
  icon,
  userInput,
  isSelected = false,
  typeAheadEnabled
}) => {
  const getLabelMarkup = () => {
    // in typetypeAheadEnabledahead and multi select modes highlight parts of the values matching user input
    if (typeAheadEnabled && userInput) {
      const startOfUserInput = item.label
        .toLowerCase()
        .indexOf(userInput.toLowerCase());
      const startOfPostText = startOfUserInput + userInput.length;

      // eslint-disable-next-line no-magic-numbers
      if (startOfUserInput === -1) {
        return item.label;
      }
      const labelArray = item.label.split("");

      return (
        <React.Fragment>
          {labelArray.slice(0, startOfUserInput).join("")}
          <span
            className={getClassName({
              descendantName: "itemInnerTextHighlight"
            })}
          >
            {labelArray.slice(startOfUserInput, startOfPostText).join("")}
          </span>
          {labelArray.slice(startOfPostText).join("")}
        </React.Fragment>
      );
    }

    return item.label; // in simple dropdown mode return text as it is
  };

  const span = (
    <React.Fragment>
      <span className={getClassName({ descendantName: "itemInnerText" })}>
        {getLabelMarkup()}
      </span>
    </React.Fragment>
  );

  let clickable;

  // Switch between <a> and <button> based on whether has href or value
  if (item.href) {
    clickable = (
      <a
        className={getClassName({ descendantName: "itemInner" })}
        href={item.href}
        tabIndex="0"
      >
        {icon && (
          <span className={getClassName({ descendantName: "itemIcon" })}>
            {icon}
          </span>
        )}
        {span}
      </a>
    );
  } else {
    clickable = (
      <button
        className={getClassName({ descendantName: "itemInner" })}
        tabIndex="0"
        // onClick conflicts with onBlur, so use onMouseDown and onKeyDown. See https://stackoverflow.com/questions/17769005/onclick-and-onblur-ordering-issue
        onKeyDown={event => {
          const enterKeyCode = 13;
          const spacebarKeyCode = 32;

          if (
            event.keyCode === enterKeyCode ||
            event.keyCode === spacebarKeyCode
          ) {
            onItemClicked(item);
          }
        }}
        onMouseDown={() => onItemClicked(item, icon)}
        onBlur={onBlur}
      >
        {icon && (
          <span className={getClassName({ descendantName: "itemIcon" })}>
            {icon}
          </span>
        )}
        {span}
      </button>
    );
  }

  return (
    <li
      className={getClassName({ descendantName: "item" })}
      role="option"
      aria-selected={isSelected}
    >
      {clickable}
    </li>
  );
};

DropdownItem.displayName = "DropdownItem";

DropdownItem.propTypes = {
  getClassName: PropTypes.func.isRequired,
  item: PropTypes.shape({
    label: PropTypes.string.isRequired,
    href: PropTypes.string
  }),
  onBlur: PropTypes.func.isRequired,
  onItemClicked: PropTypes.func.isRequired,
  icon: PropTypes.node,
  typeAheadEnabled: PropTypes.bool,
  userInput: PropTypes.string,
  isSelected: PropTypes.bool
};

export default DropdownItem;
