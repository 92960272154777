import React, { Component } from "react";
import PropTypes from "prop-types";
import getRehydratableName from "@emcm-ui/utility-rehydratable-name";

import getClassNameFactory from "@emcm-ui/utility-class-names";
import { SVGIcon } from "@emcm-ui/component-icon/lib/svg";

import { formConsts } from "../../FormConstant";

import { toggleInvalidClass, toggleSelectedClass } from "../../utilities";

class SelectControl extends Component {
  static displayName = "FormNew.SelectControl";

  static propTypes = {
    /**
     * Disable the <select>
     */
    disabled: PropTypes.bool,
    /**
     * Id attribute for <select> (and <label> `for` atrribute)
     */
    id: PropTypes.string.isRequired,
    /**
     * Text for <label>
     */
    labelText: PropTypes.string.isRequired,
    /**
     * Text for optional <label>
     */
    optionalText: PropTypes.string.isRequired,
    /**
     * Name attribute for <select>, required for serialisation
     */
    name: PropTypes.string.isRequired,
    /**
     * Listen for blur on the <select>.
     */
    onBlur: PropTypes.func,
    /**
     * Listen for changes on the <select>
     *
     * N.B. onChange should only be used for storing the value. Any action related to the value of the <select> should be handled onBlur. See https://github.com/evcohen/eslint-plugin-jsx-a11y/blob/master/docs/rules/no-onchange.md
     */
    onChange: PropTypes.func,
    /**
     * Array of objects with `value` and `label` properties
     */
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired
      })
    ),
    /**
     * Show a placeholder <option>
     */
    placeholder: PropTypes.bool,
    /**
     * Text for placeholder <option>
     */
    placeholderText: PropTypes.string,
    /**
     * Set <select> to be required
     */
    required: PropTypes.bool,
    /**
     * tabindex attribute
     */
    tabindex: PropTypes.string,
    /**
     * Text for default error message
     */
    errorMessage: PropTypes.string,
    /**
     * Explicitly set the value of the <select>. Will switch to controlled mode
     */
    value: PropTypes.string,
    /**
     * Help text, used to provide hint about the field and the entry
     */
    helpText: PropTypes.string
  };

  static defaultProps = {
    disabled: false,
    id: formConsts.selectControlName,
    labelText: formConsts.labelText,
    optionalText: formConsts.optionalText,
    name: formConsts.selectControlName,
    options: [
      { value: "1", label: "Line item 1" },
      { value: "2", label: "Line item 2" },
      { value: "3", label: "Line item 3" },
      { value: "4", label: "Line item 4" },
      { value: "5", label: "Line item 5" },
      { value: "6", label: "Line item 6" },
      { value: "7", label: "Line item 7" }
    ],
    placeholder: true,
    placeholderText: formConsts.selectControlPlaceholderText,
    required: true,
    tabindex: formConsts.tabindex,
    errorMessage: formConsts.message
  };

  constructor(props) {
    super(props);

    this.getClassName = getClassNameFactory(SelectControl.displayName);

    this.state = {
      hasError: false,
      initiated: false,
      value: this.props.value
    };
  }

  updateState = (e, onChange) => {
    e.persist();

    this.setState(
      {
        initiated: true,
        hasError: e.target.value === "",
        value: e.target.value
      },
      () => {
        if (onChange) {
          onChange(e);
        }
      }
    );
  };

  render() {
    const {
      disabled,
      id,
      labelText,
      optionalText,
      name,
      onChange,
      onBlur,
      options,
      placeholder,
      placeholderText,
      required,
      tabindex,
      errorMessage,
      value,
      helpText
    } = this.props;

    /* eslint-disable */
    return (
      <div
        className={toggleInvalidClass(
          this.getClassName(),
          this.props.required,
          this.state.hasError
        )}
        data-disabled={disabled}
        data-id={id}
        data-label-text={labelText}
        data-optional-text={optionalText}
        data-name={name}
        data-options={JSON.stringify(options)}
        data-placeholder={placeholder}
        data-placeholder-text={placeholderText}
        data-rehydratable={getRehydratableName(SelectControl.displayName)}
        data-required={required}
        data-tabindex={tabindex}
        data-error-message={errorMessage}
        data-default-value={value}
        data-help-text={helpText}
      >
        <label htmlFor={id}>
          <select
            className={toggleSelectedClass(
              this.state.initiated,
              this.state.hasError,
              this.state.value
            )}
            disabled={disabled}
            id={id}
            name={name}
            onChange={e => {
              this.updateState(e, onChange);
            }}
            onBlur={onBlur}
            required={required}
            tabIndex={tabindex}
            value={this.state.value}
          >
            {placeholder && (
              <option
                className={this.getClassName({ descendantName: "option" })}
                value=""
              >
                {placeholderText}
              </option>
            )}
            {options &&
              options.map(option => (
                <option
                  className={this.getClassName({ descendantName: "option" })}
                  key={option.value}
                  value={option.value}
                >
                  {option.label}
                </option>
              ))}
          </select>
          <span className={this.getClassName({ descendantName: "labelText" })}>
            {labelText}
            {!required && (
              <span
                className={this.getClassName({ descendantName: "optional" })}
              >
                {optionalText}
              </span>
            )}
          </span>
          <span className={this.getClassName({ descendantName: "arrow" })}>
            <SVGIcon name="caret" size="s" />
          </span>
          <div className={this.getClassName({ descendantName: "errorIcon" })}>
            <SVGIcon name="error" size="s" />
          </div>
          {required && (
            <span className={this.getClassName({ descendantName: "message" })}>
              {errorMessage}
            </span>
          )}
          {helpText && (
            <div className={this.getClassName({ descendantName: "helpText" })}>
              {helpText}
            </div>
          )}
        </label>
      </div>
    );
    /* eslint-enable */
  }
}

export default SelectControl;
