import PropTypes from "prop-types";
import React, { Component } from "react";
import classNames from "classnames";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import getRehydratableName from "@emcm-ui/utility-rehydratable-name";
import { SVGIcon } from "@emcm-ui/component-icon/lib/svg";

class BaseItem extends Component {
  static displayName = "GlobalHeader.BaseItem";

  static propTypes = {
    /**
     * The children to show in this `BaseItem`'s menu
     */
    children: PropTypes.node,

    /**
     * A destination that this `BaseItem` links to. Acts as a fallback href if children are specified.
     */
    href: PropTypes.string,

    /**
     * Icon node. Should be an icon from `@emcm-ui/component-icon`.
     */
    icon: PropTypes.node,

    /**
     * The label to show on this BaseItem.
     */
    label: PropTypes.string,

    /**
     * A long variant of the label to show on this BaseItem. This will only be shown above narrow breakpoints, and will override `label`.
     */
    longLabel: PropTypes.string,

    /**
     * Narrow variant of the menu on desktop. Used for single column menus. If this is omitted, `BaseItem` will do its best to set this automatically.
     */
    narrow: PropTypes.bool,

    /**
     * On click function. Cannot be used when BaseItem contains children.
     */
    onClick: PropTypes.func,

    /**
     * Does wrapping bar implement gradient background
     */
    gradientBackground: PropTypes.bool
  };

  constructor(props, context) {
    super(props, context);

    this.handleBlur = this.handleBlur.bind(this);
    this.handleBaseItemClick = this.handleBaseItemClick.bind(this);
    this.toggleMenuExpand = this.toggleMenuExpand.bind(this);

    this.ref = React.createRef();

    this.state = {
      expanded: false
    };
  }

  handleBlur(e) {
    // document.activeElement is IE11 fallback
    const relatedTarget = e.relatedTarget || document.activeElement;

    // React's `blur` event bubbles - it's actually closer to the `onfocusout`
    // event.
    //
    // We want to close the item when it loses focus. When something blurs, we
    // check the `relatedTarget` on the event, which corresponds to the newly
    // focused node. If it's contained inside the item, then the item still
    // has focus, and should stay open. Otherwise, we close it.
    if (
      this.ref.current &&
      relatedTarget &&
      this.ref.current.contains(relatedTarget)
    ) {
      return;
    }

    document.body.classList.remove("u-bodyModalOpenNarrowOnly");

    this.setState({ expanded: false });
  }

  handleBaseItemClick(e) {
    e.preventDefault();

    if (this.props.onClick) {
      this.props.onClick();
    } else {
      this.toggleMenuExpand();
    }
  }

  toggleMenuExpand() {
    window.scrollTo(0, 0);

    if (this.state.expanded) {
      document.body.classList.remove("u-bodyModalOpenNarrowOnly");
    } else {
      document.body.classList.add("u-bodyModalOpenNarrowOnly");
    }

    this.setState({
      expanded: !this.state.expanded
    });
  }

  render() {
    const anchorProps = {};
    const getClassName = getClassNameFactory("GlobalHeaderBaseItem");

    let AnchorElementType = "a";

    if (this.props.children || !this.props.href) {
      AnchorElementType = "button";

      anchorProps.onClick = this.handleBaseItemClick;
    }

    if (this.props.children) {
      anchorProps["aria-expanded"] = this.state.expanded;
    }

    // We need to use a tabindex to ensure onBlur fires in Safari.
    anchorProps.tabIndex = 0;

    let caret;

    if (this.props.children && this.state.expanded) {
      caret = (
        <SVGIcon
          name="caret"
          style={{ transform: "rotate(180deg)" }}
          size="s"
        />
      );
    } else if (this.props.children && !this.state.expanded) {
      caret = <SVGIcon name="caret" size="s" />;
    }

    return (
      <div
        className={getClassName({
          modifiers: classNames({
            dropdown: this.props.children,
            narrow:
              typeof this.props.narrow === "undefined"
                ? React.Children.count(this.props.children) === 1
                : this.props.narrow
          }),
          states: classNames({
            expanded: this.state.expanded
          })
        })}
        data-href={this.props.href}
        data-label={this.props.label}
        data-long-label={this.props.longLabel}
        data-narrow={this.props.narrow}
        data-gradient-background={this.props.gradientBackground}
        data-rehydratable={getRehydratableName(BaseItem.displayName)}
        onBlur={this.handleBlur}
        ref={this.ref}
        /**
         * tabIndex required so as to be blurable but not selectable,
         * we rely upon blur for closing menu when releasing focus.
         */
        tabIndex="-1"
      >
        <AnchorElementType
          className={getClassName({ descendantName: "link" })}
          href={this.props.href}
          {...anchorProps}
        >
          {this.props.label && (
            <span
              className={getClassName({
                descendantName: "linkLabel"
              })}
            >
              {this.props.label}
            </span>
          )}

          {(this.props.longLabel || this.props.label) && (
            <span
              className={getClassName({
                descendantName: "longLinkLabel"
              })}
            >
              {this.props.longLabel || this.props.label}
            </span>
          )}

          {this.props.icon && (
            <div className={getClassName({ descendantName: "linkIcon" })}>
              {this.props.icon}
            </div>
          )}

          {caret && (
            <div
              className={getClassName({
                descendantName: "linkCaret",
                modifiers: classNames({
                  gradientBackground: this.props.gradientBackground
                })
              })}
            >
              {caret}
            </div>
          )}
        </AnchorElementType>

        {this.props.children && (
          <div
            tabIndex="-1"
            className={getClassName({ descendantName: "content" })}
          >
            <div className={getClassName({ descendantName: "contentInner" })}>
              {this.props.children}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default BaseItem;
