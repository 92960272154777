import React from "react";
import PropTypes from "prop-types";
import getClassNameFactory from "@emcm-ui/utility-class-names";

import DateTime from "@emcm-ui/component-date-time";
import Duration from "@emcm-ui/component-duration";

const ArticleInfoFooter = ({
  dateTime,
  duration,
  durationText,
  durationIcon,
  name
}) => {
  const getClassName = getClassNameFactory("SearchResults");

  return (
    <div className={getClassName({ descendantName: "articleInfoFooter" })}>
      <div
        className={getClassName({
          descendantName: "articleInfoFooterMetadata"
        })}
      >
        <span
          className={getClassName({
            descendantName: "articleInfoFooterDate"
          })}
        >
          <DateTime dateTime={dateTime} format="PP" />
        </span>
        <span
          aria-hidden
          className={getClassName({
            descendantName: "articleInfoFooterDot"
          })}
          role="presentation"
        >
          {" • "}
        </span>
        <span
          className={getClassName({
            descendantName: "articleInfoFooterName"
          })}
        >
          {name}
        </span>
      </div>
      <span
        className={getClassName({
          descendantName: "articleInfoFooterDuration"
        })}
      >
        <Duration duration={duration} icon={durationIcon}>
          {durationText}
        </Duration>
      </span>
    </div>
  );
};

ArticleInfoFooter.propTypes = {
  /**
   * An ISO 8601, RFC 2822 or `new Date(str)` compatible date string, representing the date of this content.
   */
  dateTime: PropTypes.string.isRequired,

  /**
   * The machine-readable reading time of this content.
   *
   * Must be formatted according to the W3 specification: https://www.w3.org/TR/2014/REC-html5-20141028/infrastructure.html#valid-duration-string
   */
  duration: PropTypes.string.isRequired,

  /**
   * The human-readable reading time of this content.
   */
  durationText: PropTypes.string.isRequired,

  /**
   * Icon.
   */
  durationIcon: PropTypes.node,

  /**
   * The name of the author of this content.
   */
  name: PropTypes.string.isRequired
};

export default ArticleInfoFooter;
