import PropTypes from "prop-types";
import React, { Component } from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import getRehydratableName from "@emcm-ui/utility-rehydratable-name";
import Eyebrow from "@emcm-ui/component-eyebrow";
import scrollama from "scrollama";
import classNames from "classnames";
import debounce from "lodash.debounce";
import Item from "./components/Item";

import { SVGIcon } from "@emcm-ui/component-icon/lib/svg";

class PageNavInner extends Component {
  static displayName = "PageNavInner";

  static propTypes = {
    actions: PropTypes.node,
    children: PropTypes.node,
    share: PropTypes.node,
    shareLabel: PropTypes.string,
    spy: PropTypes.bool,
    sticky: PropTypes.bool,
    showPrimaryActionOnlyOnSticky: PropTypes.bool
  };

  static getItems(children) {
    const items = [];

    React.Children.forEach(children, child => {
      if (child.type === Item) {
        items.push(child.props);
      } else if (child.type === React.Fragment) {
        items.push(...PageNavInner.getItems(child.props.children));
      }
    });

    return items;
  }

  static getCurrentItem(items) {
    return Math.max(items.findIndex(item => item.active === true), 0);
  }

  constructor(props) {
    super(props);

    this.getClassName = getClassNameFactory("PageNavInner");

    const items = PageNavInner.getItems(props.children);

    this.state = {
      currentItemIndex: PageNavInner.getCurrentItem(items),
      items,
      expanded: false,
      showPrimary: true
    };

    this.scrollSpyOffset = 0.1;
    this.resizeDebounceTimeout = 200;

    this.handleBlur = this.handleBlur.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleItemClick = this.handleItemClick.bind(this);
    this.handleStepEnter = this.handleStepEnter.bind(this);
    this.handleStepExit = this.handleStepExit.bind(this);
    this.resizeScrollSpy = this.resizeScrollSpy.bind(this);
    this.resizeScrollSpyDebounced = debounce(
      this.resizeScrollSpy,
      this.resizeDebounceTimeout
    ).bind(this);
  }

  componentDidMount() {
    // Run on mount rather than constructor, as page need to have loaded.
    if (this.props.spy && typeof window !== "undefined") {
      this.setupScrollSpy();
    }
    if (
      this.props.sticky &&
      this.props.showPrimaryActionOnlyOnSticky &&
      typeof window !== "undefined"
    ) {
      window.addEventListener("scroll", this.listenToScroll);
      this.setState({
        showPrimary: false
      });
    }
  }

  static getDerivedStateFromProps(nextProps) {
    const items = PageNavInner.getItems(nextProps.children);

    return {
      currentItemIndex: PageNavInner.getCurrentItem(items),
      items
    };
  }

  componentWillUnmount() {
    if (this.scroller && typeof window !== "undefined") {
      this.scroller.destroy();
      window.removeEventListener("resize", this.resizeScrollSpyDebounced);
    }
    if (typeof window !== "undefined") {
      window.removeEventListener("scroll", this.listenToScroll);
    }
  }

  handleBlur(e) {
    // document.activeElement is IE11 fallback
    const relatedTarget = e.relatedTarget || document.activeElement;

    // This prevents blurring when the next target is within the PageNav.
    if (this.ref && relatedTarget && this.ref.contains(relatedTarget)) {
      return;
    }

    this.setState({
      expanded: false
    });
  }

  handleClick() {
    this.setState({ expanded: !this.state.expanded });
  }

  handleItemClick() {
    this.setState({ expanded: false });
  }

  handleStepEnter({ element }) {
    this.setState({
      // Use Math.max to ensure we don't crash if element doesn't exist
      currentItemIndex: Math.max(
        this.state.items.findIndex(item => item.href === `#${element.id}`),
        0
      )
    });
  }

  handleStepExit({ direction }) {
    if (direction === "up") {
      this.setState({
        currentItemIndex: Math.max(0, this.state.currentItemIndex - 1)
      });
    }
  }

  setupScrollSpy() {
    this.scroller = scrollama();

    const itemDividerClassName = getClassNameFactory("PageNavAnchor")();

    // setup the instance, pass callback functions
    this.scroller
      .setup({
        step: `.${itemDividerClassName}`,
        offset: this.scrollSpyOffset
      })
      .onStepEnter(this.handleStepEnter)
      .onStepExit(this.handleStepExit);

    window.addEventListener("resize", this.resizeScrollSpyDebounced);
  }

  resizeScrollSpy() {
    this.scroller.resize();
  }

  listenToScroll = () => {
    if (this.ref.getBoundingClientRect().top <= 0) {
      this.setState({
        showPrimary: true
      });
    } else
      this.setState({
        showPrimary: false
      });
  };

  render() {
    const { items, currentItemIndex, expanded, showPrimary } = this.state;
    const currentItem = items[currentItemIndex];

    return (
      <div
        className={this.getClassName({ states: classNames({ expanded }) })}
        ref={ref => (this.ref = ref)}
        data-rehydratable={getRehydratableName(PageNavInner.displayName)}
        data-share-label={this.props.share && this.props.shareLabel}
        data-spy={this.props.spy ? JSON.stringify(this.props.spy) : null}
        data-sticky={
          this.props.sticky ? JSON.stringify(this.props.sticky) : null
        }
        data-show-primary-action-only-on-sticky={
          this.props.showPrimaryActionOnlyOnSticky
            ? JSON.stringify(this.props.showPrimaryActionOnlyOnSticky)
            : false
        }
      >
        <div className={this.getClassName({ descendantName: "container" })}>
          <div className={this.getClassName({ descendantName: "header" })}>
            <button
              className={this.getClassName({ descendantName: "currentItem" })}
              onClick={this.handleClick}
              onBlur={this.handleBlur}
              aria-expanded={expanded ? "true" : "false"}
            >
              <span
                className={this.getClassName({
                  descendantName: "currentItemTitle"
                })}
              >
                {currentItem.title}
              </span>

              <span
                className={this.getClassName({
                  descendantName: "currentItemIcon"
                })}
              >
                {expanded ? (
                  <SVGIcon
                    name="caret"
                    style={{ transform: "rotate(180deg)" }}
                    size="s"
                  />
                ) : (
                  <SVGIcon name="caret" size="s" />
                )}
              </span>
            </button>

            {this.props.actions ? (
              <div
                className={this.getClassName({
                  descendantName: "actions",
                  /* eslint-disable no-nested-ternary */
                  states: this.props.sticky
                    ? showPrimary ? "fadein" : "fadeout"
                    : ""
                  /* eslint-enable no-nested-ternary */
                })}
              >
                {this.props.actions}
              </div>
            ) : null}

            {this.props.share ? (
              <div
                className={this.getClassName({
                  descendantName: "share",
                  /* eslint-disable no-nested-ternary */
                  states: this.props.sticky
                    ? showPrimary ? "fadein" : "fadeout"
                    : ""
                  /* eslint-enable no-nested-ternary */
                })}
              >
                <div
                  className={this.getClassName({
                    descendantName: "shareLabel"
                  })}
                >
                  <Eyebrow text={this.props.shareLabel} />
                </div>
                <div
                  className={this.getClassName({
                    descendantName: "shareInner"
                  })}
                >
                  {this.props.share}
                </div>
              </div>
            ) : null}
          </div>

          {/* We use bubbled events on the <ol>, rather than actually setting
            <ol> as interactive, so can ignore the a11y warnings.*/}
          {/* eslint-disable jsx-a11y/no-noninteractive-element-interactions,
            jsx-a11y/click-events-have-key-events */}
          <ol
            className={this.getClassName({ descendantName: "items" })}
            onClick={this.handleItemClick} // Captures bubbled events from links
            onBlur={this.handleBlur}
          >
            {items.map((item, i) => (
              <Item key={i} {...item} active={currentItemIndex === i} />
            ))}
          </ol>
          {/* eslint-enable jsx-a11y/no-noninteractive-element-interactions,
            jsx-a11y/click-events-have-key-events */}
        </div>
      </div>
    );
  }
}

export default PageNavInner;
