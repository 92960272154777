import PropTypes from "prop-types";
import React, { Component } from "react";
import dateFnsFormatDistance from "date-fns/formatDistance";
import dateFnsFormat from "date-fns/format";
import supportedLanguages from "./supportedLanguages";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import getRehydratableName from "@emcm-ui/utility-rehydratable-name";

class DateTime extends Component {
  static displayName = "DateTime";

  static propTypes = {
    /**
     * An ISO 8601, RFC 2822, or `new Date(str)` compatible date string, which is used to compare relative time. Defaults to the time that the current page loaded; you should set this if you want the time to dynamically update as time progresses.
     */
    currentTime: PropTypes.string,
    /**
     * An ISO 8601, RFC 2822 or `new Date(str)` compatible date string.
     */
    dateTime: PropTypes.string.isRequired,
    /**
     * A [date-fns format](https://date-fns.org/v2.0.0-alpha.27/docs/format). Use is discouraged. Default switches between `PP` and `PPp` based on `showTime` property.
     */
    format: PropTypes.string,
    /**
     * IETF Language code, i.e. `en-US`, `fr`, `zh-CN`. Default is en-US [date-fns I18n](https://date-fns.org/v2.0.0-alpha.27/docs/I18n).
     */
    lang: PropTypes.string,
    /**
     * Show relative time instead of a formatted date, i.e. 4 minutes ago.
     */
    relative: PropTypes.bool,
    /**
     * Show or hide time in the output.
     */
    showTime: PropTypes.bool,
    /**
     * Add an onLocaleChange callback. Called on locale changed in state
     */
    onLocaleChange: PropTypes.func
  };
  /* eslint-enable max-len */

  static defaultProps = {
    currentTime: new Date().toISOString(),
    format: "PP",
    lang: "enUS",
    showTime: false
  };

  constructor(props) {
    super(props);
    this.state = {
      langLocale: ""
    };
    this.getClassName = getClassNameFactory(DateTime.displayName);
  }

  fetchLocale = lang => {
    supportedLanguages[lang]().then(localeFile => {
      this.setState(
        {
          langLocale: localeFile
        },
        () => {
          if (this.props.onLocaleChange) {
            this.props.onLocaleChange();
          }
        }
      );
    });
  };

  async componentDidMount() {
    await this.fetchLocale(this.props.lang);
  }

  async componentDidUpdate(oldProps) {
    if (oldProps.lang !== this.props.lang) {
      await this.fetchLocale(this.props.lang);
    }
  }

  getFormattedDate = (thedate, showTime, dateFormat = null) => {
    let formatToUse;

    // If user specifies showTime and uses default format, render time
    if (showTime && dateFormat === "PP") {
      formatToUse = "PPp";
    } else {
      formatToUse = dateFormat;
    }

    return dateFnsFormat(thedate, formatToUse, {
      locale: this.state.langLocale
    });
  };

  render() {
    const { currentTime, dateTime, format, relative, showTime } = this.props;
    const thedate = new Date(dateTime.replace(/ /g, "T"));
    const theCurrentTime = new Date(currentTime.replace(/ /g, "T"));
    let content;

    if (relative) {
      content = dateFnsFormatDistance(thedate, theCurrentTime, {
        addSuffix: true,
        includeSeconds: true,
        locale: this.state.langLocale
      });
    } else {
      content = this.getFormattedDate(thedate, showTime, format);
    }

    return (
      <div
        className={this.getClassName()}
        data-rehydratable-children
        data-rehydratable={getRehydratableName(DateTime.displayName)}
        data-date-time={JSON.stringify(dateTime)}
        data-format={JSON.stringify(format)}
        data-show-time={JSON.stringify(showTime)}
      >
        <time dateTime={dateTime}>{content}</time>
      </div>
    );
  }
}

/* eslint-disable max-len */

export default DateTime;
