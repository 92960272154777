import PropTypes from "prop-types";
import React, { Component } from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import getRehydratableName from "@emcm-ui/utility-rehydratable-name";

class Tooltip extends Component {
  static displayName = "Tooltip";
  static Positions = ["top", "bottom"];

  static propTypes = {
    /**
     * TODO: Describe the property here. Supported by react-docgen.
     */
    children: PropTypes.node,
    tooltipText: PropTypes.string,
    position: PropTypes.oneOf(Tooltip.Positions)
  };

  state = {
    isVisible: false
  };

  getClassName = getClassNameFactory(Tooltip.displayName);

  show = () => this.setState({ isVisible: true });

  hide = () => this.setState({ isVisible: false });

  render() {
    const { children, tooltipText, position } = this.props;
    const { isVisible } = this.state;

    /* eslint-disable jsx-a11y/mouse-events-have-key-events */
    return (
      <div
        className={this.getClassName()}
        data-tool-tip-text={tooltipText}
        data-position={position}
        data-rehydratable={getRehydratableName(Tooltip.displayName)}
      >
        <div
          onMouseEnter={this.show}
          onMouseLeave={this.hide}
          ref={this.wrapper}
          className={this.getClassName({
            descendantName: "inner"
          })}
        >
          {children}
          {isVisible && (
            <div
              ref={this.tooltip}
              className={this.getClassName({
                descendantName: "label",
                modifiers: position
              })}
            >
              {tooltipText}

              <div
                className={this.getClassName({
                  descendantName: "arrow",
                  modifiers: position
                })}
              />
            </div>
          )}
        </div>
      </div>
    );
    /* eslint-enable jsx-a11y/mouse-events-have-key-events */
  }
}

export default Tooltip;
