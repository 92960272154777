import React from "react";

import Attribution from "@emcm-ui/component-attribution";
import Block from "@emcm-ui/component-block";
import Callout from "@emcm-ui/component-callout";
import DataCallout from "@emcm-ui/component-data-callout";
import Eyebrow from "@emcm-ui/component-eyebrow";
import Grid from "@emcm-ui/component-grid";
import Heading from "@emcm-ui/component-heading";
import Image from "@emcm-ui/component-image";
import Longform from "@emcm-ui/component-longform";
import MarkupContainer from "@emcm-ui/component-markup-container";
import Poster from "@emcm-ui/component-poster";
import Section from "@emcm-ui/component-section";
import SemanticArticle from "@emcm-ui/component-semantic-article";
import SemanticAside from "@emcm-ui/component-semantic-aside";
import SemanticMain from "@emcm-ui/component-semantic-main";
import SocialDropdown from "@emcm-ui/component-social-dropdown";
import SocialShare from "@emcm-ui/component-social-share";
import Icon from "@emcm-ui/component-icon";
import VerticalSpacing from "@emcm-ui/component-vertical-spacing";
import VideoPlayer from "@emcm-ui/component-video-player";
import { SVGIcon } from "@emcm-ui/component-icon/lib/svg";
import { getSocialIcon } from "../../sampleAssets/icons";

import SampleSchemaOrganization from "../../fragments/schema-organization";
import SampleFooter from "../../fragments/footer";
import SampleHeader from "../../fragments/header";

import articleAbigailFisher from "../../sampleAssets/Article-Abigail-Fisher.jpg";
import articleCourtHouseGradient from "../../sampleAssets/Article-court-house-gradient.jpg";
import articleHeadshot from "../../sampleAssets/Article-headshot.jpg";
import articleLadyJustice from "../../sampleAssets/Article-lady-justice.jpg";
import articleLadyJusticeMobile from "../../sampleAssets/Article-lady-justice.mob.jpg";
import imgLarge3 from "../../sampleAssets/large_3.jpg";

const Icons = {
  facebookWhite: "./icons/social/white/facebook.svg",
  linkedinWhite: "./icons/social/white/linkedin.svg",
  twitterWhite: "./icons/social/white/twitter.svg",
  emailWhite: "./icons/ui/white/email.svg",
  chartBarGrowth: "./icons/nucleo/business-finance/large/Chart-bar-growth.svg"
};

const chapters = [
  {
    anchor: "longform",
    prefix: "Chapter One",
    title: "40.3 million"
  },
  {
    anchor: "chapter-two",
    prefix: "Chapter Two",
    title: "Steps to end modern slavery"
  },
  {
    anchor: "chapter-three",
    prefix: "Chapter Three",
    title: "Commitment to change"
  }
];

const Article = () => {
  return (
    <div>
      <SampleSchemaOrganization />
      <SampleHeader />

      <SemanticMain>
        <SemanticArticle>
          <Section background="grayDark" fullWidth>
            <Poster
              height="l"
              imageOverlay="scrimBottomDark"
              imageSrc={articleLadyJustice}
              imageSrcSet={`${articleLadyJusticeMobile} 768w, ${articleLadyJustice} 2048w`}
              alignBottom
              share={
                <MarkupContainer>
                  <SocialDropdown>
                    <SocialDropdown.Item>
                      <SocialShare
                        pageUrl="https://www.thomsonreuters.com"
                        type="facebook"
                      >
                        <SVGIcon
                          name="facebook"
                          size="s"
                          getExternalIconPath={getSocialIcon}
                        />
                      </SocialShare>
                    </SocialDropdown.Item>
                    <SocialDropdown.Item>
                      <SocialShare
                        pageTitle="Thomson Reuters: The Answer Company"
                        pageUrl="https://www.thomsonreuters.com"
                        type="twitter"
                      >
                        <SVGIcon
                          name="twitter"
                          size="s"
                          getExternalIconPath={getSocialIcon}
                        />
                      </SocialShare>
                    </SocialDropdown.Item>
                    <SocialDropdown.Item>
                      <SocialShare
                        pageTitle="Thomson Reuters: The Answer Company"
                        pageUrl="https://www.thomsonreuters.com"
                        type="linkedIn"
                      >
                        <SVGIcon
                          name="linkedin"
                          size="s"
                          getExternalIconPath={getSocialIcon}
                        />
                      </SocialShare>
                    </SocialDropdown.Item>
                    <SocialDropdown.Item>
                      <SocialShare
                        pageTitle="Thomson Reuters: The Answer Company"
                        pageUrl="https://www.thomsonreuters.com"
                        type="email"
                      >
                        <SVGIcon
                          name="email"
                          size="s"
                          getExternalIconPath={getSocialIcon}
                        />
                      </SocialShare>
                    </SocialDropdown.Item>
                  </SocialDropdown>
                </MarkupContainer>
              }
            >
              <Grid variant="7/12,5/12" gutterlessAtNarrow>
                <Grid.Item>
                  <VerticalSpacing size="s">
                    <Eyebrow text="The corporate imperative" />
                  </VerticalSpacing>
                  <Heading type="xl" rank="1">
                    Ending <strong>modern slavery</strong>
                  </Heading>
                </Grid.Item>
              </Grid>
            </Poster>
          </Section>

          <Longform>
            <MarkupContainer sticky>
              <Longform.ChapterNav>
                {chapters.map((chapter, i) => (
                  <Longform.ChapterNav.Chapter
                    key={i}
                    href={`#${chapter.anchor}`}
                    title={chapter.title}
                    prefix={`${i + 1}.`}
                  />
                ))}
              </Longform.ChapterNav>
            </MarkupContainer>
            <Longform.Child>
              <Longform.RichText>
                <h2>Chapter 1: 40.3 million</h2>
                <p>
                  This is the current, and by some accounts, conservative,
                  estimate of the number of human beings currently enslaved
                  around the world.{" "}
                </p>
                <p>
                  40.3 million is more than the population of the entire state
                  of California - the largest state in the U.S.
                </p>
                <p>
                  It’s the number of monthly users of Uber. More than the number
                  of subscribers to Apple Music.
                </p>
                <p>
                  Forced into labor in factories, farms, and construction sites,
                  exploited as sex workers, and sold into arranged marriages,
                  these modern day slaves are the backbone of a $150 billion
                  economy thriving in the shadows.
                </p>
              </Longform.RichText>
            </Longform.Child>
            <Longform.Child>
              <SemanticAside>
                <Callout
                  asset={<Icon path={Icons.chartBarGrowth} size="xl" />}
                  assetWidth="square"
                  eyebrowText="By the numbers"
                >
                  Of the 40.3 million victims of modern slavery, 71% are women
                  or girls. 25% are children.
                </Callout>
              </SemanticAside>
            </Longform.Child>
            <Longform.Child>
              <Longform.RichText>
                <p>
                  Thanks to a joint effort by anti-slavery groups, that veil of
                  secrecy is beginning to be lifted. The International Labour
                  Organization (ILO), the human rights group Walk Free
                  Foundation, and the International Organization for Migration
                  have produced a{" "}
                  <a href="https://www.alliance87.org/global_estimates_of_modern_slavery-forced_labour_and_forced_marriage.pdf">
                    landmark report
                  </a>{" "}
                  which takes the vital first step of accurately identifying
                  exactly how many people are the victims of modern slavery.
                </p>
              </Longform.RichText>
            </Longform.Child>
            <Longform.Child width="wide">
              <SemanticAside>
                <Longform.BlockQuote
                  attribution={
                    <Attribution
                      caption="CEO Thomson Reuters Foundation"
                      href="https://www.linkedin.com/pulse/slavery-takes-centre-stage-un-general-assembly-monique-villa/?trackingId=cEwZMQRxUou%2BEEmgC2U6pw%3D%3D"
                      imgSrc={articleHeadshot}
                      name="Monique Villa"
                    />
                  }
                  cite="https://blogs.thomsonreuters.com/answerson/vat-middle-east-region/"
                  quotemark
                  text="Those who are involved in the fight against trafficking immediately understand the significance of such a joint announcement. For the first time, we have in fact moved away from two sets of highly inconsistent data, to come to a single global estimate, one endorsed both by the ILO – hence the UN - and by Walk Free, the NGO which in the past years has heavily invested in updating the global estimates."
                  small
                />
              </SemanticAside>
            </Longform.Child>
            <Longform.Child>
              <Longform.RichText>
                <p>Key report stats:</p>
                <ul>
                  <li>54 specially designed, national probabilistic surveys</li>
                  <li>Data representing 89 million people</li>
                  <li>Surveys conducted on-the-ground in 48 countries</li>
                  <li>More than 71,000 interviews</li>
                </ul>
              </Longform.RichText>
            </Longform.Child>
            <Longform.Child width="wide">
              <DataCallout>
                <DataCallout.Item value="54" description="surveys" />
                <DataCallout.Item value="89m" description="people" />
                <DataCallout.Item value="48" description="countries" />
                <DataCallout.Item value="71k" description="interviews" />
              </DataCallout>
            </Longform.Child>
            <Longform.Child>
              <Longform.RichText>
                <h3>The business of slavery</h3>
                <p>
                  While some 15 million are in forced marriages, a form of sex
                  trafficking, the vast majority of modern slaves (24.9 million)
                  are working in factories, on construction sites, on fishing
                  boats, and in agricultural industries. This makes slave labor
                  a significant part of the global supply chain.
                </p>
              </Longform.RichText>
            </Longform.Child>
            <Longform.Child width="wide">
              <SemanticAside>
                <Block
                  text="Custom Experience Component - Human Trafficking Map"
                  color="blue"
                  height="32em"
                />
              </SemanticAside>
            </Longform.Child>
            <Longform.Child>
              <Longform.RichText>
                <p>Key stats: Forced labor breakdown:</p>
                <p>Forced labor exploitation: 15,975,000 (64%) </p>
                <p>
                  <em>
                    Imposed by private agents for labour exploitation, including
                    bonded labour, forced domestic work, and work imposed in the
                    context of slavery or vestiges of slavery.{" "}
                  </em>
                </p>
                <p>
                  Forced sexual exploitation of adults and sexual exploitation
                  of children: 4,816,000 (19%)
                </p>
                <p>
                  <em>
                    Forced sexual exploitation of adults, imposed by private
                    agents for commercial sexual exploitation, and all forms of
                    commercial sexual exploitation of children. This encompasses
                    the use, procuring, or offering of children for prostitution
                    or pornography.
                  </em>
                </p>
                <p>State-imposed forced labor: 4,060,000 (17%)</p>
                <p>
                  <em>
                    Including work exacted by the public authorities, military,
                    or paramilitary, compulsory participation in public works,
                    and forced prison labor.
                  </em>
                </p>
                <p>Total forced labor: 24.9 million</p>
              </Longform.RichText>
            </Longform.Child>
            <Longform.Child width="wide">
              <SemanticAside>
                <Longform.BlockQuote
                  attribution={
                    <Attribution
                      caption="Global Estimates on Modern Slavery report, 2017"
                      href=""
                      imgSrc={articleHeadshot}
                      name=""
                    />
                  }
                  cite="https://www.alliance87.org/global_estimates_of_modern_slavery-forced_labour_and_forced_marriage.pdf"
                  quotemark
                  text="Forced laborers produced some of the food we eat and the clothes we wear, and they have cleaned the buildings in which many of us live or work."
                />
              </SemanticAside>
            </Longform.Child>
            <Longform.Child>
              <Longform.RichText>
                <p>
                  That’s where modern slavery stops being just a humanitarian
                  issue and starts becoming a corporate imperative.
                </p>
                <p>
                  The proliferation of slave labor in the global supply chain
                  has very real business consequences for multinational
                  corporations who are either ignorant to the problem or, worse,
                  consciously ambivalent.
                </p>
                <p>
                  Historically,{" "}
                  <a href="https://www.theguardian.com/business/2005/apr/14/ethicalbusiness.money">
                    Nike
                  </a>{" "}
                  serves as one of the most recognizable examples of the
                  financial and reputational damage that can come from a large
                  corporation being implicated with forced labor. In addition to
                  the financial penalties the company faced, it was also the
                  subject of a global consumer boycott that has since become a{" "}
                  <a href="https://www.theguardian.com/environment/green-living-blog/2012/jul/06/activism-nike">
                    case study
                  </a>{" "}
                  for the impact of consumer activism in the face of perceived
                  injustice.
                </p>
                <p>
                  More recently, though, as a global network of law enforcement,
                  government agencies and non-governmental organizations have
                  begun to commit significant resources to addressing this
                  issue, the stakes have gotten even higher for corporations.
                </p>
                <h3>Critical mass</h3>
              </Longform.RichText>
            </Longform.Child>
            <Longform.Child width="wide">
              <SemanticAside>
                <Longform.Asset
                  caption={
                    <span>
                      <strong>Abigail Fisher:</strong> the plaintiff in a
                      discrimination suit against the University of Texas,
                      prepares to speak at a news conference in front of the
                      U.S. Supreme Court in Washington, D.C.
                    </span>
                  }
                >
                  <MarkupContainer>
                    <VideoPlayer
                      description="Abigail Fisher: the plaintiff in a discrimination suit against the University of Texas, prepares to speak at a news conference in front of the U.S. Supreme Court in Washington, D.C."
                      duration="PT1M36S"
                      kind="vimeo"
                      name="Thomson Reuters + Axoni"
                      playLabel="Play video"
                      thumbnailUrl={`https://www.example.com${articleAbigailFisher}`}
                      uploadDate="2017-04-01"
                      videoId="208682545"
                    >
                      <Image alt="" src={articleAbigailFisher} fit="cover" />
                    </VideoPlayer>
                  </MarkupContainer>
                </Longform.Asset>
              </SemanticAside>
            </Longform.Child>
            <Longform.Child>
              <Longform.RichText>
                <p>
                  When the Thomson Reuters Foundation began reporting on the
                  issue of modern slavery more than 6 years ago, many were
                  unaware of its existence and doubted the severity of the
                  issue.
                </p>
                <p>
                  We have now reached a tipping point where the confluence of
                  government, corporate, mainstream press, and public interest
                  has began to catalyse real action in the form of financial
                  support and government regulation.
                </p>
                <p>
                  Laws such as the UK Modern Slavery Act, French Corporate Duty
                  of Vigilance Law, U.S. Foreign Corrupt Practices Act (FCPA),
                  UK Bribery Act, and a host of regional anti-money laundering
                  and know your customer regulations can all come into play when
                  multinational corporations knowingly or unwittingly get into
                  business with foreign third-parties that are involved with
                  illicit activity.
                </p>
                <p>
                  As an example, the FCPA, which has been enforced aggressively
                  by the U.S. in recent years, racked up a total of $2.48
                  billion, paid by 27 companies in 2016 alone -- the{" "}
                  <a href="https://www.fcpablog.com/blog/2017/1/3/the-2016-fcpa-enforcement-index.html">
                    biggest enforcement year in FCPA history
                  </a>.
                </p>
                <p>
                  Beyond the reputational and moral implications of knowingly or
                  unwittingly supporting slavery, the threat of penalties on
                  this scale creates some incentive for multinational
                  corporations to get serious about eradicating the crime from
                  their supply chains.
                </p>
                <p>
                  Fortunately, thanks to the last decade of increased compliance
                  controls and technological innovation that make it easier to
                  spot the red flags associated with these types of crimes,
                  corporations are in the best position ever to confront this
                  challenge.
                </p>
              </Longform.RichText>
            </Longform.Child>
            <Longform.Child width="full">
              <Longform.ChapterDivider
                backgroundAnchor="N"
                backgroundGradient={false}
                backgroundSrc={articleCourtHouseGradient}
                chapterText={chapters[1].prefix}
                titleText={chapters[1].title}
                id={chapters[1].anchor}
              />
            </Longform.Child>
            <Longform.Child>
              <Longform.RichText>
                <p>
                  Slave labor is an estimated $150 billion business managed by
                  networks of third-parties, shell corporations often backed by
                  organized crime, drug cartels, and terrorist organizations
                  that go to great lengths to hide their true identities.
                </p>
                <p>
                  As governments around the world ratchet-up enforcement on
                  anti-money laundering, anti-slavery, and anti-bribery laws
                  that can be used as blunt instruments to punish companies who
                  miss or ignore these red flags, the need for corporations to
                  get serious about rooting criminal activity out of their
                  supply chains has never been greater.
                </p>
              </Longform.RichText>
            </Longform.Child>
            <Longform.Child width="wide">
              <SemanticAside>
                <Longform.Asset
                  caption={
                    <span>
                      <strong>Abigail Fisher:</strong> the plaintiff in a
                      discrimination suit against the University of Texas,
                      prepares to speak at a news conference in front of the
                      U.S. Supreme Court in Washington, D.C.
                    </span>
                  }
                >
                  <MarkupContainer>
                    <VideoPlayer
                      description="Abigail Fisher: the plaintiff in a discrimination suit against the University of Texas, prepares to speak at a news conference in front of the U.S. Supreme Court in Washington, D.C."
                      duration="PT1M36S"
                      kind="vimeo"
                      name="Thomson Reuters + Axoni"
                      playLabel="Play video"
                      thumbnailUrl={`https://www.example.com${articleAbigailFisher}`}
                      uploadDate="2017-04-01"
                      videoId="208682545"
                    >
                      <Image alt="" src={articleAbigailFisher} fit="cover" />
                    </VideoPlayer>
                  </MarkupContainer>
                </Longform.Asset>
              </SemanticAside>
            </Longform.Child>
            <Longform.Child>
              <Longform.RichText>
                <h3>Step 1: Know Your Customer</h3>
                <p>
                  At the center of this issue are know your customer (KYC)
                  regulations, which are foundational to virtually every major
                  nation’s financial regulatory framework. In most basic terms,
                  the regulations require financial firms and other businesses
                  to be able to accurately identify their clients, agents,
                  consultants, and other vendors and confirm that they are
                  compliant with anti-money laundering and anti-bribery laws.
                </p>
                <p>
                  Overseen by a global patchwork of authorities in different
                  parts of the world, the regulations vary by country, but
                  follow a similar pattern. In the U.S., for example, KYC
                  provisions are embedded in the Bank Secrecy Act, the Money
                  Laundering Control Act, the PATRIOT Act, and others, and are
                  enforced by The Financial Crimes Enforcement Network (FinCEN)
                  and the Internal Revenue Service (IRS). In the UK, they are
                  part of the Money Laundering Regulations and enforced by the
                </p>{" "}
                <p>
                  Financial Services Authority (FSA), HM Revenue and Customs,
                  The Law Society, and the Institute of Chartered Accountants.
                </p>
                <p>
                  <strong>
                    A case study of one global organization’s risk exposure to
                    slavery
                  </strong>
                </p>
              </Longform.RichText>
            </Longform.Child>
            <Longform.Child width="wide">
              <SemanticAside>
                <Longform.Asset
                  attribution="REUTERS/John Smith"
                  caption={
                    <span>
                      <strong>Data sources:</strong> Thomson Reuters
                      World-Check, Data visualization by Thomson Reuters Labs
                    </span>
                  }
                >
                  <Image
                    alt="Alt attribute"
                    anchor="C"
                    src={imgLarge3}
                    width={640}
                  />
                </Longform.Asset>
              </SemanticAside>
            </Longform.Child>
            <Longform.Child>
              <Longform.RichText>
                <p>
                  Complying with these regulations has become a massive business
                  challenge for multinational corporations who must now have
                  visibility into every corner of their global operations.
                  Consider the case of HSBC, one of the world’s largest banks,
                  which agreed to a U.S. $1.3 billion{" "}
                  <a href="https://www.justice.gov/opa/pr/hsbc-holdings-plc-and-hsbc-bank-usa-na-admit-anti-money-laundering-and-sanctions-violations">
                    deferred prosecution agreement
                  </a>{" "}
                  with the U.S. Department of Justice in 2012 for “failure to
                  maintain an effective anti-money laundering program and to
                  conduct appropriate due diligence on its foreign correspondent
                  account holders.”
                </p>
                <p>
                  The investigation into HSBC found that holes in its AML and
                  KYC programs which allowed at least U.S. $88 million in
                  drug-trafficking proceeds from Mexican and Columbian drug
                  cartels to be laundered through HSBC branches in the U.S.
                </p>
                <h3>Step 2: Follow the money</h3>
                <p>
                  More recently, the Commonwealth Bank of Australia, Australia’s
                  largest bank, was{" "}
                  <a href="https://www.wsj.com/articles/australias-largest-bank-breached-money-laundering-law-regulator-says-1501746477">
                    accused
                  </a>{" "}
                  of violating the country’s Anti-Money Laundering and
                  Counter-Terrorism Financing Act more than 57,000 times for
                  failures to report A$5.8 billion in suspicious ATM deposits.
                  Each of these 57,000 violations carries a maximum penalty of
                  A$18 million.
                </p>
                <p>
                  The root of the issue was a simple product integration
                  oversight: The bank’s new “intelligent deposit machines” were
                  the subject of a programming error that prevented AML and
                  transaction monitoring departments from recognizing that large
                  cash deposits were being made at the ATMs.
                </p>
                <p>
                  While it may not be immediately clear how these examples are
                  related to modern slavery, it is critical to recognize that
                  human trafficking has been identified by the one of the most
                  frequent predicate crimes for money laundering.{" "}
                </p>
                <p>
                  According to the{" "}
                  <a href="https://www.acamstoday.org/putting-slavery-out-of-business/">
                    Financial Action Task Force
                  </a>{" "}
                  (FATF), which sets the recognized global standard in
                  anti-money laundering and counter-terrorist financing
                  regulations, a great deal of the money being laundered through
                  our financial system is generated through the illicit buying
                  and selling of human beings.{" "}
                </p>
                <p>
                  What does this mean for all of those who are responsible for
                  vetting customer relationships and conducting due diligence in
                  major financial institutions and multinational corporations?
                  Follow the money and you will find the human traffickers.
                </p>
                <p>
                  Given the deep penetration of criminal networks into global
                  supply chains and financial systems and the groundswell of
                  regulations that have been developed to root them out, the
                  corporate watchdog has strangely become the stakeholder with
                  the most to gain by spotting the red flags that are the
                  signals of modern slavery.
                </p>
              </Longform.RichText>
            </Longform.Child>
            <Longform.Child>
              <SemanticAside>
                <Callout
                  asset={<Icon path={Icons.chartBarGrowth} size="xl" />}
                  assetWidth="square"
                  eyebrowText="Did you know"
                >
                  Illegal profits generated by forced labor amount to $150
                  billion USD annually.
                </Callout>
              </SemanticAside>
            </Longform.Child>
            <Longform.Child>
              <Longform.RichText>
                <h3>Step 3: Mobilize your secret agents</h3>
                <p>
                  In the mid-1980s, at the height of his reign as the biggest
                  drug dealer in the world, Pablo Escobar was losing{" "}
                  <a href="https://www.gq-magazine.co.uk/article/pablo-escobar-facts">
                    $2 billion in cash each year due to spoilage
                  </a>, the result of having to store his profits in rundown
                  warehouses and buried in the jungles and farmlands of
                  Columbia. Today, Escobar’s money would have likely been
                  diffused throughout a complex network of shell companies and
                  stored safely on the electronic ledgers of the global banking
                  industry.
                </p>
                <p>
                  Despite the dramatic shift in the methods criminal enterprises
                  and terrorist groups use to hide their tracks – and the
                  far-reaching impact of these changes on financial institutions
                  – the process of policing this activity is still rooted very
                  much in 30-year-old best practices. That needs to change.
                  Those who are in the best position to lead that change are the
                  compliance officers of today’s global financial institutions.
                  But they are going to need to need help in the form of new
                  technology and a culture shift that puts compliance at the
                  center of global anti-crime efforts.
                </p>
                <p>
                  At first blush, it may sound a little strange to picture a
                  multinational corporation’s finance and compliance
                  professionals as the tip of the sword in the fight against
                  modern slavery. Few of these departments would be mistaken as
                  central casting for the next James Bond movie. Beneath that
                  understated appearance, however, lies the only group of people
                  in the world who have the complete purview into the financial
                  system 21st century criminals currently leverage to finance
                  their enterprises.
                </p>
                <p>
                  The chief compliance officer of a financial institution, for
                  example, has eyes and ears everywhere, from new client
                  onboarding to ongoing transaction monitoring. It is a prime
                  perch for spotting crime. Likewise, legal, tax, and finance
                  departments are in a unique position to spot the kinds of
                  trends and anomalies that are often the first red flags for
                  foul play.
                </p>
                <p>
                  Under industry standard practices, however, this visibility is
                  limited by both time and budget constraints. Even with the
                  most stringent policies in place, it is physically impossible
                  for a corporation to spot and investigate every potential red
                  flag using the linear, manual approach to risk management.
                  Empowered with the right technology, and a clear mandate to
                  call out the types of irregularities that are consistent with
                  patterns of crime, however, the vast resources of today’s
                  multinationals can be leveraged in the fight against modern
                  slavery.
                </p>
              </Longform.RichText>
            </Longform.Child>
            <Longform.Child>
              <Longform.RichText>
                <h3>Step 4: Data is your weapon</h3>
                <p>
                  With the latest corporate compliance and due diligence
                  technology, it is now relatively inexpensive and efficient to
                  cross-reference{" "}
                  <a href="https://www.youtube.com/watch?v=C0pvNA_F6wg&feature=youtu.be">
                    disparate data sets
                  </a>{" "}
                  to identify complex relationships and affiliations that could
                  signal a bad risk or a more complex relationship in a firm
                  acting on behalf of a criminal enterprise. Tools like Thomson
                  Reuters{" "}
                  <a href="https://risksolutions.thomsonreuters.com/world-check-global?utm_source=RiskPortal&utm_medium=PPC&utm_campaign=00010YR_RiskPPCLandingPages_Digital&utm_term=world%20check&elqCampaignId=998&gclid=CjwKCAjwu7LOBRBZEiwAQtfbGGb6a8GyZSoGB1u74wz0WGyqB_BNU_GfexgoCXSAhiYHZK9j3_XCXRoC7oEQAvD_BwE&ef_id=VTJ7bQAABNc8kF89:20170928172021:s">
                    World-Check
                  </a>{" "}
                  and specialists like{" "}
                  <a href="https://www.trssllc.com/">
                    Thomson Reuters Special Services
                  </a>{" "}
                  are able to help compliance professionals go far beyond
                  criminal background checks and database screens. These
                  comprehensive risk analyses can include everything from
                  negative media to voluntary associations to links with
                  political campaigns and officials to digital relationships via
                  social media.
                </p>
                <p>
                  The process also needs to extend beyond the compliance
                  department. Today, IT departments, data scientists, and even
                  finance tax professionals with visibility into the global
                  supply chain, all need to coordinate efforts to address a risk
                  that is equally diffuse throughout our economies. At Thomson
                  Reuters, for example, a team of data scientists in{" "}
                  <a href="https://innovation.thomsonreuters.com/en/labs.html?utm_source=trblog&utm_medium=referral&utm_content=data_science&utm_campaign=answercompany">
                    Thomson Reuters Labs
                  </a>{" "}
                  helped organize a hackathon called{" "}
                  <a href="https://blogs.thomsonreuters.com/answerson/sex-trafficking-mapped-app-hackathon/">
                    Hacktrafficking4Good
                  </a>, which brought together hundreds of hackers, law
                  enforcement officials, Boston Mayor Marty Walsh, Massachusetts
                  Attorney General Maura Healy, and several other public and
                  private sector participants to find new ways to use data and
                  analytics to map patterns of sex trafficking in U.S. cities.
                </p>
              </Longform.RichText>
            </Longform.Child>
            <Longform.Child width="wide">
              <SemanticAside>
                <Longform.Asset
                  caption={
                    <span>
                      <strong>Abigail Fisher:</strong> the plaintiff in a
                      discrimination suit against the University of Texas,
                      prepares to speak at a news conference in front of the
                      U.S. Supreme Court in Washington, D.C.
                    </span>
                  }
                >
                  <MarkupContainer>
                    <VideoPlayer
                      description="Abigail Fisher: the plaintiff in a discrimination suit against the University of Texas, prepares to speak at a news conference in front of the U.S. Supreme Court in Washington, D.C."
                      duration="PT1M36S"
                      kind="vimeo"
                      name="Thomson Reuters + Axoni"
                      playLabel="Play video"
                      thumbnailUrl={`https://www.example.com${articleAbigailFisher}`}
                      uploadDate="2017-04-01"
                      videoId="208682545"
                    >
                      <Image alt="" src={articleAbigailFisher} fit="cover" />
                    </VideoPlayer>
                  </MarkupContainer>
                </Longform.Asset>
              </SemanticAside>
            </Longform.Child>
            <Longform.Child>
              <Longform.RichText>
                <p>
                  The full scale embrace of this kind of tech-forward,
                  innovative thinking has the power to redefine the way we
                  approach modern slavery and ultimately prosecute its
                  offenders. If leveraged properly, it also has the power spot
                  potential risks at the point of inception, before they can
                  metastasize into much bigger problems for the communities in
                  which we live.
                </p>
                <p>
                  As we enter an age in which Big Data and sophisticated
                  analytics are being used to accurately anticipate trends in
                  retail sales and optimize healthcare benefit designs, it is
                  high time we embrace the same technology to suffocate modern
                  slavery by removing it from our supply chains.
                </p>
              </Longform.RichText>
            </Longform.Child>
            <Longform.Child width="full">
              <Longform.ChapterDivider
                backgroundAnchor="N"
                backgroundGradient={false}
                backgroundSrc={articleCourtHouseGradient}
                chapterText={chapters[2].prefix}
                titleText={chapters[2].title}
                id={chapters[2].anchor}
              />
            </Longform.Child>
            <Longform.Child>
              <Longform.RichText>
                <blockquote>
                  <p>
                    “A debasement of our common humanity.” <br />
                    &mdash;Former U.S. President Barack Obama on human
                    trafficking
                  </p>
                </blockquote>
                <blockquote>
                  <p>
                    “The greatest human rights issue of our time.” <br />
                    &mdash;Ivanka Trump, Speaking at the United National General
                    Assembly, September 2017
                  </p>
                </blockquote>
                <blockquote>
                  <p>
                    “My government will lead the way in defeating modern slavery
                    and preserving the freedoms and values that have defined our
                    country for generations.” <br />
                    &mdash;UK Prime Minister Theresa May
                  </p>
                </blockquote>
                <p>
                  It’s clear that dramatic change is needed to end a global
                  problem as massive as modern slavery.
                </p>
                <p>
                  What’s less clear is the path to making change happen. While
                  law enforcement agencies, non-governmental organizations, and
                  government officials have all committed resources to the
                  problem, multinational corporations are in the best position
                  to make a real difference.
                </p>
                <p>
                  That’s why the{" "}
                  <a href="https://www.trust.org/">
                    Thomson Reuters Foundation
                  </a>{" "}
                  has developed the{" "}
                  <a href="https://www.stopslaveryaward.com/">
                    Stop Slavery Award
                  </a>, a global initiative that recognizes companies that have
                  taken concrete steps to eradicate forced labor from their
                  supply chains. Committed to proving that business can play a
                  critical role in putting an end to modern slavery worldwide,
                  the program, now in its second year, spotlights companies who
                  have embraced the corporate imperative to stop slavery and
                  have taken a leadership role in showing the world how it can
                  be done.
                </p>
                <p>
                  This year’s shortlisted candidates are Adidas, Aldi, Barclays,
                  C&A, CH2M, COOP, Fortescue Metals Group, Intel, M&S,
                  Marshalls, MGM China Holdings Limited, Nestle, Shiva Hotels,
                  Waitrose, and Walmart.
                </p>
              </Longform.RichText>
            </Longform.Child>
            <Longform.Child width="wide">
              <SemanticAside>
                <Longform.Asset
                  caption={
                    <span>
                      <strong>Abigail Fisher:</strong> the plaintiff in a
                      discrimination suit against the University of Texas,
                      prepares to speak at a news conference in front of the
                      U.S. Supreme Court in Washington, D.C.
                    </span>
                  }
                >
                  <MarkupContainer>
                    <VideoPlayer
                      description="Abigail Fisher: the plaintiff in a discrimination suit against the University of Texas, prepares to speak at a news conference in front of the U.S. Supreme Court in Washington, D.C."
                      duration="PT1M36S"
                      kind="vimeo"
                      name="Thomson Reuters + Axoni"
                      playLabel="Play video"
                      thumbnailUrl={`https://www.example.com${articleAbigailFisher}`}
                      uploadDate="2017-04-01"
                      videoId="208682545"
                    >
                      <Image alt="" src={articleAbigailFisher} fit="cover" />
                    </VideoPlayer>
                  </MarkupContainer>
                </Longform.Asset>
              </SemanticAside>
            </Longform.Child>
            <Longform.Child>
              <Longform.RichText>
                <p>
                  Companies are assessed by a panel of independent judges based
                  on a series of objective measures, which include each
                  company’s response to existing regulatory standards, such as
                  the UK Modern Slavery Act and U.S. Federal Acquisition
                  Requirements, and best practices, such as the Know the Chain
                  Benchmarking Methodology and Business Authentication Criteria.
                  Winners will be announced at the Thomson Reuters Foundation’s
                  annual human rights conference,{" "}
                  <a href="https://www.trustconference.com/">
                    Trust Conference
                  </a>, on November 15, in London.
                </p>
                <h3>Our commitment to change</h3>
                <p>
                  At Thomson Reuters, we are committed to being part of the
                  global effort to ending modern slavery, through a number of
                  efforts across the company.
                </p>
                <p>
                  We strive to be a global role model for ethical business
                  conduct.{" "}
                  <a href="https://blogs.thomsonreuters.com/answerson/un-sustainable-development-goals-create-opportunity/">
                    We are a member of the United Nations Global Compact
                  </a>, and align our business to the compact’s Ten Principles
                  on human rights, labor, environment and anticorruption. The
                  compact includes “the elimination of all forms of forced and
                  compulsory labor” as a fundamental responsibility.{" "}
                </p>
                <p>
                  Our full Modern Slavery Act Transparency Statement{" "}
                  <a href="https://www.thomsonreuters.com/content/dam/openweb/documents/pdf/corporate/fact-sheet/modern-slavery-act-transparency-statement.pdf">
                    can be found here
                  </a>.
                </p>
                <p>
                  The Thomson Reuters Foundation covers the under-reported
                  stories around modern slavery and runs the Stop Slavery Award,
                  recognizing companies that are taking concrete steps to
                  eradicate slavery from their supply chains.
                </p>
                <p>
                  And, as a world leader in supply chain intelligence and third
                  party risk management, we help companies stamp out forced
                  labor by arming them with the tools, technology and data
                  needed to bring new levels of transparency to global
                  operations.{" "}
                </p>
              </Longform.RichText>
            </Longform.Child>
            <Longform.Child>{/* More answers component */}</Longform.Child>
            <Longform.Child>{/* Related products */}</Longform.Child>
          </Longform>
        </SemanticArticle>
      </SemanticMain>

      <SampleFooter />
    </div>
  );
};

export default Article;
