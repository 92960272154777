import React from "react";
import { storiesOf } from "@storybook/react";
import { withKnobs, boolean, select } from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";
import { Language } from "@emcm-ui/component-theme";
import CardBase from "./index";
import "./CardBase.css";

import Block from "@emcm-ui/component-block";
import cssVars from "!css-variables-loader!./CardBase.css";

import CardBaseREADME from "../../../../README.md";

const stories = storiesOf("Components/Card/Bases/CardBase", module);

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(CardBaseREADME));

stories
  .add("Default", () => (
    <div style={{ maxWidth: 360 }}>
      <CardBase
        borderless={boolean("borderless", false)}
        href="https://www.example.com"
        size={select("size", CardBase.sizes, "small")}
      >
        <Block
          color="blue"
          text="children"
          height={
            cssVars[
              `--CardBase-min-height-${select("size", CardBase.sizes, "small")}`
            ]
          }
        />
      </CardBase>
    </div>
  ))
  .add("Language: Arabic (RTL)", () => (
    <div style={{ maxWidth: 360 }}>
      <Language code="ar">
        <CardBase
          borderless={boolean("borderless", false)}
          href="https://www.example.com"
          size={select("size", CardBase.sizes, "small")}
        >
          <Block
            color="blue"
            text="الأطفال"
            height={
              cssVars[
                `--CardBase-min-height-${select(
                  "size",
                  CardBase.sizes,
                  "small"
                )}`
              ]
            }
          />
        </CardBase>
      </Language>
    </div>
  ));
