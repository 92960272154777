import React, { Component } from "react";
import PropTypes from "prop-types";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import getRehydratableName from "@emcm-ui/utility-rehydratable-name";
import { SVGIcon } from "@emcm-ui/component-icon/lib/svg";

class Selected extends Component {
  static displayName = "SearchFilters.Selected";

  static propTypes = {
    /**
     * The children of this Selected. Should be a `SearchFilters.SelectedItem` or `SearchFilters.SelectedGroup`.
     */
    children: PropTypes.node,

    /**
     * The link to clear all filters.
     */
    clearAllHref: PropTypes.string.isRequired,

    /**
     * The text to show on the "clear all filters" link
     */
    clearAllText: PropTypes.string,

    /**
     * The title to display
     */
    title: PropTypes.string
  };

  static defaultProps = {
    clearAllText: "Clear all filters",
    title: "Selected filters"
  };

  constructor(props) {
    super(props);

    this.state = {
      isExpanded: false
    };

    this.getClassName = getClassNameFactory("SearchFiltersSelected");
    this.handleToggle = this.handleToggle.bind(this);
  }

  handleToggle() {
    this.setState({
      isExpanded: !this.state.isExpanded
    });
  }

  render() {
    return (
      <div
        className={this.getClassName({
          states: this.state.isExpanded ? "expanded" : ""
        })}
        data-clear-all-href={this.props.clearAllHref}
        data-clear-all-text={this.props.clearAllText}
        data-rehydratable={getRehydratableName(Selected.displayName)}
        data-title={this.props.title}
      >
        <button
          className={this.getClassName({ descendantName: "title" })}
          onClick={this.handleToggle}
        >
          <div className={this.getClassName({ descendantName: "titleInner" })}>
            <span
              className={this.getClassName({ descendantName: "titleText" })}
            >
              {this.props.title}
            </span>

            <div
              className={this.getClassName({ descendantName: "toggleIcon" })}
            >
              {this.state.isExpanded ? (
                <span
                  className={this.getClassName({ descendantName: "caretUp" })}
                >
                  <SVGIcon name="caret" size="s" />
                </span>
              ) : (
                <SVGIcon name="caret" size="s" />
              )}
            </div>
          </div>
        </button>

        <div className={this.getClassName({ descendantName: "inner" })}>
          <ul className={this.getClassName({ descendantName: "items" })}>
            {this.props.children}
          </ul>

          <a
            className={this.getClassName({ descendantName: "clearAll" })}
            href={this.props.clearAllHref}
          >
            {this.props.clearAllText}
          </a>
        </div>
      </div>
    );
  }
}

export default Selected;
