import PropTypes from "prop-types";
import React, { Component } from "react";
import { Chart as ChartJS } from "chart.js";
import classNames from "classnames";
import DoughnutLegendWrapper from "./DoughnutLegendWrapper";
import chartConfig from "./Chart.config.js";
import getClassNameFactory from "@emcm-ui/utility-class-names";

import getRehydratableName from "@emcm-ui/utility-rehydratable-name";

class Chart extends Component {
  constructor(props) {
    super(props);
    window.addEventListener("resize", this.resize);
  }

  resize = () => {
    if (this.chartInstance) {
      this.destroyChart();
      this.renderChart();
    }
  };

  static displayName = "Chart";

  static chartTypes = [
    "bar",
    "bubble",
    "doughnut",
    "horizontalBar",
    "line",
    "pie",
    "polarArea",
    "radar",
    "scatter"
  ];

  getClassName = getClassNameFactory(Chart.displayName);

  componentDidMount() {
    this.renderChart();
  }

  componentDidUpdate() {
    this.destroyChart();
    this.renderChart();
  }

  componentWillUnmount() {
    this.destroyChart();
  }

  destroyChart = () => {
    // Put all of the datasets that have existed in the chart back on the chart
    // so that the metadata associated with this chart get destroyed.
    // This allows the datasets to be used in another chart. This can happen,
    // for example, in a tabbed UI where the chart gets created each time the
    // tab gets switched to the chart and uses the same data).
    const { chartData } = chartConfig(this.props);
    const datasets = Object.values(chartData.datasets);

    this.chartInstance.config.data.datasets = datasets;
    this.chartInstance.destroy();
  };

  renderChart = () => {
    const node = this.node;
    const { chartData, options } = chartConfig(this.props);
    const { type } = this.props;
    const { fontFamily } = window.getComputedStyle(document.body);

    ChartJS.defaults.global.defaultFontFamily = fontFamily;
    if (node) {
      this.chartInstance = new ChartJS(node, {
        data: chartData,
        type,
        options
      });
    }
  };

  renderChartLegend = () => {
    const { labels, type, colors } = this.props;

    return labels.map((label, index) => {
      // eslint-disable-next-line no-magic-numbers
      return index < 4 ? (
        <DoughnutLegendWrapper
          key={index}
          label={label}
          type={type}
          color={colors[index]}
          getClassName={this.getClassName}
        />
      ) : (
        <React.Fragment key={index} />
      );
    });
  };

  renderDoughnutText = () => {
    const { doughnutTitle, doughnutSubTitle, type } = this.props;

    return (
      <div
        className={this.getClassName({
          descendantName: classNames(`${type}Text`)
        })}
      >
        <div
          className={this.getClassName({
            descendantName: `${type}Text-title`
          })}
        >
          {doughnutTitle}
        </div>
        <div
          className={this.getClassName({
            descendantName: `${type}Text-subTitle`
          })}
        >
          {doughnutSubTitle}
        </div>
      </div>
    );
  };

  renderHorizontalBarTicks = () => {
    const { horizontalBarMaxVal, type } = this.props;

    return (
      <React.Fragment>
        <div
          className={this.getClassName({
            descendantName: `${type}Tick-left`
          })}
        >
          0
        </div>
        <div
          className={this.getClassName({
            descendantName: `${type}Tick-right`
          })}
        >
          {horizontalBarMaxVal}
        </div>
      </React.Fragment>
    );
  };

  renderHorizontalBarTitle = () => {
    const { chartTitle, type } = this.props;

    return (
      <div
        className={this.getClassName({
          descendantName: `${type}Title`
        })}
      >
        {chartTitle}
      </div>
    );
  };

  render() {
    const {
      width,
      data,
      doughnutTitle,
      doughnutSubTitle,
      horizontalBarMaxVal,
      labels,
      colors,
      chartLabel,
      chartTitle,
      type,
      tooltipTexts,
      numberOfGrids
    } = this.props;

    const { height } = this.props;
    const isDoughnut = type === "doughnut";
    const isHorizontalBar = type === "horizontalBar";
    const dataAttributes = {};

    if (isDoughnut) {
      dataAttributes["data-doughnut-title"] = JSON.stringify(doughnutTitle);
      dataAttributes["data-doughnut-sub-title"] = JSON.stringify(
        doughnutSubTitle
      );
    } else if (isHorizontalBar) {
      dataAttributes["data-horizontal-bar-max-val"] = JSON.stringify(
        horizontalBarMaxVal
      );
    }

    const chartHTML = (
      <div
        data-rehydratable={getRehydratableName(Chart.displayName)}
        data-width={JSON.stringify(width)}
        data-height={JSON.stringify(height)}
        data-type={JSON.stringify(type)}
        data-tooltip-texts={JSON.stringify(tooltipTexts)}
        data-data={JSON.stringify(data)}
        data-labels={JSON.stringify(labels)}
        data-colors={JSON.stringify(colors)}
        data-chart-label={JSON.stringify(chartLabel)}
        data-chart-title={JSON.stringify(chartTitle)}
        data-number-of-grids={JSON.stringify(numberOfGrids)}
        {...dataAttributes}
        className={this.getClassName({
          modifiers: classNames(type)
        })}
        style={{
          minHeight: height
        }}
      >
        {isHorizontalBar && this.renderHorizontalBarTitle()}
        <canvas
          height={height}
          ref={node => (this.node = node)}
          width={width}
        />
        {isDoughnut && this.renderDoughnutText()}
        {isHorizontalBar && this.renderHorizontalBarTicks()}
      </div>
    );

    return isDoughnut ? (
      <div
        className={this.getClassName({
          descendantName: `${type}-container`
        })}
      >
        <div>{chartHTML}</div>
        {isDoughnut && <div>{this.renderChartLegend()}</div>}
      </div>
    ) : (
      chartHTML
    );
  }

  static propTypes = {
    chartLabel: PropTypes.string,
    chartTitle: PropTypes.string,
    colors: PropTypes.array,
    data: PropTypes.array.isRequired,
    doughnutTitle: PropTypes.string,
    doughnutSubTitle: PropTypes.string,
    height: PropTypes.number,
    horizontalBarMaxVal: PropTypes.number,
    labels: PropTypes.array.isRequired,
    numberOfGrids: PropTypes.number,
    options: PropTypes.object,
    tooltipTexts: PropTypes.array,
    type: PropTypes.oneOf([
      "bar",
      "bubble",
      "doughnut",
      "horizontalBar",
      "line",
      "pie",
      "polarArea",
      "radar",
      "scatter"
    ]),
    width: PropTypes.number
  };

  static defaultProps = {
    chartLabel: "Default Chart",
    chartTitle: "Chart",
    colors: ["#001eff"],
    data: [],
    doughnutTitle: "Title",
    doughnutSubTitle: "Sub Title",
    height: 500,
    horizontalBarMaxVal: 10000,
    labels: [],
    legends: ["Default Chart"],
    numberOfGrids: 5,
    options: {},
    tooltipTexts: [],
    type: "bar",
    width: 300
  };
}

export default Chart;
