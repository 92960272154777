import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";
import Heading from "@emcm-ui/component-heading";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import Link from "@emcm-ui/component-link";

const Tile = ({
  icon,
  title,
  description,
  linkTitle,
  linkIcon,
  href,
  newWindow,
  type,
  headingRank
}) => {
  const getClassName = getClassNameFactory(Tile.displayName);

  return (
    <div
      className={getClassName({
        modifiers: classNames(type)
      })}
    >
      <div
        className={getClassName({
          descendantName: "iconTitle"
        })}
      >
        <div
          className={getClassName({
            descendantName: "icon"
          })}
        >
          {icon}
        </div>
        <div className={getClassName({ descendantName: "title" })}>
          <Heading rank={headingRank} type="s">
            <strong>{title}</strong>
          </Heading>
        </div>
      </div>
      <p className={getClassName({ descendantName: "content" })}>
        {description}
      </p>
      {linkTitle &&
        href && (
          <div className={getClassName({ descendantName: "link" })}>
            <Link
              href={href}
              newWindow={newWindow}
              type="icon"
              icon={linkIcon}
              iconPosition={"right"}
            >
              {linkTitle}
            </Link>
          </div>
        )}
    </div>
  );
};

Tile.types = ["centeredIcon", "inlineIcon"];

Tile.headingRanks = Heading.ranks;

Tile.defaultProps = {
  type: "centeredIcon",
  headingRank: "2"
};

Tile.displayName = "Tile";

/* eslint-disable max-len */

Tile.propTypes = {
  /**
   * Icon or image component
   */
  icon: PropTypes.node.isRequired,

  /**
   * Title text
   */
  title: PropTypes.string.isRequired,

  /**
   * description text
   */
  description: PropTypes.string,

  /**
   * Title used for link text
   */
  linkTitle: PropTypes.string,

  /**
   * Icon
   */
  linkIcon: PropTypes.node,

  /**
   * Href used for link
   */
  href: PropTypes.string,

  /**
   * Link opens in new window
   */
  newWindow: PropTypes.bool,

  /**
   * Tile type (default: centeredIcon)
   */
  type: PropTypes.oneOf(Tile.types),

  /**
   * Heading rank. 1 will render `<h1 />`, and so on. (default: 2)
   */
  headingRank: PropTypes.oneOf(Tile.headingRanks)
};
/* eslint-enable max-len */

export default Tile;
