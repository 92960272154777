import PropTypes from "prop-types";
import React, { Component } from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import getRehydratableName from "@emcm-ui/utility-rehydratable-name";
import classNames from "classnames";
import Panel from "../Panel";
import { SVGIcon } from "@emcm-ui/component-icon/lib/svg";

import { Consumer as SetOpenMenuConsumer } from "../../setOpenMenuContext";
import { Consumer as CurrentOpenMenuConsumer } from "../../currentOpenMenuContext";

export class Menu extends Component {
  static displayName = "SiteHeader.Menu";

  static propTypes = {
    /**
     * The children to show in this `Menu`'s menu
     */
    children: PropTypes.node,

    /**
     * Whether or not this menu should be open.
     */
    expanded: PropTypes.bool,

    /**
     * A destination that this `Menu` links to. If specified, this `Menu` will always be a link, and its contents will not show.
     *
     * Overrides fallbackHref.
     */
    href: PropTypes.string,

    /**
     * A fallback link for this item, for circumstances where JavaScript is unavailable.
     *
     * Overridden by href if specified.
     */
    fallbackHref: PropTypes.string,

    /**
     * Callback when this menu requires closing
     */
    onRequestClose: PropTypes.func.isRequired,

    /**
     * Callback when this menu requests opening
     */
    onRequestOpen: PropTypes.func.isRequired,

    /**
     * The title to show on this Menu.
     *
     * This doubles as a unique identifier; you should never have two `Menu`s with the same title in one `SiteHeader`.
     */
    title: PropTypes.string.isRequired,
    /**
     * Pass custom classes, attributes for analytics.
     */
    datasets: PropTypes.string
  };

  constructor(props) {
    super(props);

    this.handleBlur = this.handleBlur.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleItemClick = this.handleItemClick.bind(this);
    this.tryCloseMenu = this.tryCloseMenu.bind(this);
    this.menuRef = null;
    this.linkRef = null;
    this.panelRef = null;
    this.menuChildrenRef = null;
  }

  componentDidUpdate() {
    const { menuRef, menuChildrenRef, props: { expanded } } = this;

    if (!expanded) {
      return;
    }

    /*
     * Position open Menu children relative to their parent.
     */
    const baseFontSize = 16;
    const menuPaddingLeft = 16;
    const menuOffsetLeft = menuRef.offsetLeft - menuPaddingLeft;

    if (menuChildrenRef.offsetLeft > menuOffsetLeft) {
      menuChildrenRef.style.marginLeft = `${menuOffsetLeft / baseFontSize}rem`;
    }
  }

  handleBlur(e) {
    // document.activeElement is IE11 fallback
    const relatedTarget = e.relatedTarget || document.activeElement;
    const siteHeaderViewportWidth = 1280;

    /*
     * Only apply this behaviour above the SiteHeader viewport width, to avoid
     * unwanted blur events being fired cross-browser. At narrower viewports,
     * Menu essentially takes up the whole screen (minus fixed area with close
     * button) so utility of closing on click-outside is negligible.
     */
    if (window.innerWidth < siteHeaderViewportWidth) {
      return;
    }

    // React's `blur` event bubbles - it's actually closer to the `onfocusout`
    // event.
    //
    // We want to close the menu when it loses focus. When an item blurs, we
    // check the `relatedTarget` on the event, which corresponds to the newly
    // focused node. If it's contained inside the menu, then the menu still
    // has focus, and should stay open. Otherwise, we close it.
    if (this.menuRef && relatedTarget && this.menuRef.contains(relatedTarget)) {
      return;
    }

    this.props.onRequestClose();
  }

  handleKeyDown(e) {
    const enterKey = 13;

    if (e.keyCode === enterKey) {
      this.tryCloseMenu(e);
    }
  }

  handleItemClick(e) {
    e.preventDefault();

    if (this.props.expanded) {
      this.props.onRequestClose();
    } else {
      this.props.onRequestOpen();
      this.panelRef.menuBackRef.setAttribute("tabindex", "0");
    }
  }

  tryCloseMenu(e) {
    // Need to check if user clicked in the menu, but outside of the menu children area.
    // If clicked outside of the area, need to close the menu.

    if (
      this.menuChildrenRef.contains(e.target) ||
      this.linkRef.contains(e.target)
    ) {
      return;
    }

    this.props.onRequestClose();
    this.linkRef.focus();
  }

  render() {
    const getClassName = getClassNameFactory("SiteHeaderMenu");

    const {
      children,
      expanded,
      fallbackHref,
      href,
      title,
      datasets
    } = this.props;

    const getDatasets = datasets ? JSON.parse(datasets) : null;

    const anchorProps = {};

    // Use a different icon if it's a direct link
    let narrowLinkIcon;

    // Optionally apply props based on href and fallbackHref
    if (href) {
      narrowLinkIcon = <SVGIcon name="arrow" size="s" />;

      anchorProps.href = href;
    } else {
      narrowLinkIcon = <SVGIcon name="caret" size="s" />;

      anchorProps.onClick = this.handleItemClick;
      anchorProps["aria-expanded"] = expanded;

      if (fallbackHref) {
        anchorProps.href = fallbackHref;
      } else {
        anchorProps.href = "#";
      }
    }

    return (
      <li
        className={getClassName({
          modifiers: classNames({
            href
          }),
          states: classNames({
            expanded
          })
        })}
        ref={ref => (this.menuRef = ref)}
        onBlur={this.handleBlur}
        onClick={this.tryCloseMenu}
        onKeyDown={this.handleKeyDown}
        data-rehydratable={getRehydratableName(Menu.displayName)}
        data-fallback-href={fallbackHref}
        data-href={href}
        data-title={title}
        role="menuitem"
      >
        {/* a makes nojs fallback easier */}
        <a
          {...getDatasets}
          className={getClassName({ descendantName: "link" })}
          ref={ref => (this.linkRef = ref)}
          {...anchorProps}
          onMouseEnter={this.enterMenu}
          onMouseLeave={this.leaveMenu}
        >
          <span className={getClassName({ descendantName: "linkText" })}>
            {title}
          </span>

          <div className={getClassName({ descendantName: "linkIcon" })}>
            {expanded ? (
              <SVGIcon
                name="caret"
                size="s"
                style={{ transform: "rotate(180deg)" }}
              />
            ) : (
              <SVGIcon name="caret" size="s" />
            )}
          </div>
          <div className={getClassName({ descendantName: "narrowLinkIcon" })}>
            {narrowLinkIcon}
          </div>
        </a>

        <div
          tabIndex="-1"
          className={getClassName({ descendantName: "content" })}
          role="menu"
        >
          <Panel
            title={title}
            open={expanded}
            onBack={this.handleItemClick}
            ref={ref => (this.panelRef = ref)}
          >
            <div
              className={getClassName({ descendantName: "children" })}
              ref={ref => (this.menuChildrenRef = ref)}
            >
              {children}
            </div>
          </Panel>
        </div>
      </li>
    );
  }
}

// The prop types are defined on Menu; no need to redefine here.
// eslint-disable-next-line react/prop-types
const MenuWithContext = ({ title, ...props }) => (
  <SetOpenMenuConsumer>
    {setOpenMenu => (
      <CurrentOpenMenuConsumer>
        {currentOpenMenu => (
          <Menu
            {...props}
            expanded={currentOpenMenu === title}
            onRequestOpen={() => setOpenMenu(title)}
            onRequestClose={() => setOpenMenu(null)}
            title={title}
          />
        )}
      </CurrentOpenMenuConsumer>
    )}
  </SetOpenMenuConsumer>
);

export default MenuWithContext;
