import PropTypes from "prop-types";
import React, { Component } from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import classNames from "classnames";

import NavItem from "./components/NavItem";
import PageItem from "./components/PageItem";

class Pagination extends Component {
  static defaultProps = {
    baseUrl: "",
    condensedAtNarrow: true,
    displayNavigationButtons: true,
    displayNavigationButtonLabels: false,
    initialPage: 1,
    outerBoundPageCount: 1,
    pageRangeCount: 5
  };

  static displayName = "Pagination";

  getClassName = getClassNameFactory(Pagination.displayName);

  static propTypes = {
    /**
     * URL to use when constructing pagination hrefs, where `{currentPage}` is replaced with page index
     */
    baseUrl: PropTypes.string,

    /**
     * Hides the border on the prev/next nav items
     */
    borderlessAtStandard: PropTypes.bool,

    /**
     * Statically Renderable Pagination PageItems
     */
    children: PropTypes.node,

    /**
     * Shows condensed current/total view instead of page items
     */
    condensed: PropTypes.bool,

    /**
     * Show the condensed view when narrow
     */
    condensedAtNarrow: PropTypes.bool,

    /**
     * Whether Previous & Next buttons are visible
     */
    displayNavigationButtons: PropTypes.bool,

    /**
     * Whether Previous & Next button labels are visible
     */
    displayNavigationButtonLabels: PropTypes.bool,

    /**
     * Which page should be active?
     */
    initialPage: PropTypes.number,

    /**
     * Callback when page changes, passes page index as argument
     */
    onPageChanged: PropTypes.func,

    /**
     * How many pages to show at start / end of page items
     */
    outerBoundPageCount: PropTypes.number,

    /**
     * Total number of pages
     */
    pageCount: PropTypes.number,

    /**
     * How many PageItems to show in the Range
     */
    pageRangeCount: PropTypes.number
  };

  state = {
    currentPage: this.props.initialPage
  };

  componentDidMount() {
    this.clientSidePagination = Boolean(this.props.onPageChanged);
  }

  decrement = () => {
    const { currentPage } = this.state;

    if (currentPage === 1) {
      return;
    }
    const newPage = currentPage - 1;

    this.setIndex(newPage)();
  };

  increment = () => {
    const { pageCount } = this.props;
    const { currentPage } = this.state;

    if (currentPage === pageCount) {
      return;
    }

    const newPage = currentPage + 1;

    this.setIndex(newPage)();
  };

  setIndex = idx => () => {
    const { onPageChanged, baseUrl } = this.props;
    const url = `${baseUrl.replace("{currentPage}", idx)}`;

    if (this.clientSidePagination) {
      this.setState({ currentPage: idx });
      onPageChanged(idx);
    } else {
      window.location(url);
    }
  };

  createEllipses = idx => (
    <PageItem key={`${Pagination.displayName}.PageItemEllipses${idx}`}>
      ...
    </PageItem>
  );

  createPageItem = idx => {
    const { baseUrl } = this.props;

    return (
      <PageItem
        data-testid="pageItem"
        href={
          this.clientSidePagination
            ? null
            : `${baseUrl.replace("{currentPage}", idx)}`
        }
        key={`${Pagination.displayName}.PageItem${idx}`}
        active={this.state.currentPage === idx}
        onClick={this.setIndex(idx)}
      >
        {idx}
      </PageItem>
    );
  };

  createPageItems = () => {
    const { outerBoundPageCount, pageCount, pageRangeCount } = this.props;
    const { currentPage } = this.state;
    let items = [];
    let ellipsesCount = 0;

    if (pageCount <= pageRangeCount) {
      /**
       * When the total # of pages does not exceed a given pageRangeCount _n_
       */
      items = Array.from(Array(pageCount), (_, idx) =>
        this.createPageItem(idx + 1)
      );
    } else if (currentPage < pageRangeCount) {
      /**
       * For a given pageRangeCount _n_, the current page is in the first _n_ pages
       */
      items = [
        ...Array.from(Array(pageRangeCount), (_, idx) =>
          this.createPageItem(idx + 1)
        ),
        this.createEllipses(++ellipsesCount),
        this.createPageItem(pageCount)
      ];
    } else if (currentPage > pageCount - pageRangeCount) {
      /**
       * For a given pageRangeCount _n_, the current page is in the last _n_ pages
       */
      items = [
        this.createPageItem(1),
        this.createEllipses(++ellipsesCount),
        ...Array.from(Array(pageRangeCount), (_, idx) =>
          this.createPageItem(pageCount - idx)
        ).reverse()
      ];
    } else {
      /**
       * The current page is in the middle of the total number of pages
       * i.e. for a given pageRangeCount _n_ to display, the current page is neither within
       *   the first _n_ items, nor the last _n_ items
       */

      /**
       * lowerBound represents how many pageItems to display smaller than the current page
       * upperBound represents how many pageItems to display larger than the current page
       */
      const lowerBound = Math.floor(pageRangeCount / 2); // eslint-disable-line
      const upperBound = pageRangeCount - lowerBound - 1;

      /**
       * Generate the page items before the first ellipses
       */
      const lowerOuterBoundPageItems = Array.from(
        Array(outerBoundPageCount),
        (_, idx) => this.createPageItem(idx + 1)
      );

      /**
       * Generate the page items after the first ellipses, before the current page
       */
      const lowerBoundPageItems = Array.from(Array(lowerBound), (_, idx) =>
        this.createPageItem(currentPage - idx - 1)
      ).reverse();

      const currentItem = this.createPageItem(currentPage);

      /**
       * Generate the page items after the current page, before the last ellipses
       */
      const upperBoundPageItems = Array.from(Array(upperBound), (_, idx) =>
        this.createPageItem(currentPage + idx + 1)
      );

      /**
       * Generate the page items after the last ellipses
       */
      const upperOuterBoundPageItems = Array.from(
        Array(outerBoundPageCount),
        (_, idx) => this.createPageItem(pageCount - idx)
      ).reverse();

      items = [
        ...lowerOuterBoundPageItems,
        this.createEllipses(++ellipsesCount),
        ...lowerBoundPageItems,
        currentItem,
        ...upperBoundPageItems,
        this.createEllipses(++ellipsesCount),
        ...upperOuterBoundPageItems
      ];
    }

    return items;
  };

  render() {
    const {
      baseUrl,
      borderlessAtStandard,
      condensed,
      condensedAtNarrow,
      displayNavigationButtons,
      displayNavigationButtonLabels,
      pageCount
    } = this.props;

    const { currentPage } = this.state;

    return (
      <nav
        className={this.getClassName()}
        role="navigation"
        aria-label="Pagination Navigation"
      >
        <ul className={this.getClassName({ descendantName: "items" })}>
          {displayNavigationButtons && (
            <NavItem
              borderlessAtStandard={borderlessAtStandard}
              ariaLabel="Previous"
              role="button"
              tabIndex="0"
              data-testid="navItemLeft"
              key="NavItemLeft"
              direction="left"
              href={
                this.clientSidePagination
                  ? null
                  : `${baseUrl.replace("{currentPage}", currentPage - 1)}`
              }
              onClick={this.decrement}
              disabled={currentPage === 1}
              aria-disabled={currentPage === 1}
              displayNavigationButtonLabels={displayNavigationButtonLabels}
            />
          )}
          {condensed ? (
            <li
              className={this.getClassName({
                descendantName: "condensed",
                modifiers: classNames({ condensedAtNarrow })
              })}
              data-testid="paginationCondensed"
            >{`${currentPage} / ${pageCount}`}</li>
          ) : (
            this.createPageItems()
          )}
          {displayNavigationButtons && (
            <NavItem
              borderlessAtStandard={borderlessAtStandard}
              ariaLabel="Next"
              role="button"
              tabIndex="0"
              data-testid="navItemRight"
              key="NavItemRight"
              direction="right"
              href={
                this.clientSidePagination
                  ? null
                  : `${baseUrl.replace("{currentPage}", currentPage + 1)}`
              }
              onClick={this.increment}
              disabled={currentPage === pageCount}
              aria-disabled={currentPage === pageCount}
              displayNavigationButtonLabels={displayNavigationButtonLabels}
            />
          )}
        </ul>
      </nav>
    );
  }
}

Pagination.NavItem = NavItem;
Pagination.PageItem = PageItem;

export default Pagination;
