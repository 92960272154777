import React from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import Form from "./Form";

export default async (domNode, rehydrateChildren) => {
  const getClassName = getClassNameFactory("Form");

  const childrenNode = domNode.querySelector("[data-rehydratable-children]");
  const reactifiedChildrenPromise = rehydrateChildren(childrenNode);

  const headerNode = domNode.querySelector(
    `.${getClassName({ descendantName: "header" })}`
  );
  const footerNode = domNode.querySelector(
    `.${getClassName({ descendantName: "footer" })}`
  );

  const footerPromise = footerNode
    ? rehydrateChildren(footerNode)
    : Promise.resolve(null);
  const headerPromise = headerNode
    ? rehydrateChildren(headerNode)
    : Promise.resolve(null);

  // Ensure that all 3 rehydrateChildren calls can run in parallel, by
  // only awaiting _after_ the rehydrateChildren process has started.
  const reactifiedChildren = await reactifiedChildrenPromise;
  const footer = await footerPromise;
  const header = await headerPromise;

  const props = {
    isRehydrating: true,
    action: domNode.getAttribute("data-action"),
    id: domNode.getAttribute("data-id"),
    analyticsName: domNode.getAttribute("data-analytics-name"),
    analyticsNodes: JSON.parse(
      domNode.getAttribute("data-analytics-nodes") || `{}`
    ),
    analyticsType: domNode.getAttribute("data-analytics-type"),
    ajax: domNode.getAttribute("data-ajax") === "true",
    footer,
    header,
    submitButtonColor: domNode.getAttribute("data-submit-button-color"),
    submitButtonText: domNode.getAttribute("data-submit-button-text")
  };

  return <Form {...props}>{reactifiedChildren}</Form>;
};
