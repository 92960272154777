import React from "react";
import { storiesOf } from "@storybook/react";
import { number, withKnobs, select } from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";
import StaticRenderer from "@emcm-ui/component-static-renderer";
import GlobalFooter, { GlobalFooterItem } from "./index";
import { Language } from "@emcm-ui/component-theme";
import "./GlobalFooter.css";
import GlobalFooterREADME from "../README.md";

const stories = storiesOf("Components/GlobalFooter", module);

const logoOptions = {
  refinitiv: null,
  lseg: "./images/lseg-brand-logo-white.svg",
  "refinitiv with strapline": "../images/refinitiv-with-stripeline.svg"
};

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(GlobalFooterREADME));

const items = [
  <GlobalFooterItem href="" title="Cookie policy" />,
  <GlobalFooterItem href="#" title="Privacy statement" />,
  <GlobalFooterItem href="#" title="Terms of use" />,
  <GlobalFooterItem href="#" title="Copyright" />,
  <GlobalFooterItem href="#" title="Sitemap" />,
  <GlobalFooterItem href="#" title="Disclosures" />,
  <GlobalFooterItem href="#" title="Third-party restrictions" />
];

const arabicItems = [
  <GlobalFooterItem href="#" title="سياسة ملفات الارتباط" />,
  <GlobalFooterItem href="#" title="بيان الخصوصية" />,
  <GlobalFooterItem href="#" title="تعليمات الاستخدام" />,
  <GlobalFooterItem href="#" title="حقوق النشر" />,
  <GlobalFooterItem href="#" title="خريطة الموقع" />,
  <GlobalFooterItem href="#" title="افصاحات" />,
  <GlobalFooterItem href="#" title="قيود الجهات الخارجية" />
];

stories
  .add("Default", () => (
    <GlobalFooter logoHref="#" logoPath={select("Logo", logoOptions, null)}>
      {items.slice(0, number("Number of links", items.length))}
    </GlobalFooter>
  ))
  .add("Language: Arabic (RTL)", () => (
    <Language code="ar">
      <GlobalFooter logoHref="#" logoPath={select("Logo", logoOptions, null)}>
        {arabicItems.slice(0, number("Number of links", arabicItems.length))}
      </GlobalFooter>
    </Language>
  ))
  .add("Rehydrated", () => (
    <StaticRenderer>
      <GlobalFooter logoHref="#" logoPath={select("Logo", logoOptions, null)}>
        {items}
      </GlobalFooter>
    </StaticRenderer>
  ));
