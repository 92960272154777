import PropTypes from "prop-types";
import React, { Component } from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import getRehydratableName from "@emcm-ui/utility-rehydratable-name";
import classNames from "classnames";
import Cookies from "js-cookie";
import Button from "@emcm-ui/component-button";
import LinkItem from "./components/LinkItem";

import { SVGIcon } from "@emcm-ui/component-icon/lib/svg";

class DismissibleBanner extends Component {
  static displayName = "DismissibleBanner";

  static propTypes = {
    /**
     * The name of the cookie, which flags a user who has already seen the banner. Only used if `hideIfAlreadySeen` is true.
     */
    alreadySeenCookieName: PropTypes.string,
    /**
     * The expected value of the cookie; if the cookie has a different value than expected, the banner will show. Only used if `hideIfAlreadySeen` is true.
     */
    alreadySeenCookieExpectedValue: PropTypes.string,
    /**
     * The links to display inside the banner.
     */
    children: PropTypes.node,
    /**
     * Text to show inside the button.
     */
    dismissButtonText: PropTypes.string,
    /**
     * Sets the component to be fixed to the bottom of the viewport, on top of all other page content.
     */
    fixed: PropTypes.bool,
    /**
     * Text to show in the heading area.
     */
    heading: PropTypes.string.isRequired,
    /**
     * Hide the banner if the current user has seen it before.
     */
    hideIfAlreadySeen: PropTypes.bool,
    /**
     * Text to show in the content area.
     */
    text: PropTypes.string.isRequired,
    /**
     * Root domains that we're allowed to set cookies on.
     */
    validParentDomains: PropTypes.array
  };

  static defaultProps = {
    alreadySeenCookieName: "tr_ewp_dismissiblebanner",
    alreadySeenCookieExpectedValue: "may2018",
    dismissButtonText: "Okay to continue",
    hideIfAlreadySeen: true,
    validParentDomains: ["refinitiv.com", "reuters.com", "thomsonreuters.com"]
  };

  static cookiesEnabled() {
    // cookieEnabled needs to be exactly false, not undefined or null
    if (typeof navigator !== "undefined" && navigator.cookieEnabled === false) {
      return false;
    }

    return true;
  }

  constructor(props) {
    super(props);

    this.state = {
      manuallyClosed: false,
      isNewUser: false
    };

    this.getClassName = getClassNameFactory(DismissibleBanner.displayName);
    this.handleClose = this.handleClose.bind(this);
  }

  componentDidMount() {
    this.markUser();
  }

  componentDidUpdate(prevProps) {
    if (!this.state.isNewUser) {
      // When the component updates, there's a possibility that we need to
      // re-mark the user, but only if we haven't already identified them as
      // a new user.
      this.markUser();
    }
    if (
      prevProps.alreadySeenCookieName !== this.props.alreadySeenCookieName ||
      prevProps.alreadySeenCookieExpectedValue !==
        this.props.alreadySeenCookieExpectedValue ||
      (prevProps.hideIfAlreadySeen !== this.props.hideIfAlreadySeen &&
        this.props.hideIfAlreadySeen)
    ) {
      // If any cookie-related props have changed, we'll go through user
      // marking again. In the interim, reset to the default new user state.
      this.setState({
        isNewUser: false
      });
    }
  }

  handleClose() {
    this.setState({
      manuallyClosed: true
    });
  }

  markUser() {
    if (!DismissibleBanner.cookiesEnabled()) {
      // If cookies are disabled, we shouldn't mark the user. We also shouldn't
      // show the banner to them, so we want to skip any state setting.
      return;
    }

    if (this.props.hideIfAlreadySeen) {
      if (
        Cookies.get(this.props.alreadySeenCookieName) !==
        this.props.alreadySeenCookieExpectedValue
      ) {
        this.setState({
          isNewUser: true
        });

        const cookieOptions = { expires: 365, secure: true };

        for (const domain of this.props.validParentDomains) {
          if (window.location.hostname.endsWith(domain)) {
            cookieOptions.domain = `.${domain}`;
          }
        }

        Cookies.set(
          this.props.alreadySeenCookieName,
          this.props.alreadySeenCookieExpectedValue,
          cookieOptions
        );
      }
    }
  }

  shouldBeClosed() {
    const { hideIfAlreadySeen } = this.props;
    const { manuallyClosed, isNewUser } = this.state;

    if (manuallyClosed) {
      // Should always be closed if the user has explicitly closed it on this page.
      return true;
    }

    if (hideIfAlreadySeen) {
      if (isNewUser) {
        // The user is a new user, and hasn't closed the banner yet.
        return false;
      }

      // Either the user has been here before, or we haven't checked for the
      // cookie mark yet - i.e. this could be an initial render.
      return true;
    }

    // Should show the banner: it has not manually been closed, and checking
    // cookies is disabled. This state could be reached if a parent component
    // were doing the hiding and showing.
    return false;
  }

  render() {
    const rehydrationProps = {
      "data-dismiss-button-text": this.props.dismissButtonText,
      "data-fixed": this.props.fixed || false,
      "data-hide-if-already-seen": this.props.hideIfAlreadySeen,
      "data-rehydratable": getRehydratableName(DismissibleBanner.displayName)
    };

    if (this.props.hideIfAlreadySeen) {
      rehydrationProps[
        "data-already-seen-cookie-name"
      ] = this.props.alreadySeenCookieName;
      rehydrationProps[
        "data-already-seen-cookie-expected-value"
      ] = this.props.alreadySeenCookieExpectedValue;
      rehydrationProps["data-valid-parent-domains"] = JSON.stringify(
        this.props.validParentDomains
      );
    }

    return (
      <div
        role="alertdialog"
        className={this.getClassName({
          modifiers: classNames({
            fixed: this.props.fixed
          }),
          states: classNames({
            closed: this.shouldBeClosed()
          })
        })}
        {...rehydrationProps}
      >
        <div className={this.getClassName({ descendantName: "wrapper" })}>
          <div className={this.getClassName({ descendantName: "inner" })}>
            <p className={this.getClassName({ descendantName: "heading" })}>
              {this.props.heading}
            </p>
            <p className={this.getClassName({ descendantName: "text" })}>
              {this.props.text}
            </p>
            {this.props.children ? (
              <ul className={this.getClassName({ descendantName: "links" })}>
                {this.props.children}
              </ul>
            ) : null}
          </div>
          <div className={this.getClassName({ descendantName: "button" })}>
            <Button color="secondary" kind="button" onClick={this.handleClose}>
              {this.props.dismissButtonText}
            </Button>
          </div>
        </div>
        <div
          className={this.getClassName({
            descendantName: "closeButtonWrapper"
          })}
        >
          <button
            className={this.getClassName({ descendantName: "closeButton" })}
            onClick={this.handleClose}
          >
            <SVGIcon name="close" size="s" />
          </button>
        </div>
      </div>
    );
  }
}

DismissibleBanner.LinkItem = LinkItem;

export default DismissibleBanner;
