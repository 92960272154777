import React from "react";
import { storiesOf } from "@storybook/react";
import withReadme from "storybook-readme/with-readme";
import { Language } from "@emcm-ui/component-theme";
import SocialShare from "./index";
import "./SocialShare.css";
import SocialShareREADME from "../README.md";
import { SVGIcon } from "@emcm-ui/component-icon/lib/svg";
import { getSocialIcon } from "./icons";

const stories = storiesOf("Components/SocialShare", module);

stories.addDecorator(withReadme(SocialShareREADME));

stories
  .add("Default", () => (
    <div style={{ display: "flex" }}>
      <SocialShare pageUrl="https://www.thomsonreuters.com" type="facebook">
        <SVGIcon name="facebook" size="s" getExternalIconPath={getSocialIcon} />
      </SocialShare>

      <SocialShare
        pageTitle="Thomson Reuters: The Answer Company"
        pageUrl="https://www.thomsonreuters.com"
        type="twitter"
      >
        <SVGIcon name="twitter" size="s" getExternalIconPath={getSocialIcon} />
      </SocialShare>

      <SocialShare
        pageTitle="Thomson Reuters: The Answer Company"
        pageUrl="https://www.thomsonreuters.com"
        type="linkedIn"
      >
        <SVGIcon name="linkedin" size="s" getExternalIconPath={getSocialIcon} />
      </SocialShare>

      <SocialShare
        pageTitle="Thomson Reuters: The Answer Company"
        pageUrl="https://www.thomsonreuters.com"
        type="weibo"
      >
        <SVGIcon name="weibo" size="s" getExternalIconPath={getSocialIcon} />
      </SocialShare>

      <SocialShare pageUrl="https://www.thomsonreuters.com" type="whatsApp">
        <SVGIcon name="whatsapp" size="s" getExternalIconPath={getSocialIcon} />
      </SocialShare>

      <SocialShare pageUrl="https://www.thomsonreuters.com" type="line">
        <SVGIcon name="line" size="s" getExternalIconPath={getSocialIcon} />
      </SocialShare>

      <SocialShare pageUrl="https://www.thomsonreuters.com" type="mixi">
        <SVGIcon name="mixi" size="s" getExternalIconPath={getSocialIcon} />
      </SocialShare>

      <SocialShare
        pageTitle="Thomson Reuters: The Answer Company"
        pageUrl="https://www.thomsonreuters.com"
        type="email"
      >
        <SVGIcon name="email" size="s" getExternalIconPath={getSocialIcon} />
      </SocialShare>

      <SocialShare type="print">
        <SVGIcon name="print" size="s" getExternalIconPath={getSocialIcon} />
      </SocialShare>
    </div>
  ))
  .add("Language: Arabic (RTL)", () => (
    <Language code="ar">
      <SocialShare pageUrl="https://www.thomsonreuters.com" type="facebook">
        <SVGIcon name="facebook" size="s" getExternalIconPath={getSocialIcon} />
      </SocialShare>
    </Language>
  ));
