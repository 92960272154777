import PropTypes from "prop-types";
import React from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import classNames from "classnames";

import Grid from "@emcm-ui/component-grid";

import BlockLink from "./components/BlockLink";
import Column from "./components/Column";
import Group from "./components/Group";
import Link from "./components/Link";

const ListOfLinks = ({ children, columns, topBorder }) => {
  const getClassName = getClassNameFactory(ListOfLinks.displayName);

  let innerContent;
  const hasColumns = columns > 1;

  if (hasColumns) {
    innerContent = (
      <Grid gutterlessAtNarrow variant={`even-${columns}`}>
        {children}
      </Grid>
    );
  } else {
    innerContent = (
      <ul className={getClassName({ descendantName: "items" })}>{children}</ul>
    );
  }

  return (
    <div
      className={getClassName({
        modifiers: classNames({ topBorder, hasColumns })
      })}
    >
      <div className={getClassName({ descendantName: "inner" })}>
        {innerContent}
      </div>
    </div>
  );
};

ListOfLinks.displayName = "ListOfLinks";

ListOfLinks.propTypes = {
  /**
   * Children to render. One of Link, BlockLink, Group or Column.
   */
  children: PropTypes.node,
  /**
   * Number of columns to render. Used in conjunction with Column children.
   */
  columns: PropTypes.oneOf([1, 2, 3]), // eslint-disable-line no-magic-numbers
  /**
   * Render an optional border at the top of the ListOfLinks.
   */
  topBorder: PropTypes.bool
};

ListOfLinks.defaultProps = {
  columns: 1,
  topBorder: false
};

ListOfLinks.BlockLink = BlockLink;
ListOfLinks.Column = Column;
ListOfLinks.Group = Group;
ListOfLinks.Link = Link;

export default ListOfLinks;
