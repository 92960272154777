import React from "react";

const Space = () => {
  return (
    <div>
      <h2 className="story-themeSubHeading">Scale</h2>

      <ol className="story-scale">
        <li className="story-scaleItem">
          <span
            className="story-scaleBar"
            style={{ width: "calc(3rem / 16)" }}
          />
          XXXS
        </li>
        <li className="story-scaleItem">
          <span
            className="story-scaleBar"
            style={{ width: "calc(6rem / 16)" }}
          />
          XXS
        </li>
        <li className="story-scaleItem">
          <span
            className="story-scaleBar"
            style={{ width: "calc(12rem / 16)" }}
          />
          XS
        </li>
        <li className="story-scaleItem">
          <span
            className="story-scaleBar"
            style={{ width: "calc(18rem / 16)" }}
          />
          S
        </li>
        <li className="story-scaleItem">
          <span
            className="story-scaleBar story-scaleBar--basePrimary"
            style={{ width: "calc(24rem / 16)" }}
          />
          M <em className="story-scaleNote">– typographic base</em>
        </li>
        <li className="story-scaleItem">
          <span
            className="story-scaleBar story-scaleBar--baseSecondary"
            style={{ width: "calc(36rem / 16)" }}
          />
          L <em className="story-scaleNote">– grid gutter</em>
        </li>
        <li className="story-scaleItem">
          <span
            className="story-scaleBar"
            style={{ width: "calc(48rem / 16)" }}
          />
          XL
        </li>
        <li className="story-scaleItem">
          <span
            className="story-scaleBar"
            style={{ width: "calc(72rem / 16)" }}
          />
          XXL
        </li>
        <li className="story-scaleItem">
          <span
            className="story-scaleBar"
            style={{ width: "calc(96rem / 16)" }}
          />
          XXXL
        </li>
      </ol>

      <div>
        <h2 className="story-themeSubHeading">Inset stretch</h2>

        <div style={{ display: "flex" }}>
          <div className="story-insetStretchM story-insetStretch">
            <div>M</div>
          </div>
        </div>
      </div>

      <div>
        <h2 className="story-themeSubHeading">Inset squish</h2>

        <div style={{ display: "flex" }}>
          <div className="story-insetSquishS story-insetSquish">
            <div>S</div>
          </div>
          <div className="story-insetSquishM story-insetSquish">
            <div>M</div>
          </div>
        </div>
      </div>

      <h2 className="story-themeSubHeading">Inset</h2>

      <div style={{ display: "flex" }}>
        <div className="story-insetXS story-inset">
          <div>XS</div>
        </div>
        <div className="story-insetS story-inset">
          <div>S</div>
        </div>
        <div className="story-insetM story-inset">
          <div>M</div>
        </div>
        <div className="story-insetL story-inset">
          <div>L</div>
        </div>
        <div className="story-insetXL story-inset">
          <div>XL</div>
        </div>
      </div>

      <div style={{ display: "flex" }}>
        <div>
          <h2 className="story-themeSubHeading">Stack</h2>
          <div>
            <div className="story-stackXXXS story-stack">
              <div>XXXS</div>
            </div>
            <div className="story-stackXXS story-stack">
              <div>XXS</div>
            </div>
            <div className="story-stackXS story-stack">
              <div>XS</div>
            </div>
            <div className="story-stackS story-stack">
              <div>S</div>
            </div>
            <div className="story-stackM story-stack">
              <div>M</div>
            </div>
            <div className="story-stackL story-stack">
              <div>L</div>
            </div>
            <div className="story-stackXL story-stack">
              <div>XL</div>
            </div>
          </div>
        </div>
        <div>
          <h2 className="story-themeSubHeading">Inline</h2>

          <div style={{ display: "flex" }}>
            <div className="story-inlineXXS story-inline">
              <div>XXS</div>
            </div>
            <div className="story-inlineXS story-inline">
              <div>XS</div>
            </div>
            <div className="story-inlineS story-inline">
              <div>S</div>
            </div>
            <div className="story-inlineM story-inline">
              <div>M</div>
            </div>
            <div className="story-inlineL story-inline">
              <div>L</div>
            </div>
            <div className="story-inlineXL story-inline">
              <div>XL</div>
            </div>
            <div className="story-inlineXXL story-inline">
              <div>XXL</div>
            </div>
            <div className="story-inlineXXXL story-inline">
              <div>XXXL</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Space;
