import PropTypes from "prop-types";
import React from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import classNames from "classnames";
import CardBase from "../CardBase";
import Eyebrow from "@emcm-ui/component-eyebrow";
import Heading from "@emcm-ui/component-heading";

const TextCardBase = ({
  anchor,
  borderless,
  description,
  eyebrow,
  footer,
  heading,
  headingRank,
  href,
  largeHeading,
  size,
  renderContent,
  metaData
}) => {
  const getClassName = getClassNameFactory(TextCardBase.displayName);

  return (
    <div
      className={getClassName({
        modifiers: classNames({
          [anchor]: true
        })
      })}
    >
      <CardBase anchor={anchor} borderless={borderless} href={href} size={size}>
        {renderContent(
          <div
            className={getClassName({
              descendantName: "content"
            })}
          >
            {eyebrow ? (
              <div
                className={getClassName({
                  descendantName: "eyebrow"
                })}
              >
                <Eyebrow text={eyebrow} />
              </div>
            ) : null}

            <div
              className={getClassName({
                descendantName: "body"
              })}
            >
              <div className={getClassName({ descendantName: "heading" })}>
                <Heading rank={headingRank} type={largeHeading ? "l" : "s"}>
                  <strong>{heading}</strong>
                </Heading>
              </div>

              {metaData && metaData.length > 0 ? (
                <div
                  className={getClassName({ descendantName: "metadataGroup" })}
                >
                  {metaData.map(data => {
                    return (
                      <dl
                        className={getClassName({ descendantName: "metadata" })}
                      >
                        <dt
                          className={getClassName({ descendantName: "label" })}
                        >
                          {data.label}
                        </dt>
                        <dd
                          className={getClassName({ descendantName: "value" })}
                        >
                          {data.value}
                        </dd>
                      </dl>
                    );
                  })}
                </div>
              ) : null}

              {description ? (
                <div
                  className={getClassName({ descendantName: "description" })}
                >
                  {description}
                </div>
              ) : null}
            </div>

            {footer ? (
              <div
                className={getClassName({
                  descendantName: "footer"
                })}
              >
                {footer}
              </div>
            ) : null}
          </div>
        )}
      </CardBase>
    </div>
  );
};

TextCardBase.displayName = "TextCardBase";

TextCardBase.sizes = CardBase.sizes;
TextCardBase.headingRanks = Heading.ranks;

/* eslint-disable max-len */
TextCardBase.propTypes = {
  /**
   * Anchor the eyebrow, heading and description to the top or bottom of the card.
   */
  anchor: PropTypes.oneOf(["bottom", "top"]),
  /**
   * Hide the border
   */
  borderless: PropTypes.bool,
  /**
   * Description text
   */
  description: PropTypes.node,
  /**
   * Eyebrow text
   */
  eyebrow: PropTypes.string,
  /**
   * Footer. Should be a valid card footer, like ArticleCardFooter.
   */
  footer: PropTypes.node,
  /**
   * Heading text
   */
  heading: PropTypes.string.isRequired,
  /**
   * Heading rank. 1 will render `<h1 />`, and so on. Defaults to 3.
   */
  headingRank: PropTypes.oneOf(TextCardBase.headingRanks),
  /**
   * Card URL
   */
  href: PropTypes.string,
  /**
   * Enable large heading font size
   */
  largeHeading: PropTypes.bool,
  /**
   * A method to wrap the rendered card content. Use to apply a different visual treatment to the content, such as adding a background image. Usage: `renderContent={content => {<div>{content}</div>}}`.
   */
  renderContent: PropTypes.func,
  /**
   * Size of the Card. Will set the minumum height and grow to fit content.
   */
  size: PropTypes.oneOf(TextCardBase.sizes).isRequired,
  /**
   * Metadata content object array with label and value
   */
  metaData: PropTypes.array
};
/* eslint-enable max-len */

TextCardBase.defaultProps = {
  anchor: "top",
  headingRank: "3",
  renderContent: content => content // eslint-disable-line react/display-name
};

export default TextCardBase;
