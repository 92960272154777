import React from "react";
import { storiesOf } from "@storybook/react";
import { withKnobs, boolean, select, text } from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";
import { Language } from "@emcm-ui/component-theme";
import StaticRenderer from "@emcm-ui/component-static-renderer";
import CampaignTrackingParameterService from "@emcm-ui/utility-campaign";
import queryString from "query-string";
import "./Form.css";
import FormREADME from "../README.md";

import Button from "@emcm-ui/component-button";
import Heading from "@emcm-ui/component-heading";
import VerticalSpacing from "../../component-vertical-spacing/src/VerticalSpacing";

// referencing Form from module to avoid multiple references to context within Storybook. See issue #1435.
import Form from "./index";

const stories = storiesOf("Components/Form", module);

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(FormREADME));

const actions = {
  "https://demo1129453.mockable.io/form/succeed/inline-message":
    "Succeed inline",
  "https://demo1129453.mockable.io/form/succeed/redirect": "Succeed redirect",
  "https://demo1129453.mockable.io/form/fail/client": "Fail client",
  "https://demo1129453.mockable.io/form/fail/server": "Fail server",
  "https://demo1129453.mockable.io/form/fail/server-fault":
    "Fail server (invalid response)"
};

const countries = [
  { value: "AU", label: "Australia" },
  { value: "AT", label: "Austria" },
  { value: "CN", label: "Canada" },
  { value: "CZ", label: "Czech Republic" },
  { value: "DK", label: "Denmark" },
  { value: "KR", label: "Korea" },
  { value: "MX", label: "Mexico" },
  { value: "PH", label: "Philippines" },
  { value: "PL", label: "Poland" },
  { value: "PT", label: "Portugal" },
  { value: "GB", label: "United Kingdom" },
  { value: "US", label: "United States" },
  { value: "YE", label: "Yemen" },
  { value: "ZW", label: "Zimbabwe" },
  { value: "ZA", label: "South Africa" }
];

const compliantCountries = ["AT", "CZ", "DK", "PL", "PT", "GB", "ZA"];

const colorOptions = Button.colorOptions;

stories
  .add("Default", () => (
    <Form
      action={select("Action", actions, Object.keys(actions)[0])}
      footer={
        <span>
          By submitting this form you are acknowledging that you have read and
          agree to our <a href="https://not-visited.com">Privacy Policy</a>
        </span>
      }
      header={
        <Heading type="xs" rank="4">
          <strong>Send me the emails</strong>
        </Heading>
      }
      id={text("id", "")}
      submitButtonColor={select("Color", colorOptions, colorOptions[0])}
      submitButtonText={text("submitButtonText", "Submit")}
    >
      <Form.TextControl
        id="input1"
        labelText="Text control"
        name="input1"
        required={boolean("required", true)}
      />
    </Form>
  ))
  .add("CampaignControl", () => {
    const parameterToTrack = text("parameterToTrack", "selectedStory");
    const service = new CampaignTrackingParameterService([parameterToTrack]);

    service.add(queryString.parse(window.location.search));

    return (
      <div>
        <p>
          The CampaignControl is a hidden element. However, upon inspection, its
          value should match the {parameterToTrack} parameter ({service.get(
            parameterToTrack
          )}) in the URL of this page.
        </p>
        <Form action={select("Action", actions, Object.keys(actions)[0])}>
          <Form.CampaignControl
            name="campaign1"
            parameterToTrack={parameterToTrack}
            service={service}
          />
        </Form>
      </div>
    );
  })
  .add("PrivacyControl", () => {
    return (
      <Form action="#">
        <VerticalSpacing>
          <Form.SelectControl
            id="countries"
            labelText={"Select your country"}
            name="countries"
            options={countries}
            required
          />
        </VerticalSpacing>
        <VerticalSpacing>
          <Form.PrivacyControl
            consentName={text("consentName", "consent")}
            customCompliantsLabel="Accepted channel (Aleast any one)"
            optionalLabel={text("OptionalLabel", "")}
            customCompliants={[
              {
                country: "ZA",
                channels: [
                  {
                    name: "phone",
                    displayname: "Phone Number"
                  },
                  {
                    name: "email",
                    displayname: "Email Address"
                  }
                ],
                text:
                  "I Confirm my signature and consent to receive communication about refinitiv resources, events, product or service. Please note you can manage and update your preference at any time"
              }
            ]}
            consentText={
              <span>
                By submitting this form you are acknowledging that you have read
                and agree to our{" "}
                <a href="https://www.example.com">Marketing Policy</a> and would
                therefore be fine receiving our newsletter time to time.
              </span>
            }
            compliantCountries={compliantCountries}
            dependsOn="countries"
            explicitConsentText="I'd like to receive your newsletter"
          />
        </VerticalSpacing>
      </Form>
    );
  })
  .add("PrivacyControl with form", () => {
    return (
      <Form
        action={select("Action", actions, Object.keys(actions)[0])}
        header={
          <Heading type="xs" rank="4">
            <strong>Send me the emails</strong>
          </Heading>
        }
        id={text("id", "")}
        submitButtonColor={select("Color", colorOptions, colorOptions[0])}
        submitButtonText={text("submitButtonText", "Submit")}
      >
        <Form.TextControl
          id="email"
          labelText="Email"
          name="email"
          required={boolean("required", true)}
        />

        <Form.TextControl
          id="phone"
          labelText="Phone"
          name="phone"
          required={boolean("required", true)}
        />
        <VerticalSpacing>
          <Form.SelectControl
            id="countries"
            labelText={"Select your country"}
            name="countries"
            options={countries}
            required
          />
        </VerticalSpacing>
        <VerticalSpacing>
          <Form.PrivacyControl
            consentName={text("consentName", "consent")}
            optionalLabel={text("OptionalLabel", "")}
            consentText={
              <span>
                By submitting this form you are acknowledging that you have read
                and agree to our{" "}
                <a href="https://www.example.com">Marketing Policy</a> and would
                therefore be fine receiving our newsletter time to time.
              </span>
            }
            customCompliantsLabel="Accepted channel (Aleast any one)"
            customCompliants={[
              {
                country: "ZA",
                channels: [
                  {
                    name: "phone",
                    displayname: "Phone Number"
                  },
                  {
                    name: "email",
                    displayname: "Email Address"
                  }
                ],
                text:
                  "I Confirm my signature and consent to receive communication about refinitiv resources, events, product or service. Please note you can manage and update your preference at any time"
              }
            ]}
            compliantCountries={compliantCountries}
            dependsOn="countries"
            explicitConsentText="I'd like to receive your newsletter"
          />
        </VerticalSpacing>
      </Form>
    );
  })
  .add("CheckboxControl", () => (
    <Form action="#">
      <Form.CheckboxControl
        disabled={boolean("disabled", false)}
        id={text("id", "checkbox1")}
        labelText={text("labelText", "Subscribe to newsletter")}
        name={text("name", "checkbox1")}
        required={boolean("required", true)}
        value={text("value", "newsletter")}
        optionalLabel={text("OptionalLabel", "")}
      />
    </Form>
  ))
  .add("Fieldset", () => {
    return (
      <Form action="#">
        <Form.Fieldset legend={text("Fieldset legend", "Fieldset legend")}>
          <VerticalSpacing>
            <Form.TextControl
              id="input1"
              labelText="Text control"
              name="input1"
              required={boolean("required", true)}
            />
          </VerticalSpacing>
          <Form.TextControl
            id="input2"
            labelText="Text control"
            name="input2"
            required={boolean("required", true)}
          />
        </Form.Fieldset>
      </Form>
    );
  })
  .add("RadioControl", () => {
    const options = [
      { label: "Spam me daily", value: "daily" },
      { label: "Spam me weekly", value: "weekly" },
      { label: "Spam me monthly", value: "monthly" }
    ];

    return (
      <Form action="#">
        <Form.Fieldset legend={text("Fieldset legend", "Spam spam spam spam")}>
          <Form.RadioControl
            disabled={boolean("disabled", false)}
            name={text("name", "radios")}
            options={options}
          />
        </Form.Fieldset>
      </Form>
    );
  })
  .add("SelectControl", () => {
    const options = [
      { value: "A", label: "Apples" },
      { value: "B", label: "Bananas" },
      { value: "C", label: "Cherries" }
    ];

    return (
      <Form action="#">
        <Form.SelectControl
          disabled={boolean("disabled", false)}
          id={text("id", "fruits01")}
          labelText={text("labelText", "Fruits")}
          name={text("name", "fruits01")}
          options={options}
          placeholder={boolean("placeholder", true)}
          placeholderText={text("placeholderText", "Select…")}
          required={boolean("required", true)}
        />
      </Form>
    );
  })
  .add("TextareaControl", () => (
    <Form action="#">
      <Form.TextareaControl
        disabled={boolean("disabled", false)}
        id={text("id", "fruits02")}
        labelText={text("labelText", "Describe your fruits")}
        name={text("name", "fruits02")}
        required={boolean("required", true)}
      />
    </Form>
  ))
  .add("TextControl", () => (
    <Form action={select("Action", actions, Object.keys(actions)[0])}>
      <Form.TextControl
        disabled={boolean("disabled", false)}
        hashableIdentifier={boolean("hashableIdentifier", false)}
        id="input1"
        labelText={text("labelText", "Text control")}
        name="input1"
        required={boolean("required", true)}
        type={select("type", Form.TextControl.types, "text")}
      />
    </Form>
  ))
  .add("Language: Arabic (RTL)", () => (
    <Language code="ar">
      <Form
        action={select("Action", actions, Object.keys(actions)[0])}
        header={
          <Heading type="xs" rank="4">
            <strong>أرسل لي رسائل البريد الإلكتروني</strong>
          </Heading>
        }
        submitButtonText={text("submitButtonText", "خضع")}
      >
        الأطفال
      </Form>
    </Language>
  ))
  .add("Controlled components", () => {
    class ControlledForm extends React.Component {
      constructor(props) {
        super(props);

        this.state = {
          checkboxControl: false,
          radioControl: "second",
          selectControl: "B", // Only updated onBlur
          selectControlActive: "B", // Always the current value of the select
          textareaControl: "textarea control",
          textControl: "text control"
        };

        this.handleChangedRadio = this.handleChangedRadio.bind(this);
        this.setStateIfEnabled = this.setStateIfEnabled.bind(this);
      }

      handleChangedRadio(e) {
        this.setStateIfEnabled({
          radioControl: e.target.value
        });
      }

      setStateIfEnabled(state) {
        // eslint-disable-next-line react/prop-types
        if (this.props.allowStateChanges) {
          this.setState(state);
        }
      }

      render() {
        return (
          <Form
            action="https://demo1129453.mockable.io/form/succeed/inline-message"
            header={
              <Heading type="xs" rank="4">
                <strong>Send me the emails</strong>
              </Heading>
            }
            footer={
              <span>
                By submitting this form you are acknowledging that you have read
                and agree to our{" "}
                <a href="https://not-visited.com">Privacy Policy</a>
              </span>
            }
            submitButtonText="Submit"
          >
            <VerticalSpacing>
              <Form.TextControl
                id="input1"
                labelText="TextControl"
                name="input1"
                onChange={e =>
                  this.setStateIfEnabled({ textControl: e.target.value })
                }
                required
                value={this.state.textControl}
              />
            </VerticalSpacing>
            <VerticalSpacing>
              <Form.CheckboxControl
                checked={this.state.checkboxControl}
                id="checkbox"
                labelText="Checkbox control"
                name="checkbox"
                onChange={e =>
                  this.setStateIfEnabled({ checkboxControl: e.target.checked })
                }
                value="checkbox"
              />
            </VerticalSpacing>
            <VerticalSpacing>
              <Form.RadioControl
                name="radios"
                options={[
                  {
                    label: "RadioControl",
                    onChange: this.handleChangedRadio,
                    value: "first"
                  },
                  {
                    label: "With A Default Second",
                    onChange: this.handleChangedRadio,
                    value: "second"
                  },
                  {
                    label: "Option",
                    onChange: this.handleChangedRadio,
                    value: "option"
                  }
                ]}
                value={this.state.radioControl}
              />
            </VerticalSpacing>
            <VerticalSpacing>
              <Form.SelectControl
                id="selectcontrol"
                labelText="Required SelectControl with default Bananas value"
                name="selectcontrol"
                onChange={e =>
                  this.setStateIfEnabled({
                    selectControlActive: e.target.value
                  })
                }
                onBlur={e =>
                  this.setStateIfEnabled({ selectControl: e.target.value })
                }
                options={[
                  { value: "A", label: "Apples" },
                  { value: "B", label: "Bananas" },
                  { value: "C", label: "Cherries" }
                ]}
                value={this.state.selectControlActive}
                required
              />
            </VerticalSpacing>
            <VerticalSpacing>
              <p>
                The value in the SelectControl is {this.state.selectControl}.
              </p>
            </VerticalSpacing>
            <VerticalSpacing>
              <Form.TextareaControl
                id="textarea"
                labelText="TextareaControl"
                name="textarea"
                onChange={e =>
                  this.setStateIfEnabled({ textareaControl: e.target.value })
                }
                value={this.state.textareaControl}
              />
            </VerticalSpacing>
          </Form>
        );
      }
    }

    return (
      <ControlledForm
        allowStateChanges={boolean("Allow state changes?", true)}
      />
    );
  })
  .add("Rehydrated", () => (
    <StaticRenderer>
      <Form
        action="https://demo1129453.mockable.io/form/succeed/inline-message"
        analyticsName="analytics-name"
        analyticsNodes={{ textControl: "input1", fruits: "selectcontrol" }}
        analyticsType="analytics-type"
        header={
          <Heading type="xs" rank="4">
            <strong>Send me the emails</strong>
          </Heading>
        }
        footer={
          <span>
            By submitting this form you are acknowledging that you have read and
            agree to our <a href="https://not-visited.com">Privacy Policy</a>
          </span>
        }
        id="formId"
        submitButtonText="Submit"
      >
        <VerticalSpacing>
          <Form.TextControl
            id="input1"
            labelText="TextControl"
            name="input1"
            required
            defaultValue="Text control"
          />
        </VerticalSpacing>
        <VerticalSpacing>
          <Form.CheckboxControl
            defaultChecked
            id="checkedcheckbox"
            labelText="Checked Checkbox control"
            name="checkedcheckbox"
            value="checkedcheckbox"
          />
          <Form.CheckboxControl
            id="uncheckedcheckbox"
            labelText="Unchecked Checkbox control"
            name="uncheckedcheckbox"
            value="uncheckedcheckbox"
          />
        </VerticalSpacing>
        <VerticalSpacing>
          <Form.RadioControl
            name="radios"
            options={[
              { label: "RadioControl", value: "first" },
              { label: "With A Default Second", value: "second" },
              { label: "Option", value: "option" }
            ]}
            defaultValue="second"
          />
        </VerticalSpacing>
        <VerticalSpacing>
          <Form.SelectControl
            id="selectcontrol"
            labelText="Required SelectControl with default Bananas value"
            name="selectcontrol"
            options={[
              { value: "A", label: "Apples" },
              { value: "B", label: "Bananas" },
              { value: "C", label: "Cherries" }
            ]}
            defaultValue="B"
            required
          />
        </VerticalSpacing>
        <VerticalSpacing>
          <Form.TextareaControl
            id="textarea"
            labelText="TextareaControl"
            name="textarea"
            defaultValue="Textarea control"
          />
        </VerticalSpacing>
        <VerticalSpacing>
          <Form.TextareaControl
            disabled
            id="textarea"
            labelText="Disabled TextareaControl"
            name="textarea"
          />
        </VerticalSpacing>
      </Form>
    </StaticRenderer>
  ))
  .add("Rehydrated CampaignControl", () => {
    return (
      <StaticRenderer>
        <div>
          <p>
            The CampaignControl is a hidden element. However, upon inspection,
            its value should match the selectedStory parameter in the URL of
            this page.
          </p>
          <Form action={Object.keys(actions)[0]}>
            <Form.CampaignControl
              name="campaign1"
              defaultValue="defaultValue"
              parameterToTrack="selectedStory"
              service={null} // This will get replaced on rehydrate, using the value passed to registerService in the bundle.
            />
          </Form>
        </div>
      </StaticRenderer>
    );
  })
  .add("Rehydrated PrivacyControl", () => {
    return (
      <StaticRenderer>
        <Form action="#">
          <VerticalSpacing>
            <Form.SelectControl
              id="countries"
              labelText={"Select your country"}
              name="countries"
              options={countries}
              required
            />
          </VerticalSpacing>
          <VerticalSpacing>
            <Form.PrivacyControl
              consentName={text("consentName", "consent")}
              optionalLabel={text("OptionalLabel", "")}
              consentText={
                <span>
                  By submitting this form you are acknowledging that you have
                  read and agree to our{" "}
                  <a href="https://www.example.com">Marketing Policy</a> and
                  would therefore be fine receiving our newsletter time to time.
                </span>
              }
              customCompliantsLabel="Accepted channel (Aleast any one)"
              customCompliants={[
                {
                  country: "ZA",
                  channels: [
                    {
                      name: "phone",
                      displayname: "Phone Number"
                    },
                    {
                      name: "email",
                      displayname: "Email Address"
                    }
                  ],
                  text:
                    "I Confirm my signature and consent to receive communication about refinitiv resources, events, product or service. Please note you can manage and update your preference at any time"
                }
              ]}
              compliantCountries={compliantCountries}
              dependsOn="countries"
              explicitConsentText="I'd like to receive your newsletter"
            />
          </VerticalSpacing>
        </Form>
      </StaticRenderer>
    );
  });
