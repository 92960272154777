// Vendor
import React from "react";
import { storiesOf } from "@storybook/react";
import {
  withKnobs,
  text,
  boolean,
  number,
  select
} from "@storybook/addon-knobs/react";
import withReadme from "storybook-readme/with-readme";

// emcm-ui
import RichText from "@emcm-ui/component-rich-text";
import StaticRenderer from "@emcm-ui/component-static-renderer";
import { Language } from "@emcm-ui/component-theme";

// Local
import "./index.css";
import Expandable from "./index";
import ExpandableREADME from "../README.md";

const stories = storiesOf("Components/Expandable", module);

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(ExpandableREADME));

const exampleContent = () => (
  <RichText>
    <p>
      Lorem ipsum dolor sit, amet consectetur adipisicing elit. Provident saepe
      nam asperiores deleniti totam aut dignissimos et sapiente. Enim est magni,
      modi maiores consequatur veritatis deserunt ex corporis odio quod.
    </p>
  </RichText>
);

const exampleRTLContent = () => (
  <RichText>
    <p>
      أبجد هوز دولور الجلوس امات، الحسومات. في كثير من الأحيان الادخار يستحق هذا
      كله، أو الوضع للغاية، ورجل حكيم. أمر عظيم. على الرغم من أن وسائل النتائج
      الرئيسية للحقيقة، وأنها مهجورة عام خارج الجسم.
    </p>
  </RichText>
);

const headingRankDefault = 3;

stories
  .add("Default", () => (
    <Expandable
      title={text("title", "")}
      direction={select("direction", ["down", "up"], "down")}
      headingRank={`${number("Heading Rank", headingRankDefault)}`}
    >
      {exampleContent()}
    </Expandable>
  ))
  .add("Custom Header", () => (
    <Expandable
      header={props => (
        <button onClick={props.onClick}>
          {props.isExpanded
            ? "Hide the Extra Gubbins"
            : "View the Extra Gubbins"}
        </button>
      )}
    >
      {exampleContent()}
    </Expandable>
  ))
  .add("List", () => (
    <Expandable.List>
      <Expandable.Item
        header={props => (
          <Expandable.Item.Header {...props}>
            <strong>🍻 Learn about Beers</strong> - Brewed from the heart
          </Expandable.Item.Header>
        )}
      >
        {exampleContent()}
      </Expandable.Item>
      <Expandable.Item
        header={props => (
          <Expandable.Item.Header {...props}>
            <strong>🥃 Discover Whiskys</strong> - Distilled by bears
          </Expandable.Item.Header>
        )}
      >
        {exampleContent()}
      </Expandable.Item>
      <Expandable.Item
        header={props => (
          <Expandable.Item.Header {...props}>
            <strong>🌶️ Explore Spicy Lomboks</strong> - Spice spice baby
          </Expandable.Item.Header>
        )}
      >
        {exampleContent()}
      </Expandable.Item>
    </Expandable.List>
  ))
  .add("Ordered List", () => (
    <Expandable.List ordered>
      <Expandable.Item
        header={props => (
          <Expandable.Item.Header {...props}>
            <strong>🍻 Learn about Beers</strong> - Brewed from the heart
          </Expandable.Item.Header>
        )}
      >
        {exampleContent()}
      </Expandable.Item>
      <Expandable.Item
        header={props => (
          <Expandable.Item.Header {...props}>
            <strong>🥃 Discover Whiskys</strong> - Distilled by bears
          </Expandable.Item.Header>
        )}
      >
        {exampleContent()}
      </Expandable.Item>
      <Expandable.Item
        header={props => (
          <Expandable.Item.Header {...props}>
            <strong>🌶️ Explore Spicy Lomboks</strong> - Spice spice baby
          </Expandable.Item.Header>
        )}
      >
        {exampleContent()}
      </Expandable.Item>
    </Expandable.List>
  ))
  .add("Controlled Expansion", () => (
    <Expandable.List>
      <Expandable.Item
        isExpanded={boolean("first Expanded", true)}
        header={props => (
          <Expandable.Item.Header {...props}>
            <strong>🍻 Learn about Beers</strong> - Brewed from the heart
          </Expandable.Item.Header>
        )}
      >
        {exampleContent()}
      </Expandable.Item>
      <Expandable.Item
        isExpanded={boolean("second Expanded", false)}
        header={props => (
          <Expandable.Item.Header {...props}>
            <strong>🥃 Discover Whiskys</strong> - Distilled by bears
          </Expandable.Item.Header>
        )}
      >
        {exampleContent()}
      </Expandable.Item>
      <Expandable.Item
        isExpanded={boolean("third Expanded", false)}
        header={props => (
          <Expandable.Item.Header {...props}>
            <strong>🌶️ Explore Spicy Lomboks</strong> - Spice spice baby
          </Expandable.Item.Header>
        )}
      >
        {exampleContent()}
      </Expandable.Item>
    </Expandable.List>
  ))
  .add("Language: Arabic (RTL)", () => (
    <Language code="ar">
      <Expandable.List>
        <Expandable.Item title="شوكولاتة">
          {exampleRTLContent()}
        </Expandable.Item>
        <Expandable.Item title="شوكولاتة">
          {exampleRTLContent()}
        </Expandable.Item>
        <Expandable.Item title="شوكولاتة">
          {exampleRTLContent()}
        </Expandable.Item>
      </Expandable.List>
      <br />
      <Expandable.List ordered>
        <Expandable.Item title="شوكولاتة" subTitle="الفراولة">
          {exampleRTLContent()}
        </Expandable.Item>
        <Expandable.Item title="شوكولاتة" subTitle="الفراولة">
          {exampleRTLContent()}
        </Expandable.Item>
        <Expandable.Item title="شوكولاتة" subTitle="الفراولة">
          {exampleRTLContent()}
        </Expandable.Item>
      </Expandable.List>
    </Language>
  ))
  .add("Show More", () => (
    <Expandable
      direction={select("direction", ["down", "up"], "up")}
      expandedTitle={text("expandedTitle", "Less")}
      type={select("type", ["normal", "showMore"], "showMore")}
      title={text("title", "More")}
      headingRank={`${number("Heading Rank", headingRankDefault)}`}
    >
      {exampleContent()}
    </Expandable>
  ))
  .add("Expandable with show more", () => (
    <Expandable
      title={text("title", "")}
      direction={select("direction", ["down", "up"], "down")}
      headingRank={`${number("Heading Rank", headingRankDefault)}`}
    >
      {exampleContent()}
      <Expandable
        direction={select("direction", ["down", "up"], "up")}
        expandedTitle={text("expandedTitle", "Less")}
        type={select("type", ["normal", "showMore"], "showMore")}
        title={text("title", "More")}
        headingRank={`${number("Heading Rank", headingRankDefault)}`}
      >
        {exampleContent()}
      </Expandable>
    </Expandable>
  ))
  .add("Rehydrated", () => (
    <StaticRenderer>
      <Expandable.List>
        <Expandable.Item
          isExpanded
          title={
            <React.Fragment>
              <strong>🍻 Learn about Beers</strong> - Brewed from the heart
            </React.Fragment>
          }
        >
          {exampleContent()}
        </Expandable.Item>
        <Expandable.Item
          title={
            <React.Fragment>
              <strong>🥃 Discover Whiskys</strong> - Distilled by bears
            </React.Fragment>
          }
        >
          {exampleContent()}
        </Expandable.Item>
        <Expandable.Item
          title={
            <React.Fragment>
              <strong>🌶️ Explore Spicy Lomboks</strong> - Spice spice baby
            </React.Fragment>
          }
        >
          {exampleContent()}
        </Expandable.Item>
      </Expandable.List>
    </StaticRenderer>
  ));
