import getRehydratableName from "@emcm-ui/utility-rehydratable-name";

export default {
  [getRehydratableName("AjaxContent")]: async (...args) =>
    (await import("@emcm-ui/component-ajax-content")).ajaxContentWithContextRehydrator(
      ...args
    ),
  [getRehydratableName("AjaxContentContainer")]: async (...args) =>
    (await import("@emcm-ui/component-ajax-content")).ajaxContentContainerRehydrator(
      ...args
    ),
  [getRehydratableName("Datamap")]: async (...args) =>
    (await import("@emcm-ui/component-datamap")).rehydrator(...args),
  [getRehydratableName("DateSelect")]: async (...args) =>
    (await import("@emcm-ui/component-date-select")).rehydrator(...args),
  [getRehydratableName("DateTime")]: async (...args) =>
    (await import("@emcm-ui/component-date-time")).rehydrator(...args),
  [getRehydratableName("DismissibleBanner")]: async (...args) =>
    (await import("@emcm-ui/component-dismissible-banner")).rehydrator(...args),
  [getRehydratableName("Dropdown")]: async (...args) =>
    (await import("@emcm-ui/component-dropdown")).rehydrator(...args),
  [getRehydratableName("EventCard")]: async (...args) =>
    (await import("@emcm-ui/component-card")).eventCardRehydrator(...args),
  [getRehydratableName("Chart")]: async (...args) =>
    (await import("@emcm-ui/component-chart")).rehydrator(...args),
  [getRehydratableName("Expandable.Item")]: async (...args) =>
    (await import("@emcm-ui/component-expandable")).rehydrator(...args),
  [getRehydratableName("Filter")]: async (...args) =>
    (await import("@emcm-ui/component-filter")).rehydrator(...args),
  [getRehydratableName("GlobalFooter")]: async (...args) =>
    (await import("@emcm-ui/component-global-footer")).rehydrator(...args),
  [getRehydratableName("GlobalHeader")]: async (...args) =>
    (await import("@emcm-ui/component-global-header")).rehydrator(...args),
  [getRehydratableName("GlobalHeader.BaseItem")]: async (...args) =>
    (await import("@emcm-ui/component-global-header")).baseItemRehydrator(
      ...args
    ),
  [getRehydratableName("GlobalHeader.Login")]: async (...args) =>
    (await import("@emcm-ui/component-global-header")).loginRehydrator(...args),
  [getRehydratableName("Figure")]: async (...args) =>
    (await import("@emcm-ui/component-figure")).rehydrator(...args),
  [getRehydratableName("Form")]: async (...args) =>
    (await import("@emcm-ui/component-form")).formRehydrator(...args),
  [getRehydratableName("Form.CampaignControl")]: async (...args) =>
    (await import("@emcm-ui/component-form")).campaignControlRehydrator(
      ...args
    ),
  [getRehydratableName("Form.PrivacyControl")]: async (...args) =>
    (await import("@emcm-ui/component-form")).privacyControlRehydrator(...args),
  [getRehydratableName("FormNew")]: async (...args) =>
    (await import("@emcm-ui/component-forms-new-style")).formsNewStyleRehydrator(
      ...args
    ),
  [getRehydratableName("FormNew.CampaignControl")]: async (...args) =>
    (await import("@emcm-ui/component-forms-new-style")).campaignControlRehydrator(
      ...args
    ),
  [getRehydratableName("FormNew.PrivacyControl")]: async (...args) =>
    (await import("@emcm-ui/component-forms-new-style")).privacyControlRehydrator(
      ...args
    ),
  [getRehydratableName("FormNew.TextControl")]: async (...args) =>
    (await import("@emcm-ui/component-forms-new-style")).textControlRehydrator(
      ...args
    ),
  [getRehydratableName("FormNew.SelectControl")]: async (...args) =>
    (await import("@emcm-ui/component-forms-new-style")).selectControlRehydrator(
      ...args
    ),
  [getRehydratableName("FormNew.TextareaControl")]: async (...args) =>
    (await import("@emcm-ui/component-forms-new-style")).textareaControlRehydrator(
      ...args
    ),
  [getRehydratableName("GoogleMap")]: async (...args) =>
    (await import("@emcm-ui/component-google-map")).rehydrator(...args),
  [getRehydratableName("HorizontalOverflow")]: async (...args) =>
    (await import("@emcm-ui/component-horizontal-overflow")).rehydrator(
      ...args
    ),
  [getRehydratableName("Longform.ChapterNavInner")]: async (...args) =>
    (await import("@emcm-ui/component-longform")).chapterNavRehydrator(...args),
  [getRehydratableName("Longform.ChapterNav.Chapter")]: async (...args) =>
    (await import("@emcm-ui/component-longform")).chapterNavChapterRehydrator(
      ...args
    ),
  [getRehydratableName("MediaQuery")]: async (...args) =>
    (await import("@emcm-ui/component-media-query")).rehydrator(...args),
  [getRehydratableName("PageNavInner")]: async (...args) =>
    (await import("@emcm-ui/component-page-nav")).rehydrator(...args),
  [getRehydratableName("PageNav.Item")]: async (...args) =>
    (await import("@emcm-ui/component-page-nav")).itemRehydrator(...args),
  [getRehydratableName("SearchFilters.Filters")]: async (...args) =>
    (await import("@emcm-ui/component-search-filters")).filtersRehydrator(
      ...args
    ),
  [getRehydratableName("SearchFilters.FilterSectionOverflow")]: async (
    ...args
  ) =>
    (await import("@emcm-ui/component-search-filters")).filterSectionOverflowRehydrator(
      ...args
    ),
  [getRehydratableName("SearchFilters.Selected")]: async (...args) =>
    (await import("@emcm-ui/component-search-filters")).selectedReyhdrator(
      ...args
    ),
  [getRehydratableName("SearchInput")]: async (...args) =>
    (await import("@emcm-ui/component-search-input")).rehydrator(...args),
  [getRehydratableName("SharePriceTracker")]: async (...args) =>
    (await import("@emcm-ui/component-share-price-tracker")).rehydrator(
      ...args
    ),
  [getRehydratableName("SiteFooter")]: async (...args) =>
    (await import("@emcm-ui/component-site-footer")).rehydrator(...args),
  [getRehydratableName("SiteFooter.NavList")]: async (...args) =>
    (await import("@emcm-ui/component-site-footer")).navListRehydrator(...args),
  [getRehydratableName("SiteHeader")]: async (...args) =>
    (await import("@emcm-ui/component-site-header")).rehydrator(...args),
  [getRehydratableName("SiteHeader.Menu")]: async (...args) =>
    (await import("@emcm-ui/component-site-header")).menuRehydrator(...args),
  [getRehydratableName("SiteHeaderFlyouts")]: async (...args) =>
    (await import("@emcm-ui/component-site-header")).flyoutsRehydrator(...args),
  [getRehydratableName("SiteHeader.Flyouts.TabPanel")]: async (...args) =>
    (await import("@emcm-ui/component-site-header")).flyoutsPanelRehydrator(
      ...args
    ),
  [getRehydratableName("SocialDropdown")]: async (...args) =>
    (await import("@emcm-ui/component-social-dropdown")).rehydrator(...args),
  [getRehydratableName("TableWithFilters")]: async (...args) =>
    (await import("@emcm-ui/component-table-with-filters")).rehydrator(...args),
  [getRehydratableName("Tabs")]: async (...args) =>
    (await import("@emcm-ui/component-tabs")).rehydrator(...args),
  [getRehydratableName("Ticker")]: async (...args) =>
    (await import("@emcm-ui/component-ticker")).rehydrator(...args),
  [getRehydratableName("Ticker.Item")]: async (...args) =>
    (await import("@emcm-ui/component-ticker")).itemRehydrator(...args),
  [getRehydratableName("Tooltip")]: async (...args) =>
    (await import("@emcm-ui/component-tooltip")).rehydrator(...args),
  [getRehydratableName("TwitterCardTimeline")]: async (...args) =>
    (await import("@emcm-ui/component-card")).twitterCardTimelineRehydrator(
      ...args
    ),
  [getRehydratableName("VerticalNav")]: async (...args) =>
    (await import("@emcm-ui/component-vertical-nav")).rehydrator(...args),
  [getRehydratableName("VideoPlayerCore")]: async (...args) =>
    (await import("@emcm-ui/component-video-player")).rehydrator(...args),
  [getRehydratableName("UserInfo")]: async (...args) =>
    (await import("./components/UserInfo")).rehydrator(...args),
  [getRehydratableName("CartInfo")]: async (...args) =>
    (await import("./components/CartInfo")).rehydrator(...args),
  [getRehydratableName("Playlist")]: async (...args) =>
    (await import("@emcm-ui/component-playlist")).rehydrator(...args),
  [getRehydratableName("Recaptcha")]: async (...args) =>
    (await import("@emcm-ui/component-recaptcha")).rehydrator(...args),
  [getRehydratableName("ContentLike")]: async (...args) =>
    (await import("@emcm-ui/component-content-like")).rehydrator(...args),
  [getRehydratableName("KalturaVideoPlaylist")]: async (...args) =>
    (await import("@emcm-ui/component-kaltura-video-playlist")).rehydrator(
      ...args
    )
};
