import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";
import getClassNameFactory from "@emcm-ui/utility-class-names";

const RichText = ({ alignCenter, children, compact, large }) => {
  const getClassName = getClassNameFactory(RichText.displayName);

  return (
    <div
      className={getClassName({
        modifiers: classNames(
          alignCenter && "alignCenter",
          compact && "compact",
          large && "large"
        )
      })}
    >
      {children}
    </div>
  );
};

RichText.displayName = "RichText";

RichText.propTypes = {
  /**
   * Align text center modifier
   */
  alignCenter: PropTypes.bool,

  /**
   * Child nodes passed to the component.
   */
  children: PropTypes.node,

  /**
   * Compact variant modifier
   */
  compact: PropTypes.bool,

  /**
   * Large variant modifier
   */
  large: PropTypes.bool
};

export default RichText;
