import React from "react";
import { storiesOf } from "@storybook/react";
import { withKnobs } from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";
import { Language } from "@emcm-ui/component-theme";
import Section from "@emcm-ui/component-section";
import StaticRenderer from "@emcm-ui/component-static-renderer";
import SearchFilters from "./index";
import "./SearchFilters.css";
import SearchFiltersREADME from "../README.md";

const stories = storiesOf("Components/SearchFilters", module);

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(SearchFiltersREADME));

const SampleSearchFilters = () => (
  <Section background="grayLightest">
    <SearchFilters>
      <SearchFilters.Section>
        <SearchFilters.Selected clearAllHref="https://example.com">
          <SearchFilters.SelectedItem href="https://example.com">
            Financial
          </SearchFilters.SelectedItem>
          <SearchFilters.SelectedGroup title="Government solutions">
            <SearchFilters.SelectedItem href="https://example.com">
              Illicit Finance
            </SearchFilters.SelectedItem>
            <SearchFilters.SelectedItem href="https://example.com">
              Market Data and Analysis
            </SearchFilters.SelectedItem>
          </SearchFilters.SelectedGroup>
          <SearchFilters.SelectedItem href="https://example.com">
            Automotive
          </SearchFilters.SelectedItem>
        </SearchFilters.Selected>
      </SearchFilters.Section>
      <SearchFilters.Section>
        <SearchFilters.Filters>
          <SearchFilters.FilterSection title="Sector">
            <SearchFilters.FilterItem href="https://example.com" disabled>
              Financial (4)
            </SearchFilters.FilterItem>
            <SearchFilters.FilterItem href="https://example.com">
              Risk management solutions (12)
            </SearchFilters.FilterItem>
            <SearchFilters.FilterItem href="https://example.com">
              Tax & Accounting (18)
            </SearchFilters.FilterItem>
            <SearchFilters.FilterItem href="https://example.com">
              Thomson Reuters Corporate (16)
            </SearchFilters.FilterItem>
            <SearchFilters.FilterItem href="https://example.com">
              Legal (13)
            </SearchFilters.FilterItem>
            <SearchFilters.FilterItem href="https://example.com">
              Reuters News Agency (16)
            </SearchFilters.FilterItem>
            <SearchFilters.FilterGroup
              title="Government Solutions (6)"
              href="https://example.com"
            >
              <SearchFilters.FilterItem borderless href="https://example.com">
                Healthcare Fraud Detection and Prevention (1)
              </SearchFilters.FilterItem>
              <SearchFilters.FilterItem
                borderless
                href="https://example.com"
                disabled
              >
                Illicit Finance (1)
              </SearchFilters.FilterItem>
              <SearchFilters.FilterItem
                borderless
                href="https://example.com"
                disabled
              >
                Market Data and Analytics (1)
              </SearchFilters.FilterItem>
            </SearchFilters.FilterGroup>
            <SearchFilters.FilterSectionOverflow>
              <SearchFilters.FilterItem href="https://example.com" disabled>
                Automotive (5)
              </SearchFilters.FilterItem>
              <SearchFilters.FilterItem href="https://example.com">
                Energy (19)
              </SearchFilters.FilterItem>
            </SearchFilters.FilterSectionOverflow>
          </SearchFilters.FilterSection>

          <SearchFilters.FilterSection title="Page type">
            <SearchFilters.FilterItem href="https://example.com">
              Blog Post (11)
            </SearchFilters.FilterItem>
            <SearchFilters.FilterItem href="https://example.com">
              Press Release (7)
            </SearchFilters.FilterItem>
            <SearchFilters.FilterItem href="https://example.com">
              Products & Services (5)
            </SearchFilters.FilterItem>
            <SearchFilters.FilterItem href="https://example.com">
              Article (8)
            </SearchFilters.FilterItem>
            <SearchFilters.FilterItem href="https://example.com">
              Fact Sheet (8)
            </SearchFilters.FilterItem>
            <SearchFilters.FilterItem href="https://example.com">
              Brochure (21)
            </SearchFilters.FilterItem>
            <SearchFilters.FilterItem href="https://example.com">
              Careers (11)
            </SearchFilters.FilterItem>
            <SearchFilters.FilterItem href="https://example.com">
              White Paper (17)
            </SearchFilters.FilterItem>
            <SearchFilters.FilterItem href="https://example.com">
              Report (20)
            </SearchFilters.FilterItem>
            <SearchFilters.FilterItem href="https://example.com">
              Careers (11)
            </SearchFilters.FilterItem>
            <SearchFilters.FilterSectionOverflow>
              <SearchFilters.FilterItem href="https://example.com">
                Report (20)
              </SearchFilters.FilterItem>
              <SearchFilters.FilterItem href="https://example.com">
                Careers (11)
              </SearchFilters.FilterItem>
              <SearchFilters.FilterItem href="https://example.com">
                White Paper (17)
              </SearchFilters.FilterItem>
            </SearchFilters.FilterSectionOverflow>
          </SearchFilters.FilterSection>
        </SearchFilters.Filters>
      </SearchFilters.Section>
    </SearchFilters>
  </Section>
);

stories
  .add("Default", () => <SampleSearchFilters />)
  .add("Language: Arabic (RTL)", () => (
    <Language code="ar">
      <Section background="grayLightest">
        <SearchFilters>
          <SearchFilters.Section>
            <SearchFilters.Selected
              clearAllhref="https://example.com"
              clearAllText="الأسود"
              title="الكلب"
            >
              <SearchFilters.SelectedItem href="https://example.com">
                ملكي
              </SearchFilters.SelectedItem>
              <SearchFilters.SelectedGroup title="قبل الثعلب">
                <SearchFilters.SelectedItem href="https://example.com">
                  الأسود
                </SearchFilters.SelectedItem>
                <SearchFilters.SelectedItem href="https://example.com">
                  الانحناء
                </SearchFilters.SelectedItem>
              </SearchFilters.SelectedGroup>
              <SearchFilters.SelectedItem href="https://example.com">
                ملكي
              </SearchFilters.SelectedItem>
            </SearchFilters.Selected>
          </SearchFilters.Section>
          <SearchFilters.Section>
            <SearchFilters.Filters title="قبل الثعلب">
              <SearchFilters.FilterSection title="الكلب الأسود">
                <SearchFilters.FilterItem href="https://example.com">
                  الثعلب (4)
                </SearchFilters.FilterItem>
                <SearchFilters.FilterGroup
                  title="الأسود (5)"
                  href="https://example.com"
                >
                  <SearchFilters.FilterItem
                    borderless
                    href="https://example.com"
                  >
                    الانحناء (1)
                  </SearchFilters.FilterItem>
                  <SearchFilters.FilterItem
                    borderless
                    href="https://example.com"
                  >
                    ملكي (1)
                  </SearchFilters.FilterItem>
                </SearchFilters.FilterGroup>
                <SearchFilters.FilterSectionOverflow
                  collapseText="الانحناء"
                  expandText="ملكي"
                >
                  <SearchFilters.FilterItem href="https://example.com">
                    الثعلب (4)
                  </SearchFilters.FilterItem>
                  <SearchFilters.FilterGroup
                    title="الأسود (5)"
                    href="https://example.com"
                  >
                    <SearchFilters.FilterItem
                      borderless
                      href="https://example.com"
                    >
                      الانحناء (1)
                    </SearchFilters.FilterItem>
                    <SearchFilters.FilterItem
                      borderless
                      href="https://example.com"
                    >
                      ملكي (1)
                    </SearchFilters.FilterItem>
                  </SearchFilters.FilterGroup>
                </SearchFilters.FilterSectionOverflow>
              </SearchFilters.FilterSection>
            </SearchFilters.Filters>
          </SearchFilters.Section>
        </SearchFilters>
      </Section>
    </Language>
  ))
  .add("Rehydrated", () => (
    <StaticRenderer>
      <SampleSearchFilters />
    </StaticRenderer>
  ));
