import PropTypes from "prop-types";
import React from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import classNames from "classnames";

import Link from "@emcm-ui/component-link";

const ListOfLinksGroup = ({
  children,
  href,
  title,
  type,
  icon,
  iconPosition
}) => {
  const getClassName = getClassNameFactory(ListOfLinksGroup.displayName);

  return (
    <li
      className={getClassName({
        modifiers: classNames({ noParentLink: !href })
      })}
    >
      <div className={getClassName({ descendantName: "parent" })}>
        <Link href={href} type={type} icon={icon} iconPosition={iconPosition}>
          {title}
        </Link>
      </div>
      <ul className={getClassName({ descendantName: "items" })}>{children}</ul>
    </li>
  );
};

ListOfLinksGroup.displayName = "ListOfLinks.Group";

ListOfLinksGroup.propTypes = {
  /**
   * ListOfLinks Links to render.
   */
  children: PropTypes.node.isRequired,
  /**
   * Optional URL for the parent link.
   */
  href: PropTypes.string,
  /**
   * Title for the parent link.
   */
  title: PropTypes.string.isRequired,
  /**
   * Type of the parent link.
   */
  type: PropTypes.string,
  /**
   * Icon url of the parent link.
   */
  icon: PropTypes.string,
  /**
   * Icon position of the parent link.
   */
  iconPosition: PropTypes.string
};

export default ListOfLinksGroup;
