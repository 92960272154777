import React from "react";
import { storiesOf } from "@storybook/react";
import { withKnobs, boolean, select, text } from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";
import { Language } from "@emcm-ui/component-theme";
import DataCallout from "./index";
import "./DataCallout.css";
import DataCalloutREADME from "../README.md";
import { SVGIcon } from "@emcm-ui/component-icon/lib/svg";
import Section from "@emcm-ui/component-section";
import DateTime from "@emcm-ui/component-date-time";

const stories = storiesOf("Components/DataCallout", module);

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(DataCalloutREADME));

stories
  .add("Items with tooltip", () => (
    <Section
      background={select(
        "Section background",
        [""].concat(Section.backgrounds)
      )}
    >
      <DataCallout
        borderAndBackground={boolean("borderAndBackground", false)}
        horizontalContentAtDesktop={boolean(
          "horizontalContentAtDesktop",
          false
        )}
      >
        <DataCallout.Item
          eyebrow="Item 1 Eyebrow"
          iconType={<SVGIcon name="triangle" size="s" />}
          state="Decrease"
          value={text("Item 1 Value", "92k")}
          description={text("Item 1 Description", "Lines of code")}
          tooltipText={text("Tooltip Text", "This is a sample text")}
          tooltipIcon={<SVGIcon name="question-07" size="xs" />}
        />
        <DataCallout.Item
          eyebrow="Item 2 Eyebrow"
          iconType={
            <SVGIcon
              name="triangle"
              size="s"
              style={{ transform: "rotate(180deg)" }}
            />
          }
          state="Increase"
          value={text("Item 2 Value", "21")}
          description={text("Item 2 Description", "Developers")}
          tooltipText={text("Tooltip Text", "This is a sample text")}
          tooltipIcon={<SVGIcon name="question-07" size="xs" />}
        />
        <DataCallout.Item
          eyebrow="Item 3 Eyebrow"
          iconType={
            <SVGIcon
              name="triangle"
              size="s"
              style={{ transform: "rotate(180deg)" }}
            />
          }
          state="Increase"
          value={text("Item 3 Value", "21")}
          description={text("Item 3 Description", "Developers")}
          tooltipText={text("Tooltip Text", "This is a sample text")}
          tooltipIcon={<SVGIcon name="question-07" size="xs" />}
        />
        <DataCallout.Item
          eyebrow="Item 4 Eyebrow"
          iconType={
            <SVGIcon
              name="triangle"
              size="s"
              style={{ transform: "rotate(180deg)" }}
            />
          }
          state="Increase"
          value={text("Item 4 Value", "21")}
          description={text("Item 4 Description", "Developers")}
          tooltipText={text("Tooltip Text", "This is a sample text")}
          tooltipIcon={<SVGIcon name="question-07" size="xs" />}
        />
        <DataCallout.Item
          eyebrow="Item 5 Eyebrow"
          iconType={<SVGIcon name="triangle" size="s" />}
          state="Decrease"
          value={text("Item 5 Value", "4")}
          description={text("Item 5 Description", "Years of development")}
          tooltipText={text("Tooltip Text", "This is a sample text")}
          tooltipIcon={<SVGIcon name="question-07" size="xs" />}
        />
      </DataCallout>
    </Section>
  ))
  .add("two items", () => (
    <Section
      background={select(
        "Section background",
        [""].concat(Section.backgrounds)
      )}
    >
      <DataCallout
        borderAndBackground={boolean("borderAndBackground", false)}
        horizontalContentAtDesktop={boolean(
          "horizontalContentAtDesktop",
          false
        )}
      >
        <DataCallout.Item
          value={text("Data 1 Value", "92k")}
          description={text("Data 1 Description", "Lines of code")}
        />
        <DataCallout.Item
          value={text("Data 2 Value", "21")}
          description={text("Data 2 Description", "Developers")}
        />
      </DataCallout>
      <DataCallout
        borderAndBackground={boolean("borderAndBackground", false)}
        horizontalContentAtDesktop={boolean(
          "horizontalContentAtDesktop",
          false
        )}
      >
        <DataCallout.Item
          eyebrow="Item 1 Eyebrow"
          iconType={
            <SVGIcon
              name="triangle"
              size="s"
              style={{ transform: "rotate(180deg)" }}
            />
          }
          state="Increase"
          value={text("Item 1 Value", "92k")}
          description={text("Item 1 Description", "Lines of code")}
          tooltipText={text("Tooltip Text", "This is a sample text")}
          tooltipIcon={<SVGIcon name="question-07" size="xs" />}
        />
        <DataCallout.Item
          eyebrow="Item 2 Eyebrow"
          iconType={
            <SVGIcon
              name="triangle"
              size="s"
              style={{ transform: "rotate(180deg)" }}
            />
          }
          state="Increase"
          value={text("Item 2 Value", "21")}
          tooltipText={text("Tooltip Text", "This is a sample text")}
          description={text("Item 2 Description", "Developers")}
          tooltipIcon={<SVGIcon name="question-07" size="xs" />}
        />
      </DataCallout>
      <DataCallout
        borderAndBackground={boolean("borderAndBackground", false)}
        horizontalContentAtDesktop={boolean(
          "horizontalContentAtDesktop",
          false
        )}
      >
        <DataCallout.Item
          eyebrow="Item 1 Eyebrow"
          iconType={
            <SVGIcon
              name="triangle"
              size="s"
              style={{ transform: "rotate(180deg)" }}
            />
          }
          state="Increase"
          value={text("Item 1 Value", "92k")}
          description={text("Item 1 Description", "Lines of code")}
          tooltipText={text("Tooltip Text", "This is a sample text")}
          tooltipIcon={<SVGIcon name="question-07" size="xs" />}
        >
          Latest usage:{" "}
          <DateTime dateTime={"2016-03-02 13:57"} format="MMM Y" />
        </DataCallout.Item>
        <DataCallout.Item
          eyebrow="Item 2 Eyebrow"
          iconType={
            <SVGIcon
              name="triangle"
              size="s"
              style={{ transform: "rotate(180deg)" }}
            />
          }
          state="Increase"
          value={text("Item 2 Value", "21")}
          tooltipText={text("Tooltip Text", "This is a sample text")}
          description={text("Item 2 Description", "Developers")}
          tooltipIcon={<SVGIcon name="question-07" size="xs" />}
        >
          Latest usage:{" "}
          <DateTime dateTime={"2016-03-02 13:57"} format="MMM Y" />
        </DataCallout.Item>
      </DataCallout>
    </Section>
  ))
  .add("three items", () => (
    <Section
      background={select(
        "Section background",
        [""].concat(Section.backgrounds)
      )}
    >
      <DataCallout
        borderAndBackground={boolean("borderAndBackground", false)}
        horizontalContentAtDesktop={boolean(
          "horizontalContentAtDesktop",
          false
        )}
      >
        <DataCallout.Item
          value={text("Data 1 Value", "92k")}
          description={text("Data 1 Description", "Lines of code")}
        />
        <DataCallout.Item
          value={text("Data 2 Value", "21")}
          description={text("Data 2 Description", "Developers")}
        />
        <DataCallout.Item
          value={text("Data 3 Value", "4")}
          description={text("Data 3 Description", "Years of development")}
        />
      </DataCallout>
      <DataCallout
        borderAndBackground={boolean("borderAndBackground", false)}
        horizontalContentAtDesktop={boolean(
          "horizontalContentAtDesktop",
          false
        )}
      >
        <DataCallout.Item
          eyebrow="Item 1 Eyebrow"
          iconType={
            <SVGIcon
              name="triangle"
              size="s"
              style={{ transform: "rotate(180deg)" }}
            />
          }
          state="Increase"
          value={text("Item 1 Value", "92k")}
          description={text("Item 1 Description", "Lines of code")}
          tooltipText={text("Tooltip Text", "This is a sample text")}
          tooltipIcon={<SVGIcon name="question-07" size="xs" />}
        />
        <DataCallout.Item
          eyebrow="Item 2 Eyebrow"
          iconType={
            <SVGIcon
              name="triangle"
              size="s"
              style={{ transform: "rotate(180deg)" }}
            />
          }
          state="Increase"
          value={text("Item 2 Value", "21")}
          description={text("Item 2 Description", "Developers")}
          tooltipText={text("Tooltip Text", "This is a sample text")}
          tooltipIcon={<SVGIcon name="question-07" size="xs" />}
        />
        <DataCallout.Item
          eyebrow="Years of development Eyebrow"
          iconType={
            <SVGIcon
              name="triangle"
              size="s"
              style={{ transform: "rotate(180deg)" }}
            />
          }
          state="Increase"
          value={text("Item 3 Value", "4")}
          description={text("Item 3 Description", "Years of development")}
          tooltipText={text("Tooltip Text", "This is a sample text")}
          tooltipIcon={<SVGIcon name="question-07" size="xs" />}
        />
      </DataCallout>
      <DataCallout
        borderAndBackground={boolean("borderAndBackground", false)}
        horizontalContentAtDesktop={boolean(
          "horizontalContentAtDesktop",
          false
        )}
      >
        <DataCallout.Item
          eyebrow="Item 1 Eyebrow"
          iconType={
            <SVGIcon
              name="triangle"
              size="s"
              style={{ transform: "rotate(180deg)" }}
            />
          }
          state="Increase"
          value={text("Item 1 Value", "92k")}
          description={text("Item 1 Description", "Lines of code")}
          tooltipText={text("Tooltip Text", "This is a sample text")}
          tooltipIcon={<SVGIcon name="question-07" size="xs" />}
        >
          Latest usage:{" "}
          <DateTime dateTime={"2016-03-02 13:57"} format="MMM Y" />
        </DataCallout.Item>
        <DataCallout.Item
          eyebrow="Item 2 Eyebrow"
          iconType={
            <SVGIcon
              name="triangle"
              size="s"
              style={{ transform: "rotate(180deg)" }}
            />
          }
          state="Increase"
          value={text("Item 2 Value", "21")}
          description={text("Item 2 Description", "Developers")}
          tooltipText={text("Tooltip Text", "This is a sample text")}
          tooltipIcon={<SVGIcon name="question-07" size="xs" />}
        >
          Latest usage:{" "}
          <DateTime dateTime={"2016-03-02 13:57"} format="MMM Y" />
        </DataCallout.Item>
        <DataCallout.Item
          eyebrow="Years of development Eyebrow"
          iconType={
            <SVGIcon
              name="triangle"
              size="s"
              style={{ transform: "rotate(180deg)" }}
            />
          }
          state="Increase"
          value={text("Item 3 Value", "4")}
          description={text("Item 3 Description", "Years of development")}
          tooltipText={text("Tooltip Text", "This is a sample text")}
          tooltipIcon={<SVGIcon name="question-07" size="xs" />}
        >
          Latest usage:{" "}
          <DateTime dateTime={"2016-03-02 13:57"} format="MMM Y" />
        </DataCallout.Item>
      </DataCallout>
    </Section>
  ))
  .add("four items", () => (
    <Section
      background={select(
        "Section background",
        [""].concat(Section.backgrounds)
      )}
    >
      <DataCallout
        borderAndBackground={boolean("borderAndBackground", false)}
        horizontalContentAtDesktop={boolean(
          "horizontalContentAtDesktop",
          false
        )}
      >
        <DataCallout.Item
          value={text("Data 1 Value", "92k")}
          description={text("Data 1 Description", "Lines of code")}
        />
        <DataCallout.Item
          value={text("Data 2 Value", "21")}
          description={text("Data 2 Description", "Developers")}
        />
        <DataCallout.Item
          value={text("Data 3 Value", "4")}
          description={text("Data 3 Description", "Years of development")}
        />
        <DataCallout.Item
          value={text("Item 4 Value", "100m")}
          description={text("Item 4 Description", "Users")}
        />
      </DataCallout>
      <DataCallout
        borderAndBackground={boolean("borderAndBackground", false)}
        horizontalContentAtDesktop={boolean(
          "horizontalContentAtDesktop",
          false
        )}
      >
        <DataCallout.Item
          eyebrow="Item 1 Eyebrow"
          iconType={
            <SVGIcon
              name="triangle"
              size="s"
              style={{ transform: "rotate(180deg)" }}
            />
          }
          state="Increase"
          value={text("Item 1 Value", "92k")}
          description={text("Item 1 Description", "Lines of code")}
          tooltipText={text("Tooltip Text", "This is a sample text")}
          tooltipIcon={<SVGIcon name="question-07" size="xs" />}
        />
        <DataCallout.Item
          eyebrow="Item 2 Eyebrow"
          iconType={
            <SVGIcon
              name="triangle"
              size="s"
              style={{ transform: "rotate(180deg)" }}
            />
          }
          state="Increase"
          value={text("Item 2 Value", "21")}
          description={text("Item 2 Description", "Developers")}
          tooltipText={text("Tooltip Text", "This is a sample text")}
          tooltipIcon={<SVGIcon name="question-07" size="xs" />}
        />
        <DataCallout.Item
          eyebrow="Years of development Eyebrow"
          iconType={
            <SVGIcon
              name="triangle"
              size="s"
              style={{ transform: "rotate(180deg)" }}
            />
          }
          state="Increase"
          value={text("Item 3 Value", "4")}
          description={text("Item 3 Description", "Years of development")}
          tooltipText={text("Tooltip Text", "This is a sample text")}
          tooltipIcon={<SVGIcon name="question-07" size="xs" />}
        />
        <DataCallout.Item
          eyebrow="Users Eyebrow"
          iconType={
            <SVGIcon
              name="triangle"
              size="s"
              style={{ transform: "rotate(180deg)" }}
            />
          }
          state="Increase"
          value={text("Item 4 Value", "100m")}
          description={text("Item 4 Description", "Users")}
          tooltipText={text("Tooltip Text", "This is a sample text")}
          tooltipIcon={<SVGIcon name="question-07" size="xs" />}
        />
      </DataCallout>
      <DataCallout
        borderAndBackground={boolean("borderAndBackground", false)}
        horizontalContentAtDesktop={boolean(
          "horizontalContentAtDesktop",
          false
        )}
      >
        <DataCallout.Item
          eyebrow="Item 1 Eyebrow"
          iconType={
            <SVGIcon
              name="triangle"
              size="s"
              style={{ transform: "rotate(180deg)" }}
            />
          }
          state="Increase"
          value={text("Item 1 Value", "92k")}
          description={text("Item 1 Description", "Lines of code")}
          tooltipText={text("Tooltip Text", "This is a sample text")}
          tooltipIcon={<SVGIcon name="question-07" size="xs" />}
        >
          Latest usage:{" "}
          <DateTime dateTime={"2016-03-02 13:57"} format="MMM Y" />
        </DataCallout.Item>
        <DataCallout.Item
          eyebrow="Item 2 Eyebrow"
          iconType={
            <SVGIcon
              name="triangle"
              size="s"
              style={{ transform: "rotate(180deg)" }}
            />
          }
          state="Increase"
          value={text("Item 2 Value", "21")}
          description={text("Item 2 Description", "Developers")}
          tooltipText={text("Tooltip Text", "This is a sample text")}
          tooltipIcon={<SVGIcon name="question-07" size="xs" />}
        >
          Latest usage:{" "}
          <DateTime dateTime={"2016-03-02 13:57"} format="MMM Y" />
        </DataCallout.Item>
        <DataCallout.Item
          eyebrow="Years of development Eyebrow"
          iconType={
            <SVGIcon
              name="triangle"
              size="s"
              style={{ transform: "rotate(180deg)" }}
            />
          }
          state="Increase"
          value={text("Item 3 Value", "4")}
          description={text("Item 3 Description", "Years of development")}
          tooltipText={text("Tooltip Text", "This is a sample text")}
          tooltipIcon={<SVGIcon name="question-07" size="xs" />}
        >
          Latest usage:{" "}
          <DateTime dateTime={"2016-03-02 13:57"} format="MMM Y" />
        </DataCallout.Item>
        <DataCallout.Item
          eyebrow="Users Eyebrow"
          iconType={
            <SVGIcon
              name="triangle"
              size="s"
              style={{ transform: "rotate(180deg)" }}
            />
          }
          state="Increase"
          value={text("Item 4 Value", "100m")}
          description={text("Item 4 Description", "Users")}
          tooltipText={text("Tooltip Text", "This is a sample text")}
          tooltipIcon={<SVGIcon name="question-07" size="xs" />}
        >
          Latest usage:{" "}
          <DateTime dateTime={"2016-03-02 13:57"} format="MMM Y" />
        </DataCallout.Item>
      </DataCallout>
    </Section>
  ))
  .add("five items", () => (
    <Section
      background={select(
        "Section background",
        [""].concat(Section.backgrounds)
      )}
    >
      <DataCallout
        borderAndBackground={boolean("borderAndBackground", false)}
        horizontalContentAtDesktop={boolean(
          "horizontalContentAtDesktop",
          false
        )}
      >
        <DataCallout.Item
          eyebrow="Item 1 Eyebrow"
          iconType={<SVGIcon name="triangle" size="s" />}
          state="Decrease"
          value={text("Item 1 Value", "92k")}
          description={text("Item 1 Description", "Lines of code")}
        />
        <DataCallout.Item
          eyebrow="Two lines in the label for the heading in the eyebrow, adding extra text to validate css ellipsis on this"
          iconType={
            <SVGIcon
              name="triangle"
              size="s"
              style={{ transform: "rotate(180deg)" }}
            />
          }
          state="Increase"
          value={text("Item 2 Value", "21")}
          description={text("Item 2 Description", "Developers")}
        />
        <DataCallout.Item
          eyebrow="Years of development Eyebrow"
          iconType={
            <SVGIcon
              name="triangle"
              size="s"
              style={{ transform: "rotate(180deg)" }}
            />
          }
          state="Increase"
          value={text("Item 3 Value", "4")}
          description={text("Item 3 Description", "Years of development")}
        />
        <DataCallout.Item
          eyebrow="Users Eyebrow"
          iconType={
            <SVGIcon
              name="triangle"
              size="s"
              style={{ transform: "rotate(180deg)" }}
            />
          }
          state="Increase"
          value={text("Item 4 Value", "100m")}
          description={text("Item 4 Description", "Users")}
        />
        <DataCallout.Item
          eyebrow="Catastrophic bug Eyebrow"
          iconType={
            <SVGIcon
              name="triangle"
              size="s"
              style={{ transform: "rotate(180deg)" }}
            />
          }
          state="Increase"
          value={text("Item 5 Value", "1")}
          description={text("Item 5 Description", "Catastrophic bug")}
        />
      </DataCallout>
      <DataCallout
        borderAndBackground={boolean("borderAndBackground", false)}
        horizontalContentAtDesktop={boolean(
          "horizontalContentAtDesktop",
          false
        )}
      >
        <DataCallout.Item
          eyebrow="Item 1 Eyebrow"
          iconType={<SVGIcon name="triangle" size="s" />}
          state="Decrease"
          value={text("Item 1 Value", "92k")}
          description={text("Item 1 Description", "Lines of code")}
          tooltipText={text("Tooltip Text", "This is a sample text")}
          tooltipIcon={<SVGIcon name="question-07" size="xs" />}
        />
        <DataCallout.Item
          eyebrow="Item 2 Eyebrow"
          iconType={
            <SVGIcon
              name="triangle"
              size="s"
              style={{ transform: "rotate(180deg)" }}
            />
          }
          state="Increase"
          value={text("Item 2 Value", "21")}
          description={text("Item 2 Description", "Developers")}
          tooltipText={text("Tooltip Text", "This is a sample text")}
          tooltipIcon={<SVGIcon name="question-07" size="xs" />}
        />
        <DataCallout.Item
          eyebrow="Two lines in the label for the heading in the eyebrow, adding extra text to validate css ellipsis on this"
          iconType={
            <SVGIcon
              name="triangle"
              size="s"
              style={{ transform: "rotate(180deg)" }}
            />
          }
          state="Increase"
          value={text("Item 3 Value", "21")}
          description={text("Item 3 Description", "Developers")}
          tooltipText={text("Tooltip Text", "This is a sample text")}
          tooltipIcon={<SVGIcon name="question-07" size="xs" />}
        />
        <DataCallout.Item
          eyebrow="Item 4 Eyebrow"
          iconType={
            <SVGIcon
              name="triangle"
              size="s"
              style={{ transform: "rotate(180deg)" }}
            />
          }
          state="Increase"
          value={text("Item 4 Value", "21")}
          description={text("Item 4 Description", "Developers")}
          tooltipText={text("Tooltip Text", "This is a sample text")}
          tooltipIcon={<SVGIcon name="question-07" size="xs" />}
        />
        <DataCallout.Item
          eyebrow="Item 5 Eyebrow"
          iconType={<SVGIcon name="triangle" size="s" />}
          state="Decrease"
          value={text("Item 5 Value", "4")}
          description={text("Item 5 Description", "Years of development")}
          tooltipText={text("Tooltip Text", "This is a sample text")}
          tooltipIcon={<SVGIcon name="question-07" size="xs" />}
        />
      </DataCallout>
      <DataCallout
        borderAndBackground={boolean("borderAndBackground", false)}
        horizontalContentAtDesktop={boolean(
          "horizontalContentAtDesktop",
          false
        )}
      >
        <DataCallout.Item
          eyebrow="Item 1 Eyebrow"
          iconType={<SVGIcon name="triangle" size="s" />}
          state="Decrease"
          value={text("Item 1 Value", "92k")}
          description={text("Item 1 Description", "Lines of code")}
          tooltipText={text("Tooltip Text", "This is a sample text")}
          tooltipIcon={<SVGIcon name="question-07" size="xs" />}
        >
          Latest usage:{" "}
          <DateTime dateTime={"2016-03-02 13:57"} format="MMM Y" />
        </DataCallout.Item>
        <DataCallout.Item
          eyebrow="Item 2 Eyebrow"
          iconType={
            <SVGIcon
              name="triangle"
              size="s"
              style={{ transform: "rotate(180deg)" }}
            />
          }
          state="Increase"
          value={text("Item 2 Value", "21")}
          description={text("Item 2 Description", "Developers")}
          tooltipText={text("Tooltip Text", "This is a sample text")}
          tooltipIcon={<SVGIcon name="question-07" size="xs" />}
        >
          Latest usage:{" "}
          <DateTime dateTime={"2016-03-02 13:57"} format="MMM Y" />
        </DataCallout.Item>
        <DataCallout.Item
          eyebrow="Two lines in the label for the heading in the eyebrow, adding extra text to validate css ellipsis on this"
          iconType={
            <SVGIcon
              name="triangle"
              size="s"
              style={{ transform: "rotate(180deg)" }}
            />
          }
          state="Increase"
          value={text("Item 3 Value", "21")}
          description={text("Item 3 Description", "Developers")}
          tooltipText={text("Tooltip Text", "This is a sample text")}
          tooltipIcon={<SVGIcon name="question-07" size="xs" />}
        >
          Latest usage:{" "}
          <DateTime dateTime={"2016-03-02 13:57"} format="MMM Y" />
        </DataCallout.Item>
        <DataCallout.Item
          eyebrow="Item 4 Eyebrow"
          iconType={
            <SVGIcon
              name="triangle"
              size="s"
              style={{ transform: "rotate(180deg)" }}
            />
          }
          state="Increase"
          value={text("Item 4 Value", "21")}
          description={text("Item 4 Description", "Developers")}
          tooltipText={text("Tooltip Text", "This is a sample text")}
          tooltipIcon={<SVGIcon name="question-07" size="xs" />}
        >
          Latest usage:{" "}
          <DateTime dateTime={"2016-03-02 13:57"} format="MMM Y" />
        </DataCallout.Item>
        <DataCallout.Item
          eyebrow="Item 5 Eyebrow"
          iconType={<SVGIcon name="triangle" size="s" />}
          state="Decrease"
          value={text("Item 5 Value", "4")}
          description={text("Item 5 Description", "Years of development")}
          tooltipText={text("Tooltip Text", "This is a sample text")}
          tooltipIcon={<SVGIcon name="question-07" size="xs" />}
        >
          Latest usage:{" "}
          <DateTime dateTime={"2016-03-02 13:57"} format="MMM Y" />
        </DataCallout.Item>
      </DataCallout>
    </Section>
  ))
  .add("Language: Arabic (RTL)", () => (
    <Language code="ar">
      <Section
        background={select(
          "Section background",
          [""].concat(Section.backgrounds)
        )}
      >
        <DataCallout
          borderAndBackground={boolean("borderAndBackground", false)}
          horizontalContentAtDesktop={boolean(
            "horizontalContentAtDesktop",
            false
          )}
        >
          <DataCallout.Item
            value={text("Item 1 Value", "92k")}
            description={text(
              "Item 1 Description",
              "أسطر من التعليمات البرمجية"
            )}
          />
          <DataCallout.Item
            value={text("Item 2 Value", "21")}
            description={text("Item 2 Description", "المطورون")}
          />
          <DataCallout.Item
            value={text("Item 3 Value", "4")}
            description={text("Item 3 Description", "سنوات من التطوير")}
          />
          <DataCallout.Item
            value={text("Item 4 Value", "100m")}
            description={text("Item 4 Description", "المستخدمين")}
          />
          <DataCallout.Item
            value={text("Item 5 Value", "1")}
            description={text("Item 5 Description", "علة كارثية")}
          />
        </DataCallout>
        <DataCallout
          borderAndBackground={boolean("borderAndBackground", false)}
          horizontalContentAtDesktop={boolean(
            "horizontalContentAtDesktop",
            false
          )}
        >
          <DataCallout.Item
            eyebrow="Catastrophic bug Eyebrow"
            iconType={
              <SVGIcon
                name="triangle"
                size="s"
                style={{ transform: "rotate(180deg)" }}
              />
            }
            state="Increase"
            value={text("Item 1 Value", "92k")}
            tooltipText={text("Tooltip Text", "أسطر من التعليمات البرمجية")}
            description={text(
              "Item 1 Description",
              "أسطر من التعليمات البرمجية"
            )}
            tooltipIcon={<SVGIcon name="question-07" size="xs" />}
          />
          <DataCallout.Item
            eyebrow="Catastrophic bug Eyebrow"
            iconType={
              <SVGIcon
                name="triangle"
                size="s"
                style={{ transform: "rotate(180deg)" }}
              />
            }
            state="Increase"
            value={text("Item 2 Value", "21")}
            description={text("Item 2 Description", "المطورون")}
            tooltipText={text("Tooltip Text", "أسطر من التعليمات البرمجية")}
            tooltipIcon={<SVGIcon name="question-07" size="xs" />}
          />
          <DataCallout.Item
            eyebrow="Catastrophic bug Eyebrow"
            iconType={
              <SVGIcon
                name="triangle"
                size="s"
                style={{ transform: "rotate(180deg)" }}
              />
            }
            state="Increase"
            value={text("Item 3 Value", "4")}
            description={text("Item 3 Description", "سنوات من التطوير")}
            tooltipText={text("Tooltip Text", "أسطر من التعليمات البرمجية")}
            tooltipIcon={<SVGIcon name="question-07" size="xs" />}
          />
          <DataCallout.Item
            eyebrow="Catastrophic bug Eyebrow"
            iconType={
              <SVGIcon
                name="triangle"
                size="s"
                style={{ transform: "rotate(180deg)" }}
              />
            }
            state="Increase"
            value={text("Item 4 Value", "100m")}
            description={text("Item 4 Description", "المستخدمين")}
            tooltipText={text("Tooltip Text", "أسطر من التعليمات البرمجية")}
            tooltipIcon={<SVGIcon name="question-07" size="xs" />}
          />
          <DataCallout.Item
            eyebrow="Catastrophic bug Eyebrow"
            iconType={<SVGIcon name="triangle" size="s" />}
            state="Decrease"
            value={text("Item 5 Value", "1")}
            description={text("Item 5 Description", "علة كارثية")}
            tooltipText={text("Tooltip Text", "أسطر من التعليمات البرمجية")}
            tooltipIcon={<SVGIcon name="question-07" size="xs" />}
          />
        </DataCallout>
        <DataCallout
          borderAndBackground={boolean("borderAndBackground", false)}
          horizontalContentAtDesktop={boolean(
            "horizontalContentAtDesktop",
            false
          )}
        >
          <DataCallout.Item
            eyebrow="Catastrophic bug Eyebrow"
            iconType={
              <SVGIcon
                name="triangle"
                size="s"
                style={{ transform: "rotate(180deg)" }}
              />
            }
            state="Increase"
            value={text("Item 1 Value", "92k")}
            tooltipText={text("Tooltip Text", "أسطر من التعليمات البرمجية")}
            description={text(
              "Item 1 Description",
              "أسطر من التعليمات البرمجية"
            )}
            tooltipIcon={<SVGIcon name="question-07" size="xs" />}
          >
            أحدث استخدام:{" "}
            <DateTime dateTime={"2016-03-02 13:57"} format="MMM Y" />
          </DataCallout.Item>
          <DataCallout.Item
            eyebrow="Catastrophic bug Eyebrow"
            iconType={
              <SVGIcon
                name="triangle"
                size="s"
                style={{ transform: "rotate(180deg)" }}
              />
            }
            state="Increase"
            value={text("Item 2 Value", "21")}
            description={text("Item 2 Description", "المطورون")}
            tooltipText={text("Tooltip Text", "أسطر من التعليمات البرمجية")}
            tooltipIcon={<SVGIcon name="question-07" size="xs" />}
          >
            أحدث استخدام:{" "}
            <DateTime dateTime={"2016-03-02 13:57"} format="MMM Y" />
          </DataCallout.Item>
          <DataCallout.Item
            eyebrow="Catastrophic bug Eyebrow"
            iconType={
              <SVGIcon
                name="triangle"
                size="s"
                style={{ transform: "rotate(180deg)" }}
              />
            }
            state="Increase"
            value={text("Item 3 Value", "4")}
            description={text("Item 3 Description", "سنوات من التطوير")}
            tooltipText={text("Tooltip Text", "أسطر من التعليمات البرمجية")}
            tooltipIcon={<SVGIcon name="question-07" size="xs" />}
          >
            أحدث استخدام:{" "}
            <DateTime dateTime={"2016-03-02 13:57"} format="MMM Y" />
          </DataCallout.Item>
          <DataCallout.Item
            eyebrow="Catastrophic bug Eyebrow"
            iconType={
              <SVGIcon
                name="triangle"
                size="s"
                style={{ transform: "rotate(180deg)" }}
              />
            }
            state="Increase"
            value={text("Item 4 Value", "100m")}
            description={text("Item 4 Description", "المستخدمين")}
            tooltipText={text("Tooltip Text", "أسطر من التعليمات البرمجية")}
            tooltipIcon={<SVGIcon name="question-07" size="xs" />}
          >
            أحدث استخدام:{" "}
            <DateTime dateTime={"2016-03-02 13:57"} format="MMM Y" />
          </DataCallout.Item>
          <DataCallout.Item
            eyebrow="Catastrophic bug Eyebrow"
            iconType={<SVGIcon name="triangle" size="s" />}
            state="Decrease"
            value={text("Item 5 Value", "1")}
            description={text("Item 5 Description", "علة كارثية")}
            tooltipText={text("Tooltip Text", "أسطر من التعليمات البرمجية")}
            tooltipIcon={<SVGIcon name="question-07" size="xs" />}
          >
            أحدث استخدام:{" "}
            <DateTime dateTime={"2016-03-02 13:57"} format="MMM Y" />
          </DataCallout.Item>
        </DataCallout>
      </Section>
    </Language>
  ));
