import PropTypes from "prop-types";
import React from "react";
import Button from "@emcm-ui/component-button";
import { SVGIcon } from "@emcm-ui/component-icon/lib/svg";
import Link from "@emcm-ui/component-link";

import getClassNameFactory from "@emcm-ui/utility-class-names";

const ModalFooter = props => {
  const getClassName = getClassNameFactory(ModalFooter.displayName);

  const {
    primaryButtonColor,
    primaryButtonLabel,
    secondaryButtonLabel,
    secondaryButtonUrl,
    secondaryLinkLabel,
    secondaryLinkUrl,
    closeThisComponent
  } = props;

  const hasFooterLinks =
    primaryButtonLabel || secondaryButtonLabel || secondaryLinkLabel;

  if (!hasFooterLinks) {
    return null;
  }

  return (
    <div className={getClassName()}>
      <div
        className={getClassName({
          descendantName: "buttonGroup"
        })}
      >
        {primaryButtonLabel && (
          <Button
            kind="button"
            color={primaryButtonColor}
            onClick={closeThisComponent}
          >
            {primaryButtonLabel}
          </Button>
        )}
        {secondaryButtonLabel &&
          secondaryButtonUrl && (
            <Button kind="link" color="secondary" href={secondaryButtonUrl}>
              {secondaryButtonLabel}
            </Button>
          )}
        {secondaryLinkLabel &&
          secondaryLinkUrl && (
            <Link
              href={secondaryLinkUrl}
              icon={
                <span
                  className={getClassName({
                    descendantName: "linkArrow"
                  })}
                >
                  <SVGIcon name="arrow" size="s" />
                </span>
              }
              iconPosition={"right"}
              newWindow={false}
              type={"icon"}
            >
              {secondaryLinkLabel}
            </Link>
          )}
      </div>
    </div>
  );
};

ModalFooter.displayName = "ModalFooter";

ModalFooter.propTypes = {
  /**
   * Primary button text
   */
  primaryButtonLabel: PropTypes.string,

  /**
   * Primary button color
   */
  primaryButtonColor: PropTypes.string,

  /**
   * Secondary button text
   */
  secondaryButtonLabel: PropTypes.string,

  /**
   * Secondary button target
   */
  secondaryButtonUrl: PropTypes.string,

  /**
   * Secondary link label
   */
  secondaryLinkLabel: PropTypes.string,

  /**
   * Secondary link target
   */
  secondaryLinkUrl: PropTypes.string,

  /**
   * Callback to close the modal
   */
  closeThisComponent: PropTypes.func
};

ModalFooter.defaultProps = {
  primaryButtonColor: "primary"
};

export default ModalFooter;
