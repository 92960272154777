import React from "react";
import Dropdown from "./Dropdown";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import AjaxPanel from "@emcm-ui/component-panel/lib/Panel/Panel";

const getClassName = getClassNameFactory(Dropdown.displayName);

export default async (domNode, rehydrateChildren, extra) => {
  const getPanelClassName = getClassNameFactory(AjaxPanel.displayName);

  const panelNodes = domNode.querySelectorAll(
    `.${getClassName({ descendantName: "panels" })} > .${getPanelClassName()}`
  );

  const panels = [];

  for (let i = 0; i < panelNodes.length; i++) {
    panels.push(
      await AjaxPanel.getPanelProps(panelNodes[i], rehydrateChildren, extra)
    );
  }
  const readData = prop => {
    const data = domNode.getAttribute(`data-${prop}`);

    return data ? JSON.parse(data) : null;
  };

  const derivedChildren = readData("items")
    .reduce((acc, tab) => {
      const panel = panels.find(p => p.id === tab.value) || {};

      acc.push(Object.assign(tab, panel));

      return acc;
    }, [])
    .map((childProps, index) => {
      return (
        <AjaxPanel
          key={index}
          reactFromMarkupRehydrators={extra.rehydrators}
          reactFromMarkupOptions={{ extra }}
          {...childProps}
        />
      );
    });

  // NB you can't and shouldn't ever need to rehydrate functions
  const props = {
    isRehydrating: true,
    block: readData("block"),
    buttonText: readData("button-text"),
    buttonColor: readData("button-color"),
    buttonType: readData("button-type"),
    errorMessage: readData("error-message"),
    items: readData("items"),
    labelText: readData("label-text"),
    placeholder: readData("placeholder"),
    placeholderIcon: readData("placeholder-icon"),
    size: readData("size"),
    value: readData("value")
  };

  return <Dropdown {...props}>{derivedChildren}</Dropdown>;
};
