import React from "react";
import { storiesOf } from "@storybook/react";
import addStandardDecorators from "./addStandardDecorators";

import Billboard from "./compositions/components/Billboard";
import Form from "./compositions/components/Form";
import Hero from "./compositions/components/Hero";
import Modal from "./compositions/components/Modal";
import Rss from "./compositions/components/Rss";
import TableWithFilters from "./compositions/components/TableWithFilters";

const componentStories = storiesOf("Compositions/Component", module);

addStandardDecorators(componentStories);

componentStories.add("Billboard", () => <Billboard />);
componentStories.add("Form", () => <Form />);
componentStories.add("Hero", () => <Hero />);
componentStories.add("Modal", () => <Modal />);
componentStories.add("Rss", () => <Rss />);
componentStories.add("TableWithFilters", () => <TableWithFilters />);
