import React from "react";
import { storiesOf } from "@storybook/react";
import { withKnobs, text, boolean, select } from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";
import { Language } from "@emcm-ui/component-theme";
import StaticRenderer from "@emcm-ui/component-static-renderer";
import SharePriceTracker from "./index";
import "./SharePriceTracker.css";
import SharePriceTrackerREADME from "../README.md";

const stories = storiesOf("Components/SharePriceTracker", module);

const BackgroundType = ["light", "dark"];

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(SharePriceTrackerREADME));

stories
  .add("Default", () => (
    <SharePriceTracker
      apiUrl="https://run.mocky.io/v3/ab1b7013-0c26-4855-bc08-2405f0116f81"
      backgroundType={select("Background Type", BackgroundType, "light")}
      overlay={boolean("Overlay", true)}
      format={text("Custom format", "dd MMM, hh:mm")}
    />
  ))
  .add("Tool Tracker Variant", () => (
    <SharePriceTracker
      apiUrl="https://run.mocky.io/v3/603cd716-73bd-4683-89d0-40d3e0e9fef6"
      overlay={false}
      format={text("Custom format", "EEEE, MMMM dd, Y • hh:mm")}
      title={text("Title", "Current Share Price Label")}
    >
      <div>Current Share Price Label</div>
    </SharePriceTracker>
  ))
  .add("Language: Arabic (RTL)", () => (
    <Language code="ar">
      <SharePriceTracker
        apiUrl="https://run.mocky.io/v3/ab1b7013-0c26-4855-bc08-2405f0116f81"
        backgroundType={select("Background Type", BackgroundType, "light")}
        overlay={boolean("Overlay", true)}
        format={text("Custom format", "dd MMM, hh:mm")}
      />
    </Language>
  ))
  .add("Rehydrated", () => (
    <StaticRenderer>
      <SharePriceTracker
        apiUrl="https://run.mocky.io/v3/82bf4a4f-3c04-44ff-afe8-d0e1006925b9"
        backgroundType={select("Background Type", BackgroundType, "light")}
        overlay={boolean("Overlay", true)}
        format={text("Custom format", "dd MMM, hh:mm")}
      />
    </StaticRenderer>
  ));
