import React from "react";
import PropTypes from "prop-types";
import getClassNameFactory from "@emcm-ui/utility-class-names";

const NavItem = ({ title, href, icon }) => {
  const getClassName = getClassNameFactory("SiteFooterNavItem");

  const iconProps = {
    className: getClassName({ descendantName: "icon" })
  };

  return (
    <li className={getClassName()}>
      <a className={getClassName({ descendantName: "link" })} href={href}>
        {icon && <span {...iconProps}>{icon}</span>}
        {title}
      </a>
    </li>
  );
};

NavItem.propTypes = {
  /**
   * The destination that this `NavItem` links to
   */
  href: PropTypes.string.isRequired,

  /**
   * The title of this `NavItem`
   */
  title: PropTypes.string.isRequired,

  /**
   * An icon, if needed
   */
  icon: PropTypes.node
};

NavItem.displayName = "SiteFooter.NavItem";

export default NavItem;
