import React from "react";
import ReactDOM from "react-dom";
import AjaxContent from "@emcm-ui/component-ajax-content";
import Anchor from "@emcm-ui/component-anchor";
import Modal from "@emcm-ui/component-modal";
import { options } from "./reactFromMarkup";
import getClassNameFactory from "@emcm-ui/utility-class-names";

const getAnchorClassName = getClassNameFactory(Anchor.displayName);

let rehydrationOptions = options;

const setRehydrationOptions = newOptions => {
  rehydrationOptions = newOptions;
};

const renderModal = dataElm => {
  // The <Modal /> will use a portal. We can do the root render detached from
  // the main DOM; when ReactDOM.render reaches the portal, it'll attach to
  // #modalRoot.
  let el = document.createElement("div");

  const closeThisComponent = () => {
    ReactDOM.unmountComponentAtNode(el);

    // Remove the reference, so it can get garbage collected
    el = null;
  };

  const {
    contentLocation,
    modalLabel,
    title,
    showClose,
    closeOnAwayClick,
    primaryButtonLabel,
    secondaryButtonLabel,
    secondaryButtonUrl,
    secondaryLinkLabel,
    secondaryLinkUrl,
    primaryButtonColor,
    closeLabel
  } = dataElm.dataset;

  ReactDOM.render(
    <Modal
      ariaLabel={modalLabel}
      closeThisComponent={closeThisComponent}
      primaryButtonLabel={primaryButtonLabel}
      secondaryButtonLabel={secondaryButtonLabel}
      secondaryButtonUrl={secondaryButtonUrl}
      secondaryLinkLabel={secondaryLinkLabel}
      secondaryLinkUrl={secondaryLinkUrl}
      primaryButtonColor={primaryButtonColor}
      title={title}
      showClose={showClose ? JSON.parse(showClose) : true}
      closeOnAwayClick={closeOnAwayClick ? JSON.parse(closeOnAwayClick) : true}
      closeLabel={closeLabel}
    >
      <AjaxContent
        failedContent="Failed to retrieve content"
        loadingLabel="Loading"
        location={contentLocation}
        reactFromMarkupRehydrators={rehydrationOptions.extra.rehydrators}
        reactFromMarkupOptions={rehydrationOptions}
      />
    </Modal>,
    el
  );
};

const clickHandler = event => {
  const { target } = event;
  const anchor = target.closest(`.${getAnchorClassName()}[data-content-modal]`);

  if (anchor) {
    renderModal(anchor);

    event.stopPropagation();
    event.preventDefault();

    return false;
  }
};

const initModals = () => {
  const rootElement = document.querySelector("#root");

  const disclaimerModal = document.querySelector("#disclaimer-modal-data");

  if (rootElement) {
    rootElement.addEventListener("click", clickHandler);
  }

  if (disclaimerModal) {
    renderModal(disclaimerModal);
  }
};

export { initModals, setRehydrationOptions };
