import React, { Component } from "react";
import PropTypes from "prop-types";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import getRehydratableName from "@emcm-ui/utility-rehydratable-name";
import classNames from "classnames";
import { SVGIcon } from "@emcm-ui/component-icon/lib/svg";

import { Consumer as SetOpenMenuConsumer } from "../../setOpenMenuContext";
import { Consumer as CurrentMenuConsumer } from "../../currentMenuContext";

class NavList extends Component {
  static displayName = "SiteFooter.NavList";

  static propTypes = {
    /**
     * The children to show in this `Menu`'s menu
     */
    children: PropTypes.node,

    /**
     * The title to show on this Menu.
     *
     * This doubles as a unique identifier; you should never have two `Menu`s with the same title in one `SiteFooter`.
     */
    title: PropTypes.string.isRequired,

    /**
     * Whether or not the component is rehydrating. Do not use this.
     */
    isRehydrating: PropTypes.bool
  };

  render() {
    const { children, title } = this.props;
    const standardViewport = 768;

    return (
      <SetOpenMenuConsumer>
        {setOpenMenu => (
          <CurrentMenuConsumer>
            {currentMenu => {
              const getClassName = getClassNameFactory("SiteFooterNavList");
              const isExpanded = currentMenu !== null && currentMenu === title;
              const isWeb = typeof window !== "undefined";
              const isDesktop = isWeb && window.innerWidth >= standardViewport;

              const handleItemClick = e => {
                e.preventDefault();

                if (title === currentMenu) {
                  setOpenMenu(null);
                } else {
                  setOpenMenu(title);
                }
              };

              return (
                <li
                  className={getClassName({
                    states: classNames({
                      expanded: isExpanded
                    })
                  })}
                  data-rehydratable={getRehydratableName(NavList.displayName)}
                  data-title={title}
                >
                  <button
                    className={getClassName({ descendantName: "title" })}
                    onClick={handleItemClick}
                    role="button"
                    aria-haspopup={true}
                    aria-expanded={isExpanded}
                    tabIndex={isDesktop ? "-1" : "0"}
                  >
                    <h3
                      className={`${getClassName({
                        descendantName: "text"
                      })}
                u-typographySmallCaps`}
                    >
                      {title}
                    </h3>
                    <span className={getClassName({ descendantName: "caret" })}>
                      <SVGIcon
                        name="caret"
                        size="s"
                        style={
                          isExpanded ? { transform: "rotate(180deg)" } : null
                        }
                      />
                    </span>
                  </button>
                  <ul
                    className={getClassName({ descendantName: "inner" })}
                    role="region"
                    aria-hidden={!isExpanded && !isDesktop}
                  >
                    {children}
                  </ul>
                </li>
              );
            }}
          </CurrentMenuConsumer>
        )}
      </SetOpenMenuConsumer>
    );
  }
}

export default NavList;
