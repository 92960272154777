import React from "react";
import PropTypes from "prop-types";
import getClassNameFactory from "@emcm-ui/utility-class-names";

const Legend = ({ fills, legendProps }) => {
  const getClassName = getClassNameFactory("Datamap");
  const {
    defaultFillName,
    legendLabels = {},
    legendTitle,
    legendSubTitle
  } = legendProps;
  const legendItems = [];

  for (const fillKey in fills) {
    const style = { backgroundColor: fills[fillKey] };
    const label =
      fillKey === "defaultFill" ? defaultFillName : legendLabels[fillKey];

    // eslint-disable-next-line no-unused-expressions
    label &&
      legendItems.push(
        <div
          key={fillKey}
          className={getClassName({
            descendantName: "legendItem"
          })}
        >
          <div
            className={getClassName({
              descendantName: "legendItemColor"
            })}
            style={style}
          />
          <span
            className={getClassName({
              descendantName: "legendItemName"
            })}
          >
            {label}
          </span>
        </div>
      );
  }

  return (
    <div className={getClassName({ descendantName: "legend" })}>
      {legendTitle && (
        <div
          className={getClassName({
            descendantName: "legendTitle"
          })}
        >
          {legendTitle}
        </div>
      )}
      {legendSubTitle && (
        <div
          className={getClassName({
            descendantName: "legendSubTitle"
          })}
        >
          {legendSubTitle}
        </div>
      )}
      {legendItems}
    </div>
  );
};

Legend.displayName = "Datamap.Legend";

Legend.propTypes = {
  fills: PropTypes.object,
  legendProps: PropTypes.object
};

export default Legend;
