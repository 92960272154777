import React from "react";

import Attribution from "@emcm-ui/component-attribution";
import BlockQuote from "@emcm-ui/component-block-quote";
import {
  TextCard,
  ImageCard,
  PairedCard,
  LinkCardFooter
} from "@emcm-ui/component-card";
import DownloadButton from "@emcm-ui/component-download-button";
import Figure from "@emcm-ui/component-figure";
import Grid from "@emcm-ui/component-grid";
import Heading from "@emcm-ui/component-heading";
import Image from "@emcm-ui/component-image";
import Link from "@emcm-ui/component-link";
import MarkupContainer from "@emcm-ui/component-markup-container";
import Poster from "@emcm-ui/component-poster";
import Sandwich from "@emcm-ui/component-sandwich";
import SemanticMain from "@emcm-ui/component-semantic-main";
import Standfirst from "@emcm-ui/component-standfirst";
import Section from "@emcm-ui/component-section";
import Ticker from "@emcm-ui/component-ticker";
import TinyTile from "@emcm-ui/component-tiny-tile";
import VerticalSpacing from "@emcm-ui/component-vertical-spacing";
import VideoPlayer from "@emcm-ui/component-video-player";
import Icon from "@emcm-ui/component-icon";
import { SVGIcon } from "@emcm-ui/component-icon/lib/svg";

import brexitFlag from "../../sampleAssets/Brexit-flag.jpg";
import brexitFlagMobile from "../../sampleAssets/Brexit-flag.mob.jpg";
import SampleFooter from "../../fragments/footer";
import SampleHeader from "../../fragments/header";
import SampleSchemaOrganization from "../../fragments/schema-organization";

import imgLarge1 from "../../sampleAssets/large_1.1x.jpg";
import imgLarge2 from "../../sampleAssets/large_2.1x.jpg";
import imgLarge3 from "../../sampleAssets/large_3.1x.jpg";
import imgLarge4 from "../../sampleAssets/large_4.1x.jpg";

/* eslint-disable no-magic-numbers */
const oneMinute = 60000;
const time1 = new Date().getTime() - 65 * oneMinute;
const time2 = new Date().getTime() - 10 * oneMinute;
const time3 = new Date().getTime() - 4 * oneMinute;
const time4 = new Date().getTime() - 180 * oneMinute;
const time5 = new Date().getTime() - 120 * oneMinute;
/* eslint-enable no-magic-numbers */

const Icons = {
  home: "./icons/nucleo/energy-environment/small/home.svg",
  atom: "./icons/nucleo/healthcare-medical/small/atom.svg",
  carFront: "./icons/nucleo/transportation/small/car-front.svg",
  flaskConical: "./icons/nucleo/healthcare-medical/small/flask-conical.svg",
  download: "./icons/ui/grey/download-07.svg",
  downloadHover: "./icons/ui/white/close.svg",
  arrowRightWhite: "./icons/ui/white/arrow-right.svg",
  arrowRightBlue: "./icons/ui/blue/arrow-right.svg",
  plus: "./icons/ui/blue/plus.svg"
};

const Brexit = () => {
  return (
    <div>
      <SampleSchemaOrganization />
      <SampleHeader />
      <SemanticMain />
      <SemanticMain>
        <Section background="grayDark" fullWidth>
          <Poster
            height="s"
            imageOverlay="scrimBottomDark"
            imageSrc={brexitFlag}
            imageSrcSet={`${brexitFlagMobile} 375w, ${brexitFlag} 5184w`}
          >
            <Grid variant="7/12,5/12" gutterlessAtNarrow>
              <Grid.Item>
                <VerticalSpacing>
                  <Heading type="xl" rank="1">
                    Manage the impacts of <strong>Brexit</strong>
                  </Heading>
                </VerticalSpacing>
                <p>
                  Brexit news, insight, and analysis from Thomson Reuters.
                  Updated each week.
                </p>
              </Grid.Item>
            </Grid>
          </Poster>
        </Section>
        <MarkupContainer>
          <Ticker>
            <Ticker.Item lead title="Latest News" />
            <Ticker.Item
              href="#"
              dateTime={new Date(time1).toISOString()}
              title="Lorem ipsum dolor sit amet"
            />
            <Ticker.Item
              href="#"
              dateTime={new Date().toISOString()}
              title="Lorem ipsum dolor sit amet"
            />
            <Ticker.Item
              href="#"
              dateTime={new Date(time2).toISOString()}
              title="Lorem ipsum dolor sit amet"
            />
            <Ticker.Item
              href="#"
              dateTime={new Date(time3).toISOString()}
              title="Lorem ipsum dolor sit amet"
            />
            <Ticker.Item
              href="#"
              dateTime={new Date(time4).toISOString()}
              title="Lorem ipsum dolor sit amet"
            />
            <Ticker.Item
              href="#"
              dateTime={new Date(time5).toISOString()}
              title="Lorem ipsum dolor sit amet"
            />
          </Ticker>
        </MarkupContainer>
        <Section background="grayLightest">
          <VerticalSpacing size="m">
            <Heading rank="2" type="m">
              <b>Brexit solutions for you</b>
            </Heading>
          </VerticalSpacing>
          <VerticalSpacing size="l">
            <Grid variant="even-4" gutterBorder>
              <Grid.Item>
                <TinyTile
                  href="https://example.com"
                  icon={<Icon path={Icons.home} size="s" />}
                  title="Financial & risk professionals"
                />
              </Grid.Item>
              <Grid.Item>
                <TinyTile
                  href="https://example.com"
                  icon={<Icon path={Icons.atom} size="s" />}
                  title="Tax & accounting professionals"
                />
              </Grid.Item>
              <Grid.Item>
                <TinyTile
                  href="https://example.com"
                  icon={<Icon path={Icons.carFront} size="s" />}
                  title="Legal professionals"
                />
              </Grid.Item>
              <Grid.Item>
                <TinyTile
                  href="https://example.com"
                  icon={<Icon path={Icons.flaskConical} size="s" />}
                  title="Breakingviews Brexit insight"
                />
              </Grid.Item>
            </Grid>
          </VerticalSpacing>
        </Section>
        <Section>
          <Sandwich
            leadItems={[
              <Heading rank="2" type="l">
                <span>
                  An interview with Brexit advocate{" "}
                  <strong>Lord Digby Jones</strong>
                </span>
              </Heading>,
              <Standfirst>
                The former Director General of the Confederation of British
                Industry (CBI) and Minister for Trade & Investment sat down with
                with Thomson Reuters’ Laurence Kiddle to discuss his staunch
                support for Brexit throughout the 2016 referendum, and his
                predictions on the current Brexit talks.
              </Standfirst>
            ]}
            media={
              <MarkupContainer>
                <VideoPlayer
                  description="The former Director General of the Confederation of British Industry (CBI) and Minister for Trade & Investment sat down with Thomson Reuters' Laurence Kiddle to discuss his staunch support for Brexit throughout the 2016 referendum, and his predictions on the current Brexit talks."
                  duration="PT1M36S"
                  kind="vimeo"
                  name="An interview with Brexit advocate Lord Digby Jones"
                  playLabel="Play video"
                  thumbnailUrl={`https://www.example.com${imgLarge2}`}
                  uploadDate="2017-04-01"
                  videoId="114765613"
                >
                  <Image alt="Placeholder image" fit="cover" src={imgLarge2} />
                </VideoPlayer>
              </MarkupContainer>
            }
          >
            <Sandwich.ContentItem>
              <Link
                href="https://example.com"
                type="icon"
                icon={<Icon path={Icons.arrowRightBlue} size="s" />}
                iconPosition={"right"}
              >
                Read the interview debrief on Answers On
              </Link>
            </Sandwich.ContentItem>
          </Sandwich>
        </Section>
        <Section>
          <BlockQuote
            attribution={
              <Attribution
                caption="on Britain’s future as a trading nation"
                name="Lord Digby Jones,"
              />
            }
            text="There are so many countries in the world now secretly saying, privately, we can do a trade deal in a morning…we can do it in outline in a morning and we do the details in quite short order….I am heartened by that. And the people who tell me that isn’t possible, have a vested interest in saying it’s not possible."
          />
        </Section>
        <Section background="grayLightest">
          <VerticalSpacing size="l">
            <Heading rank="2" type="l">
              Brexit: <b>Tricky Business</b>
            </Heading>
          </VerticalSpacing>
          <MarkupContainer>
            <Figure
              caption={
                <span>
                  <strong>Brexit: Tricky Business [3:47]</strong> The countdown
                  to Brexit has started. Businesses are already having to take
                  tough decisions.
                </span>
              }
            >
              <VideoPlayer
                description="The countdown to Brexit has started. Businesses are already having to take tough decisions."
                duration="PT3M47S"
                kind="vimeo"
                name="Brexit: Tricky Business"
                playLabel="play video"
                thumbnailUrl={`https://www.example.com${imgLarge2}`}
                uploadDate="2017-04-01"
                videoId="114765613"
              >
                <Image alt="Placeholder image" fit="cover" src={imgLarge2} />
              </VideoPlayer>
            </Figure>
          </MarkupContainer>
        </Section>
        <Section>
          <Sandwich
            leadItems={[
              <Heading rank="2" type="l">
                <span>
                  Autumn 2017 <b>CFO Brexit report</b>
                </span>
              </Heading>,
              <Standfirst>
                Our quarterly report presenting the survey findings of 200 Chief
                Financial Officers from 15 sectors across the UK and Europe. A
                barometer measuring the impact Brexit has had, or could have, on
                company expansion, investment, headcount, relocation and
                compliance.
              </Standfirst>
            ]}
            media={<Image alt="Placeholder image" src={imgLarge2} />}
          >
            <Sandwich.ContentItem>
              <DownloadButton
                href="#_"
                fileSize="5.4MB"
                fileType="PDF"
                icon={<SVGIcon name="download-07" size="s" />}
              >
                Download the report
              </DownloadButton>
            </Sandwich.ContentItem>
          </Sandwich>
        </Section>
        <Section background="grayLightest">
          <VerticalSpacing size="l">
            <Heading rank="2" type="l">
              Cut through the <b>Brexit noise</b>
            </Heading>
          </VerticalSpacing>
          <VerticalSpacing size="l">
            <Grid variant="2/3,1/3">
              <Grid.Item>
                <ImageCard
                  borderless
                  eyebrow="Brexit weekly"
                  footer={
                    <LinkCardFooter
                      title="Sign up for the Brexit newsletter"
                      type="icon"
                      icon={<Icon path={Icons.arrowRightWhite} size="s" />}
                    />
                  }
                  heading="Get the Reuters UK weekly Brexit coverage wrap up delivered to your inbox."
                  href="https://www.example.com"
                  imgGradient
                  imgSrc={imgLarge1}
                  largeHeading
                  size="large"
                />
              </Grid.Item>
              <Grid.Item>
                <PairedCard
                  borderless
                  eyebrow="Brexit weekly"
                  footer={
                    <LinkCardFooter
                      title="Download PDF"
                      type="icon"
                      icon={<Icon path={Icons.plus} size="s" />}
                    />
                  }
                  heading="Download this week's wrap up"
                  href="https://www.example.com"
                  imgSrc={imgLarge4}
                />
              </Grid.Item>
            </Grid>
          </VerticalSpacing>
          <VerticalSpacing size="l">
            <Grid variant="even-3">
              <Grid.Item>
                <TextCard
                  borderless
                  description="“A majority of businesses are yet to change their strategic planning due to Britain's decision to leave the European Union.”"
                  heading="CFO Brexit report"
                  href="https://www.example.com"
                  footer={
                    <LinkCardFooter
                      title="Survey results analysis from Reuters News"
                      type="icon"
                      icon={<Icon path={Icons.arrowRightBlue} size="s" />}
                    />
                  }
                  size="small"
                />
              </Grid.Item>
              <Grid.Item>
                <TextCard
                  borderless
                  footer={
                    <LinkCardFooter
                      title="View more"
                      type="icon"
                      icon={<Icon path={Icons.arrowRightBlue} size="s" />}
                    />
                  }
                  heading="Latest Brexit expert analysis"
                  href="https://www.example.com"
                  size="small"
                />
              </Grid.Item>
              <Grid.Item>
                <TextCard
                  borderless
                  footer={
                    <LinkCardFooter
                      title="View more"
                      type="icon"
                      icon={<Icon path={Icons.arrowRightBlue} size="s" />}
                    />
                  }
                  heading="Latest Reuters Brexit coverage"
                  href="https://www.example.com"
                  size="small"
                />
              </Grid.Item>
            </Grid>
          </VerticalSpacing>
        </Section>
        <Section background="grayLighter">
          <VerticalSpacing size="l">
            <Heading rank="2" type="l">
              Brexit solutions for <b>your industry</b>
            </Heading>
          </VerticalSpacing>
          <VerticalSpacing size="l">
            <Grid variant="even-3">
              <Grid.Item>
                <PairedCard
                  borderless
                  eyebrow="EIKON Brexit app"
                  footer={
                    <LinkCardFooter
                      title="View now"
                      type="icon"
                      icon={<Icon path={Icons.arrowRightBlue} size="s" />}
                    />
                  }
                  heading="For existing Eikon customers - track Brexit market volatility using the Brexit app."
                  href="https://www.example.com"
                  imgSrc={imgLarge2}
                />
              </Grid.Item>
              <Grid.Item>
                <PairedCard
                  borderless
                  eyebrow="Brexit legal hub"
                  footer={
                    <LinkCardFooter
                      title="View now"
                      type="icon"
                      icon={<Icon path={Icons.arrowRightBlue} size="s" />}
                    />
                  }
                  heading="What does a post-Brexit world hold for legal professionals?"
                  href="https://www.example.com"
                  imgSrc={imgLarge3}
                />
              </Grid.Item>
              <Grid.Item>
                <PairedCard
                  borderless
                  eyebrow="Brexit, Tax and Accounting"
                  footer={
                    <LinkCardFooter
                      title="View now"
                      type="icon"
                      icon={<Icon path={Icons.arrowRightBlue} size="s" />}
                    />
                  }
                  heading="We can help tax professionals prepare for the impact and consequences of Brexit."
                  href="https://www.example.com"
                  imgSrc={imgLarge3}
                />
              </Grid.Item>
            </Grid>
          </VerticalSpacing>
        </Section>
      </SemanticMain>
      <SampleFooter />
    </div>
  );
};

export default Brexit;
