import React from "react";
import { storiesOf } from "@storybook/react";
import { withKnobs, boolean, text } from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";
import { Language } from "@emcm-ui/component-theme";
import ArticleCardFooter from "./index";
import "./ArticleCardFooter.css";
import Icon from "@emcm-ui/component-icon";

import CardREADME from "../../../../README.md";

const stories = storiesOf("Components/Card/Footers/ArticleCardFooter", module);

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(CardREADME));

stories
  .add("Default", () => (
    <ArticleCardFooter
      attributionName={text("attributionName", "Sophie P.")}
      dateTime={text("dateTime", "2016-03-02 13:57")}
      locked={boolean("locked", false)}
      readingTime={text("readingTime", "5 min")}
      icon={<Icon path="./icons/ui/grey/time-07.svg" size="xs" />}
      lockedIcon={<Icon path="./icons/ui/grey/locked-07.svg" size="xs" />}
    />
  ))
  .add("Language: Arabic (RTL)", () => (
    <Language code="ar">
      <ArticleCardFooter
        attributionName={text("attributionName", "Sophie P.")}
        dateTime={text("dateTime", "2016-03-02 13:57")}
        locked={boolean("locked", false)}
        readingTime={text("readingTime", "5 دقائق")}
        icon={<Icon path="./icons/ui/grey/time-07.svg" size="xs" />}
        lockedIcon={<Icon path="./icons/ui/grey/locked-07.svg" size="xs" />}
      />
    </Language>
  ));
