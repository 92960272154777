import PropTypes from "prop-types";
import React from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import classNames from "classnames";

import DateTime from "@emcm-ui/component-date-time";

const ArticleCardFooter = ({
  attributionName,
  dateTime,
  locked,
  readingTime,
  icon,
  lockedIcon
}) => {
  const getClassName = getClassNameFactory(ArticleCardFooter.displayName);

  return (
    <div
      className={getClassName({
        modifiers: classNames({})
      })}
    >
      <div className={getClassName({ descendantName: "dateAndAttribution" })}>
        <DateTime dateTime={dateTime} lang="enUS" />
        {attributionName ? (
          <span>
            <span
              className={getClassName({ descendantName: "dotSeparator" })}
              aria-hidden="true"
            >
              •
            </span>
            {attributionName}
          </span>
        ) : null}
      </div>

      {readingTime ? (
        <div className={getClassName({ descendantName: "readingTime" })}>
          <div className={getClassName({ descendantName: "readingTimeIcon" })}>
            {icon}
          </div>
          {readingTime}
        </div>
      ) : null}

      {locked ? (
        <div className={getClassName({ descendantName: "lockIcon" })}>
          {lockedIcon}
        </div>
      ) : null}
    </div>
  );
};

ArticleCardFooter.displayName = "ArticleCardFooter";

/* eslint-disable max-len */
ArticleCardFooter.propTypes = {
  /**
   * Name of the article attribution.
   */
  attributionName: PropTypes.string,
  /**
   * An ISO 8601, RFC 2822 or `new Date(str)` compatible date string.
   */
  dateTime: PropTypes.string.isRequired,
  /**
   * Optionally display a locked content indicator
   */
  locked: PropTypes.bool,
  /**
   * Reading time for the article. Use SI and common non-SI format (English: s = seconds, min = minutes, h = hours, d = days, i.e. "15 min" or "1 h". See https://en.wikipedia.org/wiki/International_System_of_Units#Units_and_prefixes.)
   */
  readingTime: PropTypes.string,
  /**
   * Time icon
   */
  icon: PropTypes.node,
  /**
   * Locked icon
   */
  lockedIcon: PropTypes.node
};
/* eslint-enable max-len */

ArticleCardFooter.defaultProps = {};

export default ArticleCardFooter;
