import PropTypes from "prop-types";
import React, { Component } from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import getRehydratableName from "@emcm-ui/utility-rehydratable-name";
import classNames from "classnames";
import Item from "./components/Item";
import { SVGIcon } from "@emcm-ui/component-icon/lib/svg";

class SocialDropdown extends Component {
  static displayName = "SocialDropdown";

  static propTypes = {
    /**
     * Child nodes, made up of <SocialDropdown.Item />.
     */
    children: PropTypes.node,

    /**
     * Modify Section with background colors.
     * Valid Options: grayDark, grayLighter, grayLightest, or false.
     */
    color: PropTypes.oneOf(["white", "gray"])
  };

  constructor(props) {
    super(props);

    this.getClassName = getClassNameFactory(SocialDropdown.displayName);

    this.state = {
      expanded: false
    };

    this.handleBlur = this.handleBlur.bind(this);
    this.handleButtonClicked = this.handleButtonClicked.bind(this);
    this.handleRef = this.handleRef.bind(this);
  }

  handleButtonClicked() {
    this.setState({
      expanded: !this.state.expanded
    });
  }

  handleBlur(e) {
    // document.activeElement is IE11 fallback
    const relatedTarget = e.relatedTarget || document.activeElement;

    if (this.ref && relatedTarget && this.ref.contains(relatedTarget)) {
      return;
    }

    this.setState({
      expanded: false
    });
  }

  handleRef(ref) {
    this.ref = ref;
  }

  render() {
    const { color = "white" } = this.props;

    return (
      <div
        className={this.getClassName({
          states: classNames({ expanded: this.state.expanded }),
          modifiers: classNames(color)
        })}
        data-rehydratable={getRehydratableName(SocialDropdown.displayName)}
        data-color={JSON.stringify(color)}
        onBlur={this.handleBlur}
        ref={this.handleRef}
      >
        <button
          className={this.getClassName({
            descendantName: "button",
            modifiers: classNames(color)
          })}
          onClick={this.handleButtonClicked}
        >
          <div className={this.getClassName({ descendantName: "buttonInner" })}>
            <SVGIcon name="share" size="s" />
          </div>
        </button>
        <ul
          className={this.getClassName({
            descendantName: "items",
            modifiers: classNames(color)
          })}
        >
          {this.props.children}
        </ul>
      </div>
    );
  }
}

SocialDropdown.Item = Item;

export default SocialDropdown;
