/**
 * @define PlaylistItem
 * PlaylistItem component for displaying list of videos.
 *
 */

// Vendor
import PropTypes from "prop-types";
import React from "react";

// emcm-ui
import getClassNameFactory from "@emcm-ui/utility-class-names";
import { SVGIcon } from "@emcm-ui/component-icon/lib/svg";

// Local
import { getIcon } from "./icons";

const PlaylistItem = ({
  title,
  description,
  url,
  state,
  isTitleEllipsisActive,
  titleEllipsisLinesSize,
  callback,
  isActive
}) => {
  const getClassName = getClassNameFactory(PlaylistItem.displayName);
  const linkAttributes = { href: url };
  const isDescriptionEnabled = description && description.length > 0;
  const getIconByState = item => {
    return item === "PLAYING" ? "pause" : "play";
  };

  if (callback) {
    linkAttributes.onClick = () => {
      callback.func(state, callback.ref);
    };
  }

  return (
    <div className={getClassName()}>
      <a
        className={`${getClassName({
          descendantName: "link",
          modifiers: isActive && "--selected"
        })} ${getClassName()}-${state.toLowerCase()}`}
        {...linkAttributes}
      >
        <div className={getClassName({ descendantName: "body" })}>
          <div
            className={getClassName({
              descendantName: "icon",
              modifiers: `--blue`
            })}
          >
            <SVGIcon
              name={getIconByState(state)}
              size="s"
              viewBox="0 0 20 20"
              getExternalIconPath={getIcon}
            />
          </div>
          <div className={getClassName({ descendantName: "text" })}>
            <div
              className={getClassName({
                descendantName: "title",
                modifiers: isTitleEllipsisActive && "--ellipsis",
                states: isTitleEllipsisActive && titleEllipsisLinesSize
              })}
            >
              {title}
            </div>
            {isDescriptionEnabled && (
              <div
                className={getClassName({
                  descendantName: "description"
                })}
              >
                {description}
              </div>
            )}
          </div>
        </div>
      </a>
    </div>
  );
};

PlaylistItem.sizes = ["small", "medium", "large"];
PlaylistItem.displayName = "Playlist.Item";
PlaylistItem.propTypes = {
  /**
   * Title
   */
  title: PropTypes.string.isRequired,

  /**
   * Description
   */
  description: PropTypes.string,

  /**
   * Url
   */
  url: PropTypes.string.isRequired,

  /**
   * State
   */
  state: PropTypes.string.isRequired,

  /**
   * Activate ellipsis css style for title
   */
  isTitleEllipsisActive: PropTypes.bool,

  /**
   * Ellipsis lines size to apply css style for title
   */
  titleEllipsisLinesSize: PropTypes.string,

  /**
   * Callback
   */
  callback: PropTypes.object,

  /**
   * Show selected view
   */
  isActive: PropTypes.bool
};

PlaylistItem.defaultProps = {
  state: "PAUSED",
  isTitleEllipsisActive: true,
  titleEllipsisLinesSize: "medium",
  isActive: false
};

export default PlaylistItem;
