import React from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";

const NoResults = () => {
  const getClassName = getClassNameFactory("SearchResults");

  return (
    <div className={getClassName({ descendantName: "noResults" })}>
      <h3 className={getClassName({ descendantName: "noResultsTitle" })}>
        Suggestions
      </h3>
      <ul className={getClassName({ descendantName: "noResultsItems" })}>
        <li className={getClassName({ descendantName: "noResultsItem" })}>
          Check your spelling
        </li>
        <li className={getClassName({ descendantName: "noResultsItem" })}>
          Try fewer words
        </li>
        <li className={getClassName({ descendantName: "noResultsItem" })}>
          Try more general keywords
        </li>
      </ul>
    </div>
  );
};

NoResults.displayName = "SearchResults.NoResults";

export default NoResults;
