import React, { Component } from "react";
import PropTypes from "prop-types";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import getRehydratableName from "@emcm-ui/utility-rehydratable-name";

import { SVGIcon } from "@emcm-ui/component-icon/lib/svg";

import { formConsts } from "../../FormConstant";

import { toggleInvalidClass } from "../../utilities";

class TextControl extends Component {
  static displayName = "FormNew.TextControl";

  static propTypes = {
    /**
     * Set autocomplete
     */
    autoComplete: PropTypes.string,
    /**
     * Initialize <input> with a value
     */
    defaultValue: PropTypes.string,
    /**
     * Disable the <input>
     */
    disabled: PropTypes.bool,
    /**
     * Id attribute for <input> (and <label>'s `for` attribute)
     */
    id: PropTypes.string.isRequired,
    /**
     * Text for <label>
     */
    labelText: PropTypes.string.isRequired,
    /**
     * Text for optional <label>
     */
    optionalText: PropTypes.string.isRequired,
    /**
     * Max. length for <input>
     */
    maxLength: PropTypes.string,
    /**
     * Name attribute for <input>, required for serialisation
     */
    name: PropTypes.string.isRequired,
    /**
     * Listen for changes on the <input>.
     */
    onChange: PropTypes.func,
    /**
     * Set <input> to be required
     */
    required: PropTypes.bool,
    /**
     * tabindex attribute
     */
    tabindex: PropTypes.string,
    /**
     * Text for default error message
     */
    errorMessage: PropTypes.string,
    /**
     * Type of <input>
     */
    type: PropTypes.oneOf(["email", "text", "url", "tel"]).isRequired,
    /**
     * Explicitly set the value of the <input>. Will switch to controlled mode
     */
    value: PropTypes.string,
    /**
     * Regex to support custom input validation along with type validation
     */
    pattern: PropTypes.string,
    /**
     * Help text, used to provide hint about the field and the entry
     */
    helpText: PropTypes.string
  };

  static defaultProps = {
    autoComplete: formConsts.autocomplete,
    defaultValue: "",
    disabled: false,
    id: formConsts.textControlName,
    labelText: formConsts.labelText,
    optionalText: formConsts.optionalText,
    maxLength: formConsts.maxlength,
    name: formConsts.textControlName,
    required: true,
    tabindex: formConsts.tabindex,
    errorMessage: formConsts.message,
    type: formConsts.textControlType
  };

  constructor(props) {
    super(props);

    this.getClassName = getClassNameFactory(TextControl.displayName);

    this.state = {
      hasError: false
    };
  }

  updateState = (e, onChange) => {
    if (onChange) {
      return onChange(e);
    }

    this.setState({
      hasError: e.target.value === ""
    });
  };

  render() {
    const {
      autoComplete,
      defaultValue,
      disabled,
      id,
      labelText,
      optionalText,
      maxLength,
      name,
      onChange,
      required,
      tabindex,
      errorMessage,
      type,
      value,
      pattern,
      helpText
    } = this.props;

    return (
      <div
        className={toggleInvalidClass(
          this.getClassName(),
          this.props.required,
          this.state.hasError
        )}
        data-autocomplete={autoComplete}
        data-default-value={defaultValue}
        data-disabled={disabled}
        data-id={id}
        data-label-text={labelText}
        data-optional-text={optionalText}
        data-maxlength={maxLength}
        data-name={name}
        data-rehydratable={getRehydratableName(TextControl.displayName)}
        data-required={required}
        data-tabindex={tabindex}
        data-error-message={errorMessage}
        data-pattern={pattern}
        data-help-text={helpText}
      >
        <label htmlFor={id}>
          <input
            autoComplete={autoComplete}
            defaultValue={defaultValue}
            disabled={disabled}
            id={id}
            name={name}
            maxLength={maxLength}
            onChange={e => {
              this.updateState(e, onChange);
            }}
            placeholder={labelText}
            required={required}
            tabIndex={tabindex}
            type={type}
            value={value}
            pattern={pattern}
          />
          <div className={this.getClassName({ descendantName: "labelText" })}>
            {labelText}
            {!required && (
              <span
                className={this.getClassName({ descendantName: "optional" })}
              >
                {optionalText}
              </span>
            )}
          </div>
          <div className={this.getClassName({ descendantName: "errorIcon" })}>
            <SVGIcon name="error" size="s" />
          </div>
          {required && (
            <span className={this.getClassName({ descendantName: "message" })}>
              {errorMessage}
            </span>
          )}
          {helpText && (
            <div className={this.getClassName({ descendantName: "helpText" })}>
              {helpText}
            </div>
          )}
        </label>
      </div>
    );
  }
}

export default TextControl;
