import PropTypes from "prop-types";
import React from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import Link from "@emcm-ui/component-link";
import Button from "@emcm-ui/component-button";
import { SVGIcon } from "@emcm-ui/component-icon/lib/svg";

const Table = ({ data, startRowIndex }) => {
  const getIcon = icon => {
    if (icon === "arrow") {
      return (
        <SVGIcon name={icon} size="s" style={{ transform: "rotate(270deg)" }} />
      );
    }
    if (icon) {
      const isExternal = icon === "externallink";

      return (
        <SVGIcon
          name={icon}
          viewBox={isExternal ? "0 0 28 28" : "0 0 32 32"}
          size={isExternal ? "m" : "s"}
        />
      );
    }

    return null;
  };

  const getClassName = getClassNameFactory(Table.displayName);

  const showCTA = cell => {
    return (
      <Button
        ariaLabel={cell.content}
        type="pill"
        kind="button"
        color="secondary"
        disabled={!cell.isCtaActive}
        {...cell.href && {
          kind: "link",
          href: cell.href,
          icon: getIcon(cell.icon),
          newWindow: cell.icon === "externallink"
        }}
      >
        {cell.content}
      </Button>
    );
  };

  const getCellContent = cell => {
    let cellContent = cell.isCta ? showCTA(cell) : cell.content;

    if (cell.link && cell.link.href) {
      cellContent = <Link href={cell.link.href}>{cell.content}</Link>;
    }

    return cellContent;
  };

  return (
    <div className={getClassName()}>
      <table>
        {data.caption &&
          data.caption.content && <caption>{data.caption.content}</caption>}
        {data.head &&
          data.head.rows &&
          data.head.rows.length && (
            <thead>
              {data.head.rows.map((row, indexRow) => (
                <tr
                  key={(typeof row.key === "undefined"
                    ? indexRow
                    : row.key
                  ).toString()}
                >
                  {row.cells &&
                    row.cells.map((cell, indexCell) => {
                      const style = {};
                      const base = 16;

                      if (cell.width) {
                        style.minWidth = `${cell.width / base}rem`;
                      }

                      return (
                        !cell.isHidden && (
                          <th
                            className={getClassName({
                              descendantName:
                                cell.isCta &&
                                !cell.disableCTASticky &&
                                "columnCTA"
                            })}
                            key={(typeof cell.key === "undefined"
                              ? indexCell
                              : cell.key
                            ).toString()}
                          >
                            <div
                              className={getClassName({
                                descendantName: "columnWidthPlaceholder"
                              })}
                              style={style}
                            />
                            {cell.content}
                          </th>
                        )
                      );
                    })}
                </tr>
              ))}
            </thead>
          )}
        {data.bodies.map((body, indexBody) => {
          const isUndefined = value => typeof value === "undefined";
          const bodyKey = `${isUndefined(body.key) ? indexBody : body.key}`;

          return (
            <tbody key={bodyKey}>
              {body.rows.map((row, indexRow) => {
                const isRowHidden = row.hidden !== true;
                const rowKey = `${isUndefined(row.key) ? indexRow : row.key}`;
                const tableRowId = startRowIndex + indexRow;

                return (
                  isRowHidden && (
                    <tr key={rowKey} id={tableRowId}>
                      {row.cells.map((cell, indexCell) => {
                        const disableCTASticky =
                          data.head.rows[0].cells[indexCell].disableCTASticky;

                        const ctaKey = `${
                          isUndefined(cell.key) ? indexCell : cell.key
                        }`;

                        return (
                          !cell.isHidden && (
                            <td
                              className={getClassName({
                                descendantName:
                                  cell.isCta && !disableCTASticky && "columnCTA"
                              })}
                              key={ctaKey}
                            >
                              {getCellContent(cell)}
                            </td>
                          )
                        );
                      })}
                    </tr>
                  )
                );
              })}
            </tbody>
          );
        })}
      </table>
    </div>
  );
};

Table.displayName = "TableWithFilters.Table";

Table.propTypes = {
  /**
   * Initialize <input> with a value
   */
  data: PropTypes.object,

  /**
   * Table row index counting from where need to start
   */
  startRowIndex: PropTypes.number
};

export default Table;
