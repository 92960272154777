import PropTypes from "prop-types";
import React, { Component } from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import getRehydratableName from "@emcm-ui/utility-rehydratable-name";
import Modal from "@emcm-ui/component-modal";
import Image from "@emcm-ui/component-image";

import { SVGIcon } from "@emcm-ui/component-icon/lib/svg";

class Figure extends Component {
  static displayName = "Figure";

  static propTypes = {
    /**
     * An attribution for the comments of the figure. For example, REUTERS/John Smith.
     */
    attribution: PropTypes.string,

    /**
     * A caption to attach to the figure. Children should be restricted to text, <a>, <em> and <strong>.
     */
    caption: PropTypes.node,

    /**
     * The contents of the figure.
     */
    children: PropTypes.node,

    /**
     * Whether or not the image is expandable to a modal / larger view.
     */
    expandable: PropTypes.bool,

    /**
     * (optional) The image URL to display. If not supplied, will fall back to original URL.
     */
    expandableUrl: PropTypes.string
  };

  getClassName = getClassNameFactory(Figure.displayName);

  state = {
    modalOpen: false
  };

  handleCloseModal = () => {
    this.setState({ modalOpen: false });
  };

  handleIconClick = event => {
    event.preventDefault();
    this.setState({
      modalOpen: true
    });
  };

  render() {
    const { children, ...rest } = this.props;
    const { attribution, caption, expandable, expandableUrl } = rest;
    const { modalOpen } = this.state;
    const rehydrationProps = {
      "data-attribution": attribution,
      "data-expandable": expandable,
      "data-expandable-url": expandableUrl
    };
    let footer;

    if (attribution) {
      footer = (
        <footer
          className={this.getClassName({ descendantName: "attribution" })}
        >
          <small>{attribution}</small>
        </footer>
      );
    }

    return (
      <figure
        className={this.getClassName()}
        {...rehydrationProps}
        data-rehydratable={getRehydratableName(Figure.displayName)}
      >
        <div className={this.getClassName({ descendantName: "inner" })}>
          {expandable && (
            <button
              className={this.getClassName({ descendantName: "expandable" })}
              onClick={this.handleIconClick}
            >
              {<SVGIcon name="fullscreen" size="s" />}
            </button>
          )}
          <div
            className={this.getClassName({ descendantName: "imageWrapper" })}
          >
            {children}
          </div>
          {footer}
        </div>
        <figcaption
          className={this.getClassName({ descendantName: "caption" })}
        >
          {caption}
        </figcaption>
        {modalOpen && (
          <Modal
            ariaLabel="Full screen image"
            closeLabel="Close"
            closeThisComponent={this.handleCloseModal}
          >
            <Figure {...rest} expandable={false}>
              {expandableUrl ? (
                <Image alt="Full screen image" src={expandableUrl} />
              ) : (
                children
              )}
            </Figure>
          </Modal>
        )}
      </figure>
    );
  }
}

export default Figure;
