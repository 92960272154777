import React from "react";

import Block from "@emcm-ui/component-block";
import Poster from "@emcm-ui/component-poster";
import SemanticMain from "@emcm-ui/component-semantic-main";
import Header from "@emcm-ui/component-header";
import Heading from "@emcm-ui/component-heading";
import Image from "@emcm-ui/component-image";
import Grid from "@emcm-ui/component-grid";
import Link from "@emcm-ui/component-link";
import ListOfLinks from "@emcm-ui/component-list-of-links";
import MarkupContainer from "@emcm-ui/component-markup-container";
import Icon from "@emcm-ui/component-icon";

import {
  ArticleCardFooter,
  PairedCard,
  TextCard,
  TwitterCard,
  LinkCardFooter
} from "@emcm-ui/component-card";
import ProfileGallery from "@emcm-ui/component-profile-gallery";
import RichText from "@emcm-ui/component-rich-text";
import Section from "@emcm-ui/component-section";
import Tile from "@emcm-ui/component-tile";
import VerticalSpacing from "@emcm-ui/component-vertical-spacing";
import VideoPlayer from "@emcm-ui/component-video-player";

import articleHeadshot from "../../sampleAssets/Article-headshot.jpg";
import articleLadyJustice from "../../sampleAssets/Article-lady-justice.jpg";
import articleLadyJusticeMobile from "../../sampleAssets/Article-lady-justice.mob.jpg";
import imgLarge7 from "../../sampleAssets/large_7.jpg";
import placeholder960 from "../../sampleAssets/placeholder-images/960x540.png";
import placeholder1920 from "../../sampleAssets/placeholder-images/1920x1080.png";
import placeholder3840 from "../../sampleAssets/placeholder-images/3840x2160.png";
import SampleSchemaOrganization from "../../fragments/schema-organization";
import SampleFooter from "../../fragments/footer";
import SampleHeader from "../../fragments/header";

const Icons = {
  arrowRightBlue: "./icons/ui/blue/arrow-right.svg",
  plus: "./icons/ui/blue/plus.svg",
  home: "./icons/nucleo/energy-environment/large/home.svg",
  atom: "./icons/nucleo/healthcare-medical/large/atom.svg",
  carFront: "./icons/nucleo/transportation/large/car-front.svg",
  flaskConical: "./icons/nucleo/healthcare-medical/large/flask-conical.svg",
  time: "./icons/ui/grey/time-07.svg"
};

const AboutUs = () => {
  return (
    <div>
      <SampleSchemaOrganization />
      <SampleHeader />

      <SemanticMain>
        <Section background="grayDark" fullWidth>
          <Poster
            height="l"
            imageOverlay="scrimBottomDark"
            imageSrc={articleLadyJustice}
            imageSrcSet={`${articleLadyJusticeMobile} 768w, ${articleLadyJustice} 2048w`}
            alignBottom
          >
            <Grid variant="7/12,5/12" gutterlessAtNarrow>
              <Grid.Item>
                <Heading type="xl" rank="1">
                  <strong>We are The Answer Company</strong>
                </Heading>
              </Grid.Item>
            </Grid>
          </Poster>
        </Section>
        <Section background="grayLightest">
          <VerticalSpacing size="xl">
            <Heading type="l">
              Providing answer across <strong>each industry</strong>
            </Heading>
          </VerticalSpacing>
          <Grid variant="even-3">
            <Grid.Item>
              <TextCard
                description="Make the tough decisions with confidence, using unrivaled tools to manage risk and reduce cost in the supply chain."
                footer={
                  <LinkCardFooter
                    title="Learn more"
                    type="icon"
                    icon={<Icon path={Icons.arrowRightBlue} size="s" />}
                  />
                }
                heading="Automotive"
                href="https://www.example.com"
                size="medium"
              />
            </Grid.Item>
            <Grid.Item>
              <TextCard
                description="Financial, legal, risk, and tax tools and insight to drive more efficient operations."
                footer={
                  <LinkCardFooter
                    title="Learn more"
                    type="icon"
                    icon={<Icon path={Icons.arrowRightBlue} size="s" />}
                  />
                }
                heading="Energy"
                href="https://www.example.com"
                size="medium"
              />
            </Grid.Item>
            <Grid.Item>
              <TextCard
                description="Trusted source for critical news, information, and analytics to help you prosper in trading or investing."
                footer={
                  <LinkCardFooter
                    title="Learn more"
                    type="icon"
                    icon={<Icon path={Icons.arrowRightBlue} size="s" />}
                  />
                }
                heading="Financial"
                href="https://www.example.com"
                size="medium"
              />
            </Grid.Item>
            <Grid.Item>
              <TextCard
                description="Inform your operational and policy decision making with trusted answers from Thomson Reuters."
                footer={
                  <LinkCardFooter
                    title="Learn more"
                    type="icon"
                    icon={<Icon path={Icons.arrowRightBlue} size="s" />}
                  />
                }
                heading="Government"
                href="https://www.example.com"
                size="medium"
              />
            </Grid.Item>
            <Grid.Item>
              <TextCard
                description="Trusted legal products and services that combine unparalleled content, deep human expertise, and intuitive technology to get you the timely answers you need."
                footer={
                  <LinkCardFooter
                    title="Learn more"
                    type="icon"
                    icon={<Icon path={Icons.arrowRightBlue} size="s" />}
                  />
                }
                heading="Legal"
                href="https://www.example.com"
                size="medium"
              />
            </Grid.Item>
            <Grid.Item>
              <TextCard
                description="Reuters is the essential partner for the 21st-century newsroom, providing award-winning global multimedia content and real-time news coverage."
                footer={
                  <LinkCardFooter
                    title="Learn more"
                    type="icon"
                    icon={<Icon path={Icons.plus} size="s" />}
                  />
                }
                heading="News & media"
                href="https://www.example.com"
                size="medium"
              />
            </Grid.Item>
            <Grid.Item>
              <TextCard
                description="Trusted data and technology to help you detect, assess, and minimize supplier, partner, and distributor risks."
                footer={
                  <LinkCardFooter
                    title="Learn more"
                    type="icon"
                    icon={<Icon path={Icons.plus} size="s" />}
                  />
                }
                heading="Risk management solutions"
                href="https://www.example.com"
                size="medium"
              />
            </Grid.Item>
            <Grid.Item>
              <TextCard
                description="Providing technology, guidance, and expertise to accounting firms, corporations, financial institutions, governments, and law firms."
                footer={
                  <LinkCardFooter
                    title="Learn more"
                    type="icon"
                    icon={<Icon path={Icons.plus} size="s" />}
                  />
                }
                heading="Tax & accounting"
                href="https://www.example.com"
                size="medium"
              />
            </Grid.Item>
            <Grid.Item>
              <TextCard
                description="Business answers for today’s tech companies."
                footer={
                  <LinkCardFooter
                    title="Learn more"
                    type="icon"
                    icon={<Icon path={Icons.plus} size="s" />}
                  />
                }
                heading="Technology"
                href="https://www.example.com"
                size="medium"
              />
            </Grid.Item>
          </Grid>
        </Section>
        <Section background="grayLighter">
          <Block text="Map Element here" color="blue" height="32em" />
        </Section>
        <Section>
          <VerticalSpacing size="xl">
            <Heading type="l">
              <strong>Our values</strong> shape our culture
            </Heading>
          </VerticalSpacing>
          <Grid variant="even-4">
            <Grid.Item>
              <Tile
                description="We act with integrity and independence by holding ourselves and each other accountable, ethical, and reliable in all that we do."
                headingRank="2"
                href="https://example.com"
                icon={<Icon path={Icons.home} size="l" />}
                linkTitle="Trust principles"
                linkIcon={<Icon path={Icons.arrowRightBlue} size="s" />}
                title="Trust"
                type="centeredIcon"
              />
            </Grid.Item>
            <Grid.Item>
              <Tile
                description="We innovate to serve our customers, drive our growth, and win in dynamic business environments."
                headingRank="2"
                href="https://example.com"
                icon={<Icon path={Icons.atom} size="l" />}
                linkTitle="Innovation @ Thomson Reuters"
                linkIcon={<Icon path={Icons.arrowRightBlue} size="s" />}
                title="Innovation"
                type="centeredIcon"
              />
            </Grid.Item>
            <Grid.Item>
              <Tile
                description="We work together – with each other, with our customers, and with industry partners – to deliver superior results and experiences."
                headingRank="2"
                href="https://example.com"
                icon={<Icon path={Icons.carFront} size="l" />}
                linkTitle="Our partnerships"
                linkIcon={<Icon path={Icons.arrowRightBlue} size="s" />}
                title="Partnership"
                type="centeredIcon"
              />
            </Grid.Item>
            <Grid.Item>
              <Tile
                description="We deliver results and we excel at work that positively affects the world."
                headingRank="2"
                href="https://example.com"
                icon={<Icon path={Icons.flaskConical} size="l" />}
                linkTitle="Our products"
                linkIcon={<Icon path={Icons.arrowRightBlue} size="s" />}
                title="Performance"
                type="centeredIcon"
              />
            </Grid.Item>
          </Grid>
        </Section>
        <Section background="grayLightest">
          <VerticalSpacing size="xl">
            <Header
              heading={
                <Heading rank="2" type="m">
                  The expertise you need to find{" "}
                  <strong>trusted answers</strong>
                </Heading>
              }
              callToAction={
                <Link
                  href="https://example.com"
                  type="icon"
                  icon={<Icon path={Icons.arrowRightBlue} size="s" />}
                  iconPosition={"right"}
                >
                  Answers on
                </Link>
              }
            />
          </VerticalSpacing>
          <Grid variant="even-3">
            <Grid.Item>
              <PairedCard
                borderless
                href="https://www.example.com"
                eyebrow="Articles"
                heading="World of cryptocurrencies"
                footer={
                  <ArticleCardFooter
                    attributionName="Sophia P."
                    dateTime="2016-02-05 13:57"
                    readingTime="1 min"
                    icon={<Icon path={Icons.time} size="xs" />}
                  />
                }
                imgSrc={imgLarge7}
                imgAnchor="C"
              />
            </Grid.Item>
            <Grid.Item>
              <PairedCard
                borderless
                href="https://www.example.com"
                eyebrow="Articles"
                heading="Electric cars and their demand shift in lithium, cobalt and copper"
                footer={
                  <ArticleCardFooter
                    attributionName="Sophia P."
                    dateTime="2016-02-05 13:57"
                    readingTime="1 min"
                    icon={<Icon path={Icons.time} size="xs" />}
                  />
                }
                imgSrc={imgLarge7}
                imgAnchor="C"
              />
            </Grid.Item>
            <Grid.Item>
              <MarkupContainer>
                <TwitterCard
                  borderless
                  timelineDataSource={{
                    sourceType: "profile",
                    screenName: "thomsonreuters"
                  }}
                  eyebrow="Featured tweets from @thomsonreuters"
                />
              </MarkupContainer>
            </Grid.Item>
          </Grid>
        </Section>
        <Section>
          <Block text="Ask the Expert here" color="blue" height="32em" />
        </Section>
        <Section background="grayLighter">
          <ProfileGallery
            introduction={
              <span>
                We are technologists, accountants, editors, lawyers, and
                everything in between –{" "}
                <strong>from all over the world.</strong>
              </span>
            }
            linkHref="https://www.example.com"
            linkTitle="Meet our experts"
            linkIcon={<Icon path={Icons.arrowRightBlue} size="s" />}
            verticalAtDesktop
          >
            <ProfileGallery.Profile
              name="Saidah Nash Carter "
              title="Head of Innovation, Africa"
              location="Cape Town"
              imgSrc={articleHeadshot}
            />
            <ProfileGallery.Profile
              name="Reginald Chua"
              title="Executive Editor, Data and Innovation"
              location="New York"
              imgSrc={articleHeadshot}
            />
            <ProfileGallery.Profile
              name="Stacey English"
              title="Head of Regulatory Intelligence"
              location="London"
              imgSrc={articleHeadshot}
            />
            <ProfileGallery.Profile
              name="Brian Zubert"
              title="Director, Thomson Reuters Labs"
              location="Waterloo"
              imgSrc={articleHeadshot}
            />
          </ProfileGallery>
        </Section>
        <Section>
          <VerticalSpacing size="xl">
            <Heading type="l">
              Opportunities on a <strong>global scale</strong>
            </Heading>
          </VerticalSpacing>
          <Grid variant="1/3,2/3">
            <Grid.Item>
              <VerticalSpacing>
                <Heading type="m">What’s it like #WorkingAtTR</Heading>
              </VerticalSpacing>
              <VerticalSpacing>
                <MarkupContainer>
                  <VideoPlayer
                    description="Hear some of our employees talk about how they bring their work and passions together at Thomson Reuters."
                    duration="PT1M36S"
                    kind="youtube"
                    name="What's it like #WorkingAtTR"
                    playLabel="play video"
                    uploadDate="2018-02-06"
                    videoId="tMMEgb8KhME"
                  >
                    <Image
                      alt="Placeholder image"
                      anchor="C"
                      sizes={Image.sizes.oneThird}
                      src={placeholder1920}
                      srcSet={`${placeholder960} 960w, ${placeholder1920} 1920w, ${placeholder3840} 3840w`}
                    />
                  </VideoPlayer>
                </MarkupContainer>
              </VerticalSpacing>
              <VerticalSpacing>
                <RichText>
                  <p>
                    Hear some of our employees talk about how they bring their
                    work and passions together at Thomson Reuters.
                  </p>
                </RichText>
              </VerticalSpacing>
            </Grid.Item>
            <Grid.Item>
              <VerticalSpacing>
                <Header
                  heading={
                    <Heading rank="2" type="m">
                      Join our team
                    </Heading>
                  }
                  callToAction={
                    <Link
                      href="https://example.com"
                      type="icon"
                      icon={<Icon path={Icons.arrowRightBlue} size="s" />}
                      iconPosition={"right"}
                    >
                      View all job openings
                    </Link>
                  }
                />
              </VerticalSpacing>
              <ListOfLinks columns={2}>
                <ListOfLinks.Column>
                  <ListOfLinks.BlockLink
                    size="s"
                    href="https://www.example.com"
                    description="New York  •  3 openings"
                  >
                    Legal product developer
                  </ListOfLinks.BlockLink>
                  <ListOfLinks.BlockLink
                    size="s"
                    href="https://www.example.com"
                    description="Toronto  •  1 opening"
                  >
                    Sales specialist, legal information solutions (graduate)
                  </ListOfLinks.BlockLink>
                  <ListOfLinks.BlockLink
                    size="s"
                    href="https://www.example.com"
                    description="Mumbai  •  2 openings"
                  >
                    Legal document automation consultant
                  </ListOfLinks.BlockLink>
                </ListOfLinks.Column>
                <ListOfLinks.Column>
                  <ListOfLinks.BlockLink
                    size="s"
                    href="https://www.example.com"
                    description="New York  •  3 openings"
                  >
                    Industry practice group market lead, legal
                  </ListOfLinks.BlockLink>
                  <ListOfLinks.BlockLink
                    size="s"
                    href="https://www.example.com"
                    description="New York  •  1 opening"
                  >
                    Product manager (legal subscriptions portfolio manager -
                    primary law)
                  </ListOfLinks.BlockLink>
                  <ListOfLinks.BlockLink
                    size="s"
                    href="https://www.example.com"
                    description="New York  •  2 openings"
                  >
                    Legal product developer - Westlaw
                  </ListOfLinks.BlockLink>
                </ListOfLinks.Column>
              </ListOfLinks>
            </Grid.Item>
          </Grid>
        </Section>
      </SemanticMain>

      <SampleFooter />
    </div>
  );
};

export default AboutUs;
