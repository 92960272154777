// Vendor
import React from "react";
import { storiesOf } from "@storybook/react";
import { withKnobs, text } from "@storybook/addon-knobs/react";
import withReadme from "storybook-readme/with-readme";

// emcm-ui
import StaticRenderer from "@emcm-ui/component-static-renderer";
import { Language } from "@emcm-ui/component-theme";
import Block from "@emcm-ui/component-block";
import Button from "@emcm-ui/component-button";
import Expandable from "@emcm-ui/component-expandable";
import Grid from "@emcm-ui/component-grid";
import PageNav from "@emcm-ui/component-page-nav";
import RichText from "@emcm-ui/component-rich-text";
import Section from "@emcm-ui/component-section";
import {
  AjaxContentWithContext,
  AjaxContentContainer
} from "@emcm-ui/component-ajax-content";

// Local
import "./VerticalNav.css";
import VerticalNav from "./index";
import VerticalNavREADME from "../README.md";
import Icon from "@emcm-ui/component-icon";

const stories = storiesOf("Components/VerticalNav", module);

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(VerticalNavREADME));

stories
  .add("Default", () => (
    <div style={{ width: "360px" }}>
      <VerticalNav>
        <VerticalNav.Menu label="Overview">
          <VerticalNav.Item label="Overview 1" href="#" />
          <VerticalNav.Item label="Overview 2" href="#" />
        </VerticalNav.Menu>
        <VerticalNav.Menu label="Development">
          <VerticalNav.Item label="Transport API - C Edition" href="#" />
          <VerticalNav.Item label="Message API - C++ Edition" href="#" />
          <VerticalNav.Menu label="MRN Datebase Models & Implementation Guide">
            <VerticalNav.Item label="Introductions" href="#" />
            <VerticalNav.Item label="Real-time news" href="#" />
            <VerticalNav.Menu label="Story Construction & News Coding">
              <VerticalNav.Item
                label="Nested listing A"
                href="#"
                isSelected={true}
                iconType={
                  <Icon path="./icons/ui/grey/document-07.svg" size="s" />
                }
              />

              <VerticalNav.Menu label="Won't work">
                <VerticalNav.Item label="Nested listing A" href="#" />
                <VerticalNav.Item label="Nested listing B" href="#" />
              </VerticalNav.Menu>
            </VerticalNav.Menu>
          </VerticalNav.Menu>
        </VerticalNav.Menu>
        <VerticalNav.Item label="FAQs" href="#" />
        <VerticalNav.Item label="Release Notes" href="#" />
      </VerticalNav>
    </div>
  ))
  .add("Inside PageNav", () => (
    <Section>
      <AjaxContentContainer>
        <Grid variant="even-2">
          <Grid.Item>
            <RichText>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Pellentesque enim ipsum, faucibus eget ante ac, pretium
                consectetur nulla. Morbi dignissim, turpis vel finibus molestie,
                odio nisi hendrerit nisl, vitae tristique mauris arcu a leo.
                Proin eget diam congue mi vulputate interdum sit amet sed nibh.
                Duis posuere turpis eleifend lobortis lobortis. Nullam nec
                placerat nisl. Fusce viverra neque sit amet elit suscipit, et
                pretium lectus placerat. Suspendisse potenti.
              </p>
            </RichText>
          </Grid.Item>
          <Grid.Item>
            <RichText>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Pellentesque enim ipsum, faucibus eget ante ac, pretium
                consectetur nulla. Morbi dignissim, turpis vel finibus molestie,
                odio nisi hendrerit nisl, vitae tristique mauris arcu a leo.
                Proin eget diam congue mi vulputate interdum sit amet sed nibh.
                Duis posuere turpis eleifend lobortis lobortis. Nullam nec
                placerat nisl. Fusce viverra neque sit amet elit suscipit, et
                pretium lectus placerat. Suspendisse potenti.
              </p>
            </RichText>
          </Grid.Item>
        </Grid>
        <div>
          <PageNav
            sticky={true}
            actions={
              <Button block color="primary" kind="link">
                Register now
              </Button>
            }
          >
            <PageNav.Item
              title="PageNav Demo"
              href="#"
              datasets={`{"data-link-type":"stringOne","data-asset-title":"stringTwo","data-href-analytics":"stringThree","data-link-text":"stringFour","data-find-method":"stringFive"}`}
            />
            <PageNav.Item title="Item One" href="#item-one" />
            <PageNav.Item title="Item Two" href="#item-two" />
            <PageNav.Item title="Item Three" href="#item-three" />
            <PageNav.Item title="External" href="https://www.notvisited.com" />
          </PageNav>

          <Block height="200px" text="PageNav Demo" />
          <PageNav.Anchor id="item-one" />
          <Block height="200px" color="green" text="Item One" />
          <PageNav.Anchor id="item-two" />

          <Grid variant="1/3,2/3">
            <Grid.Item>
              <VerticalNav>
                <VerticalNav.Menu label="Overview">
                  <VerticalNav.Item
                    label="Overview 1"
                    href="#overview1"
                    isSelected={true}
                  />
                  <VerticalNav.Item label="Overview 2" href="#overview2" />
                  <VerticalNav.Menu label="Overview Submenu">
                    <VerticalNav.Item label="Overview 3" href="#overview3" />
                    <VerticalNav.Item label="Overview 4" href="#overview4" />
                  </VerticalNav.Menu>
                  <VerticalNav.Item label="Overview 5" href="#overview5" />
                  <VerticalNav.Item label="Overview 6" href="#overview6" />
                  <VerticalNav.Item label="Overview 7" href="#overview7" />
                  <VerticalNav.Item label="Overview 8" href="#overview8" />
                </VerticalNav.Menu>
                <VerticalNav.Item
                  label="Release Notes"
                  href="https://www.mocky.io/v2/5b8527613000008100729143"
                  isAjax={true}
                  dataAnchor="ajax"
                />
              </VerticalNav>
            </Grid.Item>
            <Grid.Item>
              <div id="ajax">
                <AjaxContentWithContext
                  failedContent={text(
                    "failedContent",
                    "Failed to retrieve content"
                  )}
                  loadingLabel={text("loadingLabel", "Loading")}
                  minHeight={parseInt(text("minHeight"))}
                >
                  <Block color="red" text="default content" height="100px" />
                </AjaxContentWithContext>
              </div>
              <RichText>
                <h2 className="tr-VerticalNav-anchor" id="overview1">
                  Overview 1
                </h2>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Pellentesque enim ipsum, faucibus eget ante ac, pretium
                  consectetur nulla. Morbi dignissim, turpis vel finibus
                  molestie, odio nisi hendrerit nisl, vitae tristique mauris
                  arcu a leo. Proin eget diam congue mi vulputate interdum sit
                  amet sed nibh. Duis posuere turpis eleifend lobortis lobortis.
                  Nullam nec placerat nisl. Fusce viverra neque sit amet elit
                  suscipit, et pretium lectus placerat. Suspendisse potenti.
                </p>
                <p>
                  Aenean facilisis semper nulla id interdum. Ut sit amet orci
                  vestibulum est tristique malesuada a id dui. Etiam laoreet
                  elit sed nunc rhoncus, et porta ante lacinia. Duis eget odio
                  metus. Donec finibus massa nunc, vitae mattis lorem vehicula
                  eu. Suspendisse fermentum, nulla ut bibendum fringilla, arcu
                  justo ornare ex, vel consectetur arcu quam nec orci. Nam risus
                  tellus, tristique at erat non, elementum dignissim purus.
                  Nulla at tellus sit amet elit semper ullamcorper.
                </p>
                <p>
                  Aliquam ornare lacus id commodo suscipit. Vestibulum aliquam
                  est ante. Aenean sollicitudin efficitur egestas. Mauris
                  convallis est tristique suscipit placerat. Pellentesque nisi
                  est, aliquam vel nulla vel, condimentum varius neque. Nulla ac
                  nisi risus. Donec ultricies, est in venenatis aliquam, nisl
                  nulla consequat odio, id maximus quam lorem sodales dui.
                  Aliquam erat volutpat. Suspendisse massa leo, semper in erat
                  vitae, facilisis porttitor urna. Ut interdum dolor massa, ut
                  blandit sapien suscipit vel. Maecenas hendrerit vehicula enim,
                  non viverra augue dapibus lobortis. Sed ultricies mi nec neque
                  sollicitudin, tristique euismod elit maximus.
                </p>
              </RichText>
              <Expandable
                direction="up"
                expandedTitle="Less"
                type="showMore"
                title="More"
                headingRank="3"
              >
                <RichText>
                  <p>
                    Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                    Provident saepe nam asperiores deleniti totam aut
                    dignissimos et sapiente. Enim est magni, modi maiores
                    consequatur veritatis deserunt ex corporis odio quod.
                  </p>
                </RichText>
              </Expandable>
              <RichText>
                <h2 className="tr-VerticalNav-anchor" id="overview2">
                  Overview 2
                </h2>
                <p>
                  Maecenas lobortis nibh a pellentesque vehicula. Vestibulum
                  elementum at tellus ac porta. Lorem ipsum dolor sit amet,
                  consectetur adipiscing elit. Quisque finibus, ante a viverra
                  dictum, quam sapien lacinia felis, in ultricies massa purus
                  sed lorem. Donec id condimentum tellus. Ut ornare ipsum ac
                  finibus tempor. Nunc commodo tempor dignissim. Nunc posuere
                  augue in velit consequat auctor. Suspendisse maximus laoreet
                  est, sit amet venenatis velit mollis molestie. Sed finibus ex
                  at ex sollicitudin dignissim. Nam bibendum finibus velit sed
                  hendrerit. Vivamus scelerisque congue nisl, non convallis
                  lectus interdum non. In pulvinar fermentum ullamcorper. Proin
                  accumsan neque vel sem pretium, vitae convallis nisi
                  ultricies. Maecenas quis dictum dui, at vestibulum dui.
                </p>
                <p>
                  Duis consectetur ante id quam rutrum, vehicula tempus lorem
                  sagittis. Integer mollis justo sit amet arcu condimentum
                  sagittis. Suspendisse vulputate magna non neque scelerisque
                  ultricies. Etiam eu dictum urna. Fusce posuere ligula ac neque
                  mattis, in varius odio finibus. Donec fringilla urna eu
                  ultricies elementum. In euismod lectus non consectetur
                  malesuada. Morbi molestie, neque at rhoncus dictum, tortor
                  mauris viverra nulla, a pretium lectus augue ut quam. Duis in
                  augue quis orci luctus aliquam vitae eu tellus. Aliquam
                  dignissim vel magna nec vehicula. Donec aliquam elit eu
                  interdum viverra. Fusce sed tortor placerat, dictum nisl sed,
                  placerat sapien. Aenean blandit, massa nec ultricies finibus,
                  nibh libero pellentesque ante, id molestie orci erat vitae
                  magna. Nulla at blandit augue. Ut semper laoreet eros, eu
                  sollicitudin sem ullamcorper eu.
                </p>
                <h2 className="tr-VerticalNav-anchor" id="overview3">
                  Overview 3
                </h2>
                <p>
                  Maecenas lobortis nibh a pellentesque vehicula. Vestibulum
                  elementum at tellus ac porta. Lorem ipsum dolor sit amet,
                  consectetur adipiscing elit. Quisque finibus, ante a viverra
                  dictum, quam sapien lacinia felis, in ultricies massa purus
                  sed lorem. Donec id condimentum tellus. Ut ornare ipsum ac
                  finibus tempor. Nunc commodo tempor dignissim. Nunc posuere
                  augue in velit consequat auctor. Suspendisse maximus laoreet
                  est, sit amet venenatis velit mollis molestie. Sed finibus ex
                  at ex sollicitudin dignissim. Nam bibendum finibus velit sed
                  hendrerit. Vivamus scelerisque congue nisl, non convallis
                  lectus interdum non. In pulvinar fermentum ullamcorper. Proin
                  accumsan neque vel sem pretium, vitae convallis nisi
                  ultricies. Maecenas quis dictum dui, at vestibulum dui.
                </p>
                <p>
                  Duis consectetur ante id quam rutrum, vehicula tempus lorem
                  sagittis. Integer mollis justo sit amet arcu condimentum
                  sagittis. Suspendisse vulputate magna non neque scelerisque
                  ultricies. Etiam eu dictum urna. Fusce posuere ligula ac neque
                  mattis, in varius odio finibus. Donec fringilla urna eu
                  ultricies elementum. In euismod lectus non consectetur
                  malesuada. Morbi molestie, neque at rhoncus dictum, tortor
                  mauris viverra nulla, a pretium lectus augue ut quam. Duis in
                  augue quis orci luctus aliquam vitae eu tellus. Aliquam
                  dignissim vel magna nec vehicula. Donec aliquam elit eu
                  interdum viverra. Fusce sed tortor placerat, dictum nisl sed,
                  placerat sapien. Aenean blandit, massa nec ultricies finibus,
                  nibh libero pellentesque ante, id molestie orci erat vitae
                  magna. Nulla at blandit augue. Ut semper laoreet eros, eu
                  sollicitudin sem ullamcorper eu.
                </p>
                <h2 className="tr-VerticalNav-anchor" id="overview4">
                  Overview 4
                </h2>
                <p>
                  Maecenas lobortis nibh a pellentesque vehicula. Vestibulum
                  elementum at tellus ac porta. Lorem ipsum dolor sit amet,
                  consectetur adipiscing elit. Quisque finibus, ante a viverra
                  dictum, quam sapien lacinia felis, in ultricies massa purus
                  sed lorem. Donec id condimentum tellus. Ut ornare ipsum ac
                  finibus tempor. Nunc commodo tempor dignissim. Nunc posuere
                  augue in velit consequat auctor. Suspendisse maximus laoreet
                  est, sit amet venenatis velit mollis molestie. Sed finibus ex
                  at ex sollicitudin dignissim. Nam bibendum finibus velit sed
                  hendrerit. Vivamus scelerisque congue nisl, non convallis
                  lectus interdum non. In pulvinar fermentum ullamcorper. Proin
                  accumsan neque vel sem pretium, vitae convallis nisi
                  ultricies. Maecenas quis dictum dui, at vestibulum dui.
                </p>
                <p>
                  Duis consectetur ante id quam rutrum, vehicula tempus lorem
                  sagittis. Integer mollis justo sit amet arcu condimentum
                  sagittis. Suspendisse vulputate magna non neque scelerisque
                  ultricies. Etiam eu dictum urna. Fusce posuere ligula ac neque
                  mattis, in varius odio finibus. Donec fringilla urna eu
                  ultricies elementum. In euismod lectus non consectetur
                  malesuada. Morbi molestie, neque at rhoncus dictum, tortor
                  mauris viverra nulla, a pretium lectus augue ut quam. Duis in
                  augue quis orci luctus aliquam vitae eu tellus. Aliquam
                  dignissim vel magna nec vehicula. Donec aliquam elit eu
                  interdum viverra. Fusce sed tortor placerat, dictum nisl sed,
                  placerat sapien. Aenean blandit, massa nec ultricies finibus,
                  nibh libero pellentesque ante, id molestie orci erat vitae
                  magna. Nulla at blandit augue. Ut semper laoreet eros, eu
                  sollicitudin sem ullamcorper eu.
                </p>
                <h2 className="tr-VerticalNav-anchor" id="overview5">
                  Overview 5
                </h2>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Pellentesque enim ipsum, faucibus eget ante ac, pretium
                  consectetur nulla. Morbi dignissim, turpis vel finibus
                  molestie, odio nisi hendrerit nisl, vitae tristique mauris
                  arcu a leo. Proin eget diam congue mi vulputate interdum sit
                  amet sed nibh. Duis posuere turpis eleifend lobortis lobortis.
                  Nullam nec placerat nisl. Fusce viverra neque sit amet elit
                  suscipit, et pretium lectus placerat. Suspendisse potenti.
                </p>
                <h2 className="tr-VerticalNav-anchor" id="overview6">
                  Overview 6
                </h2>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Pellentesque enim ipsum, faucibus eget ante ac, pretium
                  consectetur nulla. Morbi dignissim, turpis vel finibus
                  molestie, odio nisi hendrerit nisl, vitae tristique mauris
                  arcu a leo. Proin eget diam congue mi vulputate interdum sit
                  amet sed nibh. Duis posuere turpis eleifend lobortis lobortis.
                  Nullam nec placerat nisl. Fusce viverra neque sit amet elit
                  suscipit, et pretium lectus placerat. Suspendisse potenti.
                </p>
                <p>
                  Aenean facilisis semper nulla id interdum. Ut sit amet orci
                  vestibulum est tristique malesuada a id dui. Etiam laoreet
                  elit sed nunc rhoncus, et porta ante lacinia. Duis eget odio
                  metus. Donec finibus massa nunc, vitae mattis lorem vehicula
                  eu. Suspendisse fermentum, nulla ut bibendum fringilla, arcu
                  justo ornare ex, vel consectetur arcu quam nec orci. Nam risus
                  tellus, tristique at erat non, elementum dignissim purus.
                  Nulla at tellus sit amet elit semper ullamcorper.
                </p>
                <p>
                  Aliquam ornare lacus id commodo suscipit. Vestibulum aliquam
                  est ante. Aenean sollicitudin efficitur egestas. Mauris
                  convallis est tristique suscipit placerat. Pellentesque nisi
                  est, aliquam vel nulla vel, condimentum varius neque. Nulla ac
                  nisi risus. Donec ultricies, est in venenatis aliquam, nisl
                  nulla consequat odio, id maximus quam lorem sodales dui.
                  Aliquam erat volutpat. Suspendisse massa leo, semper in erat
                  vitae, facilisis porttitor urna. Ut interdum dolor massa, ut
                  blandit sapien suscipit vel. Maecenas hendrerit vehicula enim,
                  non viverra augue dapibus lobortis. Sed ultricies mi nec neque
                  sollicitudin, tristique euismod elit maximus.
                </p>
                <h2 className="tr-VerticalNav-anchor" id="overview7">
                  Overview 7
                </h2>
                <p>
                  Aliquam ornare lacus id commodo suscipit. Vestibulum aliquam
                  est ante. Aenean sollicitudin efficitur egestas. Mauris
                  convallis est tristique suscipit placerat. Pellentesque nisi
                  est, aliquam vel nulla vel, condimentum varius neque. Nulla ac
                  nisi risus. Donec ultricies, est in venenatis aliquam, nisl
                  nulla consequat odio, id maximus quam lorem sodales dui.
                  Aliquam erat volutpat. Suspendisse massa leo, semper in erat
                  vitae, facilisis porttitor urna. Ut interdum dolor massa, ut
                  blandit sapien suscipit vel. Maecenas hendrerit vehicula enim,
                  non viverra augue dapibus lobortis. Sed ultricies mi nec neque
                  sollicitudin, tristique euismod elit maximus.
                </p>
                <h2 className="tr-VerticalNav-anchor" id="overview8">
                  Overview 8
                </h2>
                <p>
                  Aenean facilisis semper nulla id interdum. Ut sit amet orci
                  vestibulum est tristique malesuada a id dui. Etiam laoreet
                  elit sed nunc rhoncus, et porta ante lacinia. Duis eget odio
                  metus. Donec finibus massa nunc, vitae mattis lorem vehicula
                  eu. Suspendisse fermentum, nulla ut bibendum fringilla, arcu
                  justo ornare ex, vel consectetur arcu quam nec orci. Nam risus
                  tellus, tristique at erat non, elementum dignissim purus.
                  Nulla at tellus sit amet elit semper ullamcorper.
                </p>
                <p>
                  Aliquam ornare lacus id commodo suscipit. Vestibulum aliquam
                  est ante. Aenean sollicitudin efficitur egestas. Mauris
                  convallis est tristique suscipit placerat. Pellentesque nisi
                  est, aliquam vel nulla vel, condimentum varius neque. Nulla ac
                  nisi risus. Donec ultricies, est in venenatis aliquam, nisl
                  nulla consequat odio, id maximus quam lorem sodales dui.
                  Aliquam erat volutpat. Suspendisse massa leo, semper in erat
                  vitae, facilisis porttitor urna. Ut interdum dolor massa, ut
                  blandit sapien suscipit vel. Maecenas hendrerit vehicula enim,
                  non viverra augue dapibus lobortis. Sed ultricies mi nec neque
                  sollicitudin, tristique euismod elit maximus.
                </p>
              </RichText>
            </Grid.Item>
          </Grid>

          <PageNav.Anchor id="item-three" />
          <Block height="200px" color="red" text="Item Three" />
        </div>
      </AjaxContentContainer>
    </Section>
  ))
  .add("Outside PageNav", () => (
    <Section>
      <AjaxContentContainer>
        <Grid variant="even-2">
          <Grid.Item>
            <RichText>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Pellentesque enim ipsum, faucibus eget ante ac, pretium
                consectetur nulla. Morbi dignissim, turpis vel finibus molestie,
                odio nisi hendrerit nisl, vitae tristique mauris arcu a leo.
                Proin eget diam congue mi vulputate interdum sit amet sed nibh.
                Duis posuere turpis eleifend lobortis lobortis. Nullam nec
                placerat nisl. Fusce viverra neque sit amet elit suscipit, et
                pretium lectus placerat. Suspendisse potenti.
              </p>
            </RichText>
          </Grid.Item>
          <Grid.Item>
            <RichText>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Pellentesque enim ipsum, faucibus eget ante ac, pretium
                consectetur nulla. Morbi dignissim, turpis vel finibus molestie,
                odio nisi hendrerit nisl, vitae tristique mauris arcu a leo.
                Proin eget diam congue mi vulputate interdum sit amet sed nibh.
                Duis posuere turpis eleifend lobortis lobortis. Nullam nec
                placerat nisl. Fusce viverra neque sit amet elit suscipit, et
                pretium lectus placerat. Suspendisse potenti.
              </p>
            </RichText>
          </Grid.Item>
        </Grid>
        <div>
          <PageNav
            sticky={true}
            actions={
              <Button block color="primary" kind="link">
                Register now
              </Button>
            }
          >
            <PageNav.Item
              title="PageNav Demo"
              href="#"
              datasets={`{"data-link-type":"stringOne","data-asset-title":"stringTwo","data-href-analytics":"stringThree","data-link-text":"stringFour","data-find-method":"stringFive"}`}
            />
            <PageNav.Item title="Item One" href="#item-one" />
            <PageNav.Item title="Item Two" href="#item-two" />
            <PageNav.Item title="Item Three" href="#item-three" />
            <PageNav.Item title="External" href="https://www.notvisited.com" />
          </PageNav>

          <Block height="200px" text="PageNav Demo" />
          <PageNav.Anchor id="item-one" />
          <Block height="200px" color="green" text="Item One" />
          <PageNav.Anchor id="item-two" />
          <Block height="200px" color="orange" text="Item Two" />
          <PageNav.Anchor id="item-three" />
          <Block height="200px" color="red" text="Item Three" />
        </div>

        <Grid variant="1/3,2/3">
          <Grid.Item>
            <VerticalNav>
              <VerticalNav.Menu label="Overview">
                <VerticalNav.Item
                  label="Overview 1"
                  href="#overview1"
                  isSelected={true}
                />
                <VerticalNav.Item label="Overview 2" href="#overview2" />
                <VerticalNav.Menu label="Overview Submenu">
                  <VerticalNav.Item label="Overview 3" href="#overview3" />
                  <VerticalNav.Item label="Overview 4" href="#overview4" />
                </VerticalNav.Menu>
                <VerticalNav.Item label="Overview 5" href="#overview5" />
                <VerticalNav.Item label="Overview 6" href="#overview6" />
                <VerticalNav.Item label="Overview 7" href="#overview7" />
                <VerticalNav.Item label="Overview 8" href="#overview8" />
              </VerticalNav.Menu>
              <VerticalNav.Item
                label="Release Notes"
                href="https://www.mocky.io/v2/5b8527613000008100729143"
                isAjax={true}
                dataAnchor="ajax"
              />
            </VerticalNav>
          </Grid.Item>
          <Grid.Item>
            <div id="ajax">
              <AjaxContentWithContext
                failedContent={text(
                  "failedContent",
                  "Failed to retrieve content"
                )}
                loadingLabel={text("loadingLabel", "Loading")}
                minHeight={parseInt(text("minHeight"))}
              >
                <Block color="red" text="default content" height="100px" />
              </AjaxContentWithContext>
            </div>

            <RichText>
              <h2 className="tr-VerticalNav-anchor" id="overview1">
                Overview 1
              </h2>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Pellentesque enim ipsum, faucibus eget ante ac, pretium
                consectetur nulla. Morbi dignissim, turpis vel finibus molestie,
                odio nisi hendrerit nisl, vitae tristique mauris arcu a leo.
                Proin eget diam congue mi vulputate interdum sit amet sed nibh.
                Duis posuere turpis eleifend lobortis lobortis. Nullam nec
                placerat nisl. Fusce viverra neque sit amet elit suscipit, et
                pretium lectus placerat. Suspendisse potenti.
              </p>
              <p>
                Aenean facilisis semper nulla id interdum. Ut sit amet orci
                vestibulum est tristique malesuada a id dui. Etiam laoreet elit
                sed nunc rhoncus, et porta ante lacinia. Duis eget odio metus.
                Donec finibus massa nunc, vitae mattis lorem vehicula eu.
                Suspendisse fermentum, nulla ut bibendum fringilla, arcu justo
                ornare ex, vel consectetur arcu quam nec orci. Nam risus tellus,
                tristique at erat non, elementum dignissim purus. Nulla at
                tellus sit amet elit semper ullamcorper.
              </p>
              <p>
                Aliquam ornare lacus id commodo suscipit. Vestibulum aliquam est
                ante. Aenean sollicitudin efficitur egestas. Mauris convallis
                est tristique suscipit placerat. Pellentesque nisi est, aliquam
                vel nulla vel, condimentum varius neque. Nulla ac nisi risus.
                Donec ultricies, est in venenatis aliquam, nisl nulla consequat
                odio, id maximus quam lorem sodales dui. Aliquam erat volutpat.
                Suspendisse massa leo, semper in erat vitae, facilisis porttitor
                urna. Ut interdum dolor massa, ut blandit sapien suscipit vel.
                Maecenas hendrerit vehicula enim, non viverra augue dapibus
                lobortis. Sed ultricies mi nec neque sollicitudin, tristique
                euismod elit maximus.
              </p>
            </RichText>
            <Expandable
              direction="up"
              expandedTitle="Less"
              type="showMore"
              title="More"
              headingRank="3"
            >
              <RichText>
                <p>
                  Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                  Provident saepe nam asperiores deleniti totam aut dignissimos
                  et sapiente. Enim est magni, modi maiores consequatur
                  veritatis deserunt ex corporis odio quod.
                </p>
              </RichText>
            </Expandable>
            <RichText>
              <h2 className="tr-VerticalNav-anchor" id="overview2">
                Overview 2
              </h2>
              <p>
                Maecenas lobortis nibh a pellentesque vehicula. Vestibulum
                elementum at tellus ac porta. Lorem ipsum dolor sit amet,
                consectetur adipiscing elit. Quisque finibus, ante a viverra
                dictum, quam sapien lacinia felis, in ultricies massa purus sed
                lorem. Donec id condimentum tellus. Ut ornare ipsum ac finibus
                tempor. Nunc commodo tempor dignissim. Nunc posuere augue in
                velit consequat auctor. Suspendisse maximus laoreet est, sit
                amet venenatis velit mollis molestie. Sed finibus ex at ex
                sollicitudin dignissim. Nam bibendum finibus velit sed
                hendrerit. Vivamus scelerisque congue nisl, non convallis lectus
                interdum non. In pulvinar fermentum ullamcorper. Proin accumsan
                neque vel sem pretium, vitae convallis nisi ultricies. Maecenas
                quis dictum dui, at vestibulum dui.
              </p>
              <p>
                Duis consectetur ante id quam rutrum, vehicula tempus lorem
                sagittis. Integer mollis justo sit amet arcu condimentum
                sagittis. Suspendisse vulputate magna non neque scelerisque
                ultricies. Etiam eu dictum urna. Fusce posuere ligula ac neque
                mattis, in varius odio finibus. Donec fringilla urna eu
                ultricies elementum. In euismod lectus non consectetur
                malesuada. Morbi molestie, neque at rhoncus dictum, tortor
                mauris viverra nulla, a pretium lectus augue ut quam. Duis in
                augue quis orci luctus aliquam vitae eu tellus. Aliquam
                dignissim vel magna nec vehicula. Donec aliquam elit eu interdum
                viverra. Fusce sed tortor placerat, dictum nisl sed, placerat
                sapien. Aenean blandit, massa nec ultricies finibus, nibh libero
                pellentesque ante, id molestie orci erat vitae magna. Nulla at
                blandit augue. Ut semper laoreet eros, eu sollicitudin sem
                ullamcorper eu.
              </p>
              <h2 className="tr-VerticalNav-anchor" id="overview3">
                Overview 3
              </h2>
              <p>
                Maecenas lobortis nibh a pellentesque vehicula. Vestibulum
                elementum at tellus ac porta. Lorem ipsum dolor sit amet,
                consectetur adipiscing elit. Quisque finibus, ante a viverra
                dictum, quam sapien lacinia felis, in ultricies massa purus sed
                lorem. Donec id condimentum tellus. Ut ornare ipsum ac finibus
                tempor. Nunc commodo tempor dignissim. Nunc posuere augue in
                velit consequat auctor. Suspendisse maximus laoreet est, sit
                amet venenatis velit mollis molestie. Sed finibus ex at ex
                sollicitudin dignissim. Nam bibendum finibus velit sed
                hendrerit. Vivamus scelerisque congue nisl, non convallis lectus
                interdum non. In pulvinar fermentum ullamcorper. Proin accumsan
                neque vel sem pretium, vitae convallis nisi ultricies. Maecenas
                quis dictum dui, at vestibulum dui.
              </p>
              <p>
                Duis consectetur ante id quam rutrum, vehicula tempus lorem
                sagittis. Integer mollis justo sit amet arcu condimentum
                sagittis. Suspendisse vulputate magna non neque scelerisque
                ultricies. Etiam eu dictum urna. Fusce posuere ligula ac neque
                mattis, in varius odio finibus. Donec fringilla urna eu
                ultricies elementum. In euismod lectus non consectetur
                malesuada. Morbi molestie, neque at rhoncus dictum, tortor
                mauris viverra nulla, a pretium lectus augue ut quam. Duis in
                augue quis orci luctus aliquam vitae eu tellus. Aliquam
                dignissim vel magna nec vehicula. Donec aliquam elit eu interdum
                viverra. Fusce sed tortor placerat, dictum nisl sed, placerat
                sapien. Aenean blandit, massa nec ultricies finibus, nibh libero
                pellentesque ante, id molestie orci erat vitae magna. Nulla at
                blandit augue. Ut semper laoreet eros, eu sollicitudin sem
                ullamcorper eu.
              </p>
              <h2 className="tr-VerticalNav-anchor" id="overview4">
                Overview 4
              </h2>
              <p>
                Maecenas lobortis nibh a pellentesque vehicula. Vestibulum
                elementum at tellus ac porta. Lorem ipsum dolor sit amet,
                consectetur adipiscing elit. Quisque finibus, ante a viverra
                dictum, quam sapien lacinia felis, in ultricies massa purus sed
                lorem. Donec id condimentum tellus. Ut ornare ipsum ac finibus
                tempor. Nunc commodo tempor dignissim. Nunc posuere augue in
                velit consequat auctor. Suspendisse maximus laoreet est, sit
                amet venenatis velit mollis molestie. Sed finibus ex at ex
                sollicitudin dignissim. Nam bibendum finibus velit sed
                hendrerit. Vivamus scelerisque congue nisl, non convallis lectus
                interdum non. In pulvinar fermentum ullamcorper. Proin accumsan
                neque vel sem pretium, vitae convallis nisi ultricies. Maecenas
                quis dictum dui, at vestibulum dui.
              </p>
              <p>
                Duis consectetur ante id quam rutrum, vehicula tempus lorem
                sagittis. Integer mollis justo sit amet arcu condimentum
                sagittis. Suspendisse vulputate magna non neque scelerisque
                ultricies. Etiam eu dictum urna. Fusce posuere ligula ac neque
                mattis, in varius odio finibus. Donec fringilla urna eu
                ultricies elementum. In euismod lectus non consectetur
                malesuada. Morbi molestie, neque at rhoncus dictum, tortor
                mauris viverra nulla, a pretium lectus augue ut quam. Duis in
                augue quis orci luctus aliquam vitae eu tellus. Aliquam
                dignissim vel magna nec vehicula. Donec aliquam elit eu interdum
                viverra. Fusce sed tortor placerat, dictum nisl sed, placerat
                sapien. Aenean blandit, massa nec ultricies finibus, nibh libero
                pellentesque ante, id molestie orci erat vitae magna. Nulla at
                blandit augue. Ut semper laoreet eros, eu sollicitudin sem
                ullamcorper eu.
              </p>
              <h2 className="tr-VerticalNav-anchor" id="overview5">
                Overview 5
              </h2>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Pellentesque enim ipsum, faucibus eget ante ac, pretium
                consectetur nulla. Morbi dignissim, turpis vel finibus molestie,
                odio nisi hendrerit nisl, vitae tristique mauris arcu a leo.
                Proin eget diam congue mi vulputate interdum sit amet sed nibh.
                Duis posuere turpis eleifend lobortis lobortis. Nullam nec
                placerat nisl. Fusce viverra neque sit amet elit suscipit, et
                pretium lectus placerat. Suspendisse potenti.
              </p>
              <h2 className="tr-VerticalNav-anchor" id="overview6">
                Overview 6
              </h2>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Pellentesque enim ipsum, faucibus eget ante ac, pretium
                consectetur nulla. Morbi dignissim, turpis vel finibus molestie,
                odio nisi hendrerit nisl, vitae tristique mauris arcu a leo.
                Proin eget diam congue mi vulputate interdum sit amet sed nibh.
                Duis posuere turpis eleifend lobortis lobortis. Nullam nec
                placerat nisl. Fusce viverra neque sit amet elit suscipit, et
                pretium lectus placerat. Suspendisse potenti.
              </p>
              <p>
                Aenean facilisis semper nulla id interdum. Ut sit amet orci
                vestibulum est tristique malesuada a id dui. Etiam laoreet elit
                sed nunc rhoncus, et porta ante lacinia. Duis eget odio metus.
                Donec finibus massa nunc, vitae mattis lorem vehicula eu.
                Suspendisse fermentum, nulla ut bibendum fringilla, arcu justo
                ornare ex, vel consectetur arcu quam nec orci. Nam risus tellus,
                tristique at erat non, elementum dignissim purus. Nulla at
                tellus sit amet elit semper ullamcorper.
              </p>
              <p>
                Aliquam ornare lacus id commodo suscipit. Vestibulum aliquam est
                ante. Aenean sollicitudin efficitur egestas. Mauris convallis
                est tristique suscipit placerat. Pellentesque nisi est, aliquam
                vel nulla vel, condimentum varius neque. Nulla ac nisi risus.
                Donec ultricies, est in venenatis aliquam, nisl nulla consequat
                odio, id maximus quam lorem sodales dui. Aliquam erat volutpat.
                Suspendisse massa leo, semper in erat vitae, facilisis porttitor
                urna. Ut interdum dolor massa, ut blandit sapien suscipit vel.
                Maecenas hendrerit vehicula enim, non viverra augue dapibus
                lobortis. Sed ultricies mi nec neque sollicitudin, tristique
                euismod elit maximus.
              </p>
              <h2 className="tr-VerticalNav-anchor" id="overview7">
                Overview 7
              </h2>
              <p>
                Aliquam ornare lacus id commodo suscipit. Vestibulum aliquam est
                ante. Aenean sollicitudin efficitur egestas. Mauris convallis
                est tristique suscipit placerat. Pellentesque nisi est, aliquam
                vel nulla vel, condimentum varius neque. Nulla ac nisi risus.
                Donec ultricies, est in venenatis aliquam, nisl nulla consequat
                odio, id maximus quam lorem sodales dui. Aliquam erat volutpat.
                Suspendisse massa leo, semper in erat vitae, facilisis porttitor
                urna. Ut interdum dolor massa, ut blandit sapien suscipit vel.
                Maecenas hendrerit vehicula enim, non viverra augue dapibus
                lobortis. Sed ultricies mi nec neque sollicitudin, tristique
                euismod elit maximus.
              </p>
              <h2 className="tr-VerticalNav-anchor" id="overview8">
                Overview 8
              </h2>
              <p>
                Aenean facilisis semper nulla id interdum. Ut sit amet orci
                vestibulum est tristique malesuada a id dui. Etiam laoreet elit
                sed nunc rhoncus, et porta ante lacinia. Duis eget odio metus.
                Donec finibus massa nunc, vitae mattis lorem vehicula eu.
                Suspendisse fermentum, nulla ut bibendum fringilla, arcu justo
                ornare ex, vel consectetur arcu quam nec orci. Nam risus tellus,
                tristique at erat non, elementum dignissim purus. Nulla at
                tellus sit amet elit semper ullamcorper.
              </p>
              <p>
                Aliquam ornare lacus id commodo suscipit. Vestibulum aliquam est
                ante. Aenean sollicitudin efficitur egestas. Mauris convallis
                est tristique suscipit placerat. Pellentesque nisi est, aliquam
                vel nulla vel, condimentum varius neque. Nulla ac nisi risus.
                Donec ultricies, est in venenatis aliquam, nisl nulla consequat
                odio, id maximus quam lorem sodales dui. Aliquam erat volutpat.
                Suspendisse massa leo, semper in erat vitae, facilisis porttitor
                urna. Ut interdum dolor massa, ut blandit sapien suscipit vel.
                Maecenas hendrerit vehicula enim, non viverra augue dapibus
                lobortis. Sed ultricies mi nec neque sollicitudin, tristique
                euismod elit maximus.
              </p>
            </RichText>
          </Grid.Item>
        </Grid>
      </AjaxContentContainer>
    </Section>
  ))
  .add("AjaxContext", () => (
    <Section>
      <AjaxContentContainer>
        <Grid variant="even-2">
          <Grid.Item>
            <VerticalNav>
              <VerticalNav.Menu label="Todos">
                <VerticalNav.Item
                  label="One Todo"
                  href="https://www.mocky.io/v2/5b8527613000008100729143"
                  isSelected={true}
                  isAjax={true}
                />
                <VerticalNav.Item
                  label="Two Todos"
                  href="https://www.mocky.io/v2/5b891b8a3000006e0c338312"
                  isAjax={true}
                />
              </VerticalNav.Menu>
            </VerticalNav>
          </Grid.Item>
          <Grid.Item>
            <AjaxContentWithContext
              failedContent={text(
                "failedContent",
                "Failed to retrieve content"
              )}
              loadingLabel={text("loadingLabel", "Loading")}
              minHeight={parseInt(text("minHeight"))}
            >
              <Block color="red" text="default content" height="100px" />
            </AjaxContentWithContext>
          </Grid.Item>
        </Grid>
      </AjaxContentContainer>
    </Section>
  ))
  .add("AjaxContext HashUrl", () => (
    <Section>
      <AjaxContentContainer>
        <Grid variant="even-2">
          <Grid.Item>
            <VerticalNav>
              <VerticalNav.Menu label="Todos">
                <VerticalNav.Item
                  label="One Todo"
                  href="https://www.mocky.io/v2/5b891b8a3000006e0c338312"
                  isSelected={true}
                  isAjax={true}
                  isUrlHashActive={true}
                />
                <VerticalNav.Item
                  label="Two Todos"
                  href="https://www.mocky.io/v2/5b891b8a3000006e0c338312"
                  isAjax={true}
                  isUrlHashActive={true}
                />
              </VerticalNav.Menu>
            </VerticalNav>
          </Grid.Item>
          <Grid.Item>
            <AjaxContentWithContext
              failedContent={text(
                "failedContent",
                "Failed to retrieve content"
              )}
              loadingLabel={text("loadingLabel", "Loading")}
              minHeight={parseInt(text("minHeight"))}
            >
              <Block color="red" text="default content" height="100px" />
            </AjaxContentWithContext>
          </Grid.Item>
        </Grid>
      </AjaxContentContainer>
    </Section>
  ))

  // .add("AjaxContext Rehydrated", () => (
  //   <StaticRenderer>
  //     <Section>
  //       <AjaxContentContainer>
  //         <Grid variant="even-2">
  //           <Grid.Item>
  //             <VerticalNav>
  //               <VerticalNav.Menu label="Todos">
  //                 <VerticalNav.Item
  //                   label="One Todo"
  //                   href="https://jsonplaceholder.typicode.com/todos/1"
  //                   isSelected={true}
  //                 />
  //                 <VerticalNav.Item
  //                   label="Two Todos"
  //                   href="https://jsonplaceholder.typicode.com/todos/2"
  //                 />
  //               </VerticalNav.Menu>
  //             </VerticalNav>
  //           </Grid.Item>
  //           <Grid.Item>
  //             <AjaxContentWithContext
  //               failedContent={text(
  //                 "failedContent",
  //                 "Failed to retrieve content"
  //               )}
  //               loadingLabel={"Loading"}
  //               minHeight={parseInt(text("minHeight"))}
  //             >
  //               <Block color="red" text="default content" height="100px" />
  //             </AjaxContentWithContext>
  //           </Grid.Item>
  //         </Grid>
  //       </AjaxContentContainer>
  //     </Section>
  //   </StaticRenderer>
  // ))
  .add("Singlemenu", () => (
    <div style={{ width: "360px" }}>
      <VerticalNav>
        <VerticalNav.Menu label="Development">
          <VerticalNav.Menu label="MRN Datebase Models & Implementation Guide">
            <VerticalNav.Menu label="Story Construction & News Coding">
              <VerticalNav.Item
                label="Nested listing A"
                href="#"
                isSelected={true}
                iconType={<Icon path="./icons/ui/grey/tick-07.svg" size="s" />}
              />
            </VerticalNav.Menu>
          </VerticalNav.Menu>
        </VerticalNav.Menu>
      </VerticalNav>
    </div>
  ))
  .add("Language: Arabic (RTL)", () => (
    <div style={{ width: "360px" }}>
      <Language code="ar">
        <VerticalNav>
          <VerticalNav.Menu label="Overview">
            <VerticalNav.Item label="Overview 1" href="#" />
            <VerticalNav.Item label="Overview 2" href="#" />
          </VerticalNav.Menu>
          <VerticalNav.Menu label="Development">
            <VerticalNav.Item label="Transport API - C Edition" href="#" />
            <VerticalNav.Item label="Message API - C++ Edition" href="#" />
            <VerticalNav.Menu label="MRN Datebase Models & Implementation Guide">
              <VerticalNav.Item label="Introductions" href="#" />
              <VerticalNav.Item label="Real-time news" href="#" />
              <VerticalNav.Menu label="Story Construction & News Coding">
                <VerticalNav.Item
                  label="Nested listing A"
                  href="#"
                  isSelected={true}
                  iconType={
                    <Icon path="./icons/ui/grey/tick-07.svg" size="s" />
                  }
                />
                <VerticalNav.Item
                  label="Nested listing B"
                  href="#"
                  iconType={
                    <Icon path="./icons/ui/grey/tick-07.svg" size="s" />
                  }
                />
              </VerticalNav.Menu>
            </VerticalNav.Menu>
          </VerticalNav.Menu>
          <VerticalNav.Item label="FAQs" href="#" />
          <VerticalNav.Item label="Release Notes" href="#" />
        </VerticalNav>
      </Language>
    </div>
  ))
  .add("Rehydrated", () => (
    <StaticRenderer>
      <div style={{ width: "360px" }}>
        <VerticalNav>
          <VerticalNav.Menu label="Overview">
            <VerticalNav.Item label="Overview 1" href="#" />
            <VerticalNav.Item label="Overview 2" href="#" />
          </VerticalNav.Menu>
          <VerticalNav.Menu label="Development">
            <VerticalNav.Item label="Transport API - C Edition" href="#" />
            <VerticalNav.Item label="Message API - C++ Edition" href="#" />
            <VerticalNav.Menu label="MRN Datebase Models & Implementation Guide">
              <VerticalNav.Item label="Introductions" href="#" />
              <VerticalNav.Item label="Real-time news" href="#" />
              <VerticalNav.Menu label="Story Construction & News Coding">
                <VerticalNav.Item
                  label="Nested listing A"
                  href="#"
                  isSelected={true}
                  iconPath="./icons/ui/grey/tick-07.svg"
                />
              </VerticalNav.Menu>
            </VerticalNav.Menu>
          </VerticalNav.Menu>
          <VerticalNav.Item label="FAQs" href="#" />
          <VerticalNav.Item label="Release Notes" href="#" />
        </VerticalNav>
      </div>
    </StaticRenderer>
  ));
