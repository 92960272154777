import PropTypes from "prop-types";
import React from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import TextCardBase from "../../bases/TextCardBase";
import Image, { anchorPoints } from "@emcm-ui/component-image";

const PairedCard = ({
  borderless,
  eyebrow,
  footer,
  imgAnchor,
  imgSrc,
  imgSrcSet,
  heading,
  metaData,
  description,
  headingRank,
  href
}) => {
  const getClassName = getClassNameFactory(PairedCard.displayName);

  return (
    <div
      className={getClassName({ modifiers: borderless ? "borderless" : "" })}
    >
      <TextCardBase
        anchor="top"
        borderless
        eyebrow={eyebrow}
        footer={footer}
        heading={heading}
        metaData={metaData}
        description={description}
        headingRank={headingRank}
        href={href}
        size="large"
        renderContent={content => (
          <div className={getClassName({ descendantName: "content" })}>
            <div className={getClassName({ descendantName: "image" })}>
              <Image
                alt=""
                anchor={imgAnchor}
                fit="cover"
                // Although paired cards are most likely to be used in a 1/3
                // grid item, this assumes the card is used in a 2/3 grid
                // column. We make this assumption as we can't identify the
                // context in which the card is used. This will slightly affect
                // performance, as larger images may be loaded than necessary.
                sizes={Image.sizes.twoThirds}
                src={imgSrc}
                srcSet={imgSrcSet}
              />
            </div>

            <div className={getClassName({ descendantName: "contentInner" })}>
              {content}
            </div>
          </div>
        )}
      />
    </div>
  );
};

PairedCard.displayName = "PairedCard";

PairedCard.headingRanks = TextCardBase.headingRanks;

/* eslint-disable max-len */
PairedCard.propTypes = {
  /**
   * Hide the border
   */
  borderless: PropTypes.bool,
  /**
   * Eyebrow text
   */
  eyebrow: PropTypes.string,
  /**
   * Footer. Should be a valid card footer, like ArticleCardFooter.
   */
  footer: PropTypes.node.isRequired,
  /**
   * Heading text
   */
  heading: PropTypes.string.isRequired,
  /**
   * Description text.
   */
  description: PropTypes.string,
  /**
   * Heading rank. 1 will render `<h1 />`, and so on. Defaults to 3.
   */
  headingRank: PropTypes.oneOf(PairedCard.headingRanks),
  /**
   * Card URL
   */
  href: PropTypes.string.isRequired,
  /**
   * Anchor point for the image.
   */
  imgAnchor: PropTypes.oneOf(anchorPoints),
  /**
   * Image `src`. Used if srcSet is not defined, or as a fallback.
   */
  imgSrc: PropTypes.string.isRequired,
  /**
   * A comma-separated list indicating a set of possible image sources for the image. The image will be fit to cover. This is used by the user agent to determine the correct image for different screen sizes. Same format as [srcSet](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/img#Example_4_Using_the_srcset_and_sizes_attributes), i.e. `my-image-200.png 200w, my-image-200.png 200w`.
   */
  imgSrcSet: PropTypes.string,
  /**
   * Metadata content object array with label and value
   */
  metaData: PropTypes.array
};
/* eslint-enable max-len */

PairedCard.defaultProps = {
  headingRank: "3",
  imgGradient: false,
  imgAnchor: "C"
};

export default PairedCard;
