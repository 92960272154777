import PropTypes from "prop-types";
import React from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import Heading from "@emcm-ui/component-heading";

import NoResults from "./components/NoResults";
import Result from "./components/Result";

const SearchResults = ({ children, title }) => {
  const getClassName = getClassNameFactory(SearchResults.displayName);

  return (
    <div className={getClassName()}>
      <div className={getClassName({ descendantName: "title" })}>
        <Heading rank="2" type="s">
          {title}
        </Heading>
      </div>
      <div className={getClassName({ descendantName: "results" })}>
        {children}
      </div>
    </div>
  );
};

SearchResults.displayName = "SearchResults";

SearchResults.propTypes = {
  /**
   * Children nodes. Either `SearchResults.Result` or `SearchResults.NoResults`.
   */
  children: PropTypes.node.isRequired,
  /**
   * Title text.
   */
  title: PropTypes.string.isRequired
};

SearchResults.NoResults = NoResults;
SearchResults.Result = Result;

export default SearchResults;
