/* eslint-disable no-magic-numbers */
const chartConfig = ({
  data,
  labels,
  colors,
  legends,
  chartTitle,
  options,
  tooltipTexts,
  type,
  numberOfGrids
}) => {
  const isHorizontalBar = type === "horizontalBar";
  const isDoughnut = type === "doughnut";
  const tooltipConfig =
    isDoughnut || isHorizontalBar ? { yAlign: "right" } : {};

  const updColors = colors.length === 1 ? colors[0] : colors;
  const updLegends = legends.length === 1 ? legends[0] : legends;
  const max =
    Math.ceil(Math.max(...data) / (numberOfGrids * 10)) * (numberOfGrids * 10);
  const stepSize = Math.ceil(max / numberOfGrids);

  const sizeConfig = max && {
    max,
    stepSize
  };

  const chartOptions =
    Object.keys(options).length !== 0 && options.constructor !== Object
      ? options
      : {
          responsive: true,
          maintainAspectRatio: false,
          cutoutPercentage: 75,
          scales: {
            xAxes: [
              {
                stacked: true,
                display: !isDoughnut && !isHorizontalBar,
                gridLines: {
                  display: false,
                  drawBorder: false
                },
                ticks: {
                  fontSize: 13,
                  precision: 0
                }
              }
            ],
            yAxes: [
              {
                stacked: true,
                display: !isDoughnut && !isHorizontalBar,
                gridLines: {
                  display: !isDoughnut && !isHorizontalBar,
                  drawBorder: false
                },
                ticks: {
                  fontSize: 13,
                  padding: 10,
                  precision: 0,
                  beginAtZero: true,
                  ...sizeConfig
                }
              }
            ]
          },
          layout: {
            padding: {
              left: 40,
              right: 40,
              top: 20,
              bottom: 20
            }
          },
          legend: {
            display: !isDoughnut && !isHorizontalBar,
            position: isDoughnut ? "right" : "bottom",
            labels: {
              fontSize: 13
            },
            align: "center"
          },
          title: {
            display: !isDoughnut && !isHorizontalBar,
            text: chartTitle,
            fontSize: 15,
            lineHeight: 1.6,
            fontWeight: "500"
          },
          tooltips: {
            backgroundColor: "#FFF",
            borderColor: "#dddddd",
            borderWidth: 1,
            callbacks: {
              labelTextColor() {
                return "#543453";
              },
              title(tooltipItem) {
                const { index, label } = tooltipItem[0];

                return tooltipTexts[index] || label;
              }
            },
            labelTextColors: "#4F4F4F",
            titleFontColor: "#4F4F4F",
            titleFontSize: 15,
            titleMarginBottom: 15,
            xPadding: 20,
            yPadding: 20,
            ...tooltipConfig
          }
        };
  const getDataSet = (value, dataSetColors, dataSetLegend) => {
    return {
      label: dataSetLegend,
      fill: false,
      lineTension: 0.1,
      backgroundColor: dataSetColors,
      barThickness: 20,
      borderColor: dataSetColors,
      borderWidth: 0,
      borderCapStyle: "butt",
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: "miter",
      pointBorderColor: dataSetColors,
      pointBackgroundColor: dataSetColors,
      pointBorderWidth: 3,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: dataSetColors,
      pointHoverBorderColor: dataSetColors,
      pointHoverBorderWidth: 2,
      pointRadius: 3,
      pointHitRadius: 5,
      data: value
    };
  };
  const getDataSetList = () => {
    return isHorizontalBar ||
      (typeof data[0] === "object" && data[0].length > 0)
      ? data.map((value, index) => {
          const updatedValue =
            typeof value === "object" && value.length > 0 ? value : [value];

          return getDataSet(
            updatedValue,
            updColors[index] || updColors,
            updLegends[index] || updLegends
          );
        })
      : [getDataSet(data, updColors, updLegends)];
  };
  const chartData = {
    labels,
    datasets: getDataSetList()
  };

  return {
    chartData,
    options: chartOptions
  };
};

export default chartConfig;
