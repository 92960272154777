import PropTypes from "prop-types";
import React, { Component } from "react";
import classNames from "classnames";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import { SVGIcon } from "@emcm-ui/component-icon/lib/svg";

const getFocusableDescendants = node => {
  /*
   * this is based on the code provided by:
   * https://www.w3.org/TR/wai-aria-practices-1.1/examples/dialog-modal/dialog.html
   * at `aria.Utils.isFocusable`
   */
  const selector = [
    "a:not([disabled])",
    "button:not([disabled])",
    "[href]",
    "input:not([disabled])",
    "select:not([disabled])",
    "textarea:not([disabled])",
    "[tabindex]:not([tabindex='-1'])"
  ].join(", ");

  return node.querySelectorAll(selector);
};

class Panel extends Component {
  static propTypes = {
    /**
     * Content to show inside the Panel.
     */
    children: PropTypes.node,

    /**
     * Callback when back button is activated
     */
    onBack: PropTypes.func,

    /**
     * Whether or not this panel is open
     */
    open: PropTypes.bool,

    /**
     * Title to show in the bar.
     */
    title: PropTypes.string
  };

  static getDerivedStateFromProps(props, state) {
    if (props.open) {
      return {
        animating: true,
        closed: false
      };
    }

    if (!props.open && state.closed === false) {
      return {
        animating: true,
        closed: true
      };
    }

    return {};
  }

  constructor(props) {
    super(props);

    this.panelRef = React.createRef();
    this.menuBackRef = React.createRef();
    this.barRef = React.createRef();

    this.state = {
      // this.state.closed is trinary:
      // - null: has not been opened, therefore has not been closed. Default.
      // - false: the menu is open.
      // - true: the menu had been opened previously, but is now closed.
      // This assists in animating correctly.
      closed: null
    };

    this.handleAnimationEnd = this.handleAnimationEnd.bind(this);
  }

  componentDidMount() {
    this.lastFocusEl = this.barRef;
    this.barRef.current.focus();
  }

  componentDidUpdate() {
    if (this.props.open) {
      this.menuBackRef.focus();
      document.addEventListener("focus", this.handlePanelFocus, true);
    } else {
      document.removeEventListener("focus", this.handlePanelFocus, true);
    }
  }

  componentWillUnmount() {
    document.removeEventListener("focus", this.handlePanelFocus, true);
  }

  handleAnimationEnd() {
    this.setState({ animating: false });
  }

  handlePanelFocus = event => {
    const isTargetWithinModal = this.panelRef.current.contains(event.target);

    if (isTargetWithinModal) {
      return;
    }

    const focusableDialogDescendants = getFocusableDescendants(
      this.panelRef.current
    );

    if (!focusableDialogDescendants.length) {
      this.barRef.current.focus();

      return;
    }

    if (this.lastFocusEl === focusableDialogDescendants[0]) {
      focusableDialogDescendants[focusableDialogDescendants.length - 1].focus();
    } else {
      focusableDialogDescendants[0].focus();
    }
  };

  render() {
    const { children, onBack, open, title } = this.props;
    const { animating, closed } = this.state;
    const getClassName = getClassNameFactory("SiteHeaderPanel");

    return (
      <div
        className={getClassName({
          states: classNames({
            animating,
            closed,
            open
          })
        })}
        onAnimationEnd={this.handleAnimationEnd}
        ref={this.panelRef}
      >
        <div
          className={getClassName({ descendantName: "bar" })}
          ref={this.barRef}
        >
          <button
            aria-label="Back"
            className={getClassName({ descendantName: "backButton" })}
            onClick={onBack}
            ref={ref => (this.menuBackRef = ref)}
            tabIndex="0"
          >
            <SVGIcon name="caret" size="s" />
          </button>

          <div className={getClassName({ descendantName: "barTitle" })}>
            {title}
          </div>
        </div>
        <div className={getClassName({ descendantName: "inner" })}>
          {children}
        </div>
      </div>
    );
  }
}

export default Panel;
