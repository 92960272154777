import md5 from "blueimp-md5";

const canFireEvents = () =>
  typeof window !== "undefined" && window.digitalData && window.PubSub;

const setAnalyticsAttributes = nodes => {
  Object.keys(nodes)
    .filter(param => param)
    .forEach(key => {
      window.digitalData.page.form[key] = nodes[key];
    });
};

const transformNodesRewriteValues = datasets => {
  Object.fromEntries = arr =>
    Object.assign({}, ...Array.from(arr, ([key, value]) => ({ [key]: value })));

  return Object.fromEntries(
    Object.entries(datasets).map(([key, value]) => {
      const queryValue = document.querySelector(`[name=${value}]`) || {};

      return [key, queryValue.value || null];
    })
  );
};

export const ensureFormObjectInitialized = () => {
  if (!window.digitalData.page) {
    window.digitalData.page = {};
  }

  if (!window.digitalData.page.form) {
    window.digitalData.page.form = {};
  }
};

export const fireFormStart = ({ name = "", type = "" }) => {
  if (canFireEvents()) {
    ensureFormObjectInitialized();

    window.digitalData.page.form.formName = name;
    window.digitalData.page.form.formType = type;

    window.PubSub.publish("analytics.formStart", {});
  }
};

export const fireFormSubmitSuccess = ({
  hashableIdentifier = false,
  name = "",
  nodes = {},
  type = ""
}) => {
  if (canFireEvents()) {
    ensureFormObjectInitialized();
    setAnalyticsAttributes(transformNodesRewriteValues(nodes));

    window.digitalData.page.form.formName = name;
    window.digitalData.page.form.formType = type;

    if (hashableIdentifier) {
      window.digitalData.page.form.formEmail = md5(hashableIdentifier);
    }

    window.PubSub.publish("analytics.formSubmit", {});
    window.PubSub.publish("analytics.formSuccess", {});
  }
};

export const fireFormSubmitFailure = ({
  invalidFieldNames = [],
  name = "",
  nodes = {},
  type = ""
}) => {
  if (canFireEvents()) {
    ensureFormObjectInitialized();
    setAnalyticsAttributes(transformNodesRewriteValues(nodes));
    window.digitalData.page.form.formName = name;
    window.digitalData.page.form.formType = type;
    window.digitalData.page.form.formError = invalidFieldNames.join(",");

    window.PubSub.publish("analytics.formSubmit", {});
    window.PubSub.publish("analytics.formError", {});
  }
};
