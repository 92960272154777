import PropTypes from "prop-types";
import React from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import Tooltip from "@emcm-ui/component-tooltip";

const Item = ({
  eyebrow,
  value,
  description,
  iconType,
  state,
  tooltipText,
  tooltipIcon,
  children
}) => {
  const getClassName = getClassNameFactory("DataCallout");

  const descendant =
    state === "Increase" ? "itemIconIncrease" : "itemIconDecrease";

  const IconComponent = iconType ? (
    <div
      className={getClassName({
        descendantName: descendant
      })}
    >
      <span className={getClassName({ descendantName: "icon" })}>
        {iconType}
      </span>
      <span className={getClassName({ descendantName: "inner" })}>
        {description}
      </span>
    </div>
  ) : null;

  return (
    <li className={getClassName({ descendantName: "item" })}>
      <div className={getClassName({ descendantName: "section" })}>
        {eyebrow && (
          <div className={getClassName({ descendantName: "eyebrow" })}>
            {eyebrow}
          </div>
        )}

        <div className={getClassName({ descendantName: "itemValue" })}>
          {value}
        </div>
        {iconType ? (
          IconComponent
        ) : (
          <div className={getClassName({ descendantName: "itemDescription" })}>
            {description}
          </div>
        )}
        {children ? (
          <div className={getClassName({ descendantName: "itemBody" })}>
            {children}
          </div>
        ) : null}
      </div>
      {tooltipText && (
        <Tooltip tooltipText={tooltipText} position="top">
          {tooltipIcon}
        </Tooltip>
      )}
    </li>
  );
};

Item.displayName = "DataCallout.Item";

Item.propTypes = {
  /**
   * Value description
   */
  description: PropTypes.string.isRequired,
  /**
   * eyebrow of the Value
   */
  eyebrow: PropTypes.string,
  /**
   * type of the icon
   */
  iconType: PropTypes.node,
  /**
   * the state of icon
   */
  state: PropTypes.oneOf(["Increase", "Decrease"]),
  /**
   * width of the icon
   */
  iconWidth: PropTypes.number,
  /**
   * text on hover of tooltip
   */
  tooltipText: PropTypes.string,
  /**
   * Value
   */
  value: PropTypes.string.isRequired,
  /**
   * Child node, consisting of DateTime component.
   */
  children: PropTypes.node,
  /**
   * Tooltip icon
   */
  tooltipIcon: PropTypes.node
};

export default Item;
