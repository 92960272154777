import React from "react";
import PropTypes from "prop-types";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import classNames from "classnames";

const NavList = ({ children, title }) => {
  const getClassName = getClassNameFactory("GlobalHeaderNavList");

  return (
    <div
      className={getClassName({
        modifiers: classNames({
          noTitle: !title
        })
      })}
    >
      {title ? (
        <h3
          className={`${getClassName({
            descendantName: "title"
          })} u-typographySmallCaps`}
        >
          {title}
        </h3>
      ) : null}
      <ul className={getClassName({ descendantName: "items" })}>{children}</ul>
    </div>
  );
};

NavList.propTypes = {
  /**
   * The contents of this `NavList`. These should be `GlobalHeader.NavItem`s.
   */
  children: PropTypes.node.isRequired,

  /**
   * The title to display above the list.
   */
  title: PropTypes.string
};

NavList.displayName = "GlobalHeader.NavList";

export default NavList;
