import PropTypes from "prop-types";
import React, { Component } from "react";
import classNames from "classnames";
import DateTime from "../../component-date-time/src";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import Heading from "@emcm-ui/component-heading";

import getRehydratableName from "@emcm-ui/utility-rehydratable-name";

class SharePriceTracker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {}
    };
  }

  static displayName = "SharePriceTracker";

  static propTypes = {
    /**
     * The share price tracker title.
     */
    title: PropTypes.string,
    /**
     * Background color
     */
    backgroundType: PropTypes.string,
    /**
     *To get share price data by api
     */
    apiUrl: PropTypes.string,
    /**
     *To set share price tracker on absolute postion
     */
    overlay: PropTypes.bool,
    /**
     *To set date time format
     */
    format: PropTypes.string
  };

  componentDidMount() {
    if (this.props.apiUrl) {
      fetch(this.props.apiUrl, {
        method: "GET",
        credentials: "same-origin"
      })
        .then(response => {
          if (response.ok) {
            return response.json();
          }
        })
        .then(json => {
          this.setState({
            data: json.data
          });
        })
        .catch(err => {
          return err;
        });
    }
  }

  numberWithCommas = (value, cur) => {
    const formatter = new Intl.NumberFormat("en-US", {
      currency: cur,
      minimumFractionDigits: 1
    });

    return formatter.format(value);
  };

  getClassName = getClassNameFactory(SharePriceTracker.displayName);

  getPriceChangeClass = price => {
    if (price === 0 || !price) {
      return "";
    }

    if (price > 0) {
      return "positive";
    }

    return "negative";
  };

  getPriceChangeData = (price, sign = "") => {
    if (price === 0 || !price) {
      return `0.0${sign}`;
    }

    if (price > 0) {
      return `+${price.toFixed(1)}${sign}`;
    }

    return `${price.toFixed(1)}${sign}`;
  };

  getFormattedDate = (thedate, format) => {
    const iso = new Date(thedate).toISOString();
    const theiso = iso.replace("Z", "");

    return <DateTime dateTime={theiso} format={format} />;
  };

  render() {
    const {
      backgroundType = "light",
      overlay,
      format = "dd MMM, p",
      title
    } = this.props;

    const isMarketOpen =
      this.state.data && this.getPriceChangeClass(this.state.data.price) !== "";

    const {
      price,
      currency,
      netChange,
      percentualChange,
      code,
      time
    } = this.state.data;

    return (
      <div
        className={this.getClassName({
          descendantName: "sharePriceCurrency"
        })}
        data-rehydratable={getRehydratableName(SharePriceTracker.displayName)}
        data-api-url={this.props.apiUrl}
        data-date-format={this.props.format}
        data-overlay={this.props.overlay}
        data-background-type={this.props.backgroundType}
        data-title={this.props.title}
      >
        {isMarketOpen && (
          <div>
            {title && <Heading type="xxs">{title}</Heading>}
            <div
              className={this.getClassName({
                modifiers: classNames(
                  "container",
                  backgroundType,
                  overlay ? "tracker" : "tool"
                )
              })}
              data-rehydratable={getRehydratableName(
                SharePriceTracker.displayName
              )}
            >
              <div>
                {!overlay && (
                  <span
                    className={this.getClassName({
                      descendantName: "code"
                    })}
                  >
                    {code}:
                  </span>
                )}

                <span
                  className={this.getClassName({
                    descendantName: "price"
                  })}
                >
                  {this.numberWithCommas(price, currency)}
                </span>

                <span
                  className={this.getClassName({
                    descendantName: "currency"
                  })}
                >
                  {currency}
                </span>

                <span
                  className={this.getClassName({
                    modifiers: classNames(
                      "shareValues",
                      this.getPriceChangeClass(netChange)
                    )
                  })}
                >
                  {this.getPriceChangeData(netChange)}
                </span>

                <span
                  className={this.getClassName({
                    modifiers: classNames(
                      "shareValues",
                      this.getPriceChangeClass(percentualChange)
                    )
                  })}
                >
                  ({this.getPriceChangeData(percentualChange, "%")})
                </span>
              </div>
              <div
                className={this.getClassName({
                  descendantName: "calendar"
                })}
              >
                {overlay && (
                  <span
                    className={this.getClassName({
                      descendantName: "title"
                    })}
                  >
                    {code}
                  </span>
                )}

                {time && (
                  <span
                    className={this.getClassName({
                      descendantName: "date"
                    })}
                  >
                    {this.getFormattedDate(time, format)} GMT
                  </span>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

SharePriceTracker.defaultProps = {
  backgroundType: "light",
  overlay: true,
  format: "dd MMM, hh:mm z",
  title: null
};

export default SharePriceTracker;
