import React from "react";
import { storiesOf } from "@storybook/react";
import {
  withKnobs,
  boolean,
  text,
  select,
  number
} from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";
import StaticRenderer from "@emcm-ui/component-static-renderer";
import SiteHeader from "./index";
import Block from "@emcm-ui/component-block";
import Eyebrow from "@emcm-ui/component-eyebrow";
import GlobalHeader from "@emcm-ui/component-global-header";
import loginManager from "../../component-global-header/design-system/components/loginManager";
import Heading from "@emcm-ui/component-heading";
import Link from "@emcm-ui/component-link";
import Icon from "@emcm-ui/component-icon";
import Poster from "@emcm-ui/component-poster";
import RichText from "@emcm-ui/component-rich-text";
import Section from "@emcm-ui/component-section";
import VerticalSpacing from "@emcm-ui/component-vertical-spacing";
import { Language } from "@emcm-ui/component-theme";
import "./SiteHeader.css";
import SiteHeaderREADME from "../README.md";

import DemoLauncher from "../../design-system/src/components/DemoLauncher";
import FullPageDemo from "../../design-system/src/components/FullPageDemo";

const stories = storiesOf("Components/SiteHeader", module);

const logoOptions = {
  refinitiv: null,
  lseg: "../images/lseg-brand-logo.svg",
  "refinitiv with strapline": "../images/refinitiv-with-stripeline.svg"
};

const elasticURL =
  "https://run.mocky.io/v3/fa0967b7-8058-4356-a92f-48d2197205e2?";

const elasticResultSizeLimit = 7;

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(SiteHeaderREADME));

const getQueryParamValByName = param => {
  const params = new URLSearchParams(window.top.document.location.search);

  return params.get(param);
};

const featuredItem = (
  <SiteHeader.FeaturedItem
    imageSrc="test-images/16-9/large_1.jpg"
    imageSrcSet="test-images/16-9/large_1.jpg 2000w, test-images/16-9/medium_1.jpg 800w, test-images/16-9/small_1.jpg 400w"
  >
    <VerticalSpacing size="xs">
      <Eyebrow text="Smallcaps" />
    </VerticalSpacing>
    <VerticalSpacing size="s">
      <Heading rank="4" type="xs">
        <b>Feature headline example text</b>
      </Heading>
    </VerticalSpacing>
    <VerticalSpacing size="s">
      <RichText>
        <p>
          Thomson Reuters provides professionals with the intelligence,
          technology and human expertise they need to find trusted answers.
        </p>
      </RichText>
    </VerticalSpacing>
    <Link
      href="https://example.com"
      type="icon"
      icon={<Icon path="./icons/ui/blue/arrow-right.svg" size="s" />}
      iconPosition={"right"}
    >
      <strong>Read More</strong>
    </Link>
  </SiteHeader.FeaturedItem>
);

/**
 * Two sets of stories:
 * - For Storybook (SB)
 * - For the DesignSystem, with a custom full-page modal
 */
stories
  .add("(SB) Default", () => (
    <SiteHeader
      logoHref="#"
      logoPath={select("Logo", logoOptions, null)}
      search={boolean("enable search", true)}
      searchFormAction={text("searchFormAction") || null}
      searchFormMethod={text("searchFormMethod", "get")}
      searchInputName={text("searchInputName", "q")}
      siteName={text("siteName", "")}
      typeaheadUrl={
        boolean("enable typeahead", true) &&
        text(
          "typeaheadUrl",
          "//content.atomz.com/autocomplete/sp10/05/0d/51?_=1506335725065"
        )
      }
    >
      <SiteHeader.Menu
        title="Solutions"
        datasets={`{"data-event-type": "String Six","data-link-type":"stringOne","data-asset-title":"stringTwo","data-href-analytics":"stringThree","data-link-text":"stringFour","data-find-method":"stringFive"}`}
        fallbackHref="https://www.example.com"
      >
        <SiteHeader.Section
          title="Solutions list 1"
          relatedUrl="https://www.example.com"
          relatedName="See All"
          isHighlighted={true}
        >
          <SiteHeader.Column>
            <SiteHeader.NavList>
              <SiteHeader.NavItem
                title="My item 1"
                href="#"
                isExternal={true}
                description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
              />
              <SiteHeader.NavItem title="My item 2" href="#" />
              <SiteHeader.NavItem title="My item 3" href="#" />
              <SiteHeader.NavItem title="My item 4" href="#" />
              <SiteHeader.NavItem title="My item 4.1" href="#" />
              <SiteHeader.NavItem title="My item 4.2" href="#" />
              <SiteHeader.NavItem title="My item 4.3" href="#" />
              <SiteHeader.NavItem title="My item 4.4" href="#" />
            </SiteHeader.NavList>
          </SiteHeader.Column>
        </SiteHeader.Section>
        <SiteHeader.SectionStack>
          <SiteHeader.Section title="Solutions list 2">
            <SiteHeader.Column>
              <SiteHeader.NavList>
                <SiteHeader.NavItem title="My item 5" href="#" />
              </SiteHeader.NavList>
            </SiteHeader.Column>
          </SiteHeader.Section>
          <SiteHeader.Section
            title="Solutions list 3"
            // relatedUrl="https://www.example.com"
            // relatedName="See All"
          >
            <SiteHeader.Column>
              <SiteHeader.NavList>
                <SiteHeader.NavItem title="My item 5" href="#" />
                <SiteHeader.NavItem title="My item 6" href="#" />
                <SiteHeader.NavItem title="My item 7" href="#" />
                <SiteHeader.NavItem title="My item 8" href="#" />
                <SiteHeader.NavItem title="My item 8.1" href="#" />
                <SiteHeader.NavItem title="My item 8.2" href="#" />
                <SiteHeader.NavItem title="My item 8.3" href="#" />
                <SiteHeader.NavItem title="My item 8.4" href="#" />
                <SiteHeader.NavItem title="My item 8.5" href="#" />
                <SiteHeader.NavItem title="My item 8.6" href="#" />
                <SiteHeader.NavItem title="My item 8.7" href="#" />
              </SiteHeader.NavList>
            </SiteHeader.Column>
          </SiteHeader.Section>
        </SiteHeader.SectionStack>
        <SiteHeader.Section
          title="Solutions list 4"
          relatedUrl="https://www.example.com"
          relatedName="See All"
        >
          <SiteHeader.Column>
            <SiteHeader.NavList>
              <SiteHeader.NavItem title="My item 9" href="#" />
              <SiteHeader.NavItem title="My item 10" href="#" />
              <SiteHeader.NavItem title="My item 11" href="#" />
              <SiteHeader.NavItem title="My item 12" href="#" />
            </SiteHeader.NavList>
          </SiteHeader.Column>
        </SiteHeader.Section>
        <SiteHeader.Section title="Featured Item">
          <SiteHeader.Column>{featuredItem}</SiteHeader.Column>
        </SiteHeader.Section>
      </SiteHeader.Menu>
      <SiteHeader.Menu title="Articles" fallbackHref="https://www.example.com">
        <SiteHeader.Section title="Articles" columns={2}>
          <SiteHeader.Column>
            <SiteHeader.NavList>
              <SiteHeader.NavItem title="My item 13" href="#" />
              <SiteHeader.NavItem
                title="My item 14 with a very long title"
                href="#"
              />
              <SiteHeader.NavItem title="My item 15" href="#" />
              <SiteHeader.NavItem title="My item 16" href="#" />
            </SiteHeader.NavList>
          </SiteHeader.Column>
          <SiteHeader.Column>
            <SiteHeader.NavList>
              <SiteHeader.NavItem title="My item 17" href="#" />
              <SiteHeader.NavItem title="My item 18" href="#" />
              <SiteHeader.NavItem title="My item 19" href="#" />
              <SiteHeader.NavItem title="My item 20" href="#" />
            </SiteHeader.NavList>
          </SiteHeader.Column>
        </SiteHeader.Section>
      </SiteHeader.Menu>
      <SiteHeader.Menu title="About us" fallbackHref="https://www.example.com">
        <SiteHeader.Section title="The Company">
          <SiteHeader.Column>
            <SiteHeader.NavList>
              <SiteHeader.NavItem title="My item 21" href="#" />
              <SiteHeader.NavItem title="My item 22" href="#" />
              <SiteHeader.NavItem title="My item 23" href="#" />
              <SiteHeader.NavItem title="My item 24" href="#" />
            </SiteHeader.NavList>
          </SiteHeader.Column>
        </SiteHeader.Section>
      </SiteHeader.Menu>
      <SiteHeader.Menu title="Careers" fallbackHref="https://www.example.com">
        <SiteHeader.Section>
          <SiteHeader.Column>
            <SiteHeader.NavList>
              <SiteHeader.NavItem title="My item 21" href="#" />
              <SiteHeader.NavItem title="My item 22" href="#" />
              <SiteHeader.NavItem title="My item 23" href="#" />
              <SiteHeader.NavItem title="My item 24" href="#" />
            </SiteHeader.NavList>
          </SiteHeader.Column>
        </SiteHeader.Section>
      </SiteHeader.Menu>
      <SiteHeader.Menu title="External" href="https://www.example.com" />
    </SiteHeader>
  ))
  .add("(SB) Logo Path", () => (
    <SiteHeader
      logoHref="#"
      logoPath={select("Logo", logoOptions, "../images/lseg-brand-logo.svg")}
      search={boolean("enable search", true)}
      searchFormAction={text("searchFormAction") || null}
      searchFormMethod={text("searchFormMethod", "get")}
      searchInputName={text("searchInputName", "q")}
      siteName={text("siteName", "")}
      typeaheadUrl={
        boolean("enable typeahead", true) &&
        text(
          "typeaheadUrl",
          "//content.atomz.com/autocomplete/sp10/05/0d/51?_=1506335725065"
        )
      }
    >
      <SiteHeader.Menu
        title="Solutions"
        datasets={`{"data-event-type": "String Six","data-link-type":"stringOne","data-asset-title":"stringTwo","data-href-analytics":"stringThree","data-link-text":"stringFour","data-find-method":"stringFive"}`}
        fallbackHref="https://www.example.com"
      >
        <SiteHeader.Section
          title="Solutions list 1"
          relatedUrl="https://www.example.com"
          relatedName="See All"
        >
          <SiteHeader.Column>
            <SiteHeader.NavList>
              <SiteHeader.NavItem title="My item 1" href="#" />
              <SiteHeader.NavItem title="My item 2" href="#" />
              <SiteHeader.NavItem title="My item 3" href="#" />
              <SiteHeader.NavItem title="My item 4" href="#" />
              <SiteHeader.NavItem title="My item 4.1" href="#" />
              <SiteHeader.NavItem title="My item 4.2" href="#" />
              <SiteHeader.NavItem title="My item 4.3" href="#" />
              <SiteHeader.NavItem title="My item 4.4" href="#" />
            </SiteHeader.NavList>
          </SiteHeader.Column>
        </SiteHeader.Section>
        <SiteHeader.SectionStack>
          <SiteHeader.Section title="Solutions list 2">
            <SiteHeader.Column>
              <SiteHeader.NavList>
                <SiteHeader.NavItem title="My item 5" href="#" />
              </SiteHeader.NavList>
            </SiteHeader.Column>
          </SiteHeader.Section>
          <SiteHeader.Section
            title="Solutions list 3"
            // relatedUrl="https://www.example.com"
            // relatedName="See All"
          >
            <SiteHeader.Column>
              <SiteHeader.NavList>
                <SiteHeader.NavItem title="My item 5" href="#" />
                <SiteHeader.NavItem title="My item 6" href="#" />
                <SiteHeader.NavItem title="My item 7" href="#" />
                <SiteHeader.NavItem title="My item 8" href="#" />
                <SiteHeader.NavItem title="My item 8.1" href="#" />
                <SiteHeader.NavItem title="My item 8.2" href="#" />
                <SiteHeader.NavItem title="My item 8.3" href="#" />
                <SiteHeader.NavItem title="My item 8.4" href="#" />
                <SiteHeader.NavItem title="My item 8.5" href="#" />
                <SiteHeader.NavItem title="My item 8.6" href="#" />
                <SiteHeader.NavItem title="My item 8.7" href="#" />
              </SiteHeader.NavList>
            </SiteHeader.Column>
          </SiteHeader.Section>
        </SiteHeader.SectionStack>
        <SiteHeader.Section
          title="Solutions list 4"
          relatedUrl="https://www.example.com"
          relatedName="See All"
        >
          <SiteHeader.Column>
            <SiteHeader.NavList>
              <SiteHeader.NavItem title="My item 9" href="#" />
              <SiteHeader.NavItem title="My item 10" href="#" />
              <SiteHeader.NavItem title="My item 11" href="#" />
              <SiteHeader.NavItem title="My item 12" href="#" />
            </SiteHeader.NavList>
          </SiteHeader.Column>
        </SiteHeader.Section>
        <SiteHeader.Section title="Featured Item">
          <SiteHeader.Column>{featuredItem}</SiteHeader.Column>
        </SiteHeader.Section>
      </SiteHeader.Menu>
      <SiteHeader.Menu title="Articles" fallbackHref="https://www.example.com">
        <SiteHeader.Section title="Articles" columns={2}>
          <SiteHeader.Column>
            <SiteHeader.NavList>
              <SiteHeader.NavItem title="My item 13" href="#" />
              <SiteHeader.NavItem
                title="My item 14 with a very long title"
                href="#"
              />
              <SiteHeader.NavItem title="My item 15" href="#" />
              <SiteHeader.NavItem title="My item 16" href="#" />
            </SiteHeader.NavList>
          </SiteHeader.Column>
          <SiteHeader.Column>
            <SiteHeader.NavList>
              <SiteHeader.NavItem title="My item 17" href="#" />
              <SiteHeader.NavItem title="My item 18" href="#" />
              <SiteHeader.NavItem title="My item 19" href="#" />
              <SiteHeader.NavItem title="My item 20" href="#" />
            </SiteHeader.NavList>
          </SiteHeader.Column>
        </SiteHeader.Section>
      </SiteHeader.Menu>
      <SiteHeader.Menu title="About us" fallbackHref="https://www.example.com">
        <SiteHeader.Section title="The Company">
          <SiteHeader.Column>
            <SiteHeader.NavList>
              <SiteHeader.NavItem title="My item 21" href="#" />
              <SiteHeader.NavItem title="My item 22" href="#" />
              <SiteHeader.NavItem title="My item 23" href="#" />
              <SiteHeader.NavItem title="My item 24" href="#" />
            </SiteHeader.NavList>
          </SiteHeader.Column>
        </SiteHeader.Section>
      </SiteHeader.Menu>
      <SiteHeader.Menu title="Careers" fallbackHref="https://www.example.com">
        <SiteHeader.Section>
          <SiteHeader.Column>
            <SiteHeader.NavList>
              <SiteHeader.NavItem title="My item 21" href="#" />
              <SiteHeader.NavItem title="My item 22" href="#" />
              <SiteHeader.NavItem title="My item 23" href="#" />
              <SiteHeader.NavItem title="My item 24" href="#" />
            </SiteHeader.NavList>
          </SiteHeader.Column>
        </SiteHeader.Section>
      </SiteHeader.Menu>
      <SiteHeader.Menu title="External" href="https://www.example.com" />
    </SiteHeader>
  ))
  .add("(SB) Fully Comp'd", () => (
    <React.Fragment>
      <GlobalHeader
        leftItems={<React.Fragment />}
        rightItems={
          <React.Fragment>
            <GlobalHeader.Login
              accountLabel="Account"
              accountOverviewLabel="Account overview"
              accountOverviewIcon={
                <Icon path="./icons/ui/grey/user-07.svg" size="s" />
              }
              helloLabel="Hello"
              id="login"
              loginLabel="Login"
              loginManager={loginManager}
              logoutLabel="Logout"
            />
            <GlobalHeader.Item label="Contact" href="/contact">
              <GlobalHeader.Column>
                <GlobalHeader.NavList>
                  <GlobalHeader.NavItem
                    title="Contact item 1"
                    href="#"
                    icon={<Icon path="./icons/ui/grey/tick-07.svg" size="s" />}
                  />
                  <GlobalHeader.NavItem
                    title="Contact item 2"
                    href="#"
                    icon={
                      <Icon path="./icons/ui/grey/document-07.svg" size="s" />
                    }
                  />
                  <GlobalHeader.NavItem
                    title="Contact item 3 with more text"
                    href="#"
                    icon={
                      <Icon path="./icons/ui/grey/unlocked-07.svg" size="s" />
                    }
                  />
                </GlobalHeader.NavList>
              </GlobalHeader.Column>
            </GlobalHeader.Item>
          </React.Fragment>
        }
        loginManager={loginManager}
      />
      <SiteHeader
        logoHref="#"
        search={boolean("enable search", true)}
        searchFormAction={text("searchFormAction") || null}
        searchFormMethod={text("searchFormMethod", "get")}
        searchInputName={text("searchInputName", "q")}
        siteName={text("siteName", "Tax &amp; Accounting")}
        typeaheadUrl={
          boolean("enable typeahead", true) &&
          text(
            "typeaheadUrl",
            "//content.atomz.com/autocomplete/sp10/05/0d/51?_=1506335725065"
          )
        }
      >
        <SiteHeader.Menu
          title="Solutions"
          fallbackHref="https://www.example.com"
        >
          <SiteHeader.Section
            title="Solutions list 1"
            relatedUrl="https://www.example.com"
            relatedName="See All"
          >
            <SiteHeader.Column>
              <SiteHeader.NavList>
                <SiteHeader.NavItem title="My item 1" href="#" />
                <SiteHeader.NavItem title="My item 2" href="#" />
                <SiteHeader.NavItem title="My item 3" href="#" />
                <SiteHeader.NavItem title="My item 4" href="#" />
              </SiteHeader.NavList>
            </SiteHeader.Column>
          </SiteHeader.Section>
          <SiteHeader.SectionStack>
            <SiteHeader.Section title="Solutions list 2">
              <SiteHeader.Column>
                <SiteHeader.NavList>
                  <SiteHeader.NavItem title="My item 5" href="#" />
                </SiteHeader.NavList>
              </SiteHeader.Column>
            </SiteHeader.Section>
            <SiteHeader.Section title="Solutions list 3">
              <SiteHeader.Column>
                <SiteHeader.NavList>
                  <SiteHeader.NavItem title="My item 6" href="#" />
                  <SiteHeader.NavItem title="My item 7" href="#" />
                </SiteHeader.NavList>
              </SiteHeader.Column>
            </SiteHeader.Section>
          </SiteHeader.SectionStack>
          <SiteHeader.Section
            title="Solutions list 4"
            relatedUrl="https://www.example.com"
            relatedName="See All"
          >
            <SiteHeader.Column>
              <SiteHeader.NavList>
                <SiteHeader.NavItem title="My item 8" href="#" />
                <SiteHeader.NavItem title="My item 9" href="#" />
                <SiteHeader.NavItem title="My item 10" href="#" />
                <SiteHeader.NavItem title="My item 11" href="#" />
              </SiteHeader.NavList>
            </SiteHeader.Column>
          </SiteHeader.Section>
          <SiteHeader.Section title="Featured Item">
            <SiteHeader.Column>{featuredItem}</SiteHeader.Column>
          </SiteHeader.Section>
        </SiteHeader.Menu>
        <SiteHeader.Menu
          title="Articles"
          fallbackHref="https://www.example.com"
        >
          <SiteHeader.Section title="Articles" columns={2}>
            <SiteHeader.Column>
              <SiteHeader.NavList>
                <SiteHeader.NavItem title="My item 13" href="#" />
                <SiteHeader.NavItem title="My item 14" href="#" />
                <SiteHeader.NavItem title="My item 15" href="#" />
                <SiteHeader.NavItem title="My item 16" href="#" />
              </SiteHeader.NavList>
            </SiteHeader.Column>
            <SiteHeader.Column>
              <SiteHeader.NavList>
                <SiteHeader.NavItem title="My item 17" href="#" />
                <SiteHeader.NavItem title="My item 18" href="#" />
                <SiteHeader.NavItem title="My item 19" href="#" />
                <SiteHeader.NavItem title="My item 20" href="#" />
              </SiteHeader.NavList>
            </SiteHeader.Column>
          </SiteHeader.Section>
        </SiteHeader.Menu>
        <SiteHeader.Menu
          title="About us"
          fallbackHref="https://www.example.com"
        >
          <SiteHeader.Section>
            <SiteHeader.Column>
              <SiteHeader.NavList>
                <SiteHeader.NavItem title="My item 21" href="#" />
                <SiteHeader.NavItem title="My item 22" href="#" />
                <SiteHeader.NavItem title="My item 23" href="#" />
                <SiteHeader.NavItem title="My item 24" href="#" />
              </SiteHeader.NavList>
            </SiteHeader.Column>
          </SiteHeader.Section>
        </SiteHeader.Menu>
        <SiteHeader.Menu title="Careers" fallbackHref="https://www.example.com">
          <SiteHeader.Section>
            <SiteHeader.Column>
              <SiteHeader.NavList>
                <SiteHeader.NavItem title="My item 21" href="#" />
                <SiteHeader.NavItem title="My item 22" href="#" />
                <SiteHeader.NavItem title="My item 23" href="#" />
                <SiteHeader.NavItem title="My item 24" href="#" />
              </SiteHeader.NavList>
            </SiteHeader.Column>
          </SiteHeader.Section>
        </SiteHeader.Menu>
        <SiteHeader.Menu title="External" href="https://www.example.com" />
      </SiteHeader>

      <Section background="grayDark" fullWidth>
        <Poster
          height="l"
          imageOverlay="scrimBottomDark"
          imageSrc="test-images/original/large_1.jpg"
          alignBottom
        />
      </Section>
    </React.Fragment>
  ))
  .add("(SB) Menus, as designed", () => (
    <SiteHeader
      logoHref="#"
      logoPath={select("Logo", logoOptions, null)}
      search={boolean("enable search", true)}
      searchFormAction={text("searchFormAction") || null}
      searchFormMethod={text("searchFormMethod", "get")}
      searchInputName={text("searchInputName", "q")}
      siteName={text("siteName", "Tax & Accounting")}
      typeaheadUrl={
        boolean("enable typeahead", true) &&
        text(
          "typeaheadUrl",
          "//content.atomz.com/autocomplete/sp10/05/0d/51?_=1506335725065"
        )
      }
    >
      <SiteHeader.Menu title="1col">
        <SiteHeader.Section
          title="Section title"
          relatedUrl="https://www.example.com"
          relatedName="See All"
        >
          <SiteHeader.Column>
            <SiteHeader.NavList>
              <SiteHeader.NavItem title="Link Title" href="#" />
              <SiteHeader.NavItem
                title="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla auctor arcu ipsum."
                href="#"
              />
              <SiteHeader.NavItem title="Link Title" href="#" />
              <SiteHeader.NavItem
                description="Description"
                title="Link Title"
                href="#"
              />
              <SiteHeader.NavItem
                description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla auctor arcu ipsum."
                title="Link Title"
                href="#"
              />
            </SiteHeader.NavList>
          </SiteHeader.Column>
        </SiteHeader.Section>
      </SiteHeader.Menu>
      <SiteHeader.Menu title="2col">
        <SiteHeader.Section
          title="Section title"
          relatedUrl="https://www.example.com"
          relatedName="See All"
        >
          <SiteHeader.Column>
            <SiteHeader.NavList>
              <SiteHeader.NavItem title="Link Title" href="#" />
              <SiteHeader.NavItem
                title="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla auctor arcu ipsum."
                href="#"
              />
              <SiteHeader.NavItem title="Link Title" href="#" />
              <SiteHeader.NavItem
                description="Description"
                title="Link Title"
                href="#"
              />
              <SiteHeader.NavItem
                description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla auctor arcu ipsum."
                title="Link Title"
                href="#"
              />
            </SiteHeader.NavList>
          </SiteHeader.Column>
        </SiteHeader.Section>
        <SiteHeader.SectionStack>
          <SiteHeader.Section title="Section title">
            <SiteHeader.Column>
              <SiteHeader.NavList>
                <SiteHeader.NavItem title="Link Title" href="#" />
                <SiteHeader.NavItem title="Link Title" href="#" />
                <SiteHeader.NavItem title="Link Title" href="#" />
              </SiteHeader.NavList>
            </SiteHeader.Column>
          </SiteHeader.Section>
          <SiteHeader.Section title="Section title">
            <SiteHeader.Column>
              <SiteHeader.NavList>
                <SiteHeader.NavItem title="Link Title" href="#" />
                <SiteHeader.NavItem
                  title="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla auctor arcu ipsum."
                  href="#"
                />
                <SiteHeader.NavItem title="Link Title" href="#" />
              </SiteHeader.NavList>
            </SiteHeader.Column>
          </SiteHeader.Section>
        </SiteHeader.SectionStack>
      </SiteHeader.Menu>
      <SiteHeader.Menu title="3col">
        <SiteHeader.Section
          title="Section title"
          relatedUrl="https://www.example.com"
          relatedName="See All"
        >
          <SiteHeader.Column>
            <SiteHeader.NavList>
              <SiteHeader.NavItem title="Link Title" href="#" />
              <SiteHeader.NavItem
                title="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla auctor arcu ipsum."
                href="#"
              />
              <SiteHeader.NavItem title="Link Title" href="#" />
              <SiteHeader.NavItem
                description="Description"
                title="Link Title"
                href="#"
              />
              <SiteHeader.NavItem
                description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla auctor arcu ipsum."
                title="Link Title"
                href="#"
              />
            </SiteHeader.NavList>
          </SiteHeader.Column>
        </SiteHeader.Section>
        <SiteHeader.SectionStack>
          <SiteHeader.Section title="Section title">
            <SiteHeader.Column>
              <SiteHeader.NavList>
                <SiteHeader.NavItem title="Link Title" href="#" />
                <SiteHeader.NavItem title="Link Title" href="#" />
                <SiteHeader.NavItem title="Link Title" href="#" />
              </SiteHeader.NavList>
            </SiteHeader.Column>
          </SiteHeader.Section>
          <SiteHeader.Section title="Section title">
            <SiteHeader.Column>
              <SiteHeader.NavList>
                <SiteHeader.NavItem title="Link Title" href="#" />
                <SiteHeader.NavItem
                  title="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla auctor arcu ipsum."
                  href="#"
                />
                <SiteHeader.NavItem title="Link Title" href="#" />
              </SiteHeader.NavList>
            </SiteHeader.Column>
          </SiteHeader.Section>
        </SiteHeader.SectionStack>
        <SiteHeader.Section title="Featured Item">
          <SiteHeader.Column>{featuredItem}</SiteHeader.Column>
        </SiteHeader.Section>
      </SiteHeader.Menu>
      <SiteHeader.Menu title="4col">
        <SiteHeader.SectionStack>
          <SiteHeader.Section title="Section title">
            <SiteHeader.Column>
              <SiteHeader.NavList>
                <SiteHeader.NavItem title="Link Title" href="#" />
                <SiteHeader.NavItem title="Link Title" href="#" />
              </SiteHeader.NavList>
            </SiteHeader.Column>
          </SiteHeader.Section>
          <SiteHeader.Section title="Section title">
            <SiteHeader.Column>
              <SiteHeader.NavList>
                <SiteHeader.NavItem title="Link Title" href="#" />
                <SiteHeader.NavItem
                  title="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla auctor arcu ipsum."
                  href="#"
                />
                <SiteHeader.NavItem title="Link Title" href="#" />
              </SiteHeader.NavList>
            </SiteHeader.Column>
          </SiteHeader.Section>
        </SiteHeader.SectionStack>
        <SiteHeader.Section
          title="Section title"
          relatedUrl="https://www.example.com"
          relatedName="See All"
        >
          <SiteHeader.Column>
            <SiteHeader.NavList>
              <SiteHeader.NavItem title="Link Title" href="#" />
              <SiteHeader.NavItem
                title="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla auctor arcu ipsum."
                href="#"
              />
              <SiteHeader.NavItem title="Link Title" href="#" />
              <SiteHeader.NavItem
                description="Description"
                title="Link Title"
                href="#"
              />
              <SiteHeader.NavItem
                description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla auctor arcu ipsum."
                title="Link Title"
                href="#"
              />
            </SiteHeader.NavList>
          </SiteHeader.Column>
        </SiteHeader.Section>
        <SiteHeader.SectionStack>
          <SiteHeader.Section title="Section title">
            <SiteHeader.Column>
              <SiteHeader.NavList>
                <SiteHeader.NavItem title="Link Title" href="#" />
                <SiteHeader.NavItem title="Link Title" href="#" />
              </SiteHeader.NavList>
            </SiteHeader.Column>
          </SiteHeader.Section>
          <SiteHeader.Section title="Section title">
            <SiteHeader.Column>
              <SiteHeader.NavList>
                <SiteHeader.NavItem title="Link Title" href="#" />
                <SiteHeader.NavItem
                  title="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla auctor arcu ipsum."
                  href="#"
                />
                <SiteHeader.NavItem title="Link Title" href="#" />
              </SiteHeader.NavList>
            </SiteHeader.Column>
          </SiteHeader.Section>
        </SiteHeader.SectionStack>
        <SiteHeader.Section title="Featured Item">
          <SiteHeader.Column>{featuredItem}</SiteHeader.Column>
        </SiteHeader.Section>
      </SiteHeader.Menu>
      <SiteHeader.Menu title="1-2">
        <SiteHeader.Section title="Section title">
          <SiteHeader.Column>
            <Block height="400px" />
          </SiteHeader.Column>
        </SiteHeader.Section>
        <SiteHeader.Section title="Section title" columns={2}>
          <SiteHeader.Column>
            <Block height="400px" />
          </SiteHeader.Column>
          <SiteHeader.Column>
            <Block height="400px" />
          </SiteHeader.Column>
        </SiteHeader.Section>
      </SiteHeader.Menu>
      <SiteHeader.Menu title="1-3">
        <SiteHeader.Section title="Section title">
          <SiteHeader.Column>
            <Block height="400px" />
          </SiteHeader.Column>
        </SiteHeader.Section>
        <SiteHeader.Section title="Section title" columns={3}>
          <SiteHeader.Column>
            <Block height="400px" />
          </SiteHeader.Column>
          <SiteHeader.Column>
            <Block height="400px" />
          </SiteHeader.Column>
          <SiteHeader.Column>
            <Block height="400px" />
          </SiteHeader.Column>
        </SiteHeader.Section>
      </SiteHeader.Menu>
      <SiteHeader.Menu title="2-2">
        <SiteHeader.Section title="Section title" columns={2}>
          <SiteHeader.Column>
            <Block height="400px" />
          </SiteHeader.Column>
          <SiteHeader.Column>
            <Block height="400px" />
          </SiteHeader.Column>
        </SiteHeader.Section>
        <SiteHeader.Section title="Section title" columns={2}>
          <SiteHeader.Column>
            <Block height="400px" />
          </SiteHeader.Column>
          <SiteHeader.Column>
            <Block height="400px" />
          </SiteHeader.Column>
        </SiteHeader.Section>
      </SiteHeader.Menu>
      <SiteHeader.Menu title="Flyouts" fallbackHref="https://www.example.com">
        <SiteHeader.Flyouts
          summaryLinkHref="https://example.com"
          summaryLinkText="Link Title"
        >
          <SiteHeader.Flyouts.TabPanel id="tab1" tabTitle="1col">
            <SiteHeader.SectionStack>
              <SiteHeader.Section title="Section title">
                <SiteHeader.Column>
                  <SiteHeader.NavList>
                    <SiteHeader.NavItem title="Link Title" href="#" />
                    <SiteHeader.NavItem title="Link Title" href="#" />
                    <SiteHeader.NavItem title="Link Title" href="#" />
                  </SiteHeader.NavList>
                </SiteHeader.Column>
              </SiteHeader.Section>
              <SiteHeader.Section title="Section title">
                <SiteHeader.Column>
                  <SiteHeader.NavList>
                    <SiteHeader.NavItem title="Link Title" href="#" />
                    <SiteHeader.NavItem
                      title="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla auctor arcu ipsum."
                      href="#"
                    />
                    <SiteHeader.NavItem title="Link Title" href="#" />
                  </SiteHeader.NavList>
                </SiteHeader.Column>
              </SiteHeader.Section>
            </SiteHeader.SectionStack>
          </SiteHeader.Flyouts.TabPanel>
          <SiteHeader.Flyouts.TabPanel id="tab2" tabTitle="2col">
            <SiteHeader.Section
              title="Section title"
              relatedUrl="https://www.example.com"
              relatedName="See All"
            >
              <SiteHeader.Column>
                <SiteHeader.NavList>
                  <SiteHeader.NavItem title="Link Title" href="#" />
                  <SiteHeader.NavItem
                    title="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla auctor arcu ipsum."
                    href="#"
                  />
                  <SiteHeader.NavItem title="Link Title" href="#" />
                  <SiteHeader.NavItem
                    description="Description"
                    title="Link Title"
                    href="#"
                  />
                  <SiteHeader.NavItem
                    description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla auctor arcu ipsum."
                    title="Link Title"
                    href="#"
                  />
                </SiteHeader.NavList>
              </SiteHeader.Column>
            </SiteHeader.Section>
            <SiteHeader.Section title="Featured Item">
              <SiteHeader.Column>{featuredItem}</SiteHeader.Column>
            </SiteHeader.Section>
          </SiteHeader.Flyouts.TabPanel>
          <SiteHeader.Flyouts.TabPanel id="tab3" tabTitle="3col">
            <SiteHeader.Section
              title="Section title"
              relatedUrl="https://www.example.com"
              relatedName="See All"
            >
              <SiteHeader.Column>
                <SiteHeader.NavList>
                  <SiteHeader.NavItem title="Link Title" href="#" />
                  <SiteHeader.NavItem
                    title="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla auctor arcu ipsum."
                    href="#"
                  />
                  <SiteHeader.NavItem title="Link Title" href="#" />
                  <SiteHeader.NavItem
                    description="Description"
                    title="Link Title"
                    href="#"
                  />
                  <SiteHeader.NavItem
                    description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla auctor arcu ipsum."
                    title="Link Title"
                    href="#"
                  />
                </SiteHeader.NavList>
              </SiteHeader.Column>
            </SiteHeader.Section>
            <SiteHeader.SectionStack>
              <SiteHeader.Section title="Section title">
                <SiteHeader.Column>
                  <SiteHeader.NavList>
                    <SiteHeader.NavItem title="Link Title" href="#" />
                    <SiteHeader.NavItem title="Link Title" href="#" />
                    <SiteHeader.NavItem title="Link Title" href="#" />
                  </SiteHeader.NavList>
                </SiteHeader.Column>
              </SiteHeader.Section>
              <SiteHeader.Section title="Section title">
                <SiteHeader.Column>
                  <SiteHeader.NavList>
                    <SiteHeader.NavItem title="Link Title" href="#" />
                    <SiteHeader.NavItem
                      title="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla auctor arcu ipsum."
                      href="#"
                    />
                    <SiteHeader.NavItem title="Link Title" href="#" />
                  </SiteHeader.NavList>
                </SiteHeader.Column>
              </SiteHeader.Section>
            </SiteHeader.SectionStack>
            <SiteHeader.Section title="Featured Item">
              <SiteHeader.Column>{featuredItem}</SiteHeader.Column>
            </SiteHeader.Section>
          </SiteHeader.Flyouts.TabPanel>
          <SiteHeader.Flyouts.TabPanel id="tab4" tabTitle="2-1">
            <SiteHeader.Section
              title="Section title"
              columns={2}
              relatedUrl="https://www.example.com"
              relatedName="See All"
            >
              <SiteHeader.Column>
                <SiteHeader.NavList>
                  <SiteHeader.NavItem title="Link Title" href="#" />
                  <SiteHeader.NavItem
                    title="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla auctor arcu ipsum."
                    href="#"
                  />
                  <SiteHeader.NavItem title="Link Title" href="#" />
                  <SiteHeader.NavItem
                    description="Description"
                    title="Link Title"
                    href="#"
                  />
                  <SiteHeader.NavItem
                    description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla auctor arcu ipsum."
                    title="Link Title"
                    href="#"
                  />
                </SiteHeader.NavList>
              </SiteHeader.Column>
              <SiteHeader.Column>
                <SiteHeader.NavList>
                  <SiteHeader.NavItem title="Link Title" href="#" />
                  <SiteHeader.NavItem
                    title="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla auctor arcu ipsum."
                    href="#"
                  />
                  <SiteHeader.NavItem title="Link Title" href="#" />
                  <SiteHeader.NavItem
                    description="Description"
                    title="Link Title"
                    href="#"
                  />
                  <SiteHeader.NavItem
                    description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla auctor arcu ipsum."
                    title="Link Title"
                    href="#"
                  />
                </SiteHeader.NavList>
              </SiteHeader.Column>
            </SiteHeader.Section>
            <SiteHeader.SectionStack>
              <SiteHeader.Section title="Section title">
                <SiteHeader.Column>
                  <SiteHeader.NavList>
                    <SiteHeader.NavItem title="Link Title" href="#" />
                    <SiteHeader.NavItem title="Link Title" href="#" />
                    <SiteHeader.NavItem title="Link Title" href="#" />
                  </SiteHeader.NavList>
                </SiteHeader.Column>
              </SiteHeader.Section>
              <SiteHeader.Section title="Section title">
                <SiteHeader.Column>
                  <SiteHeader.NavList>
                    <SiteHeader.NavItem title="Link Title" href="#" />
                    <SiteHeader.NavItem
                      title="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla auctor arcu ipsum."
                      href="#"
                    />
                    <SiteHeader.NavItem title="Link Title" href="#" />
                  </SiteHeader.NavList>
                </SiteHeader.Column>
              </SiteHeader.Section>
            </SiteHeader.SectionStack>
          </SiteHeader.Flyouts.TabPanel>
          <SiteHeader.Flyouts.TabPanel
            id="tab5"
            tabTitle="Lorem ipsum dolor sit amet consectetur adipiscing elit"
          >
            <SiteHeader.Section title="Featured Item">
              <SiteHeader.Column>{featuredItem}</SiteHeader.Column>
            </SiteHeader.Section>
          </SiteHeader.Flyouts.TabPanel>
        </SiteHeader.Flyouts>
      </SiteHeader.Menu>
    </SiteHeader>
  ))
  .add("(SB) Long titles", () => (
    <SiteHeader
      logoHref="#"
      logoPath={select("Logo", logoOptions, null)}
      search={boolean("enable search", true)}
      searchFormAction={text("searchFormAction") || null}
      searchFormMethod={text("searchFormMethod", "get")}
      searchInputName={text("searchInputName", "q")}
      siteName={text("siteName", "Tax & Accounting")}
      typeaheadUrl={
        boolean("enable typeahead", true) &&
        text(
          "typeaheadUrl",
          "//content.atomz.com/autocomplete/sp10/05/0d/51?_=1506335725065"
        )
      }
    >
      <SiteHeader.Menu
        title="Long title to test wrapping in narrow view"
        href="https://www.example.com"
      />
      <SiteHeader.Menu
        title="Another long title how does this one wrap"
        href="https://www.example.com"
      />
    </SiteHeader>
  ))
  .add("(SB) Language: Arabic (RTL)", () => (
    <Language code="ar">
      <SiteHeader
        logoHref="#"
        logoPath={select("Logo", logoOptions, null)}
        search={boolean("enable search", true)}
        searchFormAction={text("searchFormAction") || null}
        searchFormMethod={text("searchFormMethod", "get")}
        searchInputName={text("searchInputName", "q")}
        siteName={text("siteName", "Tax & Accounting")}
        typeaheadUrl={
          boolean("enable typeahead", true) &&
          text(
            "typeaheadUrl",
            "//content.atomz.com/autocomplete/sp10/05/0d/51?_=1506335725065"
          )
        }
      >
        <SiteHeader.Menu title="منتجات">
          <SiteHeader.Section
            title="قائمة المنتجات 1"
            relatedUrl="https://www.example.com"
            relatedName="اظهار الكل"
          >
            <SiteHeader.Column>
              <SiteHeader.NavList>
                <SiteHeader.NavItem title="البند 1" href="#" />
                <SiteHeader.NavItem title="البند 2" href="#" />
                <SiteHeader.NavItem title="البند 3" href="#" />
                <SiteHeader.NavItem title="البند 4" href="#" />
              </SiteHeader.NavList>
            </SiteHeader.Column>
          </SiteHeader.Section>
          <SiteHeader.SectionStack>
            <SiteHeader.Section title="قائمة المنتجات 2">
              <SiteHeader.Column>
                <SiteHeader.NavList>
                  <SiteHeader.NavItem title="البند 5" href="#" />
                </SiteHeader.NavList>
              </SiteHeader.Column>
            </SiteHeader.Section>
            <SiteHeader.Section title="قائمة المنتجات 3">
              <SiteHeader.Column>
                <SiteHeader.NavList>
                  <SiteHeader.NavItem title="البند 6" href="#" />
                  <SiteHeader.NavItem title="البند 7" href="#" />
                </SiteHeader.NavList>
              </SiteHeader.Column>
            </SiteHeader.Section>
          </SiteHeader.SectionStack>
          <SiteHeader.Section title="قائمة المنتجات 4">
            <SiteHeader.Column>
              <SiteHeader.NavList>
                <SiteHeader.NavItem title="البند 8" href="#" />
                <SiteHeader.NavItem title="البند 9" href="#" />
                <SiteHeader.NavItem title="البند 10" href="#" />
                <SiteHeader.NavItem title="البند 11" href="#" />
              </SiteHeader.NavList>
            </SiteHeader.Column>
          </SiteHeader.Section>
          <SiteHeader.Section
            title="قائمة المنتجات 5"
            relatedUrl="https://www.example.com"
            relatedName="اظهار الكل"
          >
            <SiteHeader.Column>
              <div>عشوائي محتوى آخر</div>
            </SiteHeader.Column>
          </SiteHeader.Section>
        </SiteHeader.Menu>
        <SiteHeader.Menu title="الصناعات">
          <SiteHeader.Section title="1 قائمة الصناعات">
            <SiteHeader.Column>
              <SiteHeader.NavList>
                <SiteHeader.NavItem title="البند 13" href="#" />
                <SiteHeader.NavItem title="البند 14" href="#" />
                <SiteHeader.NavItem title="البند 15" href="#" />
                <SiteHeader.NavItem title="البند 16" href="#" />
              </SiteHeader.NavList>
            </SiteHeader.Column>
          </SiteHeader.Section>
          <SiteHeader.Section title="2 قائمة الصناعات">
            <SiteHeader.Column>
              <SiteHeader.NavList>
                <SiteHeader.NavItem title="البند 17" href="#" />
                <SiteHeader.NavItem title="البند 18" href="#" />
                <SiteHeader.NavItem title="البند 19" href="#" />
                <SiteHeader.NavItem title="البند 20" href="#" />
              </SiteHeader.NavList>
            </SiteHeader.Column>
          </SiteHeader.Section>
        </SiteHeader.Menu>
        <SiteHeader.Menu title="تطير" fallbackHref="https://www.example.com">
          <SiteHeader.Flyouts
            summaryLinkHref="https://example.com"
            summaryLinkText="Link Title"
          >
            <SiteHeader.Flyouts.TabPanel
              id="tab1"
              tabTitle="Lorem ipsum dolor sit amet consectetur adipiscing elit"
            >
              <SiteHeader.Section title="Featured Item">
                <SiteHeader.Column>{featuredItem}</SiteHeader.Column>
              </SiteHeader.Section>
            </SiteHeader.Flyouts.TabPanel>
            <SiteHeader.Flyouts.TabPanel
              id="tab2"
              tabTitle="Elit adipiscing consectetur amet sit dolor ipsum lorem."
            >
              <SiteHeader.Section title="Featured Item">
                <SiteHeader.Column>{featuredItem}</SiteHeader.Column>
              </SiteHeader.Section>
            </SiteHeader.Flyouts.TabPanel>
          </SiteHeader.Flyouts>
        </SiteHeader.Menu>
      </SiteHeader>
    </Language>
  ))
  .add("(SB) Rehydrated", () => (
    <StaticRenderer>
      <SiteHeader
        logoHref="#"
        logoPath={select("Logo", logoOptions, null)}
        logoText="logo-alt-text"
        search={boolean("enable search", true)}
        searchFormAction={text("searchFormAction") || null}
        searchFormMethod={text("searchFormMethod", "get")}
        searchInputName={text("searchInputName", "q")}
        siteName={text("siteName", "Tax & Accounting")}
        typeaheadUrl={
          boolean("enable typeahead", true) &&
          text(
            "typeaheadUrl",
            "//content.atomz.com/autocomplete/sp10/05/0d/51?_=1506335725065"
          )
        }
      >
        <SiteHeader.Menu
          title="1col"
          datasets={`{"data-event-type": "String Six","data-link-type":"stringOne","data-asset-title":"stringTwo","data-href-analytics":"stringThree","data-link-text":"stringFour","data-find-method":"stringFive"}`}
        >
          <SiteHeader.Section title="Section title">
            <SiteHeader.Column>
              <SiteHeader.NavList>
                <SiteHeader.NavItem title="Link Title" href="#" />
                <SiteHeader.NavItem
                  title="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla auctor arcu ipsum."
                  href="#"
                />
                <SiteHeader.NavItem title="Link Title" href="#" />
                <SiteHeader.NavItem
                  description="Description"
                  title="Link Title"
                  href="#"
                />
                <SiteHeader.NavItem
                  description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla auctor arcu ipsum."
                  title="Link Title"
                  href="#"
                />
              </SiteHeader.NavList>
            </SiteHeader.Column>
          </SiteHeader.Section>
        </SiteHeader.Menu>
        <SiteHeader.Menu title="2col">
          <SiteHeader.Section title="Section title">
            <SiteHeader.Column>
              <SiteHeader.NavList>
                <SiteHeader.NavItem title="Link Title" href="#" />
                <SiteHeader.NavItem
                  title="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla auctor arcu ipsum."
                  href="#"
                />
                <SiteHeader.NavItem title="Link Title" href="#" />
                <SiteHeader.NavItem
                  description="Description"
                  title="Link Title"
                  href="#"
                />
                <SiteHeader.NavItem
                  description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla auctor arcu ipsum."
                  title="Link Title"
                  href="#"
                />
              </SiteHeader.NavList>
            </SiteHeader.Column>
          </SiteHeader.Section>
          <SiteHeader.SectionStack>
            <SiteHeader.Section title="Section title">
              <SiteHeader.Column>
                <SiteHeader.NavList>
                  <SiteHeader.NavItem title="Link Title" href="#" />
                  <SiteHeader.NavItem title="Link Title" href="#" />
                  <SiteHeader.NavItem title="Link Title" href="#" />
                </SiteHeader.NavList>
              </SiteHeader.Column>
            </SiteHeader.Section>
            <SiteHeader.Section title="Section title">
              <SiteHeader.Column>
                <SiteHeader.NavList>
                  <SiteHeader.NavItem title="Link Title" href="#" />
                  <SiteHeader.NavItem
                    title="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla auctor arcu ipsum."
                    href="#"
                  />
                  <SiteHeader.NavItem title="Link Title" href="#" />
                </SiteHeader.NavList>
              </SiteHeader.Column>
            </SiteHeader.Section>
          </SiteHeader.SectionStack>
        </SiteHeader.Menu>
        <SiteHeader.Menu title="3col">
          <SiteHeader.Section title="Section title">
            <SiteHeader.Column>
              <SiteHeader.NavList>
                <SiteHeader.NavItem title="Link Title" href="#" />
                <SiteHeader.NavItem
                  title="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla auctor arcu ipsum."
                  href="#"
                />
                <SiteHeader.NavItem title="Link Title" href="#" />
                <SiteHeader.NavItem
                  description="Description"
                  title="Link Title"
                  href="#"
                />
                <SiteHeader.NavItem
                  description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla auctor arcu ipsum."
                  title="Link Title"
                  href="#"
                />
              </SiteHeader.NavList>
            </SiteHeader.Column>
          </SiteHeader.Section>
          <SiteHeader.SectionStack>
            <SiteHeader.Section title="Section title">
              <SiteHeader.Column>
                <SiteHeader.NavList>
                  <SiteHeader.NavItem title="Link Title" href="#" />
                  <SiteHeader.NavItem title="Link Title" href="#" />
                  <SiteHeader.NavItem title="Link Title" href="#" />
                </SiteHeader.NavList>
              </SiteHeader.Column>
            </SiteHeader.Section>
            <SiteHeader.Section title="Section title">
              <SiteHeader.Column>
                <SiteHeader.NavList>
                  <SiteHeader.NavItem title="Link Title" href="#" />
                  <SiteHeader.NavItem
                    title="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla auctor arcu ipsum."
                    href="#"
                  />
                  <SiteHeader.NavItem title="Link Title" href="#" />
                </SiteHeader.NavList>
              </SiteHeader.Column>
            </SiteHeader.Section>
          </SiteHeader.SectionStack>
          <SiteHeader.Section title="Section title">
            <SiteHeader.Column>{featuredItem}</SiteHeader.Column>
          </SiteHeader.Section>
        </SiteHeader.Menu>
        <SiteHeader.Menu title="4col">
          <SiteHeader.SectionStack>
            <SiteHeader.Section title="Section title">
              <SiteHeader.Column>
                <SiteHeader.NavList>
                  <SiteHeader.NavItem title="Link Title" href="#" />
                  <SiteHeader.NavItem title="Link Title" href="#" />
                </SiteHeader.NavList>
              </SiteHeader.Column>
            </SiteHeader.Section>
            <SiteHeader.Section title="Section title">
              <SiteHeader.Column>
                <SiteHeader.NavList>
                  <SiteHeader.NavItem title="Link Title" href="#" />
                  <SiteHeader.NavItem
                    title="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla auctor arcu ipsum."
                    href="#"
                  />
                  <SiteHeader.NavItem title="Link Title" href="#" />
                </SiteHeader.NavList>
              </SiteHeader.Column>
            </SiteHeader.Section>
          </SiteHeader.SectionStack>
          <SiteHeader.Section title="Section title">
            <SiteHeader.Column>
              <SiteHeader.NavList>
                <SiteHeader.NavItem title="Link Title" href="#" />
                <SiteHeader.NavItem
                  title="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla auctor arcu ipsum."
                  href="#"
                />
                <SiteHeader.NavItem title="Link Title" href="#" />
                <SiteHeader.NavItem
                  description="Description"
                  title="Link Title"
                  href="#"
                />
                <SiteHeader.NavItem
                  description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla auctor arcu ipsum."
                  title="Link Title"
                  href="#"
                />
              </SiteHeader.NavList>
            </SiteHeader.Column>
          </SiteHeader.Section>
          <SiteHeader.SectionStack>
            <SiteHeader.Section title="Section title">
              <SiteHeader.Column>
                <SiteHeader.NavList>
                  <SiteHeader.NavItem title="Link Title" href="#" />
                  <SiteHeader.NavItem title="Link Title" href="#" />
                </SiteHeader.NavList>
              </SiteHeader.Column>
            </SiteHeader.Section>
            <SiteHeader.Section title="Section title">
              <SiteHeader.Column>
                <SiteHeader.NavList>
                  <SiteHeader.NavItem title="Link Title" href="#" />
                  <SiteHeader.NavItem
                    title="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla auctor arcu ipsum."
                    href="#"
                  />
                  <SiteHeader.NavItem title="Link Title" href="#" />
                </SiteHeader.NavList>
              </SiteHeader.Column>
            </SiteHeader.Section>
          </SiteHeader.SectionStack>
          <SiteHeader.Section title="Section title">
            <SiteHeader.Column>{featuredItem}</SiteHeader.Column>
          </SiteHeader.Section>
        </SiteHeader.Menu>
        <SiteHeader.Menu title="1-2">
          <SiteHeader.Section title="Section title">
            <SiteHeader.Column>
              <Block height="400px" />
            </SiteHeader.Column>
          </SiteHeader.Section>
          <SiteHeader.Section title="Section title" columns={2}>
            <SiteHeader.Column>
              <Block height="400px" />
            </SiteHeader.Column>
            <SiteHeader.Column>
              <Block height="400px" />
            </SiteHeader.Column>
          </SiteHeader.Section>
        </SiteHeader.Menu>
        <SiteHeader.Menu title="1-3">
          <SiteHeader.Section title="Section title">
            <SiteHeader.Column>
              <Block height="400px" />
            </SiteHeader.Column>
          </SiteHeader.Section>
          <SiteHeader.Section title="Section title" columns={3}>
            <SiteHeader.Column>
              <Block height="400px" />
            </SiteHeader.Column>
            <SiteHeader.Column>
              <Block height="400px" />
            </SiteHeader.Column>
            <SiteHeader.Column>
              <Block height="400px" />
            </SiteHeader.Column>
          </SiteHeader.Section>
        </SiteHeader.Menu>
        <SiteHeader.Menu title="2-2">
          <SiteHeader.Section title="Section title" columns={2}>
            <SiteHeader.Column>
              <Block height="400px" />
            </SiteHeader.Column>
            <SiteHeader.Column>
              <Block height="400px" />
            </SiteHeader.Column>
          </SiteHeader.Section>
          <SiteHeader.Section title="Section title" columns={2}>
            <SiteHeader.Column>
              <Block height="400px" />
            </SiteHeader.Column>
            <SiteHeader.Column>
              <Block height="400px" />
            </SiteHeader.Column>
          </SiteHeader.Section>
        </SiteHeader.Menu>
        <SiteHeader.Menu title="Flyouts" fallbackHref="https://www.example.com">
          <SiteHeader.Flyouts
            summaryLinkHref="https://example.com"
            summaryLinkText="Link Title"
          >
            <SiteHeader.Flyouts.TabPanel id="tab1" tabTitle="1col">
              <SiteHeader.SectionStack>
                <SiteHeader.Section title="Section title">
                  <SiteHeader.Column>
                    <SiteHeader.NavList>
                      <SiteHeader.NavItem title="Link Title" href="#" />
                      <SiteHeader.NavItem title="Link Title" href="#" />
                      <SiteHeader.NavItem title="Link Title" href="#" />
                    </SiteHeader.NavList>
                  </SiteHeader.Column>
                </SiteHeader.Section>
                <SiteHeader.Section title="Section title">
                  <SiteHeader.Column>
                    <SiteHeader.NavList>
                      <SiteHeader.NavItem title="Link Title" href="#" />
                      <SiteHeader.NavItem
                        title="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla auctor arcu ipsum."
                        href="#"
                      />
                      <SiteHeader.NavItem title="Link Title" href="#" />
                    </SiteHeader.NavList>
                  </SiteHeader.Column>
                </SiteHeader.Section>
              </SiteHeader.SectionStack>
            </SiteHeader.Flyouts.TabPanel>
            <SiteHeader.Flyouts.TabPanel id="tab2" tabTitle="2col">
              <SiteHeader.Section
                title="Section title"
                relatedUrl="https://www.example.com"
                relatedName="See All"
              >
                <SiteHeader.Column>
                  <SiteHeader.NavList>
                    <SiteHeader.NavItem title="Link Title" href="#" />
                    <SiteHeader.NavItem
                      title="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla auctor arcu ipsum."
                      href="#"
                    />
                    <SiteHeader.NavItem title="Link Title" href="#" />
                    <SiteHeader.NavItem
                      description="Description"
                      title="Link Title"
                      href="#"
                    />
                    <SiteHeader.NavItem
                      description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla auctor arcu ipsum."
                      title="Link Title"
                      href="#"
                    />
                  </SiteHeader.NavList>
                </SiteHeader.Column>
              </SiteHeader.Section>
              <SiteHeader.Section title="Featured Item">
                <SiteHeader.Column>{featuredItem}</SiteHeader.Column>
              </SiteHeader.Section>
            </SiteHeader.Flyouts.TabPanel>
            <SiteHeader.Flyouts.TabPanel id="tab3" tabTitle="3col">
              <SiteHeader.Section
                title="Section title"
                relatedUrl="https://www.example.com"
                relatedName="See All"
              >
                <SiteHeader.Column>
                  <SiteHeader.NavList>
                    <SiteHeader.NavItem title="Link Title" href="#" />
                    <SiteHeader.NavItem
                      title="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla auctor arcu ipsum."
                      href="#"
                    />
                    <SiteHeader.NavItem title="Link Title" href="#" />
                    <SiteHeader.NavItem
                      description="Description"
                      title="Link Title"
                      href="#"
                    />
                    <SiteHeader.NavItem
                      description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla auctor arcu ipsum."
                      title="Link Title"
                      href="#"
                    />
                  </SiteHeader.NavList>
                </SiteHeader.Column>
              </SiteHeader.Section>
              <SiteHeader.SectionStack>
                <SiteHeader.Section title="Section title">
                  <SiteHeader.Column>
                    <SiteHeader.NavList>
                      <SiteHeader.NavItem title="Link Title" href="#" />
                      <SiteHeader.NavItem title="Link Title" href="#" />
                      <SiteHeader.NavItem title="Link Title" href="#" />
                    </SiteHeader.NavList>
                  </SiteHeader.Column>
                </SiteHeader.Section>
                <SiteHeader.Section title="Section title">
                  <SiteHeader.Column>
                    <SiteHeader.NavList>
                      <SiteHeader.NavItem title="Link Title" href="#" />
                      <SiteHeader.NavItem
                        title="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla auctor arcu ipsum."
                        href="#"
                      />
                      <SiteHeader.NavItem title="Link Title" href="#" />
                    </SiteHeader.NavList>
                  </SiteHeader.Column>
                </SiteHeader.Section>
              </SiteHeader.SectionStack>
              <SiteHeader.Section title="Featured Item">
                <SiteHeader.Column>{featuredItem}</SiteHeader.Column>
              </SiteHeader.Section>
            </SiteHeader.Flyouts.TabPanel>
            <SiteHeader.Flyouts.TabPanel id="tab4" tabTitle="2-1">
              <SiteHeader.Section
                title="Section title"
                columns={2}
                relatedUrl="https://www.example.com"
                relatedName="See All"
              >
                <SiteHeader.Column>
                  <SiteHeader.NavList>
                    <SiteHeader.NavItem title="Link Title" href="#" />
                    <SiteHeader.NavItem
                      title="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla auctor arcu ipsum."
                      href="#"
                    />
                    <SiteHeader.NavItem title="Link Title" href="#" />
                    <SiteHeader.NavItem
                      description="Description"
                      title="Link Title"
                      href="#"
                    />
                    <SiteHeader.NavItem
                      description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla auctor arcu ipsum."
                      title="Link Title"
                      href="#"
                    />
                  </SiteHeader.NavList>
                </SiteHeader.Column>
                <SiteHeader.Column>
                  <SiteHeader.NavList>
                    <SiteHeader.NavItem title="Link Title" href="#" />
                    <SiteHeader.NavItem
                      title="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla auctor arcu ipsum."
                      href="#"
                    />
                    <SiteHeader.NavItem title="Link Title" href="#" />
                    <SiteHeader.NavItem
                      description="Description"
                      title="Link Title"
                      href="#"
                    />
                    <SiteHeader.NavItem
                      description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla auctor arcu ipsum."
                      title="Link Title"
                      href="#"
                    />
                  </SiteHeader.NavList>
                </SiteHeader.Column>
              </SiteHeader.Section>
              <SiteHeader.SectionStack>
                <SiteHeader.Section title="Section title">
                  <SiteHeader.Column>
                    <SiteHeader.NavList>
                      <SiteHeader.NavItem title="Link Title" href="#" />
                      <SiteHeader.NavItem title="Link Title" href="#" />
                      <SiteHeader.NavItem title="Link Title" href="#" />
                    </SiteHeader.NavList>
                  </SiteHeader.Column>
                </SiteHeader.Section>
                <SiteHeader.Section title="Section title">
                  <SiteHeader.Column>
                    <SiteHeader.NavList>
                      <SiteHeader.NavItem title="Link Title" href="#" />
                      <SiteHeader.NavItem
                        title="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla auctor arcu ipsum."
                        href="#"
                      />
                      <SiteHeader.NavItem title="Link Title" href="#" />
                    </SiteHeader.NavList>
                  </SiteHeader.Column>
                </SiteHeader.Section>
              </SiteHeader.SectionStack>
            </SiteHeader.Flyouts.TabPanel>
            <SiteHeader.Flyouts.TabPanel
              id="tab5"
              tabTitle="Lorem ipsum dolor sit amet consectetur adipiscing elit"
            >
              <SiteHeader.Section title="Featured Item">
                <SiteHeader.Column>{featuredItem}</SiteHeader.Column>
              </SiteHeader.Section>
            </SiteHeader.Flyouts.TabPanel>
          </SiteHeader.Flyouts>
        </SiteHeader.Menu>
      </SiteHeader>
    </StaticRenderer>
  ))
  .add("(SB) Elastic Search", () => (
    <SiteHeader
      logoHref="#"
      logoPath={select("Logo", logoOptions, null)}
      search={boolean("enable search", true)}
      searchFormAction={text("searchFormAction") || null}
      searchFormMethod={text("searchFormMethod", "get")}
      searchInputName={text("searchInputName", "q")}
      siteName={text("siteName", "")}
      typeaheadUrl={
        boolean("enable typeahead", true) && text("typeaheadUrl", elasticURL)
      }
      elasticKey={text("elasticKey", getQueryParamValByName("elasticKey"))}
      autosuggestion={text("autosuggestion", "description,w_internal_title")}
      size={number("size", elasticResultSizeLimit)}
    >
      <SiteHeader.Menu
        title="Solutions"
        datasets={`{"data-event-type": "String Six","data-link-type":"stringOne","data-asset-title":"stringTwo","data-href-analytics":"stringThree","data-link-text":"stringFour","data-find-method":"stringFive"}`}
        fallbackHref="https://www.example.com"
      >
        <SiteHeader.Section
          title="Solutions list 1"
          relatedUrl="https://www.example.com"
          relatedName="See All"
        >
          <SiteHeader.Column>
            <SiteHeader.NavList>
              <SiteHeader.NavItem title="My item 1" href="#" />
              <SiteHeader.NavItem title="My item 2" href="#" />
              <SiteHeader.NavItem title="My item 3" href="#" />
              <SiteHeader.NavItem title="My item 4" href="#" />
              <SiteHeader.NavItem title="My item 4.1" href="#" />
              <SiteHeader.NavItem title="My item 4.2" href="#" />
              <SiteHeader.NavItem title="My item 4.3" href="#" />
              <SiteHeader.NavItem title="My item 4.4" href="#" />
            </SiteHeader.NavList>
          </SiteHeader.Column>
        </SiteHeader.Section>
        <SiteHeader.SectionStack>
          <SiteHeader.Section title="Solutions list 2">
            <SiteHeader.Column>
              <SiteHeader.NavList>
                <SiteHeader.NavItem title="My item 5" href="#" />
              </SiteHeader.NavList>
            </SiteHeader.Column>
          </SiteHeader.Section>
          <SiteHeader.Section
            title="Solutions list 3"
            // relatedUrl="https://www.example.com"
            // relatedName="See All"
          >
            <SiteHeader.Column>
              <SiteHeader.NavList>
                <SiteHeader.NavItem title="My item 5" href="#" />
                <SiteHeader.NavItem title="My item 6" href="#" />
                <SiteHeader.NavItem title="My item 7" href="#" />
                <SiteHeader.NavItem title="My item 8" href="#" />
                <SiteHeader.NavItem title="My item 8.1" href="#" />
                <SiteHeader.NavItem title="My item 8.2" href="#" />
                <SiteHeader.NavItem title="My item 8.3" href="#" />
                <SiteHeader.NavItem title="My item 8.4" href="#" />
                <SiteHeader.NavItem title="My item 8.5" href="#" />
                <SiteHeader.NavItem title="My item 8.6" href="#" />
                <SiteHeader.NavItem title="My item 8.7" href="#" />
              </SiteHeader.NavList>
            </SiteHeader.Column>
          </SiteHeader.Section>
        </SiteHeader.SectionStack>
        <SiteHeader.Section
          title="Solutions list 4"
          relatedUrl="https://www.example.com"
          relatedName="See All"
        >
          <SiteHeader.Column>
            <SiteHeader.NavList>
              <SiteHeader.NavItem title="My item 9" href="#" />
              <SiteHeader.NavItem title="My item 10" href="#" />
              <SiteHeader.NavItem title="My item 11" href="#" />
              <SiteHeader.NavItem title="My item 12" href="#" />
            </SiteHeader.NavList>
          </SiteHeader.Column>
        </SiteHeader.Section>
        <SiteHeader.Section title="Featured Item">
          <SiteHeader.Column>{featuredItem}</SiteHeader.Column>
        </SiteHeader.Section>
      </SiteHeader.Menu>
      <SiteHeader.Menu title="Articles" fallbackHref="https://www.example.com">
        <SiteHeader.Section title="Articles" columns={2}>
          <SiteHeader.Column>
            <SiteHeader.NavList>
              <SiteHeader.NavItem title="My item 13" href="#" />
              <SiteHeader.NavItem
                title="My item 14 with a very long title"
                href="#"
              />
              <SiteHeader.NavItem title="My item 15" href="#" />
              <SiteHeader.NavItem title="My item 16" href="#" />
            </SiteHeader.NavList>
          </SiteHeader.Column>
          <SiteHeader.Column>
            <SiteHeader.NavList>
              <SiteHeader.NavItem title="My item 17" href="#" />
              <SiteHeader.NavItem title="My item 18" href="#" />
              <SiteHeader.NavItem title="My item 19" href="#" />
              <SiteHeader.NavItem title="My item 20" href="#" />
            </SiteHeader.NavList>
          </SiteHeader.Column>
        </SiteHeader.Section>
      </SiteHeader.Menu>
      <SiteHeader.Menu title="About us" fallbackHref="https://www.example.com">
        <SiteHeader.Section title="The Company">
          <SiteHeader.Column>
            <SiteHeader.NavList>
              <SiteHeader.NavItem title="My item 21" href="#" />
              <SiteHeader.NavItem title="My item 22" href="#" />
              <SiteHeader.NavItem title="My item 23" href="#" />
              <SiteHeader.NavItem title="My item 24" href="#" />
            </SiteHeader.NavList>
          </SiteHeader.Column>
        </SiteHeader.Section>
      </SiteHeader.Menu>
      <SiteHeader.Menu title="Careers" fallbackHref="https://www.example.com">
        <SiteHeader.Section>
          <SiteHeader.Column>
            <SiteHeader.NavList>
              <SiteHeader.NavItem title="My item 21" href="#" />
              <SiteHeader.NavItem title="My item 22" href="#" />
              <SiteHeader.NavItem title="My item 23" href="#" />
              <SiteHeader.NavItem title="My item 24" href="#" />
            </SiteHeader.NavList>
          </SiteHeader.Column>
        </SiteHeader.Section>
      </SiteHeader.Menu>
      <SiteHeader.Menu title="External" href="https://www.example.com" />
    </SiteHeader>
  ))
  .add("(SB) Rehydrated Elastic Search", () => (
    <StaticRenderer>
      <SiteHeader
        logoHref="#"
        logoPath={select("Logo", logoOptions, null)}
        search={boolean("enable search", true)}
        searchFormAction={text("searchFormAction") || null}
        searchFormMethod={text("searchFormMethod", "get")}
        searchInputName={text("searchInputName", "q")}
        siteName={text("siteName", "")}
        typeaheadUrl={
          boolean("enable typeahead", true) && text("typeaheadUrl", elasticURL)
        }
        elasticKey={text("elasticKey", getQueryParamValByName("elasticKey"))}
        autosuggestion={text("autosuggestion", "description,w_internal_title")}
        size={number("size", elasticResultSizeLimit)}
      >
        <SiteHeader.Menu
          title="Solutions"
          datasets={`{"data-event-type": "String Six","data-link-type":"stringOne","data-asset-title":"stringTwo","data-href-analytics":"stringThree","data-link-text":"stringFour","data-find-method":"stringFive"}`}
          fallbackHref="https://www.example.com"
        >
          <SiteHeader.Section
            title="Solutions list 1"
            relatedUrl="https://www.example.com"
            relatedName="See All"
          >
            <SiteHeader.Column>
              <SiteHeader.NavList>
                <SiteHeader.NavItem title="My item 1" href="#" />
                <SiteHeader.NavItem title="My item 2" href="#" />
                <SiteHeader.NavItem title="My item 3" href="#" />
                <SiteHeader.NavItem title="My item 4" href="#" />
                <SiteHeader.NavItem title="My item 4.1" href="#" />
                <SiteHeader.NavItem title="My item 4.2" href="#" />
                <SiteHeader.NavItem title="My item 4.3" href="#" />
                <SiteHeader.NavItem title="My item 4.4" href="#" />
              </SiteHeader.NavList>
            </SiteHeader.Column>
          </SiteHeader.Section>
          <SiteHeader.SectionStack>
            <SiteHeader.Section title="Solutions list 2">
              <SiteHeader.Column>
                <SiteHeader.NavList>
                  <SiteHeader.NavItem title="My item 5" href="#" />
                </SiteHeader.NavList>
              </SiteHeader.Column>
            </SiteHeader.Section>
            <SiteHeader.Section
              title="Solutions list 3"
              // relatedUrl="https://www.example.com"
              // relatedName="See All"
            >
              <SiteHeader.Column>
                <SiteHeader.NavList>
                  <SiteHeader.NavItem title="My item 5" href="#" />
                  <SiteHeader.NavItem title="My item 6" href="#" />
                  <SiteHeader.NavItem title="My item 7" href="#" />
                  <SiteHeader.NavItem title="My item 8" href="#" />
                  <SiteHeader.NavItem title="My item 8.1" href="#" />
                  <SiteHeader.NavItem title="My item 8.2" href="#" />
                  <SiteHeader.NavItem title="My item 8.3" href="#" />
                  <SiteHeader.NavItem title="My item 8.4" href="#" />
                  <SiteHeader.NavItem title="My item 8.5" href="#" />
                  <SiteHeader.NavItem title="My item 8.6" href="#" />
                  <SiteHeader.NavItem title="My item 8.7" href="#" />
                </SiteHeader.NavList>
              </SiteHeader.Column>
            </SiteHeader.Section>
          </SiteHeader.SectionStack>
          <SiteHeader.Section
            title="Solutions list 4"
            relatedUrl="https://www.example.com"
            relatedName="See All"
          >
            <SiteHeader.Column>
              <SiteHeader.NavList>
                <SiteHeader.NavItem title="My item 9" href="#" />
                <SiteHeader.NavItem title="My item 10" href="#" />
                <SiteHeader.NavItem title="My item 11" href="#" />
                <SiteHeader.NavItem title="My item 12" href="#" />
              </SiteHeader.NavList>
            </SiteHeader.Column>
          </SiteHeader.Section>
          <SiteHeader.Section title="Featured Item">
            <SiteHeader.Column>{featuredItem}</SiteHeader.Column>
          </SiteHeader.Section>
        </SiteHeader.Menu>
        <SiteHeader.Menu
          title="Articles"
          fallbackHref="https://www.example.com"
        >
          <SiteHeader.Section title="Articles" columns={2}>
            <SiteHeader.Column>
              <SiteHeader.NavList>
                <SiteHeader.NavItem title="My item 13" href="#" />
                <SiteHeader.NavItem
                  title="My item 14 with a very long title"
                  href="#"
                />
                <SiteHeader.NavItem title="My item 15" href="#" />
                <SiteHeader.NavItem title="My item 16" href="#" />
              </SiteHeader.NavList>
            </SiteHeader.Column>
            <SiteHeader.Column>
              <SiteHeader.NavList>
                <SiteHeader.NavItem title="My item 17" href="#" />
                <SiteHeader.NavItem title="My item 18" href="#" />
                <SiteHeader.NavItem title="My item 19" href="#" />
                <SiteHeader.NavItem title="My item 20" href="#" />
              </SiteHeader.NavList>
            </SiteHeader.Column>
          </SiteHeader.Section>
        </SiteHeader.Menu>
        <SiteHeader.Menu
          title="About us"
          fallbackHref="https://www.example.com"
        >
          <SiteHeader.Section title="The Company">
            <SiteHeader.Column>
              <SiteHeader.NavList>
                <SiteHeader.NavItem title="My item 21" href="#" />
                <SiteHeader.NavItem title="My item 22" href="#" />
                <SiteHeader.NavItem title="My item 23" href="#" />
                <SiteHeader.NavItem title="My item 24" href="#" />
              </SiteHeader.NavList>
            </SiteHeader.Column>
          </SiteHeader.Section>
        </SiteHeader.Menu>
        <SiteHeader.Menu title="Careers" fallbackHref="https://www.example.com">
          <SiteHeader.Section>
            <SiteHeader.Column>
              <SiteHeader.NavList>
                <SiteHeader.NavItem title="My item 21" href="#" />
                <SiteHeader.NavItem title="My item 22" href="#" />
                <SiteHeader.NavItem title="My item 23" href="#" />
                <SiteHeader.NavItem title="My item 24" href="#" />
              </SiteHeader.NavList>
            </SiteHeader.Column>
          </SiteHeader.Section>
        </SiteHeader.Menu>
        <SiteHeader.Menu title="External" href="https://www.example.com" />
      </SiteHeader>
    </StaticRenderer>
  ))

  .add("Default", () => (
    <Section>
      <DemoLauncher buttonLabel="Show the default SiteHeader">
        <FullPageDemo>
          {" "}
          <SiteHeader
            logoHref="#"
            search={boolean("enable search", true)}
            searchFormAction={text("searchFormAction") || null}
            searchFormMethod={text("searchFormMethod", "get")}
            searchInputName={text("searchInputName", "q")}
            siteName={text("siteName", "")}
            typeaheadUrl={
              boolean("enable typeahead", true) &&
              text(
                "typeaheadUrl",
                "//content.atomz.com/autocomplete/sp10/05/0d/51?_=1506335725065"
              )
            }
          >
            <SiteHeader.Menu
              title="Solutions"
              datasets={`{"data-event-type": "String Six","data-link-type":"stringOne","data-asset-title":"stringTwo","data-href-analytics":"stringThree","data-link-text":"stringFour","data-find-method":"stringFive"}`}
              fallbackHref="https://www.example.com"
            >
              <SiteHeader.Section
                title="Solutions list 1"
                relatedUrl="https://www.example.com"
                relatedName="See All"
              >
                <SiteHeader.Column>
                  <SiteHeader.NavList>
                    <SiteHeader.NavItem title="My item 1" href="#" />
                    <SiteHeader.NavItem title="My item 2" href="#" />
                    <SiteHeader.NavItem title="My item 3" href="#" />
                    <SiteHeader.NavItem title="My item 4" href="#" />
                    <SiteHeader.NavItem title="My item 4.1" href="#" />
                    <SiteHeader.NavItem title="My item 4.2" href="#" />
                    <SiteHeader.NavItem title="My item 4.3" href="#" />
                    <SiteHeader.NavItem title="My item 4.4" href="#" />
                  </SiteHeader.NavList>
                </SiteHeader.Column>
              </SiteHeader.Section>
              <SiteHeader.SectionStack>
                <SiteHeader.Section title="Solutions list 2">
                  <SiteHeader.Column>
                    <SiteHeader.NavList>
                      <SiteHeader.NavItem title="My item 5" href="#" />
                    </SiteHeader.NavList>
                  </SiteHeader.Column>
                </SiteHeader.Section>
                <SiteHeader.Section
                  title="Solutions list 3"
                  // relatedUrl="https://www.example.com"
                  // relatedName="See All"
                >
                  <SiteHeader.Column>
                    <SiteHeader.NavList>
                      <SiteHeader.NavItem title="My item 5" href="#" />
                      <SiteHeader.NavItem title="My item 6" href="#" />
                      <SiteHeader.NavItem title="My item 7" href="#" />
                      <SiteHeader.NavItem title="My item 8" href="#" />
                      <SiteHeader.NavItem title="My item 8.1" href="#" />
                      <SiteHeader.NavItem title="My item 8.2" href="#" />
                      <SiteHeader.NavItem title="My item 8.3" href="#" />
                      <SiteHeader.NavItem title="My item 8.4" href="#" />
                      <SiteHeader.NavItem title="My item 8.5" href="#" />
                      <SiteHeader.NavItem title="My item 8.6" href="#" />
                      <SiteHeader.NavItem title="My item 8.7" href="#" />
                    </SiteHeader.NavList>
                  </SiteHeader.Column>
                </SiteHeader.Section>
              </SiteHeader.SectionStack>
              <SiteHeader.Section
                title="Solutions list 4"
                relatedUrl="https://www.example.com"
                relatedName="See All"
              >
                <SiteHeader.Column>
                  <SiteHeader.NavList>
                    <SiteHeader.NavItem title="My item 9" href="#" />
                    <SiteHeader.NavItem title="My item 10" href="#" />
                    <SiteHeader.NavItem title="My item 11" href="#" />
                    <SiteHeader.NavItem title="My item 12" href="#" />
                  </SiteHeader.NavList>
                </SiteHeader.Column>
              </SiteHeader.Section>
              <SiteHeader.Section title="Featured Item">
                <SiteHeader.Column>{featuredItem}</SiteHeader.Column>
              </SiteHeader.Section>
            </SiteHeader.Menu>
            <SiteHeader.Menu
              title="Articles"
              fallbackHref="https://www.example.com"
            >
              <SiteHeader.Section title="Articles" columns={2}>
                <SiteHeader.Column>
                  <SiteHeader.NavList>
                    <SiteHeader.NavItem title="My item 13" href="#" />
                    <SiteHeader.NavItem
                      title="My item 14 with a very long title"
                      href="#"
                    />
                    <SiteHeader.NavItem title="My item 15" href="#" />
                    <SiteHeader.NavItem title="My item 16" href="#" />
                  </SiteHeader.NavList>
                </SiteHeader.Column>
                <SiteHeader.Column>
                  <SiteHeader.NavList>
                    <SiteHeader.NavItem title="My item 17" href="#" />
                    <SiteHeader.NavItem title="My item 18" href="#" />
                    <SiteHeader.NavItem title="My item 19" href="#" />
                    <SiteHeader.NavItem title="My item 20" href="#" />
                  </SiteHeader.NavList>
                </SiteHeader.Column>
              </SiteHeader.Section>
            </SiteHeader.Menu>
            <SiteHeader.Menu
              title="About us"
              fallbackHref="https://www.example.com"
            >
              <SiteHeader.Section title="The Company">
                <SiteHeader.Column>
                  <SiteHeader.NavList>
                    <SiteHeader.NavItem title="My item 21" href="#" />
                    <SiteHeader.NavItem title="My item 22" href="#" />
                    <SiteHeader.NavItem title="My item 23" href="#" />
                    <SiteHeader.NavItem title="My item 24" href="#" />
                  </SiteHeader.NavList>
                </SiteHeader.Column>
              </SiteHeader.Section>
            </SiteHeader.Menu>
            <SiteHeader.Menu
              title="Careers"
              fallbackHref="https://www.example.com"
            >
              <SiteHeader.Section>
                <SiteHeader.Column>
                  <SiteHeader.NavList>
                    <SiteHeader.NavItem title="My item 21" href="#" />
                    <SiteHeader.NavItem title="My item 22" href="#" />
                    <SiteHeader.NavItem title="My item 23" href="#" />
                    <SiteHeader.NavItem title="My item 24" href="#" />
                  </SiteHeader.NavList>
                </SiteHeader.Column>
              </SiteHeader.Section>
            </SiteHeader.Menu>
            <SiteHeader.Menu title="External" href="https://www.example.com" />
          </SiteHeader>
        </FullPageDemo>
      </DemoLauncher>
    </Section>
  ))
  .add("Fully Comp'd", () => (
    <Section>
      <DemoLauncher buttonLabel="Show SiteHeader fully comp'd">
        <FullPageDemo>
          {" "}
          <React.Fragment>
            <GlobalHeader
              leftItems={<React.Fragment />}
              rightItems={
                <React.Fragment>
                  <GlobalHeader.Login
                    accountLabel="Account"
                    accountOverviewLabel="Account overview"
                    accountOverviewIcon={
                      <Icon path="./icons/ui/grey/user-07.svg" size="s" />
                    }
                    helloLabel="Hello"
                    id="login"
                    loginLabel="Login"
                    loginManager={loginManager}
                    logoutLabel="Logout"
                  />
                  <GlobalHeader.Item label="Contact" href="/contact">
                    <GlobalHeader.Column>
                      <GlobalHeader.NavList>
                        <GlobalHeader.NavItem
                          title="Contact item 1"
                          href="#"
                          icon={
                            <Icon path="./icons/ui/grey/tick-07.svg" size="s" />
                          }
                        />
                        <GlobalHeader.NavItem
                          title="Contact item 2"
                          href="#"
                          icon={
                            <Icon
                              path="./icons/ui/grey/document-07.svg"
                              size="s"
                            />
                          }
                        />
                        <GlobalHeader.NavItem
                          title="Contact item 3 with more text"
                          href="#"
                          icon={
                            <Icon
                              path="./icons/ui/grey/unlocked-07.svg"
                              size="s"
                            />
                          }
                        />
                      </GlobalHeader.NavList>
                    </GlobalHeader.Column>
                  </GlobalHeader.Item>
                </React.Fragment>
              }
              loginManager={loginManager}
            />
            <SiteHeader
              logoHref="#"
              search={boolean("enable search", true)}
              searchFormAction={text("searchFormAction") || null}
              searchFormMethod={text("searchFormMethod", "get")}
              searchInputName={text("searchInputName", "q")}
              siteName={text("siteName", "Tax &amp; Accounting")}
              typeaheadUrl={
                boolean("enable typeahead", true) &&
                text(
                  "typeaheadUrl",
                  "//content.atomz.com/autocomplete/sp10/05/0d/51?_=1506335725065"
                )
              }
            >
              <SiteHeader.Menu
                title="Solutions"
                fallbackHref="https://www.example.com"
              >
                <SiteHeader.Section
                  title="Solutions list 1"
                  relatedUrl="https://www.example.com"
                  relatedName="See All"
                >
                  <SiteHeader.Column>
                    <SiteHeader.NavList>
                      <SiteHeader.NavItem title="My item 1" href="#" />
                      <SiteHeader.NavItem title="My item 2" href="#" />
                      <SiteHeader.NavItem title="My item 3" href="#" />
                      <SiteHeader.NavItem title="My item 4" href="#" />
                    </SiteHeader.NavList>
                  </SiteHeader.Column>
                </SiteHeader.Section>
                <SiteHeader.SectionStack>
                  <SiteHeader.Section title="Solutions list 2">
                    <SiteHeader.Column>
                      <SiteHeader.NavList>
                        <SiteHeader.NavItem title="My item 5" href="#" />
                      </SiteHeader.NavList>
                    </SiteHeader.Column>
                  </SiteHeader.Section>
                  <SiteHeader.Section title="Solutions list 3">
                    <SiteHeader.Column>
                      <SiteHeader.NavList>
                        <SiteHeader.NavItem title="My item 6" href="#" />
                        <SiteHeader.NavItem title="My item 7" href="#" />
                      </SiteHeader.NavList>
                    </SiteHeader.Column>
                  </SiteHeader.Section>
                </SiteHeader.SectionStack>
                <SiteHeader.Section
                  title="Solutions list 4"
                  relatedUrl="https://www.example.com"
                  relatedName="See All"
                >
                  <SiteHeader.Column>
                    <SiteHeader.NavList>
                      <SiteHeader.NavItem title="My item 8" href="#" />
                      <SiteHeader.NavItem title="My item 9" href="#" />
                      <SiteHeader.NavItem title="My item 10" href="#" />
                      <SiteHeader.NavItem title="My item 11" href="#" />
                    </SiteHeader.NavList>
                  </SiteHeader.Column>
                </SiteHeader.Section>
                <SiteHeader.Section title="Featured Item">
                  <SiteHeader.Column>{featuredItem}</SiteHeader.Column>
                </SiteHeader.Section>
              </SiteHeader.Menu>
              <SiteHeader.Menu
                title="Articles"
                fallbackHref="https://www.example.com"
              >
                <SiteHeader.Section title="Articles" columns={2}>
                  <SiteHeader.Column>
                    <SiteHeader.NavList>
                      <SiteHeader.NavItem title="My item 13" href="#" />
                      <SiteHeader.NavItem title="My item 14" href="#" />
                      <SiteHeader.NavItem title="My item 15" href="#" />
                      <SiteHeader.NavItem title="My item 16" href="#" />
                    </SiteHeader.NavList>
                  </SiteHeader.Column>
                  <SiteHeader.Column>
                    <SiteHeader.NavList>
                      <SiteHeader.NavItem title="My item 17" href="#" />
                      <SiteHeader.NavItem title="My item 18" href="#" />
                      <SiteHeader.NavItem title="My item 19" href="#" />
                      <SiteHeader.NavItem title="My item 20" href="#" />
                    </SiteHeader.NavList>
                  </SiteHeader.Column>
                </SiteHeader.Section>
              </SiteHeader.Menu>
              <SiteHeader.Menu
                title="About us"
                fallbackHref="https://www.example.com"
              >
                <SiteHeader.Section>
                  <SiteHeader.Column>
                    <SiteHeader.NavList>
                      <SiteHeader.NavItem title="My item 21" href="#" />
                      <SiteHeader.NavItem title="My item 22" href="#" />
                      <SiteHeader.NavItem title="My item 23" href="#" />
                      <SiteHeader.NavItem title="My item 24" href="#" />
                    </SiteHeader.NavList>
                  </SiteHeader.Column>
                </SiteHeader.Section>
              </SiteHeader.Menu>
              <SiteHeader.Menu
                title="Careers"
                fallbackHref="https://www.example.com"
              >
                <SiteHeader.Section>
                  <SiteHeader.Column>
                    <SiteHeader.NavList>
                      <SiteHeader.NavItem title="My item 21" href="#" />
                      <SiteHeader.NavItem title="My item 22" href="#" />
                      <SiteHeader.NavItem title="My item 23" href="#" />
                      <SiteHeader.NavItem title="My item 24" href="#" />
                    </SiteHeader.NavList>
                  </SiteHeader.Column>
                </SiteHeader.Section>
              </SiteHeader.Menu>
              <SiteHeader.Menu
                title="External"
                href="https://www.example.com"
              />
            </SiteHeader>

            <Section background="grayDark" fullWidth>
              <Poster
                height="l"
                imageOverlay="scrimBottomDark"
                imageSrc="test-images/original/large_1.jpg"
                alignBottom
              />
            </Section>
          </React.Fragment>
        </FullPageDemo>
      </DemoLauncher>
    </Section>
  ))
  .add("Menus, as designed", () => (
    <Section>
      <DemoLauncher buttonLabel="Show SiteHeader with Menus, as designed">
        <FullPageDemo>
          {" "}
          <SiteHeader
            logoHref="#"
            search={boolean("enable search", true)}
            searchFormAction={text("searchFormAction") || null}
            searchFormMethod={text("searchFormMethod", "get")}
            searchInputName={text("searchInputName", "q")}
            siteName={text("siteName", "Tax & Accounting")}
            typeaheadUrl={
              boolean("enable typeahead", true) &&
              text(
                "typeaheadUrl",
                "//content.atomz.com/autocomplete/sp10/05/0d/51?_=1506335725065"
              )
            }
          >
            <SiteHeader.Menu title="1col">
              <SiteHeader.Section
                title="Section title"
                relatedUrl="https://www.example.com"
                relatedName="See All"
              >
                <SiteHeader.Column>
                  <SiteHeader.NavList>
                    <SiteHeader.NavItem title="Link Title" href="#" />
                    <SiteHeader.NavItem
                      title="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla auctor arcu ipsum."
                      href="#"
                    />
                    <SiteHeader.NavItem title="Link Title" href="#" />
                    <SiteHeader.NavItem
                      description="Description"
                      title="Link Title"
                      href="#"
                    />
                    <SiteHeader.NavItem
                      description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla auctor arcu ipsum."
                      title="Link Title"
                      href="#"
                    />
                  </SiteHeader.NavList>
                </SiteHeader.Column>
              </SiteHeader.Section>
            </SiteHeader.Menu>
            <SiteHeader.Menu title="2col">
              <SiteHeader.Section
                title="Section title"
                relatedUrl="https://www.example.com"
                relatedName="See All"
              >
                <SiteHeader.Column>
                  <SiteHeader.NavList>
                    <SiteHeader.NavItem title="Link Title" href="#" />
                    <SiteHeader.NavItem
                      title="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla auctor arcu ipsum."
                      href="#"
                    />
                    <SiteHeader.NavItem title="Link Title" href="#" />
                    <SiteHeader.NavItem
                      description="Description"
                      title="Link Title"
                      href="#"
                    />
                    <SiteHeader.NavItem
                      description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla auctor arcu ipsum."
                      title="Link Title"
                      href="#"
                    />
                  </SiteHeader.NavList>
                </SiteHeader.Column>
              </SiteHeader.Section>
              <SiteHeader.SectionStack>
                <SiteHeader.Section title="Section title">
                  <SiteHeader.Column>
                    <SiteHeader.NavList>
                      <SiteHeader.NavItem title="Link Title" href="#" />
                      <SiteHeader.NavItem title="Link Title" href="#" />
                      <SiteHeader.NavItem title="Link Title" href="#" />
                    </SiteHeader.NavList>
                  </SiteHeader.Column>
                </SiteHeader.Section>
                <SiteHeader.Section title="Section title">
                  <SiteHeader.Column>
                    <SiteHeader.NavList>
                      <SiteHeader.NavItem title="Link Title" href="#" />
                      <SiteHeader.NavItem
                        title="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla auctor arcu ipsum."
                        href="#"
                      />
                      <SiteHeader.NavItem title="Link Title" href="#" />
                    </SiteHeader.NavList>
                  </SiteHeader.Column>
                </SiteHeader.Section>
              </SiteHeader.SectionStack>
            </SiteHeader.Menu>
            <SiteHeader.Menu title="3col">
              <SiteHeader.Section
                title="Section title"
                relatedUrl="https://www.example.com"
                relatedName="See All"
              >
                <SiteHeader.Column>
                  <SiteHeader.NavList>
                    <SiteHeader.NavItem title="Link Title" href="#" />
                    <SiteHeader.NavItem
                      title="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla auctor arcu ipsum."
                      href="#"
                    />
                    <SiteHeader.NavItem title="Link Title" href="#" />
                    <SiteHeader.NavItem
                      description="Description"
                      title="Link Title"
                      href="#"
                    />
                    <SiteHeader.NavItem
                      description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla auctor arcu ipsum."
                      title="Link Title"
                      href="#"
                    />
                  </SiteHeader.NavList>
                </SiteHeader.Column>
              </SiteHeader.Section>
              <SiteHeader.SectionStack>
                <SiteHeader.Section title="Section title">
                  <SiteHeader.Column>
                    <SiteHeader.NavList>
                      <SiteHeader.NavItem title="Link Title" href="#" />
                      <SiteHeader.NavItem title="Link Title" href="#" />
                      <SiteHeader.NavItem title="Link Title" href="#" />
                    </SiteHeader.NavList>
                  </SiteHeader.Column>
                </SiteHeader.Section>
                <SiteHeader.Section title="Section title">
                  <SiteHeader.Column>
                    <SiteHeader.NavList>
                      <SiteHeader.NavItem title="Link Title" href="#" />
                      <SiteHeader.NavItem
                        title="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla auctor arcu ipsum."
                        href="#"
                      />
                      <SiteHeader.NavItem title="Link Title" href="#" />
                    </SiteHeader.NavList>
                  </SiteHeader.Column>
                </SiteHeader.Section>
              </SiteHeader.SectionStack>
              <SiteHeader.Section title="Featured Item">
                <SiteHeader.Column>{featuredItem}</SiteHeader.Column>
              </SiteHeader.Section>
            </SiteHeader.Menu>
            <SiteHeader.Menu title="4col">
              <SiteHeader.SectionStack>
                <SiteHeader.Section title="Section title">
                  <SiteHeader.Column>
                    <SiteHeader.NavList>
                      <SiteHeader.NavItem title="Link Title" href="#" />
                      <SiteHeader.NavItem title="Link Title" href="#" />
                    </SiteHeader.NavList>
                  </SiteHeader.Column>
                </SiteHeader.Section>
                <SiteHeader.Section title="Section title">
                  <SiteHeader.Column>
                    <SiteHeader.NavList>
                      <SiteHeader.NavItem title="Link Title" href="#" />
                      <SiteHeader.NavItem
                        title="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla auctor arcu ipsum."
                        href="#"
                      />
                      <SiteHeader.NavItem title="Link Title" href="#" />
                    </SiteHeader.NavList>
                  </SiteHeader.Column>
                </SiteHeader.Section>
              </SiteHeader.SectionStack>
              <SiteHeader.Section
                title="Section title"
                relatedUrl="https://www.example.com"
                relatedName="See All"
              >
                <SiteHeader.Column>
                  <SiteHeader.NavList>
                    <SiteHeader.NavItem title="Link Title" href="#" />
                    <SiteHeader.NavItem
                      title="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla auctor arcu ipsum."
                      href="#"
                    />
                    <SiteHeader.NavItem title="Link Title" href="#" />
                    <SiteHeader.NavItem
                      description="Description"
                      title="Link Title"
                      href="#"
                    />
                    <SiteHeader.NavItem
                      description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla auctor arcu ipsum."
                      title="Link Title"
                      href="#"
                    />
                  </SiteHeader.NavList>
                </SiteHeader.Column>
              </SiteHeader.Section>
              <SiteHeader.SectionStack>
                <SiteHeader.Section title="Section title">
                  <SiteHeader.Column>
                    <SiteHeader.NavList>
                      <SiteHeader.NavItem title="Link Title" href="#" />
                      <SiteHeader.NavItem title="Link Title" href="#" />
                    </SiteHeader.NavList>
                  </SiteHeader.Column>
                </SiteHeader.Section>
                <SiteHeader.Section title="Section title">
                  <SiteHeader.Column>
                    <SiteHeader.NavList>
                      <SiteHeader.NavItem title="Link Title" href="#" />
                      <SiteHeader.NavItem
                        title="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla auctor arcu ipsum."
                        href="#"
                      />
                      <SiteHeader.NavItem title="Link Title" href="#" />
                    </SiteHeader.NavList>
                  </SiteHeader.Column>
                </SiteHeader.Section>
              </SiteHeader.SectionStack>
              <SiteHeader.Section title="Featured Item">
                <SiteHeader.Column>{featuredItem}</SiteHeader.Column>
              </SiteHeader.Section>
            </SiteHeader.Menu>
            <SiteHeader.Menu title="1-2">
              <SiteHeader.Section title="Section title">
                <SiteHeader.Column>
                  <Block height="400px" />
                </SiteHeader.Column>
              </SiteHeader.Section>
              <SiteHeader.Section title="Section title" columns={2}>
                <SiteHeader.Column>
                  <Block height="400px" />
                </SiteHeader.Column>
                <SiteHeader.Column>
                  <Block height="400px" />
                </SiteHeader.Column>
              </SiteHeader.Section>
            </SiteHeader.Menu>
            <SiteHeader.Menu title="1-3">
              <SiteHeader.Section title="Section title">
                <SiteHeader.Column>
                  <Block height="400px" />
                </SiteHeader.Column>
              </SiteHeader.Section>
              <SiteHeader.Section title="Section title" columns={3}>
                <SiteHeader.Column>
                  <Block height="400px" />
                </SiteHeader.Column>
                <SiteHeader.Column>
                  <Block height="400px" />
                </SiteHeader.Column>
                <SiteHeader.Column>
                  <Block height="400px" />
                </SiteHeader.Column>
              </SiteHeader.Section>
            </SiteHeader.Menu>
            <SiteHeader.Menu title="2-2">
              <SiteHeader.Section title="Section title" columns={2}>
                <SiteHeader.Column>
                  <Block height="400px" />
                </SiteHeader.Column>
                <SiteHeader.Column>
                  <Block height="400px" />
                </SiteHeader.Column>
              </SiteHeader.Section>
              <SiteHeader.Section title="Section title" columns={2}>
                <SiteHeader.Column>
                  <Block height="400px" />
                </SiteHeader.Column>
                <SiteHeader.Column>
                  <Block height="400px" />
                </SiteHeader.Column>
              </SiteHeader.Section>
            </SiteHeader.Menu>
            <SiteHeader.Menu
              title="Flyouts"
              fallbackHref="https://www.example.com"
            >
              <SiteHeader.Flyouts
                summaryLinkHref="https://example.com"
                summaryLinkText="Link Title"
              >
                <SiteHeader.Flyouts.TabPanel id="tab1" tabTitle="1col">
                  <SiteHeader.SectionStack>
                    <SiteHeader.Section title="Section title">
                      <SiteHeader.Column>
                        <SiteHeader.NavList>
                          <SiteHeader.NavItem title="Link Title" href="#" />
                          <SiteHeader.NavItem title="Link Title" href="#" />
                          <SiteHeader.NavItem title="Link Title" href="#" />
                        </SiteHeader.NavList>
                      </SiteHeader.Column>
                    </SiteHeader.Section>
                    <SiteHeader.Section title="Section title">
                      <SiteHeader.Column>
                        <SiteHeader.NavList>
                          <SiteHeader.NavItem title="Link Title" href="#" />
                          <SiteHeader.NavItem
                            title="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla auctor arcu ipsum."
                            href="#"
                          />
                          <SiteHeader.NavItem title="Link Title" href="#" />
                        </SiteHeader.NavList>
                      </SiteHeader.Column>
                    </SiteHeader.Section>
                  </SiteHeader.SectionStack>
                </SiteHeader.Flyouts.TabPanel>
                <SiteHeader.Flyouts.TabPanel id="tab2" tabTitle="2col">
                  <SiteHeader.Section
                    title="Section title"
                    relatedUrl="https://www.example.com"
                    relatedName="See All"
                  >
                    <SiteHeader.Column>
                      <SiteHeader.NavList>
                        <SiteHeader.NavItem title="Link Title" href="#" />
                        <SiteHeader.NavItem
                          title="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla auctor arcu ipsum."
                          href="#"
                        />
                        <SiteHeader.NavItem title="Link Title" href="#" />
                        <SiteHeader.NavItem
                          description="Description"
                          title="Link Title"
                          href="#"
                        />
                        <SiteHeader.NavItem
                          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla auctor arcu ipsum."
                          title="Link Title"
                          href="#"
                        />
                      </SiteHeader.NavList>
                    </SiteHeader.Column>
                  </SiteHeader.Section>
                  <SiteHeader.Section title="Featured Item">
                    <SiteHeader.Column>{featuredItem}</SiteHeader.Column>
                  </SiteHeader.Section>
                </SiteHeader.Flyouts.TabPanel>
                <SiteHeader.Flyouts.TabPanel id="tab3" tabTitle="3col">
                  <SiteHeader.Section
                    title="Section title"
                    relatedUrl="https://www.example.com"
                    relatedName="See All"
                  >
                    <SiteHeader.Column>
                      <SiteHeader.NavList>
                        <SiteHeader.NavItem title="Link Title" href="#" />
                        <SiteHeader.NavItem
                          title="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla auctor arcu ipsum."
                          href="#"
                        />
                        <SiteHeader.NavItem title="Link Title" href="#" />
                        <SiteHeader.NavItem
                          description="Description"
                          title="Link Title"
                          href="#"
                        />
                        <SiteHeader.NavItem
                          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla auctor arcu ipsum."
                          title="Link Title"
                          href="#"
                        />
                      </SiteHeader.NavList>
                    </SiteHeader.Column>
                  </SiteHeader.Section>
                  <SiteHeader.SectionStack>
                    <SiteHeader.Section title="Section title">
                      <SiteHeader.Column>
                        <SiteHeader.NavList>
                          <SiteHeader.NavItem title="Link Title" href="#" />
                          <SiteHeader.NavItem title="Link Title" href="#" />
                          <SiteHeader.NavItem title="Link Title" href="#" />
                        </SiteHeader.NavList>
                      </SiteHeader.Column>
                    </SiteHeader.Section>
                    <SiteHeader.Section title="Section title">
                      <SiteHeader.Column>
                        <SiteHeader.NavList>
                          <SiteHeader.NavItem title="Link Title" href="#" />
                          <SiteHeader.NavItem
                            title="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla auctor arcu ipsum."
                            href="#"
                          />
                          <SiteHeader.NavItem title="Link Title" href="#" />
                        </SiteHeader.NavList>
                      </SiteHeader.Column>
                    </SiteHeader.Section>
                  </SiteHeader.SectionStack>
                  <SiteHeader.Section title="Featured Item">
                    <SiteHeader.Column>{featuredItem}</SiteHeader.Column>
                  </SiteHeader.Section>
                </SiteHeader.Flyouts.TabPanel>
                <SiteHeader.Flyouts.TabPanel id="tab4" tabTitle="2-1">
                  <SiteHeader.Section
                    title="Section title"
                    columns={2}
                    relatedUrl="https://www.example.com"
                    relatedName="See All"
                  >
                    <SiteHeader.Column>
                      <SiteHeader.NavList>
                        <SiteHeader.NavItem title="Link Title" href="#" />
                        <SiteHeader.NavItem
                          title="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla auctor arcu ipsum."
                          href="#"
                        />
                        <SiteHeader.NavItem title="Link Title" href="#" />
                        <SiteHeader.NavItem
                          description="Description"
                          title="Link Title"
                          href="#"
                        />
                        <SiteHeader.NavItem
                          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla auctor arcu ipsum."
                          title="Link Title"
                          href="#"
                        />
                      </SiteHeader.NavList>
                    </SiteHeader.Column>
                    <SiteHeader.Column>
                      <SiteHeader.NavList>
                        <SiteHeader.NavItem title="Link Title" href="#" />
                        <SiteHeader.NavItem
                          title="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla auctor arcu ipsum."
                          href="#"
                        />
                        <SiteHeader.NavItem title="Link Title" href="#" />
                        <SiteHeader.NavItem
                          description="Description"
                          title="Link Title"
                          href="#"
                        />
                        <SiteHeader.NavItem
                          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla auctor arcu ipsum."
                          title="Link Title"
                          href="#"
                        />
                      </SiteHeader.NavList>
                    </SiteHeader.Column>
                  </SiteHeader.Section>
                  <SiteHeader.SectionStack>
                    <SiteHeader.Section title="Section title">
                      <SiteHeader.Column>
                        <SiteHeader.NavList>
                          <SiteHeader.NavItem title="Link Title" href="#" />
                          <SiteHeader.NavItem title="Link Title" href="#" />
                          <SiteHeader.NavItem title="Link Title" href="#" />
                        </SiteHeader.NavList>
                      </SiteHeader.Column>
                    </SiteHeader.Section>
                    <SiteHeader.Section title="Section title">
                      <SiteHeader.Column>
                        <SiteHeader.NavList>
                          <SiteHeader.NavItem title="Link Title" href="#" />
                          <SiteHeader.NavItem
                            title="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla auctor arcu ipsum."
                            href="#"
                          />
                          <SiteHeader.NavItem title="Link Title" href="#" />
                        </SiteHeader.NavList>
                      </SiteHeader.Column>
                    </SiteHeader.Section>
                  </SiteHeader.SectionStack>
                </SiteHeader.Flyouts.TabPanel>
                <SiteHeader.Flyouts.TabPanel
                  id="tab5"
                  tabTitle="Lorem ipsum dolor sit amet consectetur adipiscing elit"
                >
                  <SiteHeader.Section title="Featured Item">
                    <SiteHeader.Column>{featuredItem}</SiteHeader.Column>
                  </SiteHeader.Section>
                </SiteHeader.Flyouts.TabPanel>
              </SiteHeader.Flyouts>
            </SiteHeader.Menu>
          </SiteHeader>
        </FullPageDemo>
      </DemoLauncher>
    </Section>
  ))
  .add("Long titles", () => (
    <Section>
      <DemoLauncher buttonLabel="Show SiteHeader with long titles">
        <FullPageDemo>
          {" "}
          <SiteHeader
            logoHref="#"
            search={boolean("enable search", true)}
            searchFormAction={text("searchFormAction") || null}
            searchFormMethod={text("searchFormMethod", "get")}
            searchInputName={text("searchInputName", "q")}
            siteName={text("siteName", "Tax & Accounting")}
            typeaheadUrl={
              boolean("enable typeahead", true) &&
              text(
                "typeaheadUrl",
                "//content.atomz.com/autocomplete/sp10/05/0d/51?_=1506335725065"
              )
            }
          >
            <SiteHeader.Menu
              title="Long title to test wrapping in narrow view"
              href="https://www.example.com"
            />
            <SiteHeader.Menu
              title="Another long title how does this one wrap"
              href="https://www.example.com"
            />
          </SiteHeader>
        </FullPageDemo>
      </DemoLauncher>
    </Section>
  ))
  .add("Language: Arabic (RTL)", () => (
    <Section>
      <DemoLauncher buttonLabel="Show SiteHeader in Arabic (RTL)">
        <FullPageDemo>
          {" "}
          <Language code="ar">
            <SiteHeader
              logoHref="#"
              search={boolean("enable search", true)}
              searchFormAction={text("searchFormAction") || null}
              searchFormMethod={text("searchFormMethod", "get")}
              searchInputName={text("searchInputName", "q")}
              siteName={text("siteName", "Tax & Accounting")}
              typeaheadUrl={
                boolean("enable typeahead", true) &&
                text(
                  "typeaheadUrl",
                  "//content.atomz.com/autocomplete/sp10/05/0d/51?_=1506335725065"
                )
              }
            >
              <SiteHeader.Menu title="منتجات">
                <SiteHeader.Section
                  title="قائمة المنتجات 1"
                  relatedUrl="https://www.example.com"
                  relatedName="اظهار الكل"
                >
                  <SiteHeader.Column>
                    <SiteHeader.NavList>
                      <SiteHeader.NavItem title="البند 1" href="#" />
                      <SiteHeader.NavItem title="البند 2" href="#" />
                      <SiteHeader.NavItem title="البند 3" href="#" />
                      <SiteHeader.NavItem title="البند 4" href="#" />
                    </SiteHeader.NavList>
                  </SiteHeader.Column>
                </SiteHeader.Section>
                <SiteHeader.SectionStack>
                  <SiteHeader.Section title="قائمة المنتجات 2">
                    <SiteHeader.Column>
                      <SiteHeader.NavList>
                        <SiteHeader.NavItem title="البند 5" href="#" />
                      </SiteHeader.NavList>
                    </SiteHeader.Column>
                  </SiteHeader.Section>
                  <SiteHeader.Section title="قائمة المنتجات 3">
                    <SiteHeader.Column>
                      <SiteHeader.NavList>
                        <SiteHeader.NavItem title="البند 6" href="#" />
                        <SiteHeader.NavItem title="البند 7" href="#" />
                      </SiteHeader.NavList>
                    </SiteHeader.Column>
                  </SiteHeader.Section>
                </SiteHeader.SectionStack>
                <SiteHeader.Section title="قائمة المنتجات 4">
                  <SiteHeader.Column>
                    <SiteHeader.NavList>
                      <SiteHeader.NavItem title="البند 8" href="#" />
                      <SiteHeader.NavItem title="البند 9" href="#" />
                      <SiteHeader.NavItem title="البند 10" href="#" />
                      <SiteHeader.NavItem title="البند 11" href="#" />
                    </SiteHeader.NavList>
                  </SiteHeader.Column>
                </SiteHeader.Section>
                <SiteHeader.Section
                  title="قائمة المنتجات 5"
                  relatedUrl="https://www.example.com"
                  relatedName="اظهار الكل"
                >
                  <SiteHeader.Column>
                    <div>عشوائي محتوى آخر</div>
                  </SiteHeader.Column>
                </SiteHeader.Section>
              </SiteHeader.Menu>
              <SiteHeader.Menu title="الصناعات">
                <SiteHeader.Section title="1 قائمة الصناعات">
                  <SiteHeader.Column>
                    <SiteHeader.NavList>
                      <SiteHeader.NavItem title="البند 13" href="#" />
                      <SiteHeader.NavItem title="البند 14" href="#" />
                      <SiteHeader.NavItem title="البند 15" href="#" />
                      <SiteHeader.NavItem title="البند 16" href="#" />
                    </SiteHeader.NavList>
                  </SiteHeader.Column>
                </SiteHeader.Section>
                <SiteHeader.Section title="2 قائمة الصناعات">
                  <SiteHeader.Column>
                    <SiteHeader.NavList>
                      <SiteHeader.NavItem title="البند 17" href="#" />
                      <SiteHeader.NavItem title="البند 18" href="#" />
                      <SiteHeader.NavItem title="البند 19" href="#" />
                      <SiteHeader.NavItem title="البند 20" href="#" />
                    </SiteHeader.NavList>
                  </SiteHeader.Column>
                </SiteHeader.Section>
              </SiteHeader.Menu>
              <SiteHeader.Menu
                title="تطير"
                fallbackHref="https://www.example.com"
              >
                <SiteHeader.Flyouts
                  summaryLinkHref="https://example.com"
                  summaryLinkText="Link Title"
                >
                  <SiteHeader.Flyouts.TabPanel
                    id="tab1"
                    tabTitle="Lorem ipsum dolor sit amet consectetur adipiscing elit"
                  >
                    <SiteHeader.Section title="Featured Item">
                      <SiteHeader.Column>{featuredItem}</SiteHeader.Column>
                    </SiteHeader.Section>
                  </SiteHeader.Flyouts.TabPanel>
                  <SiteHeader.Flyouts.TabPanel
                    id="tab2"
                    tabTitle="Elit adipiscing consectetur amet sit dolor ipsum lorem."
                  >
                    <SiteHeader.Section title="Featured Item">
                      <SiteHeader.Column>{featuredItem}</SiteHeader.Column>
                    </SiteHeader.Section>
                  </SiteHeader.Flyouts.TabPanel>
                </SiteHeader.Flyouts>
              </SiteHeader.Menu>
            </SiteHeader>
          </Language>
        </FullPageDemo>
      </DemoLauncher>
    </Section>
  ));

const storyFullyComped = (
  <React.Fragment>
    <GlobalHeader
      leftItems={<React.Fragment />}
      rightItems={
        <React.Fragment>
          <GlobalHeader.Login
            accountLabel="Account"
            accountOverviewLabel="Account overview"
            accountOverviewIcon={
              <Icon path="./icons/ui/grey/user-07.svg" size="s" />
            }
            helloLabel="Hello"
            id="login"
            loginLabel="Login"
            loginManager={loginManager}
            logoutLabel="Logout"
          />
          <GlobalHeader.Item label="Contact" href="/contact">
            <GlobalHeader.Column>
              <GlobalHeader.NavList>
                <GlobalHeader.NavItem
                  title="Contact item 1"
                  href="#"
                  icon={<Icon path="./icons/ui/grey/tick-07.svg" size="s" />}
                />
                <GlobalHeader.NavItem
                  title="Contact item 2"
                  href="#"
                  icon={
                    <Icon path="./icons/ui/grey/document-07.svg" size="s" />
                  }
                />
                <GlobalHeader.NavItem
                  title="Contact item 3 with more text"
                  href="#"
                  icon={
                    <Icon path="./icons/ui/grey/unlocked-07.svg" size="s" />
                  }
                />
              </GlobalHeader.NavList>
            </GlobalHeader.Column>
          </GlobalHeader.Item>
        </React.Fragment>
      }
      loginManager={loginManager}
    />
    <SiteHeader
      logoHref="#"
      search={boolean("enable search", true)}
      searchFormAction={text("searchFormAction") || null}
      searchFormMethod={text("searchFormMethod", "get")}
      searchInputName={text("searchInputName", "q")}
      siteName={text("siteName", "Tax &amp; Accounting")}
      typeaheadUrl={
        boolean("enable typeahead", true) &&
        text(
          "typeaheadUrl",
          "//content.atomz.com/autocomplete/sp10/05/0d/51?_=1506335725065"
        )
      }
    >
      <SiteHeader.Menu title="Solutions" fallbackHref="https://www.example.com">
        <SiteHeader.Section
          title="Solutions list 1"
          relatedUrl="https://www.example.com"
          relatedName="See All"
        >
          <SiteHeader.Column>
            <SiteHeader.NavList>
              <SiteHeader.NavItem title="My item 1" href="#" />
              <SiteHeader.NavItem title="My item 2" href="#" />
              <SiteHeader.NavItem title="My item 3" href="#" />
              <SiteHeader.NavItem title="My item 4" href="#" />
            </SiteHeader.NavList>
          </SiteHeader.Column>
        </SiteHeader.Section>
        <SiteHeader.SectionStack>
          <SiteHeader.Section title="Solutions list 2">
            <SiteHeader.Column>
              <SiteHeader.NavList>
                <SiteHeader.NavItem title="My item 5" href="#" />
              </SiteHeader.NavList>
            </SiteHeader.Column>
          </SiteHeader.Section>
          <SiteHeader.Section title="Solutions list 3">
            <SiteHeader.Column>
              <SiteHeader.NavList>
                <SiteHeader.NavItem title="My item 6" href="#" />
                <SiteHeader.NavItem title="My item 7" href="#" />
              </SiteHeader.NavList>
            </SiteHeader.Column>
          </SiteHeader.Section>
        </SiteHeader.SectionStack>
        <SiteHeader.Section
          title="Solutions list 4"
          relatedUrl="https://www.example.com"
          relatedName="See All"
        >
          <SiteHeader.Column>
            <SiteHeader.NavList>
              <SiteHeader.NavItem title="My item 8" href="#" />
              <SiteHeader.NavItem title="My item 9" href="#" />
              <SiteHeader.NavItem title="My item 10" href="#" />
              <SiteHeader.NavItem title="My item 11" href="#" />
            </SiteHeader.NavList>
          </SiteHeader.Column>
        </SiteHeader.Section>
        <SiteHeader.Section title="Featured Item">
          <SiteHeader.Column>{featuredItem}</SiteHeader.Column>
        </SiteHeader.Section>
      </SiteHeader.Menu>
      <SiteHeader.Menu title="Articles" fallbackHref="https://www.example.com">
        <SiteHeader.Section title="Articles" columns={2}>
          <SiteHeader.Column>
            <SiteHeader.NavList>
              <SiteHeader.NavItem title="My item 13" href="#" />
              <SiteHeader.NavItem title="My item 14" href="#" />
              <SiteHeader.NavItem title="My item 15" href="#" />
              <SiteHeader.NavItem title="My item 16" href="#" />
            </SiteHeader.NavList>
          </SiteHeader.Column>
          <SiteHeader.Column>
            <SiteHeader.NavList>
              <SiteHeader.NavItem title="My item 17" href="#" />
              <SiteHeader.NavItem title="My item 18" href="#" />
              <SiteHeader.NavItem title="My item 19" href="#" />
              <SiteHeader.NavItem title="My item 20" href="#" />
            </SiteHeader.NavList>
          </SiteHeader.Column>
        </SiteHeader.Section>
      </SiteHeader.Menu>
      <SiteHeader.Menu title="About us" fallbackHref="https://www.example.com">
        <SiteHeader.Section>
          <SiteHeader.Column>
            <SiteHeader.NavList>
              <SiteHeader.NavItem title="My item 21" href="#" />
              <SiteHeader.NavItem title="My item 22" href="#" />
              <SiteHeader.NavItem title="My item 23" href="#" />
              <SiteHeader.NavItem title="My item 24" href="#" />
            </SiteHeader.NavList>
          </SiteHeader.Column>
        </SiteHeader.Section>
      </SiteHeader.Menu>
      <SiteHeader.Menu title="Careers" fallbackHref="https://www.example.com">
        <SiteHeader.Section>
          <SiteHeader.Column>
            <SiteHeader.NavList>
              <SiteHeader.NavItem title="My item 21" href="#" />
              <SiteHeader.NavItem title="My item 22" href="#" />
              <SiteHeader.NavItem title="My item 23" href="#" />
              <SiteHeader.NavItem title="My item 24" href="#" />
            </SiteHeader.NavList>
          </SiteHeader.Column>
        </SiteHeader.Section>
      </SiteHeader.Menu>
      <SiteHeader.Menu title="External" href="https://www.example.com" />
    </SiteHeader>

    <Section background="grayDark" fullWidth>
      <Poster
        height="l"
        imageOverlay="scrimBottomDark"
        imageSrc="test-images/original/large_1.jpg"
        alignBottom
      />
    </Section>
  </React.Fragment>
);

export { storyFullyComped };
