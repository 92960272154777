import React from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import SiteFooter from "./SiteFooter";

export default async (domNode, rehydrateChildren) => {
  const getClassName = getClassNameFactory("SiteFooter");

  const childrenNode = domNode.querySelector(
    `.${getClassName({ descendantName: "listsInner" })}`
  );

  const reactifiedChildren = childrenNode
    ? await rehydrateChildren(childrenNode)
    : null;

  const autosuggestion = domNode.getAttribute("data-autosuggestion");

  const size = domNode.getAttribute("data-size");

  const searchMinLength = domNode.getAttribute("data-search-min-length");

  const placeholder = domNode.getAttribute("data-placeholder");

  const props = {
    isRehydrating: true,
    searchFormAction: domNode.getAttribute("data-search-form-action"),
    searchFormMethod: domNode.getAttribute("data-search-form-method"),
    searchInputName: domNode.getAttribute("data-search-input-name"),
    typeaheadUrl: domNode.getAttribute("data-typeahead-url"),
    elasticKey: domNode.getAttribute("data-elastic-key"),
    ...(searchMinLength && {
      searchMinLength: parseInt(searchMinLength)
    }),
    ...(autosuggestion && {
      autosuggestion
    }),
    ...(size && {
      size: parseInt(size)
    }),
    ...(placeholder && {
      placeholder
    })
  };

  return <SiteFooter {...props}>{reactifiedChildren}</SiteFooter>;
};
