import PropTypes from "prop-types";
import React from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";

const Svg = ({ children, height, label, width }) => {
  const getClassName = getClassNameFactory(Svg.displayName);

  const baseFontSize = 16;

  /* eslint-disable no-undefined */
  const svg = React.cloneElement(children, {
    "aria-hidden": label ? undefined : true,
    "aria-label": label,
    role: label ? "img" : undefined
  });
  /* eslint-enable no-undefined */

  const svgProps = { className: getClassName() };
  const innerProps = { className: getClassName({ descendantName: "inner" }) };

  if (width) {
    svgProps.style = { width: `${width / baseFontSize}rem` };
  }

  /* eslint-disable no-magic-numbers */
  if (height && width) {
    innerProps.style = { paddingBottom: `${height / width * 100}%` };
  }
  /* eslint-enable no-magic-numbers */

  return (
    <span {...svgProps}>
      <span {...innerProps}>{svg}</span>
    </span>
  );
};

Svg.displayName = "Svg";

Svg.propTypes = {
  /**
   * A single SVG element
   */
  children: PropTypes.node.isRequired,
  /**
   * Height of the SVG, in pixels
   */
  height: PropTypes.number,
  /**
   * Accessible alternative text
   */
  label: PropTypes.string,
  /**
   * Width of the SVG, in pixels
   */
  width: PropTypes.number
};

export default Svg;
