import { getClassName as baseGetClassName } from "react-suitcssify/dist/lib/utility";
import configuration from "@emcm-ui/utility-configuration";

const getClassName = args => {
  return baseGetClassName({
    namespace: configuration.current.styles.namespace,
    ...args
  });
};

const getClassNameFactory = componentName => {
  return args =>
    getClassName({
      componentName,
      ...args
    });
};

export default getClassNameFactory;
export { getClassName };
