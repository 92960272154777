/**
 * @define VerticalNav.Item
 * VerticalNav.Item Component
 */

// Vendor
import React, { Component } from "react";
import kebabCase from "lodash.kebabcase";
import PropTypes from "prop-types";
import classNames from "classnames";

// emcm-ui
import Anchor from "@emcm-ui/component-anchor";
import getClassNameFactory from "@emcm-ui/utility-class-names";

// local
import { Consumer as VerticalNavConsumer } from "../../verticalNavContext";

class Item extends Component {
  static displayName = "VerticalNav.Item";

  static defaultProps = {
    label: "",
    href: "",
    dataAnchor: ""
  };

  static propTypes = {
    /**
     * ajaxContext object provided if in a AjaxContextContainer
     */
    ajaxContext: PropTypes.oneOfType([
      PropTypes.shape({
        href: PropTypes.string,
        updateHref: PropTypes.func
      }),
      PropTypes.bool
    ]),
    /**
     * Title for the menu item
     */
    label: PropTypes.string,
    /**
     * to set default selectedItem
     */
    isSelected: PropTypes.bool,
    /**
     * href for the menu item
     */
    href: PropTypes.string,
    /**
     * indent for the menu item calculated internally. Don't use it
     */
    indent: PropTypes.number,
    /**
     * To know whether rehydration happens or not. Don't use it
     */
    isRehydrating: PropTypes.bool,
    /**
     * Callback when item is selected
     */
    onItemClick: PropTypes.func,
    /**
     * selectedItem name to highlight
     */
    selectedItem: PropTypes.string,
    /**
     * icon type name to highlight
     */
    iconType: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    /**
     * The account overview icon type id, to be passed into <NavItem>.
     */
    iconPath: PropTypes.string,
    /**
     * opt in for Ajax. Requires the component to be a chilf of a AjaxContentContainer.
     */
    isAjax: PropTypes.bool,

    isUrlHashActive: PropTypes.bool,

    /**
     * selectedItem name to highlight
     */
    dataAnchor: PropTypes.string
  };

  state = {
    isSelected: false
  };

  componentDidMount() {
    const { label, href, ajaxContext, dataAnchor } = this.props;

    if (this.props.isSelected)
      this.props.onItemClick({ label, href, ajaxContext, dataAnchor });
  }

  render() {
    const {
      label,
      isSelected,
      href,
      indent,
      selectedItem,
      onItemClick,
      iconType,
      iconPath,
      isAjax,
      isUrlHashActive,
      ajaxContext,
      dataAnchor
    } = this.props;
    const getClassName = getClassNameFactory("VerticalNavItem");

    return (
      <div
        role="presentation"
        onClick={event => {
          this.setState(
            { isSelected: true },
            onItemClick({
              label,
              event,
              href,
              ajaxContext,
              isAjax,
              isUrlHashActive,
              dataAnchor
            })
          );
        }}
        className={getClassName({
          modifiers: classNames(indent),
          states: selectedItem === label ? "selected" : ""
        })}
        data-is-urlactive={isUrlHashActive}
        data-is-ajax={isAjax}
        data-href={href}
        data-icon-type={iconType}
        data-icon-path={iconPath}
        data-label={label}
        data-is-selected={isSelected}
        data-id={kebabCase(label)}
        data-anchor={dataAnchor}
      >
        {iconType && (
          <span className={getClassName({ descendantName: "icon" })}>
            {iconType}
          </span>
        )}
        <Anchor
          className={getClassName({ descendantName: "link" })}
          href={href}
          kind="button"
        >
          {label}
        </Anchor>
      </div>
    );
  }
}

const ItemWithContext = ({ ...props }) => (
  <VerticalNavConsumer>
    {({ selectedItem, onItemClick }) => (
      <Item {...props} onItemClick={onItemClick} selectedItem={selectedItem} />
    )}
  </VerticalNavConsumer>
);

export default ItemWithContext;
