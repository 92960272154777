import React from "react";
import { storiesOf } from "@storybook/react";
import { withKnobs, select } from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";
import { Language } from "@emcm-ui/component-theme";
import Section from "@emcm-ui/component-section";
import Table from "./index";
import "./Table.css";
import TableREADME from "../README.md";

const stories = storiesOf("Components/Table", module);

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(TableREADME));

stories
  .add("Default", () => (
    <Section>
      <Table size={select("size", ["default", "compact"], "default")}>
        <table>
          <caption>Caption for table</caption>
          <thead>
            <tr>
              <th scope="col">Name of Entity</th>
              <th scope="col">Registered Address</th>
              <th scope="col">Registered Number</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Thomson Reuters (Markets) SA., Belgium Branch</td>
              <td>Marnixlaan 17 1000, Brussels Belgium</td>
              <td>0473.542.617</td>
            </tr>
            <tr>
              <td>eXimius NV</td>
              <td>Zandvoortstraat 45C 2800, Mechelen Belgium</td>
              <td>BTW BE 0881.564.110 RPR Mechelen</td>
            </tr>
            <tr>
              <td>Aktor Knowledge Technology N.V.</td>
              <td>Sint Pietersvliet 7 Antwerp, 2000 Belgium</td>
              <td>HRA 332462</td>
            </tr>
            <tr>
              <td>Compu-Mark N.V.</td>
              <td>St Pietersvliet 7 B-2000 Antwerpen Belgium</td>
              <td>271 928</td>
            </tr>
          </tbody>
        </table>
      </Table>
    </Section>
  ))
  .add("Border example", () => (
    <Section>
      <Table size={select("size", ["default", "compact"], "default")}>
        <table>
          <caption>Caption for table</caption>
          <thead>
            <tr>
              <th scope="col">Name of Entity</th>
              <th scope="col">Registered Address</th>
              <th scope="col">Registered Number</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Thomson Reuters (Markets) SA., Belgium Branch</td>
              <td>Marnixlaan 17 1000, Brussels Belgium</td>
              <td>0473.542.617</td>
            </tr>
            <tr>
              <td border="medium">eXimius NV</td>
              <td border="medium">
                Zandvoortstraat 45C 2800, Mechelen Belgium
              </td>
              <td border="medium">BTW BE 0881.564.110 RPR Mechelen</td>
            </tr>
            <tr>
              <td border="dark">Aktor Knowledge Technology N.V.</td>
              <td border="dark">Sint Pietersvliet 7 Antwerp, 2000 Belgium</td>
              <td border="dark">HRA 332462</td>
            </tr>
          </tbody>
        </table>
      </Table>
    </Section>
  ))
  .add("Earnings example", () => (
    <Section>
      <Table size={select("size", ["default", "compact"], "default")}>
        <table>
          <caption>Caption for table</caption>
          <thead>
            <tr>
              <th scope="col">
                IFRS Financial Measures <sup>1</sup>
              </th>
              <th scope="col">2017</th>
              <th scope="col">2016</th>
              <th scope="col">Change</th>
              <th scope="col">Change at Constant Currency</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Revenues</td>
              <td>$2,782</td>
              <td>$2,769</td>
              <td>0%</td>
              <td />
            </tr>
            <tr>
              <td>Operating profit</td>
              <td>$399</td>
              <td>$401</td>
              <td>0%</td>
              <td />
            </tr>
            <tr>
              <td>Diluted EPS (includes discontinued operations)</td>
              <td>$0.27</td>
              <td>$0.45</td>
              <td>-40%</td>
              <td />
            </tr>
            <tr>
              <td>
                Cash flow from operations (includes discontinued operations)
              </td>
              <td>$834</td>
              <td>$770</td>
              <td>8%</td>
              <td />
            </tr>
            <tr>
              <td>
                Non-IFRS Financial Measures <sup>1</sup>
              </td>
              <td />
              <td />
              <td />
              <td />
            </tr>
            <tr>
              <td>Revenues</td>
              <td>$2,782</td>
              <td>$2,769</td>
              <td>0%</td>
              <td>2%</td>
            </tr>
            <tr>
              <td>Adjusted EBITDA</td>
              <td>$838</td>
              <td>$757</td>
              <td>11%</td>
              <td>11%</td>
            </tr>
            <tr>
              <td>
                <i>Adjusted EBITDA margin</i>
              </td>
              <td>
                <i>30.1%</i>
              </td>
              <td>
                <i>27.3%</i>
              </td>
              <td>
                <i>280bp</i>
              </td>
              <td>
                <i>230bp</i>
              </td>
            </tr>
            <tr>
              <td>Adjusted EPS</td>
              <td>$0.60</td>
              <td>$0.47</td>
              <td>28%</td>
              <td>28%</td>
            </tr>
            <tr>
              <td>Free cash flow (includes discontinued operations)</td>
              <td>$580</td>
              <td>$525</td>
              <td>10%</td>
              <td />
            </tr>
          </tbody>
        </table>
      </Table>
    </Section>
  ))
  .add("Language: Arabic (RTL)", () => (
    <Language code="ar">
      <Section>
        <Table size={select("size", ["default", "compact"], "default")}>
          <table>
            <caption>فإن الجمعية العامة</caption>
            <thead>
              <tr>
                <th scope="col">فإن الجمعية العامة</th>
                <th scope="col">فإن الجمعية العامة</th>
                <th scope="col">فإن الجمعية العامة</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>فإن الجمعية العامة</td>
                <td>فإن الجمعية العامة</td>
                <td>فإن الجمعية العامة</td>
              </tr>
              <tr>
                <td>فإن الجمعية العامة</td>
                <td>فإن الجمعية العامة</td>
                <td>فإن الجمعية العامة</td>
              </tr>
              <tr>
                <td>فإن الجمعية العامة</td>
                <td>فإن الجمعية العامة</td>
                <td>فإن الجمعية العامة</td>
              </tr>
              <tr>
                <td>فإن الجمعية العامة</td>
                <td>فإن الجمعية العامة</td>
                <td>فإن الجمعية العامة</td>
              </tr>
            </tbody>
          </table>
          <table>
            <caption>فإن الجمعية العامة</caption>
            <thead>
              <tr>
                <th scope="col">
                  فإن الجمعية العامة<sup>1</sup>
                </th>
                <th scope="col">2017</th>
                <th scope="col">2016</th>
                <th scope="col">Change</th>
                <th scope="col">فإن الجمعية العامة</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>فإنة</td>
                <td>$2,782</td>
                <td>$2,769</td>
                <td>0%</td>
                <td />
              </tr>
              <tr>
                <td>فإن الجمعية </td>
                <td>$399</td>
                <td>$401</td>
                <td>0%</td>
                <td />
              </tr>
              <tr>
                <td>فإن الجمعية العامة</td>
                <td>$0.27</td>
                <td>$0.45</td>
                <td>-40%</td>
                <td />
              </tr>
              <tr>
                <td>فإن الجمعية العامة</td>
                <td>$834</td>
                <td>$770</td>
                <td>8%</td>
                <td />
              </tr>
              <tr>
                <td>
                  فإن الجمعية العامة <sup>1</sup>
                </td>
                <td />
                <td />
                <td />
                <td />
              </tr>
              <tr>
                <td>فإن الجمعية </td>
                <td>$2,782</td>
                <td>$2,769</td>
                <td>0%</td>
                <td>2%</td>
              </tr>
              <tr>
                <td>فإن الجمعية </td>
                <td>$838</td>
                <td>$757</td>
                <td>11%</td>
                <td>11%</td>
              </tr>
              <tr>
                <td>
                  <i>فإن الجمعية العامة</i>
                </td>
                <td>
                  <i>30.1%</i>
                </td>
                <td>
                  <i>27.3%</i>
                </td>
                <td>
                  <i>280bp</i>
                </td>
                <td>
                  <i>230bp</i>
                </td>
              </tr>
              <tr>
                <td>فإن الجمعية العامة</td>
                <td>$0.60</td>
                <td>$0.47</td>
                <td>28%</td>
                <td>28%</td>
              </tr>
              <tr>
                <td>فإن الجمعية العامة</td>
                <td>$580</td>
                <td>$525</td>
                <td>10%</td>
                <td />
              </tr>
            </tbody>
          </table>
        </Table>
      </Section>
    </Language>
  ));
