import PropTypes from "prop-types";
import React, { Component } from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import getRehydratableName from "@emcm-ui/utility-rehydratable-name";

import NavList from "./components/NavList";
import NavItem from "./components/NavItem";
import SiteFooterSearch from "./SiteFooterSearch";

import { Provider as SetOpenMenuProvider } from "./setOpenMenuContext";
import { Provider as CurrentMenuProvider } from "./currentMenuContext";

class SiteFooter extends Component {
  static displayName = "SiteFooter";

  /* eslint-disable max-len */
  static propTypes = {
    /**
     * The SiteFooter contents. These should be `SiteFooter.List`s
     */
    children: PropTypes.node,

    /**
     * Whether or not the component is rehydrating. Do not use this.
     */
    isRehydrating: PropTypes.bool,

    /**
     * Search form action
     */
    searchFormAction: PropTypes.string.isRequired,

    /**
     * Search form method
     */
    searchFormMethod: PropTypes.string,

    /**
     * Search input name
     */
    searchInputName: PropTypes.string,

    /**
     * Search Typeahead URL
     */
    typeaheadUrl: PropTypes.string.isRequired,
    /**
     * JSON URL for elasticKey.
     */
    elasticKey: PropTypes.string,
    /**
     * JSON URL for autosuggestion.
     */
    autosuggestion: PropTypes.string,
    /**
     * JSON URL for size.
     */
    size: PropTypes.number,
    /**
     * minimum search character limit
     */
    searchMinLength: PropTypes.number,
    /**
     * placeholder text for the search input
     */
    placeholder: PropTypes.string
  };
  /* eslint-enable max-len */

  static defaultProps = {
    isRehydrating: false,
    searchFormMethod: "get",
    searchInputName: "q"
  };

  constructor(props) {
    super(props);

    this.getClassName = getClassNameFactory(SiteFooter.displayName);

    this.state = {
      currentMenu: null
    };

    this.setOpenMenu = this.setOpenMenu.bind(this);
  }

  setOpenMenu(menuId) {
    this.setState({
      currentMenu: menuId
    });
  }

  render() {
    const { children } = this.props;

    return (
      <SetOpenMenuProvider value={this.setOpenMenu}>
        <CurrentMenuProvider value={this.state.currentMenu}>
          <div
            className={this.getClassName()}
            data-rehydratable={getRehydratableName(SiteFooter.displayName)}
            data-search-form-action={this.props.searchFormAction}
            data-search-form-method={this.props.searchFormMethod}
            data-search-input-name={this.props.searchInputName}
            data-typeahead-url={this.props.typeaheadUrl}
            data-elastic-key={this.props.elasticKey}
            data-search-min-length={this.props.searchMinLength}
            data-autosuggestion={this.props.autosuggestion}
            data-size={this.props.size}
            data-placeholder={this.props.placeholder}
          >
            <div className={this.getClassName({ descendantName: "inner" })}>
              <SiteFooterSearch
                getClassName={this.getClassName}
                searchFormAction={this.props.searchFormAction}
                searchFormMethod={this.props.searchFormMethod}
                searchInputName={this.props.searchInputName}
                typeaheadUrl={this.props.typeaheadUrl}
                elasticKey={this.props.elasticKey}
                searchMinLength={this.props.searchMinLength}
                autosuggestion={this.props.autosuggestion}
                size={this.props.size}
                placeholder={this.props.placeholder}
              />

              <nav className={this.getClassName({ descendantName: "lists" })}>
                <ul
                  className={this.getClassName({
                    descendantName: "listsInner"
                  })}
                >
                  {children}
                </ul>
              </nav>
            </div>
          </div>
        </CurrentMenuProvider>
      </SetOpenMenuProvider>
    );
  }
}

SiteFooter.NavList = NavList;
SiteFooter.NavItem = NavItem;

export default SiteFooter;
