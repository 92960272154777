/* eslint-disable no-magic-numbers */

import React from "react";
import { storiesOf } from "@storybook/react";
import { withKnobs, number, text } from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";

import GoogleMapREADME from "../README.md";
import StaticRenderer from "@emcm-ui/component-static-renderer";
import GoogleMap from "./index";
import "./GoogleMap.css";

import Section from "@emcm-ui/component-section";

const stories = storiesOf("Components/GoogleMap", module);

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(GoogleMapREADME));

const getQueryParamValByName = param => {
  const params = new URLSearchParams(window.top.document.location.search);

  return params.get(param);
};

stories
  .add("Default", () => (
    <Section>
      <GoogleMap
        id="myMap"
        apiKey={text("apiKey", getQueryParamValByName("apiKey"))}
        latitude={number("latitude", 51.507032)}
        longitude={number("longitude", -0.003396)}
        zoom={number("zoom", 12)}
      />
    </Section>
  ))
  .add("w/o ID & Key", () => (
    <GoogleMap
      latitude={number("latitude", 51.507032)}
      longitude={number("longitude", -0.003396)}
    />
  ))
  .add("Sized", () => (
    <GoogleMap
      id="myMap"
      apiKey={text("apiKey", getQueryParamValByName("apiKey"))}
      latitude={number("latitude", 51.507032)}
      longitude={number("longitude", -0.003396)}
      zoom={number("zoom", 18)}
      height={number("height", 600)}
      width={number("width", 600)}
    />
  ))
  .add("Rehydrated", () => (
    <StaticRenderer>
      <GoogleMap
        id="myMapRehydrator"
        apiKey={text("apiKey", getQueryParamValByName("apiKey"))}
        latitude={number("latitude", 51.507032)}
        longitude={number("longitude", -0.003396)}
        zoom={number("zoom", 18)}
        height={number("height", 750)}
        width={number("width", 725)}
      />
    </StaticRenderer>
  ));
