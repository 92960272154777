/* eslint-disable no-magic-numbers */
import React from "react";
import { storiesOf } from "@storybook/react";
import { withKnobs, text, select } from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";
import StaticRenderer from "@emcm-ui/component-static-renderer";
import Chart from "./index";
import Grid from "@emcm-ui/component-grid";
import "./Chart.css";
import ChartREADME from "../README.md";

const stories = storiesOf("Components/Chart", module);
const defaultProps = {
  data: [65, 59, 80, 81, 56, 55, 40, 25, 80, 42, 50, 100],
  labels: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec"
  ]
};

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(ChartREADME));

const chartTypes = [
  "bar",
  "line",
  "radar",
  "doughnut",
  "pie",
  "polarArea",
  "bubble",
  "scatter"
];

stories
  .add("Default", () => <Chart {...defaultProps} />)
  .add("Bar", () => (
    <Chart
      {...defaultProps}
      colors={text("Chart Color", "#001eff")}
      chartLabel={text("Chart Label", "Usage Report")}
      chartTitle={text("Chart Title", "Bar Chart")}
      type={select("Type", chartTypes, "bar")}
      height={350}
      width={500}
    />
  ))
  .add("Line", () => (
    <Chart
      {...defaultProps}
      colors={text("Chart Color", "#001eff")}
      chartLabel={text("Chart Label", "Usage Report")}
      chartTitle={text("Chart Title", "Line Chart")}
      type={select("Type", chartTypes, "line")}
      height={350}
      width={500}
    />
  ))
  .add("Doughnut", () => (
    <Chart
      {...defaultProps}
      colors={[
        "#1FFFF1",
        "#F1FF1F",
        "#2EFFE2",
        "#E2FF2E",
        "#3DFFD3",
        "#D3FF3D",
        "#4CFFC4",
        "#5BFFB5",
        "#6AFFA6",
        "#A6FF6A",
        "#79FF97",
        "#97FF79"
      ]}
      chartLabel={text("Chart Label", "Usage Report")}
      chartTitle={text("Chart Title", "Line Chart")}
      doughnutTitle={text("Doughnut Title", "87 %")}
      doughnutSubTitle={text("Doughnut Sub-Title", "Total usage data")}
      type={select("Type", chartTypes, "doughnut")}
      height={350}
      width={500}
    />
  ))
  .add("Multiple charts with different inputs", () => (
    <div>
      <Grid variant="even-2" gutterVerticalSmall={true}>
        <Grid.Item>
          <Chart
            data={[65, 59, 80, 81, 56, 55, 40]}
            labels={["Jan", "Feb", "Mar", "Apr", "Jun"]}
            colors={text("Chart Color", "#001eff")}
            chartLabel={text("Chart Label", "Usage Report")}
            chartTitle={text("Chart Title", "Line User")}
            type={select("Type", chartTypes, "line")}
            height={350}
            width={500}
          />
        </Grid.Item>
        <Grid.Item>
          <Chart
            data={[45, 32, 60, 55, 80, 73]}
            labels={["Jan", "Feb", "Mar", "Apr", "Jun"]}
            colors={text("Chart Color", "#001eff")}
            chartLabel={text("Chart Label", "Usage Report")}
            chartTitle={text("Chart Title", "Bar Chart")}
            type={select("Type", chartTypes, "bar")}
            height={350}
            width={500}
          />
        </Grid.Item>
      </Grid>
      <Grid variant="even-2" gutterVerticalSmall={true}>
        <Grid.Item>
          <Chart
            {...defaultProps}
            colors={text("Chart Color", "#ffc900")}
            chartLabel={text("Chart Label", "Usage Report")}
            chartTitle={text("Chart Title", "Line Chart")}
            numberOfGrids={10}
            type={select("Type", chartTypes, "line")}
            height={550}
            width={500}
          />
        </Grid.Item>
        <Grid.Item>
          <Chart
            {...defaultProps}
            colors={text("Chart Color", "#ffc900")}
            chartLabel={text("Chart Label", "Usage Report")}
            chartTitle={text("Chart Title", "Bar Chart")}
            numberOfGrids={10}
            type={select("Type", chartTypes, "bar")}
            height={550}
            width={500}
          />
        </Grid.Item>
      </Grid>
    </div>
  ))
  .add("Rehydrated", () => (
    <StaticRenderer>
      <Chart {...defaultProps} />
    </StaticRenderer>
  ));
