import PropTypes from "prop-types";
import React, { Component } from "react";
import classNames from "classnames";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import getRehydratableName from "@emcm-ui/utility-rehydratable-name";
import SearchInput from "@emcm-ui/component-search-input";
import { SVGIcon } from "@emcm-ui/component-icon/lib/svg";

import Column from "./components/Column";
import FeaturedItem from "./components/FeaturedItem";
import Flyouts from "./components/Flyouts";
import Menu from "./components/Menu";
import NavList from "./components/NavList";
import NavItem from "./components/NavItem";
import Section from "./components/Section";
import SectionStack from "./components/SectionStack";

import { Provider as SetOpenMenuProvider } from "./setOpenMenuContext";
import { Provider as CurrentOpenMenuProvider } from "./currentOpenMenuContext";

const getFocusableDescendants = node => {
  /*
   * this is based on the code provided by:
   * https://www.w3.org/TR/wai-aria-practices-1.1/examples/dialog-modal/dialog.html
   * at `aria.Utils.isFocusable`
   */
  const selector = [
    "a:not([disabled])",
    "button:not([disabled])",
    "[href]",
    "input:not([disabled])",
    "select:not([disabled])",
    "textarea:not([disabled])",
    "[tabindex]:not([tabindex='-1'])"
  ].join(", ");

  return node.querySelectorAll(selector);
};

class SiteHeader extends Component {
  static displayName = "SiteHeader";

  static propTypes = {
    /**
     * The SiteHeader contents. These should be `SiteHeader.Menu`s
     */
    children: PropTypes.node,

    /**
     * URL for the logo link
     */
    logoHref: PropTypes.string.isRequired,

    /**
     * Alternative text for the logo
     */
    logoText: PropTypes.string,

    /**
     * path to logo file
     */
    logoPath: PropTypes.string,

    /**
     * Enable or disable search.
     */
    search: PropTypes.bool,

    /**
     * Search form action. If unspecified, will submit to the current page address.
     */
    searchFormAction: PropTypes.string,

    /**
     * Search form type
     */
    searchFormMethod: PropTypes.string,

    /**
     * Search input name
     */
    searchInputName: PropTypes.string,

    /**
     * Site name for the header.
     */
    siteName: PropTypes.string,

    /**
     * Search Typeahead URL. If unspecified, typeahead will be disabled.
     */
    typeaheadUrl: PropTypes.string,
    /**
     * Elastic Search
     */
    elasticKey: PropTypes.string,
    /**
     * autosuggestion
     */
    autosuggestion: PropTypes.string,
    /**
     * size
     */
    size: PropTypes.number,
    /**
     * minimum search character limit
     */
    searchMinLength: PropTypes.number,
    /**
     * placeholder text for the search input
     */
    placeholder: PropTypes.string
  };

  static defaultProps = {
    logoText: "LSEG",
    search: true,
    searchFormAction: null,
    searchFormMethod: "get",
    searchInputName: "q",
    typeaheadUrl: null
  };

  constructor(props) {
    super(props);

    this.state = {
      currentMenu: null,
      expanded: false,
      searching: false
    };

    this.bannerRef = React.createRef();
    this.logoRef = React.createRef();
    this.navRef = React.createRef();

    this.searchRef = null;
    this.buttonsRef = null;

    this.setOpenMenu = this.setOpenMenu.bind(this);
    this.handleSearchClick = this.handleSearchClick.bind(this);
    this.handleSearchBlur = this.handleSearchBlur.bind(this);
    this.handleToggleClick = this.handleToggleClick.bind(this);
  }

  handleSearchBlur(e) {
    // document.activeElement is IE11 fallback
    const relatedTarget = e.relatedTarget || document.activeElement;

    // This prevents blur from triggering an additional setState in the event
    // that the search button is tapped on mobile.
    if (
      relatedTarget &&
      ((this.searchRef && this.searchRef.contains(relatedTarget)) ||
        (this.buttonsRef && this.buttonsRef.contains(relatedTarget)))
    ) {
      return;
    }

    this.setState({
      searching: false
    });
  }

  handleSearchClick() {
    this.setState({
      searching: !this.state.searching
    });
  }

  handleToggleClick() {
    const expanded = !this.state.expanded;

    window.scrollTo(0, 0);

    if (expanded) {
      document.body.classList.add("u-bodyModalOpenNarrowOnly");
      document.addEventListener("focus", this.handleFocus, true);

      this.lastFocusEl = this.bannerRef;
      this.bannerRef.current.focus();
    } else {
      document.body.classList.remove("u-bodyModalOpenNarrowOnly");
      document.removeEventListener("focus", this.handleFocus, true);
    }

    this.setState({
      expanded
    });
  }

  setOpenMenu(menuId) {
    if (this.menusEl) {
      // Scroll back to 0, so that panels are in view and
      // cover the available space and can't be over-scrolled.
      this.menusEl.scrollTop = 0;
    }

    this.setState({
      currentMenu: menuId
    });
  }

  handleFocus = event => {
    const isTargetWithinModal = this.navRef.current.contains(event.target);

    if (isTargetWithinModal) {
      this.lastFocusEl = event.target;
      this.lastFocusEl.focus();

      return;
    }

    const focusableDialogDescendants = getFocusableDescendants(
      this.navRef.current
    );

    if (!focusableDialogDescendants.length) {
      this.navRef.current.focus();

      return;
    }

    if (this.lastFocusEl === focusableDialogDescendants[0]) {
      focusableDialogDescendants[focusableDialogDescendants.length - 1].focus();
    } else {
      focusableDialogDescendants[0].focus();
    }

    this.lastFocusEl = document.activeElement;
  };

  render() {
    const getClassName = getClassNameFactory("SiteHeader");

    return (
      <SetOpenMenuProvider value={this.setOpenMenu}>
        <CurrentOpenMenuProvider value={this.state.currentMenu}>
          <div
            role="banner"
            className={getClassName({
              states: classNames({
                expanded: this.state.expanded,
                expandedMenu: this.state.currentMenu !== null,
                searching: this.state.searching
              }),
              modifiers: classNames({
                noSearch: !this.props.search,
                withSiteName: Boolean(this.props.siteName)
              })
            })}
            data-logo-href={this.props.logoHref}
            data-logo-path={this.props.logoPath}
            data-logo-text={this.props.logoText}
            data-search={this.props.search}
            data-search-form-action={this.props.searchFormAction}
            data-search-form-method={this.props.searchFormMethod}
            data-search-input-name={this.props.searchInputName}
            data-rehydratable={getRehydratableName(SiteHeader.displayName)}
            data-typeahead-url={this.props.typeaheadUrl}
            data-elastic-key={this.props.elasticKey}
            data-search-min-length={this.props.searchMinLength}
            data-autosuggestion={this.props.autosuggestion}
            data-size={this.props.size}
            data-placeholder={this.props.placeholder}
            ref={this.bannerRef}
          >
            <nav
              className={getClassName({ descendantName: "inner" })}
              ref={this.navRef}
            >
              <a
                className={getClassName({ descendantName: "logo" })}
                href={this.props.logoHref}
                ref={this.logoRef}
              >
                {this.props.logoPath ? (
                  <div
                    className={getClassName({
                      descendantName: "logoContainer"
                    })}
                  >
                    <img
                      className={getClassName({ descendantName: "logoImg" })}
                      src={this.props.logoPath}
                      alt={this.props.logoText}
                    />
                  </div>
                ) : (
                  <div
                    className={getClassName({ descendantName: "logoInner" })}
                  >
                    <span
                      className={getClassName({
                        descendantName: "logoInnerLabel",
                        utilities: "hiddenVisually"
                      })}
                    >
                      {this.props.logoText}
                    </span>
                  </div>
                )}
              </a>

              {this.props.siteName ? (
                <span
                  className={getClassName({
                    descendantName: "siteName",
                    utilities: "typographySmallCaps"
                  })}
                >
                  {this.props.siteName}
                </span>
              ) : null}

              <div
                className={getClassName({ descendantName: "buttons" })}
                ref={ref => (this.buttonsRef = ref)}
              >
                <button
                  aria-label={this.state.expanded ? "Close Menu" : "Menu"}
                  className={getClassName({ descendantName: "toggleButton" })}
                  onClick={this.handleToggleClick}
                >
                  {this.state.expanded ? (
                    <SVGIcon name="close" size="s" />
                  ) : (
                    <SVGIcon name="menu" size="s" />
                  )}
                </button>
                {this.props.search ? (
                  <button
                    aria-label="Search"
                    className={getClassName({ descendantName: "searchButton" })}
                    onClick={this.handleSearchClick}
                  >
                    {this.state.searching ? (
                      <SVGIcon name="close" size="s" />
                    ) : (
                      <SVGIcon name="search" size="s" />
                    )}
                  </button>
                ) : null}
              </div>

              {this.state.searching ? null : (
                <ul
                  className={getClassName({ descendantName: "menus" })}
                  ref={ref => {
                    this.menusEl = ref;
                  }}
                >
                  {this.props.children}
                </ul>
              )}

              {this.state.searching ? (
                <div
                  className={getClassName({ descendantName: "search" })}
                  ref={ref => (this.searchRef = ref)}
                >
                  <form
                    action={this.props.searchFormAction}
                    type="get"
                    role="search"
                  >
                    <SearchInput
                      // When the user clicks the search button, and the search
                      // input appears, the expectation is that the input will be in
                      // focus.
                      autoFocus // eslint-disable-line jsx-a11y/no-autofocus
                      name={this.props.searchInputName}
                      typeahead={Boolean(this.props.typeaheadUrl)}
                      typeaheadUrl={this.props.typeaheadUrl}
                      onBlur={this.handleSearchBlur}
                      elasticKey={this.props.elasticKey}
                      searchMinLength={this.props.searchMinLength}
                      autosuggestion={this.props.autosuggestion}
                      size={this.props.size}
                      placeholder={this.props.placeholder}
                    />
                  </form>
                </div>
              ) : null}
            </nav>
          </div>
        </CurrentOpenMenuProvider>
      </SetOpenMenuProvider>
    );
  }
}

SiteHeader.Column = Column;
SiteHeader.FeaturedItem = FeaturedItem;
SiteHeader.Flyouts = Flyouts;
SiteHeader.Menu = Menu;
SiteHeader.NavList = NavList;
SiteHeader.NavItem = NavItem;
SiteHeader.Section = Section;
SiteHeader.SectionStack = SectionStack;

export default SiteHeader;
