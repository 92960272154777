import React from "react";
import { storiesOf } from "@storybook/react";
import { withKnobs, text } from "@storybook/addon-knobs";
import Image from "@emcm-ui/component-image";
import VideoPlayer from "@emcm-ui/component-video-player";
import StaticRenderer from "@emcm-ui/component-static-renderer";
import { Language } from "@emcm-ui/component-theme";
import Figure from "./index";
import "./Figure.css";

const stories = storiesOf("Components/Figure", module);

stories.addDecorator(withKnobs);

stories
  .add("Image only", () => (
    <div style={{ width: 500 }}>
      <Figure
        caption={
          <span>
            <strong>Hello:</strong> I am a caption for an image. I can also{" "}
            <a href="https://www.example.com/">link off to a separate page</a>.
          </span>
        }
      >
        <Image alt="Alt attribute" src="test-images/original/large_3.jpg" />
      </Figure>
    </div>
  ))
  .add("Image with expandable button", () => (
    <div style={{ width: 500 }}>
      <Figure
        caption={
          <span>
            <strong>Hello:</strong> I am a caption for an image. I can also{" "}
            <a href="https://www.example.com/">link off to a separate page</a>.
          </span>
        }
        expandable={true}
        // iconPath="./icons/ui/white/fullscreen.svg"
      >
        <Image alt="Alt attribute" src="test-images/original/large_3.jpg" />
      </Figure>
    </div>
  ))
  .add("Image with expandable button and expandable url", () => (
    <div style={{ width: 500 }}>
      <Figure
        caption={
          <span>
            <strong>Hello:</strong> I am a caption for an image. I can also{" "}
            <a href="https://www.example.com/">link off to a separate page</a>.
          </span>
        }
        expandable={true}
        expandableUrl="test-images/original/large_4.jpg"
        // iconPath="./icons/ui/white/fullscreen.svg"
      >
        <Image alt="Alt attribute" src="test-images/original/large_3.jpg" />
      </Figure>
    </div>
  ))
  .add("Rehydrated Figure with expandable button and attribution", () => (
    <div style={{ width: 500 }}>
      <StaticRenderer>
        <Figure
          caption={
            <span>
              <strong>Hello:</strong> I am a caption for an image. I can also{" "}
              <a href="https://www.example.com/">link off to a separate page</a>.
            </span>
          }
          attribution="REUTERS/John Smith"
          expandable={true}
          expandableUrl="test-images/original/large_4.jpg"
          // iconPath="./icons/ui/white/fullscreen.svg"
        >
          <Image alt="Alt attribute" src="test-images/original/large_3.jpg" />
        </Figure>
      </StaticRenderer>
    </div>
  ))
  .add("Image with attribution", () => (
    <div style={{ width: 500 }}>
      <Figure
        caption={
          <span>
            <strong>Hello:</strong> I am a caption for an image. I can also{" "}
            <a href="https://www.example.com/">link off to a separate page</a>.
          </span>
        }
        attribution={text("Attribution", "REUTERS/John Smith")}
      >
        <Image alt="Alt attribute" src="test-images/original/large_3.jpg" />
      </Figure>
    </div>
  ))
  .add("Image with attribution, right to left", () => (
    <Language code="ar">
      <div style={{ width: 500 }}>
        <Figure
          caption={
            <span>
              اعتُمد بموجب قرار الجمعية العامة 217 ألف (د-3) المؤرخ في 10 كانون
              الأول / ديسمبر 1948.
            </span>
          }
          attribution={text("Attribution", "الإعلان العالمي لحقوق الإنسان")}
        >
          <Image alt="Alt attribute" src="test-images/original/large_3.jpg" />
        </Figure>
      </div>
    </Language>
  ))
  .add("Multiple Images", () => (
    <div style={{ width: 500 }}>
      <Figure
        caption={
          <span>A caption for two images within the same figure element.</span>
        }
      >
        <Image alt="Image 1" src="test-images/original/large_3.jpg" />
        <Image alt="Image 2" src="test-images/original/large_4.jpg" />
      </Figure>
    </div>
  ))
  .add("Video", () => (
    <div style={{ width: 500 }}>
      <Figure
        caption={
          <span>
            <strong>Hello:</strong> I am a caption for an video. I can also{" "}
            <a href="https://www.example.com/">link off to a separate page</a>.
          </span>
        }
      >
        <VideoPlayer
          description="Eikon overview (1:36)"
          duration="PT1M36S"
          kind="youtube"
          playLabel="play video"
          videoId="JcaRpzbWxi8"
        >
          <Image
            alt="Placeholder image"
            src="placeholder-images/1920x1080.png"
            fit="cover"
          />
        </VideoPlayer>
      </Figure>
    </div>
  ));
