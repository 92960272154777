import React from "react";
import { storiesOf } from "@storybook/react";
import { action } from "@storybook/addon-actions";
import { withKnobs, boolean, select, text } from "@storybook/addon-knobs";
import { Language } from "@emcm-ui/component-theme";
import Section from "@emcm-ui/component-section";
import Button from "./Button.js";
import "./Button.css";
import { SVGIcon } from "@emcm-ui/component-icon/lib/svg";

const cartIcon = <SVGIcon name="cart" size="s" />;

const userIcon = <SVGIcon name="user" size="s" />;

const caretDownIcon = <SVGIcon name="caret" size="s" />;

const externalLinkIcon = <SVGIcon name="externallink" size="m" />;

const colorOptions = Button.colorOptions;
const buttonTypes = Button.buttonTypes;

const stories = storiesOf("Components/Button", module);

stories.addDecorator(withKnobs);

stories
  .add("Kind: Link", () => (
    <Section
      background={select(
        "Section background",
        [""].concat(Section.backgrounds)
      )}
    >
      <Button
        active={boolean("Active", false)}
        block={boolean("Block", false)}
        color={select("Color", colorOptions, colorOptions[0])}
        disabled={boolean("Disabled", false)}
        href="#_"
        icon={boolean("Icon", false) && externalLinkIcon}
        iconOnly={
          select("Color", colorOptions, colorOptions[0]) === colorOptions[1] &&
          select("Type", buttonTypes, buttonTypes[0]) === buttonTypes[1]
            ? false
            : boolean("Icon only", false)
        }
        kind="link"
        type={select("Type", buttonTypes, buttonTypes[0])}
        newWindow={boolean("newWindow", false)}
        ariaLabel="button"
      >
        {text("Text", "Button")}
      </Button>
    </Section>
  ))
  .add("Kind: Button", () => (
    <Section
      background={select(
        "Section background",
        [""].concat(Section.backgrounds)
      )}
    >
      <Button
        active={boolean("Active", false)}
        block={boolean("Block", false)}
        color={select("Color", colorOptions, colorOptions[0])}
        disabled={boolean("Disabled", false)}
        icon={boolean("Icon", false) && cartIcon}
        iconOnly={
          select("Color", colorOptions, colorOptions[0]) === colorOptions[1] &&
          select("Type", buttonTypes, buttonTypes[0]) === buttonTypes[1]
            ? false
            : boolean("Icon only", false)
        }
        kind="button"
        type={select("Type", buttonTypes, buttonTypes[0])}
      >
        {text("Text", "Button")}
      </Button>
    </Section>
  ))
  .add("Kind: Submit", () => (
    <Section
      background={select(
        "Section background",
        [""].concat(Section.backgrounds)
      )}
    >
      <Button
        active={boolean("Active", false)}
        block={boolean("Block", false)}
        color={select("Color", colorOptions, colorOptions[0])}
        disabled={boolean("Disabled", false)}
        icon={boolean("Icon", false) && cartIcon}
        iconOnly={
          select("Color", colorOptions, colorOptions[0]) === colorOptions[1] &&
          select("Type", buttonTypes, buttonTypes[0]) === buttonTypes[1]
            ? false
            : boolean("Icon only", false)
        }
        kind="submit"
        type={select("Type", buttonTypes, buttonTypes[0])}
      >
        {text("Text", "Button")}
      </Button>
    </Section>
  ))
  .add("Small Pill", () => (
    <Section
      background={select(
        "Section background",
        [""].concat(Section.backgrounds)
      )}
    >
      <Button
        active={boolean("Active", false)}
        block={boolean("Block", false)}
        color="secondary"
        disabled={boolean("Disabled", false)}
        icon={boolean("Icon", false) && cartIcon}
        kind="button"
        type={select("Type", buttonTypes, buttonTypes[1])}
      >
        {text("Text", "Button")}
      </Button>
    </Section>
  ))
  .add("Utility navigation", () => (
    <Section
      background={select(
        "Section background",
        [""].concat(Section.backgrounds)
      )}
    >
      <Button
        active={boolean("Active", false)}
        color="secondary"
        userIcon={userIcon}
        icon={boolean("Dropdown", false) && caretDownIcon}
        kind="button"
        type={"utility"}
      >
        {text("Text", "Sign in / Register")}
      </Button>
    </Section>
  ))
  .add("Utility navigation (authenticated)", () => (
    <Section
      background={select(
        "Section background",
        [""].concat(Section.backgrounds)
      )}
    >
      <Button
        active={boolean("Active", false)}
        color="secondary"
        userIcon={userIcon}
        icon={caretDownIcon}
        kind="button"
        type="utility"
        authentication="authenticated"
      >
        {text("Text", "Hi [first name]")}
      </Button>
    </Section>
  ))

  .add("Language: Arabic", () => (
    <Language code="ar">
      <Section
        background={select(
          "Section background",
          [""].concat(Section.backgrounds)
        )}
      >
        <Button
          active={boolean("Active", false)}
          block={boolean("Block", false)}
          color={select("Color", colorOptions, colorOptions[0])}
          disabled={boolean("Disabled", false)}
          icon={boolean("Icon", false) && cartIcon}
          iconOnly={boolean("Icon only", false)}
          kind="button"
        >
          {text("Text", "اضغط الزر")}
        </Button>
      </Section>
    </Language>
  ))
  .add("Language: Japanese", () => (
    <Language code="ja">
      <Section
        background={select(
          "Section background",
          [""].concat(Section.backgrounds)
        )}
      >
        <Button
          active={boolean("Active", false)}
          block={boolean("Block", false)}
          color={select("Color", colorOptions, colorOptions[0])}
          disabled={boolean("Disabled", false)}
          icon={boolean("Icon", false) && cartIcon}
          iconOnly={boolean("Icon only", false)}
          kind="button"
        >
          {text("Text", "ボタンを押す")}
        </Button>
      </Section>
    </Language>
  ))
  .add("Language: Korean", () => (
    <Language code="ko">
      <Section
        background={select(
          "Section background",
          [""].concat(Section.backgrounds)
        )}
      >
        <Button
          active={boolean("Active", false)}
          block={boolean("Block", false)}
          color={select("Color", colorOptions, colorOptions[0])}
          disabled={boolean("Disabled", false)}
          icon={boolean("Icon", false) && cartIcon}
          iconOnly={boolean("Icon only", false)}
          kind="button"
        >
          {text("Text", "누름 단추")}
        </Button>
      </Section>
    </Language>
  ))
  .add("Language: Russian", () => (
    <Language code="ru">
      <Section
        background={select(
          "Section background",
          [""].concat(Section.backgrounds)
        )}
      >
        <Button
          active={boolean("Active", false)}
          block={boolean("Block", false)}
          color={select("Color", colorOptions, colorOptions[0])}
          disabled={boolean("Disabled", false)}
          icon={boolean("Icon", false) && cartIcon}
          iconOnly={boolean("Icon only", false)}
          kind="button"
        >
          {text("Text", "Нажать кнопку")}
        </Button>
      </Section>
    </Language>
  ))
  .add("Language: Chinese (simple)", () => (
    <Language code="zh-Hans">
      <Section
        background={select(
          "Section background",
          [""].concat(Section.backgrounds)
        )}
      >
        <Button
          active={boolean("Active", false)}
          block={boolean("Block", false)}
          color={select("Color", colorOptions, colorOptions[0])}
          disabled={boolean("Disabled", false)}
          icon={boolean("Icon", false) && cartIcon}
          iconOnly={boolean("Icon only", false)}
          kind="button"
        >
          {text("Text", "按钮")}
        </Button>
      </Section>
    </Language>
  ))
  .add("Language: Chinese (traditional)", () => (
    <Language code="zh-Hant">
      <Section
        background={select(
          "Section background",
          [""].concat(Section.backgrounds)
        )}
      >
        <Button
          active={boolean("Active", false)}
          block={boolean("Block", false)}
          color={select("Color", colorOptions, colorOptions[0])}
          disabled={boolean("Disabled", false)}
          icon={boolean("Icon", false) && cartIcon}
          iconOnly={boolean("Icon only", false)}
          kind="button"
        >
          {text("Text", "按鈕")}
        </Button>
      </Section>
    </Language>
  ))
  .add("open modal window", () => (
    <Button
      color={select("Color", colorOptions, colorOptions[0])}
      contentLocation="https://www.mocky.io/v2/5b8527613000008100729143"
      href="https://example.com"
      kind="link"
      modalLabel="modal example"
    >
      Open modal window
    </Button>
  ))
  .add("onClick callback", () => (
    <Section
      background={select(
        "Section background",
        [""].concat(Section.backgrounds)
      )}
    >
      <Button
        active={boolean("Active", false)}
        block={boolean("Block", false)}
        color={select("Color", colorOptions, colorOptions[0])}
        disabled={boolean("Disabled", false)}
        icon={boolean("Icon", false) && cartIcon}
        iconOnly={boolean("Icon only", false)}
        kind="button"
        onClick={action("Button clicked")}
      >
        {text("Text", "Button")}
      </Button>
    </Section>
  ));
