import React from "react";
import { storiesOf } from "@storybook/react";
import { withKnobs } from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";
import StaticRenderer from "@emcm-ui/component-static-renderer";
import KalturaVideoPlaylist from "./KalturaVideoPlaylist";
import VideoPlaylistREADME from "../README.md";
import "./KalturaVideoPlaylist.css";
import { text, object } from "@storybook/addon-knobs/react";

import VideoPlayer from "@emcm-ui/component-video-player";
import Image from "@emcm-ui/component-image";

const url = window.location.href;
const params = new URL(url);
const paramsVideoId = params.searchParams.get("videoId");
const videoId = paramsVideoId ? paramsVideoId : "1_fsj13mxl";
const playlist = [
  {
    id: "1_qysmwsgx",
    title: "HTML 5 Video. This is for testing.",
    duration: 74
  },
  {
    id: "1_fsj13mxl",
    title: "Javascript Video. This is for testing.",
    duration: 1114
  },
  {
    id: "1_e56uvn1b",
    title: "ReactJS Video. This is for testing.",
    duration: 683
  }
];
const playlistPlayerContainer = {
  display: "flex",
  width: "100%"
};
const playerContainer = {
  width: "50%"
};
const playlistContainer = {
  paddingLeft: "24px",
  width: "50%"
};

const stories = storiesOf("Components/KalturaVideoPlaylist", module);

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(VideoPlaylistREADME));

stories
  .add("Default", () => (
    <KalturaVideoPlaylist
      playerId={text("playerId", "KalturaPlayerId")}
      playlistId={text("playlistId", "4")}
      playlist={object("playlist", playlist)}
      userId={text("userId", "1234234")}
      videoId={text("videoId", videoId)}
      url={text("url", url)}
    />
  ))
  .add("Rehydrated", () => (
    <StaticRenderer>
      <KalturaVideoPlaylist
        playerId={text("playerId", "KalturaPlayerId")}
        playlistId={text("playlistId", "4")}
        playlist={object("playlist", playlist)}
        userId={text("userId", "1234234")}
        videoId={text("videoId", videoId)}
        url={text("url", url)}
      />
    </StaticRenderer>
  ))
  .add("Playlist with video player", () => (
    <div style={playlistPlayerContainer}>
      <div style={playerContainer}>
        <VideoPlayer
          duration={text("Duration", "PT1M56S")}
          kind="kaltura"
          autoplayInModal={true}
          allowAutoplay={true}
          kaltura={`{
            "style": {"width":"100%","height":"300px"},
            "script":"https://cdnapi.kaltura.com/p/2591911/sp/259191100/embedIframeJs/uiconf_id/48936922/partner_id/2591911",
            "targetId":"KalturaPlayerId",
            "wid":"_2591911",
            "uiconf_id":48936922,
            "flashvars":{
              "autoPlay":true
            },
            "entry_id":"${videoId}"
          }`}
        >
          <Image
            alt="Placeholder image"
            fit="cover"
            src="placeholder-images/1920x1080.png"
          />
        </VideoPlayer>
      </div>
      <div style={playlistContainer}>
        <KalturaVideoPlaylist
          playerId={text("playerId", "KalturaPlayerId")}
          playlistId={text("playlistId", "4")}
          playlist={object("playlist", playlist)}
          userId={text("userId", "1234234")}
          videoId={text("videoId", videoId)}
          url={text("url", url)}
        />
      </div>
    </div>
  ));
