import PropTypes from "prop-types";
import React, { Component } from "react";
import classNames from "classnames";
import Eyebrow from "@emcm-ui/component-eyebrow";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import { SVGIcon } from "@emcm-ui/component-icon/lib/svg";

const iconTypes = ["arrowRight", "document", "download", "external"];
const sizes = ["small", "large"];

class ListOfLinksBlockLink extends Component {
  static displayName = "ListOfLinks.BlockLink";

  /* eslint-disable max-len */
  static propTypes = {
    /**
     * Title text.
     */
    children: PropTypes.string.isRequired,

    /**
     * Description text.
     */
    description: PropTypes.string,

    /**
     * Metadata node.
     */
    metadata: PropTypes.node,

    /**
     * Eyebrow text for the title. Not visible on small BlockLinks.
     */
    eyebrow: PropTypes.string,

    /**
     * URL.
     */
    href: PropTypes.string.isRequired,

    /**
     * Color
     */
    color: PropTypes.string,

    /**
     * Meta Data Line One
     */
    eventMonth: PropTypes.string,

    /**
     * Meta Data Line Two
     */
    eventDay: PropTypes.string,

    /**
     * Open link in new tab/window.
     */
    newWindow: PropTypes.bool,

    /**
     * SVG Icon
     */
    icon: PropTypes.node,

    /**
     * Size of the BlockLink. Small will hide the eyebrow.
     */
    size: PropTypes.oneOf(sizes)
  };
  /* eslint-enable max-len */

  static defaultProps = {
    color: "blue",
    newWindow: false,
    size: "large",
    icon: (
      <SVGIcon name="arrow" style={{ transform: "rotate(270deg)" }} size="s" />
    )
  };

  static iconTypes = iconTypes;
  static sizes = sizes;

  constructor(props) {
    super(props);

    this.getClassName = getClassNameFactory(ListOfLinksBlockLink.displayName);
  }

  render() {
    const {
      children,
      metadata,
      description,
      eyebrow,
      color,
      href,
      eventMonth,
      eventDay,
      newWindow,
      icon,
      size
    } = this.props;

    const linkAttributes = {
      href
    };

    const withEventData = eventMonth || eventDay;
    const isDescriptionEnabled = description && description.length > 0;
    const eventData = (
      <div className={this.getClassName({ descendantName: "eventData" })}>
        <div className={this.getClassName({ descendantName: "eventMonth" })}>
          {eventMonth}
        </div>
        <div className={this.getClassName({ descendantName: "eventDay" })}>
          {eventDay}
        </div>
      </div>
    );

    if (newWindow) {
      linkAttributes.target = "_blank";
      linkAttributes.rel = "noopener";
    }

    return (
      <li
        className={this.getClassName({
          modifiers: classNames({
            [size]: true,
            withEventData
          })
        })}
      >
        <a
          className={this.getClassName({ descendantName: "link" })}
          {...linkAttributes}
        >
          <div
            className={this.getClassName({ descendantName: "inner" })}
            role="menuitem"
          >
            {eyebrow && eyebrow.length > 0 ? (
              <div className={this.getClassName({ descendantName: "eyebrow" })}>
                <Eyebrow text={eyebrow} />
              </div>
            ) : null}
            <div className={this.getClassName({ descendantName: "body" })}>
              {withEventData && eventData}
              <div className={this.getClassName({ descendantName: "text" })}>
                <div className={this.getClassName({ descendantName: "title" })}>
                  {children}
                </div>
                {metadata && (
                  <div
                    className={this.getClassName({
                      descendantName: "metadata"
                    })}
                  >
                    {metadata}
                  </div>
                )}
                {isDescriptionEnabled && (
                  <div
                    className={this.getClassName({
                      descendantName: "description"
                    })}
                  >
                    {description}
                  </div>
                )}
              </div>
              {color === "grey" ? (
                <div
                  className={this.getClassName({ descendantName: "icon" })}
                  focusable="false"
                  aria-hidden="true"
                >
                  {icon}
                </div>
              ) : (
                <div
                  className={this.getClassName({
                    descendantName: "icon",
                    modifiers: "--blue"
                  })}
                  focusable="false"
                  aria-hidden="true"
                >
                  {icon}
                </div>
              )}
            </div>
          </div>
        </a>
      </li>
    );
  }
}

export default ListOfLinksBlockLink;
