import PropTypes from "prop-types";
import React from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import TextCardBase from "../../bases/TextCardBase";
import Image, { anchorPoints } from "@emcm-ui/component-image";

const ImageCard = ({
  eyebrow,
  footer,
  imgAnchor,
  imgGradient,
  imgSrc,
  imgSrcSet,
  heading,
  headingRank,
  href,
  largeHeading,
  size
}) => {
  const getClassName = getClassNameFactory(ImageCard.displayName);

  return (
    <div className={getClassName()}>
      <TextCardBase
        anchor="bottom"
        borderless
        eyebrow={eyebrow}
        footer={footer}
        heading={heading}
        headingRank={headingRank}
        href={href}
        size={size}
        largeHeading={largeHeading}
        renderContent={content => (
          <div className={getClassName({ descendantName: "content" })}>
            <div className={getClassName({ descendantName: "background" })}>
              <div
                className={getClassName({ descendantName: "backgroundImage" })}
              >
                <Image
                  alt=""
                  anchor={imgAnchor}
                  fit="cover"
                  overlay={imgGradient ? "scrimBottomDark" : null}
                  // This assumes the card is used in a 2/3 grid column. We make
                  // this assumption as we can't identify the context in which
                  // the card is used. This will slightly affect performance, as
                  // larger images may be loaded than necessary.
                  sizes={Image.sizes.twoThirds}
                  src={imgSrc}
                  srcSet={imgSrcSet}
                />
              </div>
            </div>

            <div className={getClassName({ descendantName: "contentInner" })}>
              {content}
            </div>
          </div>
        )}
      />
    </div>
  );
};

ImageCard.displayName = "ImageCard";

ImageCard.sizes = TextCardBase.sizes;
ImageCard.headingRanks = TextCardBase.headingRanks;

/* eslint-disable max-len */
ImageCard.propTypes = {
  /**
   * Eyebrow text
   */
  eyebrow: PropTypes.string.isRequired,
  /**
   * Footer. Should be a valid card footer, like ArticleCardFooter.
   */
  footer: PropTypes.node,
  /**
   * Heading text
   */
  heading: PropTypes.string.isRequired,
  /**
   * Heading rank. 1 will render `<h1 />`, and so on. Defaults to 3.
   */
  headingRank: PropTypes.oneOf(ImageCard.headingRanks),
  /**
   * Card URL
   */
  href: PropTypes.string.isRequired,
  /**
   * Anchor point for the image.
   */
  imgAnchor: PropTypes.oneOf(anchorPoints),
  /**
   * Apply a gradient to the image. Should not be used if the image has a rasterized gradient.
   */
  imgGradient: PropTypes.bool,
  /**
   * Image `src`. Used if srcSet is not defined, or as a fallback.
   */
  imgSrc: PropTypes.string.isRequired,
  /**
   * A comma-separated list indicating a set of possible image sources for the image. The image will be fit to cover. This is used by the user agent to determine the correct image for different screen sizes. Same format as [srcSet](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/img#Example_4_Using_the_srcset_and_sizes_attributes), i.e. `my-image-200.png 200w, my-image-200.png 200w`.
   */
  imgSrcSet: PropTypes.string,
  /**
   * Enable large heading font size
   */
  largeHeading: PropTypes.bool,
  /**
   * Size of the Card. Will set the minimum height and grow to fit content.
   */
  size: PropTypes.oneOf(ImageCard.sizes).isRequired
};
/* eslint-enable max-len */

ImageCard.defaultProps = {
  headingRank: "3",
  imgGradient: false,
  imgAnchor: "C",
  largeHeading: false
};

export default ImageCard;
